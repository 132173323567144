import { FontWeights, NativeList, noWrap, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConfidentialByProviderText } from "../investigations/side-panel/ConfidentialByProviderText.tsx";
import {
  EN_DASH,
  formatDateStringToTime,
  ObservationItemProps,
  ObservationsLabels
} from "./utils.ts";

export const GroupByExaminationObservationsView: React.FC<
  ObservationItemProps
> = ({ primaryKey, observations }) => {
  const { core } = useStores();
  const hasBloodPressureOrPulse = observations.some(
    m =>
      m.typeLabel === ObservationsLabels.BloodPressure ||
      m.typeLabel === ObservationsLabels.PulseBpmInTreeView
  );

  const renderBPAndPulseList = () => (
    <Stack tokens={{ childrenGap: 4 }}>
      <Stack horizontal verticalAlign="center">
        <Text
          styles={{
            root: {
              fontWeight: FontWeights.semibold
            }
          }}
        >
          {primaryKey}
        </Text>
      </Stack>
      <NativeList>
        {observations.map(observation => {
          let displayText = "";
          const labelText = observation.label
            ? ` ${EN_DASH} ${observation.label}`
            : "";

          switch (observation.typeLabel) {
            case ObservationsLabels.BloodPressure:
              displayText =
                observation.systolic && observation.diastolic
                  ? `${observation.systolic}/${observation.diastolic}`
                  : observation.value;
              break;

            case ObservationsLabels.PulseBpmInTreeView:
              displayText = observation.pulse
                ? `${observation.pulse} bpm`
                : `${observation.value} bpm`;
              break;

            default:
              displayText = observation.value;
              break;
          }

          const timestamp = formatDateStringToTime(observation.timestamp);

          return (
            <li key={observation.id}>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 4 }}
              >
                <Text>
                  {timestamp} {EN_DASH}{" "}
                </Text>
                <Text
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold
                    }
                  }}
                >
                  {displayText}
                </Text>
                <Text>{labelText}</Text>
              </Stack>
            </li>
          );
        })}
      </NativeList>
    </Stack>
  );

  const renderOtherObservations = () => {
    const isSingleObservationForDate = observations.length === 1;
    const formattedDate = DateTime.fromISO(observations[0].timestamp).toFormat(
      "dd/MM/yy"
    );

    return (
      <Stack verticalAlign="center">
        {observations.length > 1 && (
          <Stack horizontal verticalAlign="center">
            <Text>{primaryKey}</Text>
          </Stack>
        )}

        {isSingleObservationForDate ? (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <Text>{`${formattedDate}${EN_DASH}`}</Text>
            {core.hasAccessToSecGroup(observations[0].secGroupId) ? (
              <Text
                styles={{
                  root: {
                    fontWeight: FontWeights.semibold
                  }
                }}
              >
                {observations[0].value}
              </Text>
            ) : (
              <ConfidentialByProviderText
                secGroupId={observations[0].secGroupId ?? ""}
                showIcon
                styles={{
                  root: {
                    ...noWrap
                  }
                }}
              />
            )}
          </Stack>
        ) : (
          observations.map(observation => {
            return (
              <Stack horizontal verticalAlign="center" key={observation.id}>
                <Text
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold
                    }
                  }}
                >
                  {core.hasAccessToSecGroup(observations[0].secGroupId) ? (
                    <Text bold>{observation.value}</Text>
                  ) : (
                    <ConfidentialByProviderText
                      secGroupId={observations[0].secGroupId ?? ""}
                      showIcon
                      styles={{
                        root: {
                          ...noWrap
                        }
                      }}
                    />
                  )}
                </Text>
              </Stack>
            );
          })
        )}
      </Stack>
    );
  };

  return (
    <Stack
      key={primaryKey}
      styles={{
        root: { marginLeft: 32 }
      }}
    >
      {hasBloodPressureOrPulse
        ? renderBPAndPulseList()
        : renderOtherObservations()}
    </Stack>
  );
};
