import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { PresetButtonType, Stack, Text } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { BhbOnlineApptType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { BhbProvidersOnlineFilter } from "./BhbProvidersCard.types.ts";

type BhbProvidersFilterProps = Pick<
  FilterBarProps<BhbProvidersOnlineFilter>,
  "children"
> & {
  providers: BhbProvider[];
  apptTypes: BhbOnlineApptType[];
  singleProvider: boolean | undefined;
};

export const BhbProvidersFilter: FunctionComponent<BhbProvidersFilterProps> =
  observer(({ children, apptTypes, providers, singleProvider }) => {
    const nameOf = nameOfFactory<BhbProvidersOnlineFilter>();
    const onlineAvailabilityOptions = [
      {
        key: "ShowAndBook",
        text: "Show & book"
      },
      {
        key: "ShowAndCall",
        text: "Show & call"
      },
      {
        key: "DontShow",
        text: "Don't show"
      }
    ];

    const providerOptions = providers.map(provider => {
      return {
        key: provider.id,
        text: provider.displayName
      };
    });

    const onlineAvailabilityPresetValues = ["ShowAndBook", "ShowAndCall"];

    const presets: PresetButtonType<BhbProvidersOnlineFilter>[] = [
      {
        name: nameOf("displayedOnlinePreset"),
        text: "Displayed online",
        iconName: "GlobeFavorite",
        tooltip: <Text>Show only displayed online appointment types</Text>,
        tooltipPresses: (
          <Stack horizontalAlign="center">
            <Text>Showing appointment types available online only.</Text>
            <Text>Release to show all</Text>
          </Stack>
        ),
        valuesToBeSetOnToggleOn: {
          onlineAvailability: onlineAvailabilityPresetValues
        },
        valuesToBeSetOnToggleOff: {
          onlineAvailability: undefined
        }
      }
    ];

    const items: FilterBarProps<BhbProvidersOnlineFilter>["items"] = [];

    if (!singleProvider) {
      items.push({
        name: nameOf("search"),
        type: "searchBox",
        stickItem: true,
        props: {
          id: "providers-filter-searchBox",
          placeholder: "Search",
          styles: { root: { width: 300 } },
          removeSpecialCharacters: false
        }
      });
    }

    items.push(
      {
        type: "optionsSelect",
        name: nameOf("appointmentTypes"),
        props: {
          id: "providers-filter-appointmentTypes",
          placeholder: "Appointment type",
          styles: { root: { width: 155 } },
          options: apptTypes,
          multiSelect: true,
          hideSearchOption: false,
          calloutWidth: 300
        }
      },
      {
        type: "optionsSelect",
        name: nameOf("onlineAvailability"),
        props: {
          id: "providers-filter-onlineAvailability",
          placeholder: "Online availability",
          styles: { root: { width: 150 } },
          options: onlineAvailabilityOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 200
        }
      }
    );

    if (!singleProvider) {
      items.push({
        type: "optionsSelect",
        name: nameOf("providers"),
        props: {
          id: "providers-filter-onlineproviders",
          placeholder: "Providers",
          styles: { root: { width: 100 } },
          options: providerOptions,
          multiSelect: true,
          hideSearchOption: false,
          calloutWidth: 300
        }
      });
    }

    return (
      <FilterBar<BhbProvidersOnlineFilter>
        initialValues={{
          search: "",
          onlineAvailability: onlineAvailabilityPresetValues,
          displayedOnlinePreset: true
        }}
        items={items}
        presets={presets}
        styles={{ searchBox: { minWidth: 206 } }}
      >
        {children}
      </FilterBar>
    );
  });
