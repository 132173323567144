import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useScrollToViewById
} from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";

import { UserScreenCardKeys } from "../../UserScreen.types.ts";
import { UserOnlineBookingProfileFormContext } from "./UserOnlineBookingProfileFormContext.ts";

interface UserOnlineBookingProfileSettingsWarningProps {
  user: User;
}

export const UserOnlineBookingProfileSettingsWarning = observer(
  ({ user }: UserOnlineBookingProfileSettingsWarningProps) => {
    const disableShowAndBook = !(
      (user.userSetting?.showOnCalendar ?? false) &&
      user.status === UserStatus.Active
    );

    const { userAvailability, saveUserSetting } = useContext(
      UserOnlineBookingProfileFormContext
    );

    const scroll = useScrollToViewById();

    if (!disableShowAndBook) return null;

    const onClick = () => {
      if (!!userAvailability?.schedules?.length) {
        user.userSetting && saveUserSetting(user.userSetting);
      } else {
        scroll(UserScreenCardKeys.AppointmentSettings);
      }
    };

    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <Text
            styles={{
              root: { fontSize: FontSizes.size12 }
            }}
          >
            Online profile display won't take effect until appointment book
            display is activated
          </Text>
          <Link onClick={onClick}>Show user on appointment book</Link>
        </Stack>
      </MessageBar>
    );
  }
);
