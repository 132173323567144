import { Observer } from "mobx-react-lite";

import { MessageBar, Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import { ObservationType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { ClincialToolViewData } from "../clinical-tool-view/ClinicalToolViewDataFetcher.ts";
import { getClinicalToolViewData } from "../clinical-tool-view/utils.ts";
import { DASS21Questions } from "./DASS21Questions.tsx";

export interface DASS21QuestionnaireViewProps {
  encounterId: string;
}

export const DASS21QuestionnaireView: React.FC<
  DASS21QuestionnaireViewProps
> = ({ encounterId }) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <DataFetcher<ClincialToolViewData>
      fetch={getClinicalToolViewData(encounterId, ObservationType.DASS21)}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        observations,
        answers,
        summary,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <Observer>
          {() => {
            return (
              <>
                <div
                  ref={r => {
                    if (r && !element) {
                      setElement(r);
                    }
                  }}
                />
                <ClinicalToolView
                  title="DASS 21"
                  encounterId={encounterId}
                  isConfidential={isConfidential}
                  lastUpdatedDate={lastUpdatedDate}
                  lastUpdatedUserId={lastUpdatedUserId}
                  summary={summary}
                  onRenderSummary={() => {
                    const summaries = [];

                    if (!observations) {
                      return undefined;
                    }

                    const depression = observations.find(
                      x => x.summary && x.summary.includes("Depression")
                    );

                    const anxiety = observations.find(
                      x => x.summary && x.summary.includes("Anxiety")
                    );

                    const stress = observations.find(
                      x => x.summary && x.summary.includes("Stress")
                    );

                    if (depression) summaries.push(depression);
                    if (anxiety) summaries.push(anxiety);
                    if (stress) summaries.push(stress);

                    const summariesWithValues = summaries
                      .map(x => {
                        return `${x.summary} (${x.values[0].value})`;
                      })
                      .join("; ");

                    return <MessageBar>{summariesWithValues}</MessageBar>;
                  }}
                >
                  <DASS21Questions
                    questionnaire={questionnaire}
                    width={panelWidth}
                    answers={answers}
                    questionPrefix="q"
                  />
                </ClinicalToolView>
              </>
            );
          }}
        </Observer>
      )}
    </DataFetcher>
  );
};
