import { FunctionComponent, useContext, useState } from "react";
import { useField } from "react-final-form";

import {
  GenericSelect,
  GenericSelectProps,
  mergeStyleSets,
  Option
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ServiceSearchDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { getInvoiceItem } from "@stores/billing/utils/billing.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { getEmptyInvoiceItemFormValue } from "../utils.ts";
import { ServicePickerInputFieldSuggestionItem } from "./ServiceSelectFieldSuggestionItem.tsx";

interface ServiceSelectFieldProps
  extends Pick<GenericSelectProps, "placeholder" | "styles"> {
  name: string;
  useDescription?: boolean;
}

export const ServiceSelectField: FunctionComponent<
  ServiceSelectFieldProps
> = props => {
  const { name, useDescription, ...selectProps } = props;

  const { billing } = useStores();
  const { setIsAddItemDialogVisible } = useContext(InvoiceFormContext);
  const { input } = useField<InvoiceItemFormValue>(name, {
    subscription: { value: true }
  });

  const service = input.value.serviceSearch;
  const serviceDate = input.value.serviceDate;
  const selectedKey = input.value.serviceId;

  const getSchedules = async (scheduleIds: string[]) => {
    const schedulesToGet = scheduleIds.filter(
      id => !billing.schedulesMap.has(id)
    );
    if (schedulesToGet.length > 0) {
      await billing.getSchedules({ scheduleIds: schedulesToGet });
    }
  };

  const [services, setServices] = useState<ServiceSearchDto[]>([]);

  const onSearchItem = async (filter: string) => {
    const results = await billing.getServiceSearch({
      searchText: filter,
      effectiveDate: DateTime.jsDateToISODate(serviceDate),
      excludeDescription: true
    });

    const uniqueScheduleIds = Array.from(
      new Set(results.map(({ scheduleId }) => scheduleId))
    );

    await getSchedules(uniqueScheduleIds);

    setServices(results);
  };

  const onChange = (serviceId: string) => {
    const item = services.find(service => service.serviceId === serviceId);

    const updatedItem = item
      ? getInvoiceItem({
          service: item,
          serviceDate,
          gstPercent: billing.gstPercent
        })
      : getEmptyInvoiceItemFormValue(serviceDate);

    input.onChange({
      ...updatedItem,
      // persist these values on change
      comment: input.value.comment,
      purchaseOrderNumber: input.value.purchaseOrderNumber
    });
  };

  const onRenderSuggestionsItem = (
    option: Option<ServiceSearchDto>
  ): JSX.Element => {
    return <ServicePickerInputFieldSuggestionItem item={option.data!} />;
  };

  let calloutServices = services;
  if (service) {
    calloutServices = services.filter(
      otherService => otherService.serviceId !== service.serviceId
    );
    calloutServices.unshift(service);
  }

  const options: Option<ServiceSearchDto>[] = calloutServices.map(data => ({
    key: data.serviceId,
    text: useDescription ? data.name || data.description || "" : data.code,
    data
  }));

  const onRenderCalloutFooter = (renderDefault: () => JSX.Element) => (
    <>
      <Navigate
        onClick={() => setIsAddItemDialogVisible(true)}
        styles={{ root: { flexGrow: 1, paddingLeft: 4 } }}
      >
        Advanced search
      </Navigate>
      {renderDefault()}
    </>
  );

  return (
    <GenericSelect
      {...selectProps}
      searchBoxProps={{ onSearch: onSearchItem }}
      calloutWidth={500}
      options={options}
      selectedKeys={selectedKey}
      onChangeSelectedKeys={onChange}
      onRenderOption={onRenderSuggestionsItem}
      onRenderCalloutFooter={onRenderCalloutFooter}
      hideSelectAllButton
      styles={mergeStyleSets(
        {
          singleOption: { padding: 0 },
          fieldContent: { justifyContent: "flex-start" }
        },
        props.styles
      )}
    />
  );
};
