import { isEqual } from "lodash";
import React from "react";
import { useField, useForm } from "react-final-form";

import {
  ActionButtonWithMaxedReached,
  AnimatedListWithKeys,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { sideFilter, terminologyFilter } from "../utils.ts";
import { DiagnosisField } from "./DiagnosisField.tsx";
import { DiagnosisHeader } from "./DiagnosisHeader.tsx";

interface DiagnosisFormFieldsBaseProps {
  arrayFieldName: string;
  isClaimAdjustment?: boolean;
  showStatusCol: boolean;
  maxDiagnosisCount?: number;
  emptyDiagnosis: () => ClaimDiagnosisDto;
  diagnoses?: ClaimDiagnosisFormValues[];
  disabled?: boolean;
}

const DiagnosisFormFieldsBase: React.FC<DiagnosisFormFieldsBaseProps> = ({
  arrayFieldName,
  isClaimAdjustment,
  showStatusCol,
  maxDiagnosisCount,
  emptyDiagnosis,
  diagnoses,
  disabled
}) => {
  const {
    input: { value: diagnosisFields }
  } = useField(arrayFieldName, {
    isEqual,
    subscription: { value: true }
  });

  const { change } = useForm();

  return (
    <FieldArray name={arrayFieldName}>
      {({ fields }) => {
        if (!fields.value.length) return null;
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            <Fieldset frame styles={{ root: { padding: "0px 0px 8px" } }}>
              <DiagnosisHeader
                styles={{
                  root: {
                    paddingRight: 48
                  }
                }}
                showStatusCol={showStatusCol}
              />
              <Stack
                styles={{
                  root: {
                    minHeight: 32,
                    padding: "0px 8px 0px 8px"
                  }
                }}
              >
                <AnimatedListWithKeys
                  items={fields.value}
                  onItemIdRemoved={id => {
                    const index = fields.value.findIndex(v => v.id === id);
                    fields.remove(index);
                  }}
                >
                  {(id, onRemoveItem) => {
                    const index = fields.value.findIndex(v => v.id === id);
                    const value = fields.value[index];
                    const showWarningMessage = value.aCCAcceptable === false;
                    const showNonStandardBadge =
                      isClaimAdjustment &&
                      fields.value[index].diagnosisCode &&
                      fields.value[index].aCC32Acceptable === false;
                    if (value && index >= 0) {
                      return (
                        <div
                          className={mergeStyles({
                            "&:not(:first-child)": {
                              marginTop: 8
                            }
                          })}
                          key={fields.value[index].id}
                        >
                          <DiagnosisField
                            name={`${arrayFieldName}[${index}]`}
                            showStatusCol={showStatusCol}
                            showNonStandardBadge={showNonStandardBadge}
                            filters={{
                              terminologyFilter: terminologyFilter(
                                diagnoses
                                  ? [...Array.from(fields.value), ...diagnoses]
                                  : Array.from(fields.value),
                                Array.from(fields.value)[index]
                              ),
                              sideFilter: sideFilter(
                                diagnoses
                                  ? [...Array.from(fields.value), ...diagnoses]
                                  : Array.from(fields.value),
                                Array.from(fields.value)[index]
                              )
                            }}
                            button={
                              <Stack
                                styles={{
                                  root: {
                                    flexBasis: 34
                                  }
                                }}
                              >
                                <DeleteButton
                                  onClick={() => {
                                    if (diagnosisFields.length !== 1)
                                      onRemoveItem();
                                    else {
                                      // when there is only 1 item, reset the values, don't remove the item.
                                      change(`${arrayFieldName}[0]` as any, {
                                        id: diagnosisFields[0].id
                                      });
                                    }
                                  }}
                                />
                              </Stack>
                            }
                            disabled={disabled}
                          />

                          {showWarningMessage && (
                            <MessageBar
                              messageBarType={MessageBarType.warning}
                              messageBarIconProps={{ iconName: "Error" }}
                              styles={{ root: { marginTop: 8 } }}
                            >
                              Non-standard diagnosis; ACC may reject this
                              diagnosis
                            </MessageBar>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }}
                </AnimatedListWithKeys>
              </Stack>
            </Fieldset>
            <ActionButtonWithMaxedReached
              styles={{ root: { alignSelf: "baseline" } }}
              text="Add another diagnosis"
              onClick={() => fields.push(emptyDiagnosis())}
              maxCountReached={
                !!maxDiagnosisCount && fields.length === maxDiagnosisCount
              }
              disabled={disabled}
            />
          </Stack>
        );
      }}
    </FieldArray>
  );
};

export const DiagnosisFormFields = withFetch(
  x => [x.acc.ref.diagnosisStatuses.load(), x.acc.ref.diagnosisSides.load()],
  DiagnosisFormFieldsBase
);
