import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, MouseEvent, useContext } from "react";

import {
  ActionButton,
  ContextualMenu,
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  IContextualMenuItem,
  IContextualMenuListProps,
  IContextualMenuProps,
  IIconProps,
  mergeStyles,
  PersonaSize,
  useTheme
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AppointmentStatusCode,
  CalendarEventPriority,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { getAppointmentStatusText } from "@modules/booking/screens/booking-calendar/components/shared-components/appointment-status/utils.ts";
import {
  isAppointmentDeletable,
  isAppointmentEditable
} from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { useBookingCalendarScreenContext } from "../../../context/BookingCalendarScreenContext.tsx";
import { invoiceStatusIcon } from "../BookingCalendarEvent.types.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { AppointmentNoticesItem } from "./AppointmentNoticesItem.tsx";
import { AppointmentNoticesSubMenuItem } from "./AppointmentNoticesSubMenuItem.tsx";
import { AppointmentStatusItem } from "./AppointmentStatusItem.tsx";
import { AttendeeInvoiceSubMenu } from "./AttendeeInvoiceSubMenu.tsx";
import { AttendeeReminderSubMenu } from "./AttendeeReminderSubMenu.tsx";
import { ContextualMenuClaimItem } from "./ContextualMenuClaimItem.tsx";
import {
  ClaimSubMenuKeys,
  ContextualMenuClaimSubMenu
} from "./ContextualMenuClaimSubMenu.tsx";
import { EventTypeInfo } from "./EventTypeInfo.tsx";
import { GroupAppointmentMenuItem } from "./GroupAppointmentMenuItem.tsx";
import { SubMenuSeparator } from "./SubMenuSeparator.tsx";
import { TimeRange } from "./TimeRange.tsx";

type BookingEventContextualMenuProps = Omit<
  IContextualMenuProps,
  "children" | "items"
>;
enum DividerKeyEnum {
  persona = "persona-divider",
  statuses = "statuses-divider",
  reminder = "reminder-divider"
}

const IgnoreDividerKeys: DividerKeyEnum[] = [DividerKeyEnum.persona];
const BookingEventContextualMenuBase: FC<BookingEventContextualMenuProps> =
  observer(calloutProps => {
    const root = useStores();
    const theme = useTheme();
    const {
      core,
      routing,
      booking,
      practice: {
        ui: { showContactDetails }
      },
      notification
    } = root;

    const patientLabel = usePatientLabel(false);

    const {
      setCurrentOccurrenceId,
      setCurrentRecurrenceId,
      setReminderArgs,
      showCalendarEventDialog
    } = useBookingCalendarScreenContext();

    const {
      calendarEvent,
      changeAppointmentStatus,
      currentAppointmentStatus,
      eventType,
      pastDayAppointment,
      futureDayAppointment,
      isAppointment,
      isUnavailableType,
      isSeries,
      isRecurrenceDeleted,
      showCancelEvent,
      getStates,
      addClaimBeforeFormDeploy,
      loadCanStatusChange,
      getClinicalMenuItems,
      enabled,
      startPatientMatchWorkflow
    } = useContext(BookingCalendarEventContext);

    const { canShowPatientMatchReview } = calendarEvent;
    const noHoverStyles = {
      root: {
        cursor: "default",
        "&:hover": {
          backgroundColor: "none"
        },
        "&:active": {
          backgroundColor: "none"
        }
      }
    };

    const onClickPrevent = (evt: MouseEvent<HTMLElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
    };

    const {
      startDateTime,
      appointmentStatus,
      hasEncounter,
      sortedAttendeesAsContacts
    } = calendarEvent;

    const isAppointmentValidForEdit = isAppointmentEditable(
      startDateTime,
      appointmentStatus
    );

    const isAppointmentValidForDelete = isAppointmentDeletable(
      startDateTime,
      appointmentStatus,
      hasEncounter
    );

    const canSendReminder =
      core.hasPermissions(Permission.SendApptRemindersAllowed) && isAppointment;

    const canSendInvoice =
      core.hasPermissions(Permission.InvoiceCreate) &&
      isAppointment &&
      !futureDayAppointment &&
      calendarEvent.contactId &&
      calendarEvent.contact &&
      calendarEvent.contact.type === ContactType.Patient;

    const canStartOrViewConsult =
      core.hasPermissions([
        Permission.ClinicalRead,
        Permission.EncounterRead
      ]) && calendarEvent.type !== CalendarEventType.Unavailable;

    const MAX_ATTENDEE_DISPLAY = 10;
    const getAttendeesPersona = () => {
      let contextMenuItems: IContextualMenuItem[] = [];

      if (calendarEvent.isGroupAppointment) {
        contextMenuItems = sortedAttendeesAsContacts.map(attendee => {
          const appointmentStatuses = core.hasPermissions(
            Permission.CalendarEventWrite
          )
            ? [...booking.ref.appointmentStatuses.keyTextValues]
            : [];

          const showClinicalSub =
            canStartOrViewConsult &&
            (calendarEvent.type !== CalendarEventType.Meeting || enabled);

          const canShowChevronIcon =
            appointmentStatuses.length > 0 ||
            showClinicalSub ||
            canSendInvoice ||
            canSendReminder;

          return {
            key: attendee.id,
            id: attendee.id,
            onRenderContent: () => (
              <>
                <Persona
                  key={attendee.id}
                  text={attendee.name}
                  id={attendee.id}
                  size={PersonaSize.size24}
                  imageInitials={attendee.initials}
                  styles={{
                    root: { width: 200, margin: "16px 4px" }
                  }}
                  coinProps={{
                    styles: { initials: { fontSize: "10px" } }
                  }}
                />

                {canShowChevronIcon && (
                  <FontIcon
                    styles={{ root: { fontSize: 12 } }}
                    iconName="ChevronRight"
                  />
                )}
              </>
            ),
            subMenuProps: {
              items:
                appointmentStatuses.length > 0
                  ? appointmentStatuses
                  : [
                      {
                        key: "attendee-persona-stab",
                        className: mergeStyles({
                          height: 0
                        })
                      }
                    ],
              onRenderMenuList: (
                listProps: IContextualMenuListProps,
                defaultRender
              ) => {
                const currentAttendeeApptStatus =
                  calendarEvent.attendeesPatientAndContact.find(
                    a => a.attendeeId === attendee.id
                  )?.attendeeStatus;
                if (!defaultRender) return null;
                return (
                  <>
                    {appointmentStatuses.length > 0 && (
                      <AppointmentStatusItem
                        listProps={listProps}
                        defaultRender={defaultRender!}
                        onDismiss={calloutProps.onDismiss}
                        currentAppointmentStatus={
                          currentAttendeeApptStatus ||
                          AppointmentStatusCode.Booked
                        }
                        changeAppointmentStatus={async (
                          newStatusCode,
                          currentStatusCode
                        ) => {
                          if (newStatusCode !== currentStatusCode) {
                            await booking.updateCalendarEventAttendeeStatusChange(
                              calendarEvent.id,
                              attendee.id,
                              newStatusCode as AppointmentStatusCode
                            );
                          }
                        }}
                      />
                    )}
                    {appointmentStatuses.length > 0 && showClinicalSub && (
                      <SubMenuSeparator
                        listProps={listProps}
                        defaultRender={defaultRender}
                        key={DividerKeyEnum.statuses}
                      />
                    )}
                    {showClinicalSub &&
                      defaultRender({
                        ...listProps,
                        items: [...getClinicalMenuItems(attendee.id)]
                      })}
                    {(appointmentStatuses.length > 0 || showClinicalSub) &&
                      (canSendReminder || canSendInvoice) && (
                        <SubMenuSeparator
                          listProps={listProps}
                          defaultRender={defaultRender}
                          key={DividerKeyEnum.reminder}
                        />
                      )}
                    {canSendReminder && (
                      <AttendeeReminderSubMenu
                        listProps={listProps}
                        defaultRender={defaultRender}
                        contactId={attendee.id}
                      />
                    )}
                    {canSendInvoice && (
                      <AttendeeInvoiceSubMenu
                        listProps={listProps}
                        defaultRender={defaultRender}
                        contactId={attendee.id}
                      />
                    )}
                  </>
                );
              }
            }
          };
        });
      }

      return contextMenuItems;
    };

    const onClickEditThisEvent = () => {
      if (isUnavailableType) {
        setCurrentOccurrenceId(calendarEvent.id);
      } else {
        showCalendarEventDialog({
          type: calendarEvent.type,
          id: calendarEvent.id
        });
        runInAction(() => {
          booking.ui.isEditSingleEvent = true;
        });
      }
    };

    const onClickEditAppointment = () => {
      showCalendarEventDialog({
        type: calendarEvent.type,
        id: calendarEvent.id
      });
    };

    const formMenus = useFormMenu(calendarEvent.contact, {
      claimId: calendarEvent?.claimId,
      addContextBeforeDeployAction: async (
        selectedTemplate,
        currentContext
      ) => {
        currentContext.CalendarEventId = calendarEvent.id;
        return addClaimBeforeFormDeploy(selectedTemplate, currentContext);
      }
    });

    const getContextualMenuItems = () => {
      const contextMenuItems: IContextualMenuItem[] = [];

      // ⚠️ it is needed here because of a bug in Fluent UI with
      // the first menu item with subMenu https://github.com/microsoft/fluentui/issues/18488
      /** Stab*/
      if (calendarEvent.contact) {
        contextMenuItems.push({
          key: "stab",
          className: mergeStyles({
            height: 0
          })
        });
      }

      if (calendarEvent.isGroupAppointment) {
        contextMenuItems.push({
          key: "group-description-or-max",

          onRenderContent: () => {
            return (
              <GroupAppointmentMenuItem
                onClickDescription={
                  isSeries && !isRecurrenceDeleted.get()
                    ? onClickEditThisEvent
                    : onClickEditAppointment
                }
              />
            );
          },
          subMenuProps: {
            items: [
              {
                key: "group-description-or-max-sub-menu",
                onRender: () => <AppointmentNoticesSubMenuItem />
              }
            ]
          }
        });
      } else {
        /** Appointment notices */
        calendarEvent.contact &&
          contextMenuItems.push({
            key: "notes",
            onRenderContent: () => {
              return <AppointmentNoticesItem />;
            },
            subMenuProps: {
              items: [
                {
                  key: "notes-sub-menu",
                  onRender: () => <AppointmentNoticesSubMenuItem />
                }
              ]
            }
          });
        if (canShowPatientMatchReview) {
          contextMenuItems.push({
            key: "review-unmatched-patient",
            onRenderContent: () => {
              return (
                <ActionButton
                  iconProps={{
                    iconName: "ProfileSearch",
                    styles: {
                      root: {
                        color: theme.palette.redDark,
                        fontSize: FontSizes.size16,
                        marginLeft: 0
                      }
                    }
                  }}
                  styles={{ label: { color: theme.semanticColors.link } }}
                  text={`${ContextMenuItemsEnum.ReviewUnmatchedPatient} ${patientLabel}`}
                  onClick={startPatientMatchWorkflow}
                />
              );
            }
          });
        }
      }

      /** Event time range */
      contextMenuItems.push({
        key: "time-range",
        onRenderContent: () => <TimeRange />,
        itemProps: {
          styles: noHoverStyles
        },
        onClick: onClickPrevent
      });

      /** Event info */
      contextMenuItems.push({
        key: "event-type-info",
        onRenderContent: () => <EventTypeInfo />,
        itemProps: {
          styles: noHoverStyles
        },
        onClick: onClickPrevent
      });

      contextMenuItems.push({
        key: "divider1",
        itemType: ContextualMenuItemType.Divider
      });

      /** Appointment Status */

      /**
       * The code has been removed around setting appointment reminder from the context menu.
       * Please check PR section of the ticket below if you need to bring it back.
       * Product Backlog Item 35758: Remove appointment reminder options from Appt book right click menu
       */

      const afterAppointmentStatusChange = () => {
        calloutProps.onDismiss && calloutProps.onDismiss();
        if (canShowPatientMatchReview) startPatientMatchWorkflow();
      };

      if (isAppointment && core.hasPermissions(Permission.CalendarEventWrite)) {
        if (currentAppointmentStatus === AppointmentStatusCode.Booked) {
          contextMenuItems.push({
            key: "arrivePatient",
            text: ContextMenuItemsEnum.Arrive,
            iconProps: { iconName: "FollowUser" },
            disabled: futureDayAppointment,
            onClick: () => {
              changeAppointmentStatus(AppointmentStatusCode.Waiting).then(
                afterAppointmentStatusChange
              );
            }
          });
        }
        if (currentAppointmentStatus === AppointmentStatusCode.Waiting) {
          contextMenuItems.push({
            key: "unarrivePatient",
            text: ContextMenuItemsEnum.UnArrive,
            iconProps: { iconName: "FollowUser" },
            onClick: () => {
              changeAppointmentStatus(AppointmentStatusCode.Booked).then(
                afterAppointmentStatusChange
              );
            }
          });
        }

        const currentStatusText =
          booking.ref.appointmentStatuses.map.get(currentAppointmentStatus)
            ?.text ??
          getAppointmentStatusText(
            AppointmentStatusCode.Booked,
            ContextMenuItemsEnum.Booked,
            calendarEvent.priority === CalendarEventPriority.Urgent
          );

        const useStatusText =
          currentAppointmentStatus === AppointmentStatusCode.Booked
            ? currentStatusText
            : getAppointmentStatusText(
                currentAppointmentStatus,
                currentStatusText,
                calendarEvent.priority === CalendarEventPriority.Urgent
              );

        contextMenuItems.push({
          key: "changeStatus",
          text: useStatusText,
          subMenuProps: {
            items: booking.ref.appointmentStatuses.keyTextValues,
            onRenderMenuList: (
              listProps: IContextualMenuListProps,
              defaultRender
            ) => (
              <AppointmentStatusItem
                listProps={listProps}
                defaultRender={defaultRender!}
                onDismiss={calloutProps.onDismiss}
                changeAppointmentStatus={changeAppointmentStatus}
                currentAppointmentStatus={currentAppointmentStatus}
              />
            )
          },
          iconProps: { iconName: "FollowUser" }
        });

        contextMenuItems.push({
          key: "divider2",
          itemType: ContextualMenuItemType.Divider
        });
      }

      /** Claim */
      if (
        core.tenantDetails!.country === Country.NewZealand &&
        core.hasPermissions(Permission.ClaimRead) &&
        !calendarEvent.isGroupAppointment &&
        calendarEvent.isPatientCalendarEvent
      ) {
        const submenuItems = [
          {
            key: ClaimSubMenuKeys.openClaim,
            text: ContextMenuItemsEnum.Open
          },
          {
            key: ClaimSubMenuKeys.print,
            text: "Print patient claim form"
          },
          {
            key: ClaimSubMenuKeys.printSummary,
            text: "Print summary form"
          }
        ];

        if (core.hasPermissions(Permission.ClaimWrite)) {
          submenuItems.push({
            key: ClaimSubMenuKeys.emailSummary,
            text: "Email to client"
          });
        }

        contextMenuItems.push({
          ...dataAttribute(
            DataAttributes.Element,
            "claim-event-context-reminder-btn"
          ),
          key: "claim",
          onRenderContent: props => (
            <ContextualMenuClaimItem itemProp={props} />
          ),
          iconProps: {
            iconName: "DocumentSet"
          },
          subMenuProps: {
            onRenderMenuList: (
              listProps: IContextualMenuListProps | undefined,
              defaultRender
            ) => (
              <ContextualMenuClaimSubMenu
                listProps={listProps}
                defaultRender={defaultRender}
              />
            ),
            items: submenuItems
          }
        });
        contextMenuItems.push({
          key: "divider3",
          itemType: ContextualMenuItemType.Divider
        });
      }

      // get attendees personas
      const personaMenuItems = getAttendeesPersona();
      if (personaMenuItems.length > MAX_ATTENDEE_DISPLAY) {
        //reduce persona items then move the spare ones to a new array.
        const reducedPersonaMenuItems = personaMenuItems.slice(
          0,
          MAX_ATTENDEE_DISPLAY - 1
        );

        const sparePersonalMenuItems = personaMenuItems.slice(
          MAX_ATTENDEE_DISPLAY - 1,
          personaMenuItems.length
        );

        contextMenuItems.push(...reducedPersonaMenuItems);
        contextMenuItems.push({
          key: "attendeeViewMore",
          subMenuProps: { items: sparePersonalMenuItems },
          onRenderContent: () => (
            <>
              <Persona
                text="View more"
                size={PersonaSize.size24}
                imageInitials={`+${sparePersonalMenuItems.length}`}
                styles={{
                  root: { width: 200, margin: "16px 4px" }
                }}
                coinProps={{
                  initialsColor: theme.palette.neutralSecondaryAlt,
                  styles: {
                    initials: {
                      fontSize: "10px"
                    }
                  }
                }}
              />
              <FontIcon
                styles={{ root: { fontSize: 12 } }}
                iconName="ChevronRight"
              />
            </>
          )
        });
        contextMenuItems.push({
          key: "divider3",
          itemType: ContextualMenuItemType.Divider
        });
      } else {
        contextMenuItems.push(...personaMenuItems);
        if (
          personaMenuItems.length > 0 &&
          personaMenuItems.length <= MAX_ATTENDEE_DISPLAY
        ) {
          contextMenuItems.push({
            key: DividerKeyEnum.persona,
            itemType: ContextualMenuItemType.Divider
          });
        }
      }

      if (canSendReminder && !calendarEvent.isGroupAppointment) {
        contextMenuItems.push({
          id: "booking-event-context-reminder-btn",
          key: "reminder",
          text: ContextMenuItemsEnum.SendApptReminder,
          iconProps: {
            iconName: "CellPhone"
          },
          onClick: () => {
            setReminderArgs({ calendarEventId: calendarEvent.id });
          }
        });
      }
      if (
        isAppointment &&
        calendarEvent.contactId &&
        !calendarEvent.isGroupAppointment
      ) {
        const seeResponses = {
          key: "seeResponses",
          text: "See responses",
          onClick: () => {
            calendarEvent.contactId &&
              showContactDetails(calendarEvent.contactId, PatientCardIds.forms);
          }
        };

        const sendFormOptions: IContextualMenuItem[] = [
          ...formMenus,
          seeResponses
        ];

        contextMenuItems.push({
          id: "booking-event-context-forms-btn",
          key: "forms",
          iconProps: { iconName: "ClipboardList" },
          text: ContextMenuItemsEnum.Forms,
          subMenuProps: { items: sendFormOptions }
        });
      }
      if (canSendInvoice && !calendarEvent.isGroupAppointment) {
        const { invoiceId, invoiceStatus } = calendarEvent;
        const invoiceExists =
          invoiceId &&
          invoiceStatus &&
          invoiceStatus !== InvoiceStatus.Cancelled;

        const iconProps: IIconProps = invoiceExists
          ? {
              iconName: invoiceStatusIcon[invoiceStatus],
              styles: ({ theme }) => ({
                root: { color: theme?.palette.neutralPrimary }
              })
            }
          : { iconName: "M365InvoicingLogo" };

        const onClick = () => {
          if (invoiceExists && invoiceId) {
            routing.push(
              routes.accounts.invoices.invoice.path({ id: invoiceId }),
              root.routing.getStateWithFromQuery()
            );
          } else {
            openNewInvoiceFromCalendar(root, calendarEvent.id);
          }
        };

        contextMenuItems.push({
          ...dataAttribute(
            DataAttributes.Element,
            "booking-event-context-invoice-btn"
          ),
          key: "invoice",
          text: ContextMenuItemsEnum.Invoice,
          iconProps,
          onClick
        });
      }
      if (canStartOrViewConsult && !calendarEvent.isGroupAppointment) {
        if (calendarEvent.type !== CalendarEventType.Meeting || enabled) {
          if (calendarEvent.contact) {
            contextMenuItems.push({
              key: "patientCard",
              id: "booking-event-context-patientCard-btn",
              text: ContextMenuItemsEnum.OpenPatientRecord,
              iconProps: { iconName: "ContactCard" },
              subMenuProps: {
                calloutProps: {
                  calloutMaxWidth: 200
                },
                items: getClinicalMenuItems(calendarEvent.contact.id)
              }
            });
          }
        }
      }

      const editSubMenuOptions = [
        {
          key: "editThisEvent",
          text: "This event",
          onClick: onClickEditThisEvent
        }
      ];

      if (isUnavailableType) {
        editSubMenuOptions.push({
          key: "allEventsInTheSeries",
          text: "All events in the series",
          onClick: () => {
            runInAction(() => {
              setCurrentRecurrenceId(calendarEvent.calendarEventRecurrenceId);
            });
          }
        });
      } else if (!pastDayAppointment) {
        editSubMenuOptions.push({
          key: "editThisAndFollowing",
          text: "This and the following events",
          onClick: onClickEditAppointment
        });
      }

      if (core.hasPermissions(Permission.CalendarEventWrite)) {
        contextMenuItems.push(
          isSeries && !isRecurrenceDeleted.get()
            ? {
                key: "Edit",
                id: "booking-event-context-edit-btn",
                text: `${ContextMenuItemsEnum.Edit}`,
                iconProps: { iconName: "Edit" },
                subMenuProps: {
                  items: [...editSubMenuOptions]
                }
              }
            : {
                key: "Edit",
                id: "booking-event-context-edit-btn",
                text: `${ContextMenuItemsEnum.Edit} ${eventType.toLowerCase()}`,
                iconProps: { iconName: "Edit" },
                disabled: isAppointmentValidForEdit,
                onClick: onClickEditAppointment
              }
        );

        const cancelSubMenuOptions = [
          {
            key: "cancelThisEvent",
            text: "This event",
            onClick: () => {
              booking.ui.setShowCancelCalendarEventDialog(
                true,
                calendarEvent.id
              );
            }
          }
        ];

        if (isUnavailableType) {
          cancelSubMenuOptions.push({
            key: "cancelAllEventsInTheSeries",
            text: "All events in the series",
            onClick: () => {
              booking.ui.setCancelCalendarEventRecurrenceId(
                calendarEvent.calendarEventRecurrenceId
              );
              booking.ui.setShowCancelCalendarEventDialog(
                true,
                calendarEvent.id
              );
            }
          });
        } else if (!pastDayAppointment) {
          cancelSubMenuOptions.push({
            key: "cancelThisAndFollowing",
            text: "This and the following events",
            onClick: () => {
              booking.ui.setCancelCalendarEventRecurrenceId(
                calendarEvent.calendarEventRecurrenceId
              );
              booking.ui.setShowCancelCalendarEventDialog(
                true,
                calendarEvent.id
              );
            }
          });
        }

        contextMenuItems.push(
          isSeries && !isRecurrenceDeleted.get()
            ? {
                key: "Delete",
                id: "booking-event-context-delete-btn",
                text: ContextMenuItemsEnum.Cancel,
                iconProps: { iconName: "Delete" },
                subMenuProps: {
                  items: [...cancelSubMenuOptions]
                }
              }
            : {
                key: "deleteEvent",
                text: `${
                  ContextMenuItemsEnum.Cancel
                } ${eventType.toLowerCase()}`,
                iconProps: { iconName: "Delete" },
                onClick: showCancelEvent,
                disabled: isAppointmentValidForDelete
              }
        );
      }

      return contextMenuItems
        .filter(
          (item, index) =>
            !(
              IgnoreDividerKeys.includes(item.key as DividerKeyEnum) &&
              index === contextMenuItems.length - 1
            )
        )
        .map(item => item);
    };

    const loadCalendarSeries = async () => {
      if (calendarEvent.calendarEventRecurrenceId) {
        const recurrence = await booking.getRecurrence(
          calendarEvent.calendarEventRecurrenceId
        );
        recurrence.seriesId &&
          (await booking.getCalendarSeries(recurrence.seriesId, true));
      }
    };

    return (
      <DataFetcher
        refetchId={calendarEvent.id}
        fetch={async () => {
          await Promise.all([
            getStates(),
            loadCanStatusChange(),
            loadCalendarSeries()
          ]);
        }}
        renderError={notification.error}
        fallback={null}
      >
        {() => (
          <ContextualMenu
            {...calloutProps}
            gapSpace={5}
            id="booking-event-context"
            items={getContextualMenuItems()}
          />
        )}
      </DataFetcher>
    );
  });

export const BookingEventContextualMenu = withFetch(
  x => [
    x.booking.ref.appointmentStatuses.load(),
    x.booking.ref.calendarEventReminderStatuses.load()
  ],
  BookingEventContextualMenuBase,
  { fallback: null }
);
