import { FC } from "react";

import {
  applyOpacityToHexColor,
  FontIcon,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

interface LocationOptionProps {
  color: string | undefined;
  text: string;
  disabled?: boolean;
  showIcon?: boolean;
  showInactiveBadge?: boolean | undefined;
  showDefaultLocationBadge?: boolean;
}
export const LocationOption: FC<LocationOptionProps> = ({
  color,
  text,
  disabled,
  showIcon = true,
  showInactiveBadge = false,
  showDefaultLocationBadge = false
}) => {
  const theme = useTheme();
  const getIconColor = () => {
    if (disabled) {
      return applyOpacityToHexColor(color ?? theme.palette.neutralTertiary, 25);
    } else if (showInactiveBadge) {
      return theme.palette.neutralSecondaryAlt;
    }
    return color;
  };

  const getTooltipText = () => {
    if (text.length > 40) {
      return text;
    }
    return undefined;
  };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      styles={{ root: { width: "100%" } }}
      wrap
    >
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
        {showIcon && (
          <FontIcon
            iconName="LocationFill"
            styles={{ root: { color: getIconColor() } }}
          />
        )}
        <Text
          nowrap
          styles={{
            root: {
              color: disabled ? theme.semanticColors.disabledText : "inherit"
            }
          }}
        >
          <TooltipHost content={getTooltipText()}>{text}</TooltipHost>
        </Text>
      </Stack>
      <Stack horizontal horizontalAlign="end" verticalAlign="center">
        {showInactiveBadge && (
          <TextBadge
            badgeColor={TextBadgeColor.grey}
            badgeSize={TextBadgeSize.small}
          >
            Inactive
          </TextBadge>
        )}
        {showDefaultLocationBadge && (
          <TextBadge
            badgeColor={TextBadgeColor.blue}
            badgeSize={TextBadgeSize.small}
            styles={{ root: { flexGrow: 1 } }}
          >
            Default
          </TextBadge>
        )}
        {disabled && <span>(in use)</span>}
      </Stack>
    </Stack>
  );
};
