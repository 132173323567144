import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const StatementLastGeneratedMessageBar = () => {
  return (
    <DataFetcher fetch={({ billing }) => billing.getLastDateStatement()}>
      {res => {
        const date =
          res === "0" || res === undefined ? undefined : DateTime.fromISO(res);
        if (!date) return null;

        return (
          <MessageBar messageBarType={MessageBarType.info}>
            Last statement run was {date.toDayDefaultFormat()}
          </MessageBar>
        );
      }}
    </DataFetcher>
  );
};
