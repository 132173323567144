import React from "react";
import { FormSpy } from "react-final-form";

import {
  MessageBar,
  MessageBarType,
  Separator,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ClinicalDataTypeLabel,
  CodedFieldClinicalDataItemDto,
  PSFSContextClinicalDataItemDto,
  QuestionnaireDto,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TerminologyPickerField } from "@modules/clinical/screens/patient-record/components/clinical-form/TerminologyPickerField.tsx";
import { getSideOfBodyText } from "@modules/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ClinicalToolReferences } from "../ClinicalTool.types.ts";
import { ClinicalToolInformation } from "../shared-components/ClinicalToolInformation.tsx";
import { ReadCodesHint } from "../shared-components/ReadCodesHint.tsx";
import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import { psfsNameOf } from "./PSFSForm.types.ts";
import { PSFSQuestions } from "./PSFSQuestions.tsx";

export interface PSFSBaselineViewProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  context?: PSFSContextClinicalDataItemDto;
  isActiveDiagnosisSideExist?: () => boolean;
}

export const PSFSBaselineView: React.FunctionComponent<
  PSFSBaselineViewProps
> = ({ questionnaire, answers, context, isActiveDiagnosisSideExist }) => {
  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();

  let diagnoses = clinicalRecord.episodeOfCare?.diagnoses
    ? clinicalRecord.episodeOfCare?.diagnoses?.find(d => d.isPrimaryDiagnosis)
    : clinicalRecord.clinicalData?.diagnoses?.diagnoses?.find(
        d => d.isPrimaryDiagnosis
      );

  const primaryMedicalHistory = clinicalRecord.medicalHistories.find(
    x => x.isPrimary && x.episodeOfCareId === clinicalRecord.episodeOfCare?.id
  );

  if (!diagnoses) {
    diagnoses = {
      diagnosisCode: primaryMedicalHistory?.diagnosisCode,
      diagnosisSide: primaryMedicalHistory?.diagnosisSide,
      isPrimaryDiagnosis: true
    };
  }

  const savedValue = {
    codeSystem: diagnoses?.diagnosisCode?.codeSystem ?? "",
    version: diagnoses?.diagnosisCode?.version ?? ""
  };

  const terminology: CodedFieldClinicalDataItemDto =
    diagnoses &&
    diagnoses.diagnosisCode?.code &&
    diagnoses.diagnosisCode?.originalText
      ? {
          code: diagnoses.diagnosisCode?.code,
          originalText: diagnoses.diagnosisCode?.originalText,
          ...savedValue
        }
      : context?.diagnosis ?? {
          code: context?.diagnosis.code!,
          originalText: context?.diagnosis.originalText!,
          ...savedValue
        };

  return (
    <Stack>
      <ClinicalToolInformation
        clinicalToolType={ClinicalDataTypeLabel.PSFS}
        paragraphs={ClinicalToolReferences.PSFS}
      />
      <Text variant="large" block>
        Information
      </Text>
      <Text block>
        This questionnaire can be used to quantify activity limitation and
        measure functional outcome for patients with an orthopaedic diagnosis.
        Clinician to read and fill in.
      </Text>
      <Separator
        styles={{ root: { paddingTop: "8px", paddingBottom: "8px" } }}
      />
      <Text variant="large" block>
        Setup
      </Text>
      <Text block>
        Assign a condition to this Patient Specific Function Scale and specify
        side of body as applicable.
      </Text>
      <Stack.Item styles={{ root: { paddingTop: "8px" } }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <Stack.Item styles={{ root: { width: "calc(50% - 8px)" } }}>
            {!!answers ? (
              <ReadOnlyTextField
                label="Condition"
                value={context?.diagnosis.originalText}
                hint={
                  terminology.code ? (
                    <ReadCodesHint data={terminology} />
                  ) : undefined
                }
              />
            ) : (
              <TerminologyPickerField
                name={psfsNameOf("diagnosisKey")}
                label="Condition"
                take={50}
                isDiagnosis={true}
                terminology={
                  context?.diagnosis?.code ? context?.diagnosis : terminology
                }
                required
                validateOnInitialize={
                  isActiveDiagnosisSideExist && isActiveDiagnosisSideExist()
                }
                hint={
                  terminology.code ? (
                    <ReadCodesHint data={terminology} />
                  ) : undefined
                }
              />
            )}
          </Stack.Item>
          <Stack.Item styles={{ root: { width: "calc(50% - 8px)" } }}>
            {!!answers && context && context.side ? (
              <ReadOnlyTextField
                label="Side"
                value={getSideOfBodyText(context.side)}
              />
            ) : (
              <DropdownField
                name={psfsNameOf("side")}
                options={clinical.ref.sidesOfBody.keyTextValues.filter(
                  s => s.key !== SideOfBody.Both
                )}
                placeholder="Left, right or neither"
                label="Side"
                validateOnInitialize={
                  isActiveDiagnosisSideExist && isActiveDiagnosisSideExist()
                }
                required
              />
            )}
          </Stack.Item>
        </Stack>
        {!!isActiveDiagnosisSideExist && (
          <FormSpy
            subscription={{
              errors: true
            }}
          >
            {({ errors }) => {
              const shouldShowMessageBar =
                errors && errors["diagnosis"] === " ";
              return (
                !!shouldShowMessageBar && (
                  <MessageBar
                    messageBarType={MessageBarType.blocked}
                    styles={{
                      root: {
                        width: "calc(100% - 8px)",
                        paddingLeft: 8
                      }
                    }}
                  >
                    An active PSFS exisits for this diagnosis & side
                  </MessageBar>
                )
              );
            }}
          </FormSpy>
        )}
      </Stack.Item>
      <Separator
        styles={{ root: { paddingTop: "8px", paddingBottom: "8px" } }}
      />
      <Text variant="large" block>
        Activities
      </Text>
      <Text block>
        The subject must identify between one (1) and five (5) important
        activities that they are unable to do or are having difficulty with as a
        result of their problem. Rate each activity from 0 to 10 where 0 means
        you are unable to perform the activity and 10 means you are able to
        perform the activity at the same level as before the injury or problem.
      </Text>
      <Stack.Item styles={{ root: { paddingTop: "8px" } }}>
        <PSFSQuestions
          questionnaire={questionnaire}
          answers={answers}
          context={context}
        />
      </Stack.Item>
    </Stack>
  );
};
