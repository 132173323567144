import { Heading, Stack } from "@bps/fluent-ui";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { DeleteClaimDialogProps } from "./ClaimDeleteConfirmDialog.tsx";

interface FormValues {
  wouldYouLikeTo: WouldYouLikeToOption;
}

enum WouldYouLikeToOption {
  private = "private",
  delete = "delete"
}

interface ClaimDeleteAppointmentDialogProps extends DeleteClaimDialogProps {
  deleteClaim: () => Promise<void>;
  deletingClaim: Claim | undefined;
  isDemographic?: boolean;
}

export const ClaimDeleteAppointmentDialog: React.FC<
  ClaimDeleteAppointmentDialogProps
> = ({ makePrivate, onDismiss, deleteClaim, isDemographic, deletingClaim }) => {
  const wouldYouLikeToOption = [
    {
      key: WouldYouLikeToOption.private,
      text: "Change to a private condition, and keep appt. links"
    },
    {
      key: WouldYouLikeToOption.delete,
      text: "Delete claim and condition, leaving appts unlinked"
    }
  ];

  const onSubmit = async (values: FormValues) => {
    if (values.wouldYouLikeTo === WouldYouLikeToOption.delete) {
      await deleteClaim();
    } else {
      await makePrivate();
    }
  };

  return (
    <SubmissionFormDialog<FormValues>
      dialogName="Delete Claim"
      onSubmit={onSubmit}
      onSubmitSucceeded={onDismiss}
      buttonsProps={form => ({
        submitButtonProps: {
          iconProps: undefined,
          text:
            form.getState().values.wouldYouLikeTo ===
            WouldYouLikeToOption.private
              ? "Change to private"
              : "Delete"
        }
      })}
      dialogProps={{
        minWidth: 480,
        maxWidth: 480,
        onDismiss,
        dialogContentProps: {
          showCloseButton: false,
          title: (
            <Heading variant="modal-heading">
              {isDemographic
                ? "Appointments are linked to claim"
                : `Appointments are linked to ${deletingClaim?.patientFullName} claim`}
            </Heading>
          )
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 8 }}>
          <ChoiceGroupField
            label="Would you like to"
            name={nameof<FormValues>("wouldYouLikeTo")}
            options={wouldYouLikeToOption}
          />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};
