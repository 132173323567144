import { Observer } from "mobx-react-lite";

import {
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  useResizeElementObserver
} from "@bps/fluent-ui";
import { ObservationType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { ClincialToolViewData } from "../clinical-tool-view/ClinicalToolViewDataFetcher.ts";
import { getClinicalToolViewData } from "../clinical-tool-view/utils.ts";
import { EPDSQuestions } from "./EPDSQuestions.tsx";

export interface EPDSQuestionnaireViewProps {
  encounterId: string;
}

const QUESTION_10_NEVER_ANSWER = "NEVR";

export const EPDSQuestionnaireView: React.FC<EPDSQuestionnaireViewProps> = ({
  encounterId
}) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <DataFetcher<ClincialToolViewData>
      fetch={getClinicalToolViewData(encounterId, ObservationType.EPDS)}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <Observer>
          {() => {
            return (
              <>
                <div
                  ref={r => {
                    if (r && !element) {
                      setElement(r);
                    }
                  }}
                />
                <ClinicalToolView
                  title="EPDS"
                  encounterId={encounterId}
                  isConfidential={isConfidential}
                  lastUpdatedDate={lastUpdatedDate}
                  lastUpdatedUserId={lastUpdatedUserId}
                  summary={summary}
                  onRenderSummary={() => {
                    const warningAnswer = answers.find(
                      x =>
                        x.questionId === "q10" &&
                        x.answerValue !== QUESTION_10_NEVER_ANSWER
                    );

                    return (
                      <Stack tokens={{ childrenGap: 8 }}>
                        <MessageBar>{summary}</MessageBar>
                        {warningAnswer && (
                          <MessageBar messageBarType={MessageBarType.warning}>
                            The response to question 10 indicates that further
                            evaluation and management of their self-harm risk is
                            suggested before leaving the office. This is to
                            ensure the safety of the woman and her baby.
                          </MessageBar>
                        )}
                      </Stack>
                    );
                  }}
                >
                  <EPDSQuestions
                    questionnaire={questionnaire}
                    width={panelWidth}
                    answers={answers}
                    questionPrefix="q"
                  />
                </ClinicalToolView>
              </>
            );
          }}
        </Observer>
      )}
    </DataFetcher>
  );
};
