import React from "react";

import {
  FontWeights,
  Heading,
  Separator,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  DASHModuleTypes,
  DASHQuestionnaireResponseDto,
  ObservationType,
  QuestionnaireItemDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswerText
} from "../utils/ClinicalToolAnswerFinder.ts";
import { DASHModule } from "./DASHModule.tsx";

interface DASHQuestionnaireViewProps {
  encounterId: string;
}

interface DASHFormViewModel {
  encounterDate?: string;
  workModule: boolean;
  sportModule: boolean;
  disabilityQuestions: QuestionnaireItemDto[];
  workQuestions: QuestionnaireItemDto[];
  sportQuestions: QuestionnaireItemDto[];
  workText: Record<string, string>;
  sportText: Record<string, string>;
  answers: ClinicalToolAnswer[];
  summary: string;
  isConfidential: boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
}

export const DASHFormView: React.FunctionComponent<
  DASHQuestionnaireViewProps
> = ({ encounterId }) => {
  const getDASHViewData =
    () =>
    async ({ clinical }: RootStore): Promise<DASHFormViewModel> => {
      const questionnaire = await clinical.getQuestionnaires(
        QuestionnaireType.DASHV1
      );

      const savedData = (
        await clinical.getEncounterClinicalData({
          encounterId,
          types: [ClinicalDataType.DASH]
        })
      )[
        ObservationType.DASH.toLocaleLowerCase()
      ] as DASHQuestionnaireResponseDto;

      const choiceAnswers = savedData.items.filter(
        x =>
          Number(x.questionnaireItemId) !== 12 &&
          Number(x.questionnaireItemId) !== 17
      );

      const answers = savedData
        ? choiceAnswers.map(i => ({
            questionId: `${i.questionnaireItemId}`,
            answerValue: i.answer,
            answerText: findClinicalToolAnswerText(
              i.questionnaireItemId,
              i.answer!,
              questionnaire
            )
          }))
        : [];

      const workTextItem = savedData.items.find(
        x => x.questionnaireItemId === 12
      );

      const workText: Record<string, string> = {
        q12: workTextItem ? workTextItem.answer ?? "" : ""
      };

      const sportTextItem = savedData.items.find(
        x => x.questionnaireItemId === 17
      );

      const sportText: Record<string, string> = {
        q17: sportTextItem ? sportTextItem.answer ?? "" : ""
      };

      const result = await clinical.getObservations({
        patientId: clinical.activeRecordPatientId,
        encounterIds: [encounterId],
        types: [ObservationType.DASH]
      });

      const observations = result.results;

      if (observations.length === 0 || observations.length > 3)
        throw new Error(
          `Unable to retrieve ${ObservationType.DASH} observation data`
        );

      const getSummary = (observations: Observation[]) => {
        const disabilityObservation = observations.find(
          x => x.summary === DASHModuleTypes.Disability
        );

        const workObservation = observations.find(
          x => x.summary === DASHModuleTypes.Work
        );

        const sportObservation = observations.find(
          x => x.summary === DASHModuleTypes.Sport
        );

        let summary: string = "";
        let workModule = false;
        let sportModule = false;
        if (disabilityObservation && workObservation && sportObservation) {
          summary = `Disability = ${
            disabilityObservation!.values[0].value ?? 0
          }, Work = ${workObservation.values[0] ?? 0}, Sport/Music = ${
            sportObservation.values[0] ?? 0
          }`;
          workModule = true;
          sportModule = true;
          return { summary, workModule, sportModule };
        }
        if (disabilityObservation && workObservation && !sportObservation) {
          summary = `Disability = ${
            disabilityObservation!.values[0] ?? 0
          }, Work = ${workObservation.values[0].value ?? 0}`;
          workModule = true;
          return { summary, workModule, sportModule };
        }
        if (disabilityObservation && !workObservation && sportObservation) {
          summary = `Disability = ${
            disabilityObservation!.values[0] ?? 0
          }, Sport/Music = ${sportObservation.values[0].value ?? 0}`;
          sportModule = true;
          return { summary, workModule, sportModule };
        }
        if (disabilityObservation && !workObservation && !sportObservation) {
          summary = `Disability = ${
            disabilityObservation.values[0].value ?? 0
          }`;
          return { summary, workModule, sportModule };
        }
        return { summary, workModule, sportModule };
      };

      const { summary, workModule, sportModule } = getSummary(observations);

      const disablityQuestions = questionnaire.items.filter(
        x => x.id > 0 && x.id <= 11
      );

      const workQuestions = questionnaire.items.filter(
        x => x.id > 12 && x.id <= 16
      );

      const sportQuestions = questionnaire.items.filter(
        x => x.id > 17 && x.id <= 21
      );

      const isConfidential = !!savedData.secGroupId;

      const lastUpdatedDate = getClinicalDataLastUpdatedDate(savedData);

      const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(savedData);

      return {
        answers,
        summary,
        encounterDate: savedData.createLog?.createdDateTime,
        workModule,
        sportModule,
        disabilityQuestions: disablityQuestions,
        workQuestions,
        sportQuestions,
        workText,
        sportText,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      };
    };

  const FORM_HEADING =
    "Disabilities of the Arm, Shoulder, and Hand (DASH) Score";

  const title = (encounterDate: string | undefined) =>
    `${FORM_HEADING} - ${DateTime.fromISOOrNow(
      encounterDate
    ).toDayDefaultFormat()}`;

  return (
    <DataFetcher<DASHFormViewModel>
      fetch={getDASHViewData()}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        encounterDate,
        workModule,
        sportModule,
        disabilityQuestions,
        workQuestions,
        sportQuestions,
        workText,
        sportText,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <ClinicalToolView
          title={title(encounterDate)}
          summary={summary}
          isConfidential={isConfidential}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          {/* need to create baselineForm */}
          <Stack>
            <DASHModule questions={disabilityQuestions} answers={answers} />
          </Stack>
          {workModule && (
            <Stack>
              <Separator
                styles={{
                  root: { paddingTop: "16px", paddingBottom: "16px" }
                }}
              />
              <Stack tokens={{ childrenGap: 8 }}>
                <Heading variant="section-heading">Work module</Heading>

                <Text block>
                  The following questions ask about the impact or your arm,
                  shoulder or hand problem on your ability to work (including
                  homemaking if that is your main role).
                </Text>
                <Stack>
                  <Text styles={{ root: { fontWeight: FontWeights.bold } }}>
                    Work
                  </Text>
                  <Stack
                    styles={(props, theme) => ({
                      root: {
                        minHeight: 30,
                        width: 300,
                        marginBottom: 16,
                        backgroundColor: theme.semanticColors.disabledBackground
                      }
                    })}
                  >
                    <Text
                      styles={(_props, theme) => ({
                        root: {
                          paddingTop: 5,
                          paddingLeft: 8,
                          text: theme.semanticColors.disabledText
                        }
                      })}
                    >
                      {workText["q12"]}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              <DASHModule questions={workQuestions} answers={answers} />
            </Stack>
          )}
          {sportModule && (
            <Stack>
              <Separator
                styles={{
                  root: { paddingTop: "16px", paddingBottom: "16px" }
                }}
              />
              <Stack tokens={{ childrenGap: 8 }}>
                <Heading variant="section-heading">
                  Sports/Performing Arts module
                </Heading>

                <Text block>
                  The following questions ask about the impact or your arm,
                  shoulder or hand problem on playing your musical instrument or
                  sport or both. If you play more than one sport or instrument
                  (or play both), please answer with respect to the activity
                  which is the most important to you
                </Text>
                <Stack>
                  <Text styles={{ root: { fontWeight: FontWeights.bold } }}>
                    Sport or Instrument
                  </Text>
                  <Stack
                    styles={(props, theme) => ({
                      root: {
                        minHeight: 30,
                        width: 300,
                        marginBottom: 16,
                        backgroundColor: theme.semanticColors.disabledBackground
                      }
                    })}
                  >
                    <Text
                      styles={(props, theme) => ({
                        root: {
                          paddingTop: 5,
                          paddingLeft: 8,
                          text: theme.semanticColors.disabledText
                        }
                      })}
                    >
                      {sportText["q17"]}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              <DASHModule questions={sportQuestions} answers={answers} />
            </Stack>
          )}
        </ClinicalToolView>
      )}
    </DataFetcher>
  );
};
