import { FunctionComponent, useEffect, useRef } from "react";

import {
  DefaultButton,
  Dialog,
  DialogType,
  FocusZone,
  HideStack,
  IButton,
  mergeStyleSets,
  PrimaryButton,
  Stack
} from "@bps/fluent-ui";

import { ConfirmMultiDialogProps } from "./confirmMulti.tsx";

export const ConfirmMultiDialog: FunctionComponent<
  ConfirmMultiDialogProps
> = props => {
  const hiddenButtonRef = useRef<null | HTMLButtonElement>();
  const primaryButtonRef = useRef<null | IButton>();

  useEffect(() => {
    if (!props.hidden && !props.focusOnPrimaryButton) {
      setTimeout(() => {
        hiddenButtonRef.current?.focus();
      }, 100);
    } else if (props.focusOnPrimaryButton) {
      setTimeout(() => {
        primaryButtonRef.current?.focus();
      }, 100);
    }
  }, [props.focusOnPrimaryButton, props.hidden]);

  const {
    dialogContentProps,
    modalProps,
    children,
    buttons = [],
    isBlocking = true,
    minWidth = 0,
    maxWidth = 450,
    ...otherDialogProps
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (text: string) => {
    // Placeholder to do a generic handle if no handler passed
  };

  return (
    <Dialog
      onDismiss={() => {
        const cancelButton = buttons.find(button => button.text === "Cancel");
        if (cancelButton) {
          handleClick(cancelButton.text || "Cancel");
          cancelButton.onClick?.();
        }
      }}
      dialogContentProps={{
        type: DialogType.normal,
        showCloseButton: false,
        ...dialogContentProps
      }}
      modalProps={{
        isBlocking,
        ...modalProps,
        styles: mergeStyleSets(
          { main: { minHeight: "auto" } },
          modalProps && modalProps.styles
        )
      }}
      minWidth={minWidth}
      maxWidth={maxWidth}
      {...otherDialogProps}
    >
      {children}
      <FocusZone>
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              marginTop: 24,
              justifyContent: "flex-end"
            }
          }}
        >
          {buttons.map((button, index) => {
            const ButtonComponent = button.primary
              ? PrimaryButton
              : DefaultButton;

            return (
              <ButtonComponent
                key={button.text || index}
                id={`${button.text?.toLowerCase()}-button`}
                {...button}
                onClick={() =>
                  button.onClick
                    ? button.onClick()
                    : handleClick(button.text || "")
                }
                componentRef={ref => {
                  if (index === 0 && button.primary) {
                    primaryButtonRef.current = ref;
                  }
                }}
              />
            );
          })}
        </Stack>
      </FocusZone>

      <HideStack when={true}>
        <button
          ref={ref => {
            hiddenButtonRef.current = ref;
          }}
        />
      </HideStack>
    </Dialog>
  );
};
