import React, { ReactNode } from "react";

import { getTheme } from "@bps/fluent-ui";
import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { ClinicalActivityListFilter } from "../clinical-activity/ClinicalActivityList.tsx";
import { ClinicalActivityType } from "../clinical-activity/types/clinical-activity.type.ts";

export interface ClinicalReminderManagementFilterProps {
  children: ReactNode;
  hidden?: boolean;
}

export interface ClinicalReminderManagementFilterValues
  extends ClinicalActivityListFilter {
  providerIds?: string[];
  statuses?: string[];
  reasons?: string[];
  remainingVisits?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  patientIds?: string[];
  name?: string;
  clinicallySignificant?: boolean;
  urgent?: boolean;
}

export const ClinicalReminderManagementFilter: React.FC<
  ClinicalReminderManagementFilterProps
> = ({ children, hidden }) => {
  const { clinical } = useStores();
  const getReminderReasons = () => {
    const values = clinical.activityDescriptionMapValues
      .filter(x => x.activityType === ClinicalActivityType.Reminder)
      .sort((a: ActivityDescriptionDto, b: ActivityDescriptionDto) => {
        if (!a.isInactive && b.isInactive) {
          return -1;
        } else if (a.isInactive && !b.isInactive) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(({ id, reasonText }) => ({ key: id, text: reasonText }));
    return values;
  };

  const theme = getTheme();
  return (
    <FilterBar<ClinicalReminderManagementFilterValues>
      initialValues={{
        activityStatuses: [
          ClinicalActivityStatus.InProgress,
          ClinicalActivityStatus.New
        ]
      }}
      hidden={hidden}
      items={[
        {
          type: "optionsSelect",
          name: "reasons",
          stickItem: true,
          props: {
            id: "event-reminder-list-filter-reasons",
            placeholder: "Select reason(s)",
            options: getReminderReasons(),
            multiSelect: true,
            calloutWidth: 300,
            styles: { root: { width: "100%" } }
          }
        },
        {
          type: "dateTimeFramePicker",
          name: "dueDateStartDate",
          props: {
            id: "event-reminder-list-filter-due",
            startDateName: "dueDateStartDate",
            endDateName: "dueDateEndDate",
            placeholder: "Due",
            incrementName: "dueInVisits",
            incrementProps: {
              incrementTitle: "Consult count",
              suffix: "consults",
              tooltipText: "From the start date",
              label: "In a span of"
            }
          }
        },
        {
          type: "bookableUsersSelect",
          name: "providerIds",
          props: {
            id: "event-reminder-list-filter-providers",
            multiSelect: true,
            usersFilter: {
              showOnCalendar: true,
              statusCodes: [UserStatus.Active],
              businessRoleClasses: [BusinessRoleClasses.Provider]
            },
            placeholder: "Providers",
            calloutWidth: 250
          }
        },
        {
          type: "patientsSelect",
          name: "patientIds",
          props: {
            id: "event-reminder-list-filter-patients",
            calloutWidth: 230,
            placeholder: "Patients",
            multiSelect: true
          }
        }
      ]}
      presets={[
        {
          text: "Urgent",
          name: "urgent",
          iconName: "BPApptFailed16",
          iconColor: theme.palette.yellowDark,
          tooltip: "Reminders due in less than 2 weeks / 1 consult"
        },
        {
          text: "Clinically significant",
          name: "clinicallySignificant",
          iconName: "UserOptional",
          tooltip: "Clinically significant reminders only",
          tooltipPresses: "Show clinically significant entries only"
        }
      ]}
    >
      {() => children}
    </FilterBar>
  );
};
