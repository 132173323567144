import { compareISOStrDatesPredicate, DateTime } from "@bps/utils";
import {
  Frequency,
  ScheduleDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { toMonthYearRecurrence } from "@libs/utils/calendar/calendar.utils.ts";
import {
  getCount,
  getDayRecur,
  getMonthDayRecur,
  getMonthRecur,
  getRecurrenceWeekPosition,
  getUntil
} from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { ScheduleFields } from "./ScheduleFields.types.ts";

export const toScheduleFormValues = (
  schedules: ScheduleDto[],
  core: CoreStore,
  apptTypeBaseInterval?: string
) => {
  const sortByCreatedDateDesc = (a: ScheduleDto, b: ScheduleDto) => {
    const orgUnitAIsInactive = core.hasInactiveFlag(a.orgUnitId);
    const orgUnitBIsInactive = core.hasInactiveFlag(b.orgUnitId);

    if (orgUnitAIsInactive && !orgUnitBIsInactive) return 1;
    if (!orgUnitAIsInactive && orgUnitBIsInactive) return -1;

    return compareISOStrDatesPredicate(
      a.changeLog?.createdDate,
      b.changeLog?.createdDate,
      false
    );
  };

  return schedules.sort(sortByCreatedDateDesc).map<ScheduleFields>(s => {
    const startDate = DateTime.fromISO(s.startDate).startOf("day");

    let endScheduleType: string;
    if (s.recurrenceRule.count) {
      endScheduleType = EndScheduleType.After;
    } else if (s.recurrenceRule.until) {
      endScheduleType = EndScheduleType.OnDate;
    } else {
      endScheduleType = EndScheduleType.Never;
    }

    return {
      id: s.id,
      eTag: s.eTag,
      atWork: s.isAvailable ? "1" : "0",
      location: s.orgUnitId,
      count: s.recurrenceRule.count,
      endScheduleType,
      frequency: s.recurrenceRule.frequency,
      interval: s.recurrenceRule.interval,
      reasonForNotWorking: s.reasonForNotWorking,
      dayRecur:
        // Note: Month and Year Reoccur to not get shown on the UI control,
        // this gets set dynamically on save.
        s.recurrenceRule.frequency === Frequency.Week
          ? s.recurrenceRule.dayRecur || []
          : undefined,
      monthYearRecurrence:
        s.recurrenceRule.frequency !== Frequency.Week
          ? toMonthYearRecurrence(
              s.recurrenceRule.weekPosition,
              s.recurrenceRule.monthDayRecur
            )
          : undefined,
      startTime: DateTime.fromParsedTimeString(
        s.isAvailable && s.startTime ? s.startTime : "8:00"
      )?.toTimeInputFormat(),
      endTime: DateTime.fromParsedTimeString(
        s.isAvailable && s.endTime ? s.endTime : "17:00"
      )?.toTimeInputFormat(),
      startDate: startDate.toJSDate(),
      until: DateTime.fromISO(s.recurrenceRule?.until)
        ?.startOf("day")
        .toJSDate(),
      usePracticeBaseInterval: s.usePracticeBaseInterval,
      defaultAppointmentDuration:
        !!s.usePracticeBaseInterval && apptTypeBaseInterval
          ? apptTypeBaseInterval
          : s.defaultAppointmentDuration
    };
  });
};

export const isAtWork = (value: string) => value === "1";

export const toBookingSchedules = (
  schedules: ScheduleFields[],
  core: CoreStore
): ScheduleDto[] => {
  return schedules.map<ScheduleDto>(s => {
    const atWork = (s.atWork && isAtWork(s.atWork)) || false;
    const startDate = DateTime.fromJSDate(s.startDate);
    const frequency = Number(s.frequency);

    return {
      orgUnitId: core.hasMultipleActiveLocations ? s.location : core.locationId,
      id: s.id,
      eTag: s.eTag,
      startDate: DateTime.fromJSDate(s.startDate).toISODate(), // Do not send offset
      startTime: s.startTime,
      endTime: s.endTime,
      isAvailable: atWork,
      reasonForNotWorking: atWork ? undefined : s.reasonForNotWorking,
      recurrenceRule: {
        count: getCount(s.endScheduleType, s.count),
        frequency,
        interval: s.interval,
        monthRecur: getMonthRecur(frequency, startDate),
        weekPosition: getRecurrenceWeekPosition(
          startDate,
          s.frequency,
          s.monthYearRecurrence
        ),
        dayRecur: getDayRecur({
          startDate,
          frequency: s.frequency,
          dayRecur: s.dayRecur,
          monthYearRecurrence: s.monthYearRecurrence
        }),
        monthDayRecur: getMonthDayRecur(
          startDate,
          s.frequency,
          s.monthYearRecurrence
        ),
        until: getUntil(s.endScheduleType, s.until)
      },
      defaultAppointmentDuration: s.defaultAppointmentDuration,
      usePracticeBaseInterval: s.usePracticeBaseInterval
    };
  });
};
