import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  Dropdown,
  FontIcon,
  Heading,
  IDropdownProps,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  CodedText,
  PhysitrackType,
  PSFSContextClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PhysitrackApiKeyDto } from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SideNavContent } from "../../SideNavContent.tsx";
import {
  fetchContactAndPhysitrackKey,
  getPhysitrackUrl,
  redirectToPhysitrack
} from "../../SOTAP/physitrack/utils.ts";
import {
  getClinicalToolsListOptions,
  getCodedTextsFromContexts
} from "../utils/clinicalToolsUtils.ts";
import { ClinicalToolConfirmEditModal } from "./ClinicalToolConfirmEditModal.tsx";
import { ClinicalToolResultOpener } from "./ClinicalToolResultOpener.ts";
import { ClinicalToolsList } from "./ClinicalToolsList.tsx";

export interface ClinicalToolsProps {
  clinicalRecord: ClinicalRecord;
}

export const ClinicalTools: FunctionComponent<ClinicalToolsProps> = observer(
  ({ clinicalRecord }) => {
    const { clinical, core } = useStores();
    const clinicalToolResultOpener = new ClinicalToolResultOpener(
      clinical,
      clinicalRecord
    );

    const { isViewOnlyOrDischarged, patientId } =
      usePatientRecordScreenContext();

    const contexts: PSFSContextClinicalDataItemDto[] | undefined =
      clinicalRecord.clinicalData?.psfsContext?.contexts;

    const codedTexts: CodedText[] = getCodedTextsFromContexts(contexts);

    const url = getPhysitrackUrl(core.tenantDetails!.country);

    let clinicalToolsOptions = getClinicalToolsListOptions();

    if (!core.hasPermissions(Permission.EPDSAllowed)) {
      clinicalToolsOptions = clinicalToolsOptions.filter(
        x => x.key !== ClinicalDataType.EPDS
      );
    }

    if (!core.hasPermissions(Permission.DASS21Alowed)) {
      clinicalToolsOptions = clinicalToolsOptions.filter(
        x => x.key !== ClinicalDataType.DASS21
      );
    }

    return (
      <>
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{ root: { height: "100%" } }}
        >
          <Heading
            variant="section-heading-light"
            styles={{ root: { padding: "5px 0" } }}
          >
            Clinical tools
          </Heading>
          {!isViewOnlyOrDischarged && (
            <DataFetcher<{
              contact: Contact;
              physitrackApiKeyDto: PhysitrackApiKeyDto | undefined;
            }>
              fallback={<Spinner />}
              fetch={async root =>
                await fetchContactAndPhysitrackKey(root, patientId)
              }
            >
              {({ contact, physitrackApiKeyDto }) => (
                <Dropdown
                  name="clinicaltools"
                  {...dataAttribute(
                    DataAttributes.Element,
                    "clinical-tools-dropdown"
                  )}
                  onChange={(e, item) => {
                    if (item?.key === PhysitrackType.Key) {
                      window.open(
                        redirectToPhysitrack(physitrackApiKeyDto, contact, url)
                      );
                    } else if (item) {
                      clinicalToolResultOpener.openClinicalToolFromDropDown(
                        item.key as ClinicalDataType
                      );
                    }
                  }}
                  selectedKey={
                    clinical.ui.tabs.currentPatientRecordTab?.activeTab?.type
                  }
                  placeholder="New tool"
                  options={
                    physitrackApiKeyDto !== undefined
                      ? clinicalToolsOptions
                      : clinicalToolsOptions.filter(
                          x => x.key !== PhysitrackType.Key
                        )
                  }
                  withNoEmptyOption
                  onRenderPlaceholder={(props: IDropdownProps): JSX.Element => {
                    return (
                      <div>
                        <FontIcon
                          iconName="Add"
                          styles={{ root: { paddingRight: 16 } }}
                        />
                        {props.placeholder}
                      </div>
                    );
                  }}
                />
              )}
            </DataFetcher>
          )}
          <SideNavContent>
            <DataFetcher
              key={JSON.stringify(contexts)}
              fetch={async () => {
                await clinicalRecord.loadObservationData();
                return codedTexts;
              }}
              fallback={<Spinner />}
            >
              {codedTexts =>
                clinicalRecord.observations?.length > 0 ? (
                  <ClinicalToolsList
                    clinicalRecord={clinicalRecord}
                    clinicalTabs={clinical.ui.tabs}
                    codedTexts={codedTexts}
                    clinicalToolResultOpener={clinicalToolResultOpener}
                  />
                ) : (
                  <Stack
                    {...dataAttribute(
                      DataAttributes.Element,
                      "clinical-tools-empty-state"
                    )}
                    styles={(_props, theme) => ({
                      root: {
                        fontStyle: "italic",
                        backgroundColor: theme.palette.neutralLighterAlt,
                        height: "100%",
                        textAlign: "center",
                        paddingTop: "30%"
                      }
                    })}
                  >
                    No clinical tools have been recorded
                  </Stack>
                )
              }
            </DataFetcher>
          </SideNavContent>
        </Stack>
        {clinicalToolResultOpener.showConfirmDialog && (
          <ClinicalToolConfirmEditModal
            resultType={clinicalToolResultOpener.resultTypeName}
            onConfirm={clinicalToolResultOpener.onConfirmOpenCurrentResult}
            onCancel={clinicalToolResultOpener.closeConfirmDialog}
          />
        )}
      </>
    );
  }
);
