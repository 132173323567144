import { computed } from "mobx";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BhbStore } from "@stores/bhb/BhbStore.ts";
import {
  BhbAppointmentType,
  BhbOnlineApptType
} from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { User } from "@stores/core/models/User.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";

export interface UserOnlineBookingProfileFormHelperProps {
  user: User;
  bhbProviders: BhbProvider[];
  userAvailability?: UserAvailabilityModel;
  root: RootStore;
}

export class UserOnlineBookingProfileFormHelper {
  constructor(private props: UserOnlineBookingProfileFormHelperProps) {}

  private get core(): CoreStore {
    return this.props.root.core;
  }

  private get bhb(): BhbStore {
    return this.props.root.bhb;
  }

  private get userExperience(): UserExperienceStore {
    return this.props.root.userExperience;
  }

  @computed
  get user(): User {
    return this.props.user;
  }

  @computed
  get bhbProviders(): BhbProvider[] {
    return this.props.bhbProviders;
  }

  @computed
  get bhbProvider(): BhbProvider | undefined {
    return this.props.bhbProviders[0];
  }

  @computed
  get userAvailability(): UserAvailabilityModel | undefined {
    return this.props.userAvailability;
  }

  get initialValues(): UserOnlineBookingProfileFormValues {
    return {
      displayName: this.bhbProvider?.displayName ?? this.user.fullName,
      language: this.bhbProvider?.language,
      areaOfInterest: this.bhbProvider?.areaOfInterest,
      profileDetail: this.bhbProvider?.profileDetail,
      photoUrl: this.bhbProvider?.photoUrl
    };
  }

  saveUserSetting = async (userSetting: UserSetting) => {
    await this.userExperience.upsertUserSetting({
      showOnCalendar: !userSetting.showOnCalendar,
      userId: userSetting.userId
    });
  };

  static reduceToOnlineApptTypes = (apptTypes: BhbAppointmentType[]) => {
    const onlineAppointmentTypes = (Array.from(apptTypes?.values()) ?? []).sort(
      (a, b) => a.name.localeCompare(b.name)
    );

    const apptTypesReduced = onlineAppointmentTypes.reduce(
      (filtered: BhbOnlineApptType[], type) => {
        if (type.isAvailableExistingPatients || type.isAvailableNewPatients) {
          const bhbOnlineApptType = {
            key: type.id,
            data: type,
            text: type.name
          };
          return [...filtered, bhbOnlineApptType];
        }
        return filtered;
      },
      []
    );
    return apptTypesReduced;
  };

  onSubmit = async (values: UserOnlineBookingProfileFormValues) => {
    if (
      this.bhbProvider &&
      this.core.hasPermissions([
        Permission.BhbConfigAllowed,
        Permission.BhbWrite
      ])
    ) {
      await Promise.all(
        this.bhbProviders.map(provider => {
          const updatedProvider = {
            ...provider.dto,
            displayName: values.displayName ?? "",
            language: values?.language,
            areaOfInterest: values?.areaOfInterest,
            profileDetail: values?.profileDetail,
            photoUrl: values.photoUrl
          };
          return this.bhb.updateProvider(updatedProvider);
        })
      );
    }
  };
}
