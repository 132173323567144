import { FC } from "react";

import { NoDataTile } from "@bps/fluent-ui";

export const NoObservationDataTile: FC = () => {
  return (
    <NoDataTile
      styles={{
        root: {
          padding: 0,
          minHeight: 120,
          width: "100%",
          height: "100%"
        }
      }}
      textProps={{ text: "No observation recorded" }}
      linkProps={{ hidden: true }}
      showBoxShadow={false}
    />
  );
};
