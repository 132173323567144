import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import {
  CenteredLargeSpinner,
  CollapsibleCard,
  confirm,
  Heading,
  IPivotProps,
  Pivot,
  PivotItem,
  Stack
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FORM_PROMPT_DEFAULT_MESSAGE } from "@ui-components/form/FinalForm.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";
import {
  LocationsSelect,
  LocationsSelectProps
} from "@ui-components/selects/LocationsSelect.tsx";

import { BhbConfigurationSettingsForm } from "./bhb-configuration-form/BhbConfigurationSettingsForm.tsx";
import { BhbProvidersForm } from "./providers-form/BhbProvidersForm.tsx";

enum PivotKeys {
  General = "1",
  Providers = "2"
}

export const BhbConfigurationSettingsCard = observer(() => {
  const { core } = useStores();

  const [orgUnitId, setOrgUnitId] = useState(core.locationId);
  const [pivotKey, setPivotKey] = useState<string>(PivotKeys.General);
  const dirtyRef = useRef(false);

  const getIsFormDirty = async (): Promise<boolean> => {
    if (!dirtyRef.current) return false;

    const isConfirmed = await confirm({
      dialogContentProps: {
        subText: FORM_PROMPT_DEFAULT_MESSAGE
      },
      confirmButtonProps: {
        text: defaultButtonsText.confirm
      },
      cancelButtonProps: {
        text: defaultButtonsText.cancel
      }
    });

    if (isConfirmed) {
      dirtyRef.current = false;
    }

    return !isConfirmed;
  };

  const changeLocation: LocationsSelectProps["onChangeSelectedKeys"] =
    async id => {
      if (typeof id !== "string") return;

      const isDirty = await getIsFormDirty();

      if (!isDirty) {
        setOrgUnitId(id);
      }
    };

  const changePivot: IPivotProps["onLinkClick"] = async item => {
    if (!item?.props.itemKey) return;

    const isDirty = await getIsFormDirty();

    if (!isDirty) {
      setPivotKey(item.props.itemKey);
    }
  };

  const heading = (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
      <Heading variant="modal-heading">BHB configuration</Heading>
      {core.hasMultipleLocations && (
        <Stack onClick={event => event.stopPropagation()}>
          <LocationsSelect
            hideClearButton
            hideSearchOption
            multiSelect={false}
            selectedKeys={orgUnitId}
            onChangeSelectedKeys={changeLocation}
          />
        </Stack>
      )}
    </Stack>
  );

  return (
    <CollapsibleCard
      heading={heading}
      headingLevel="modal-heading"
      openOnRender
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Pivot selectedKey={pivotKey} onLinkClick={changePivot}>
          <PivotItem itemKey={PivotKeys.General} headerText="General" />
          <PivotItem itemKey={PivotKeys.Providers} headerText="Providers" />
        </Pivot>
        <DataFetcher
          fetch={({ bhb }) => bhb.getLocation(orgUnitId)}
          refetchId={orgUnitId}
          fallback={<CenteredLargeSpinner />}
        >
          {location => (
            <>
              {pivotKey === PivotKeys.General && (
                <BhbConfigurationSettingsForm
                  location={location}
                  setIsDirty={dirty => {
                    dirtyRef.current = dirty;
                  }}
                />
              )}
              {pivotKey === PivotKeys.Providers && (
                <BhbProvidersForm location={location} />
              )}
            </>
          )}
        </DataFetcher>
      </Stack>
    </CollapsibleCard>
  );
});
