import { action, observable, runInAction } from "mobx";

import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  AddCommPreferenceDto,
  AddContactPreferencesDto,
  AddOutboundCommTemplateDto,
  CommPreferenceDto,
  CommTypePreferencesDto,
  ContactPreferencesDto,
  MessageCreditBalanceDto,
  OutboundCommContentGetByArgs,
  OutboundCommTemplateDto,
  OutboundCommTemplateMasterDto,
  OutboundCommTemplateReplyActionDto,
  PatchCommPreferenceDto,
  PatchContactPreferencesDto,
  PatchOutboundCommTemplateDto,
  RenderOutboundCommTemplateDto,
  ReplyActionsArgs,
  TemplateRenderResponseDto
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { ICommsGateway } from "@libs/gateways/comms/CommsGateway.interface.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { CommsRef } from "./CommsRef.ts";
import { CommPreference } from "./models/CommPreference.ts";
import { ContactPreferences } from "./models/ContactPreferences.tsx";
import { OutboundCommTemplate } from "./models/OutboundCommTemplate.ts";
import { OutboundCommTemplateReplyAction } from "./models/OutboundCommTemplateReplyAction.ts";

export class CommsStore implements Store<CommsStore, CommsRef> {
  constructor(
    private gateway: ICommsGateway,
    public hub: IHubGateway
  ) {
    this.ref = new CommsRef(this.gateway);
  }

  root: IRootStore;
  outboundCommTemplatesMap = observable.map<string, OutboundCommTemplate>();
  ref: CommsRef;
  outboundCommTemplateReplyActionsMap = observable.map<
    string,
    OutboundCommTemplateReplyAction
  >();

  commPreferencesMap = observable.map<string, CommPreference>();
  contactPreferencesMap = observable.map<string, ContactPreferences>();

  @observable
  recentlyChangedTemplateListId?: string;

  afterAttachRoot() {
    this.hub.onEntityEvent(
      Entity.OutboundCommTemplate,
      this.onOutboundCommTemplate
    );
  }

  private onOutboundCommTemplate = async (
    event: EntityEventData<{ id: string }>
  ) => {
    if (event.action === EventAction.Delete) {
      runInAction(() => {
        this.outboundCommTemplatesMap.delete(event.id);
        this.recentlyChangedTemplateListId = event.id;
      });
    } else if (event.action === EventAction.Create) {
      runInAction(() => {
        this.recentlyChangedTemplateListId = event.etag;
      });
    } else if (event.action === EventAction.Update) {
      runInAction(() => {
        this.recentlyChangedTemplateListId = event.etag;
      });
    }
  };

  addOrUpdateContactPreferences = async (
    updatedPatientId: string,
    commTypePreferences: CommTypePreferencesDto[]
  ) => {
    const contactPreferences =
      await this.getContactPreference(updatedPatientId);
    if (contactPreferences)
      await this.patchContactPreferences({
        commTypePreferences,
        id: updatedPatientId,
        eTag: contactPreferences.eTag
      });
    else
      await this.addContactPreferences({
        commTypePreferences,
        id: updatedPatientId
      });
  };
  async addOutboundCommTemplate(request: AddOutboundCommTemplateDto) {
    const outboundCommTemplate = await this.gateway
      .addOutboundCommTemplate(request)
      .then(this.mergeOutboundCommTemplate);

    //Create Outbound Comm Reply Actions
    const createOutboundCommTemplateReplyActions = async (
      campaignId: string,
      args: ReplyActionsArgs
    ) => {
      await this.addOutboundCommTemplateReplyActions(campaignId, args);
    };
    if (outboundCommTemplate && outboundCommTemplate.channelTemplates) {
      const promises: Promise<void>[] = [];
      outboundCommTemplate.channelTemplates.forEach(channelTemplate => {
        promises.push(
          createOutboundCommTemplateReplyActions(outboundCommTemplate.id, {
            outboundCommChannelCode: channelTemplate.outboundCommChannelCode,
            replyActions: [
              {
                responsePatterns: ["YES", "Y"],
                outboundCommTypeReplyActionCode: "CONFIRMED",
                outboundCommTemplateId: channelTemplate.outboundCommTemplateId!
              },
              {
                responsePatterns: ["NO", "N"],
                outboundCommTypeReplyActionCode: "DECLINED",
                outboundCommTemplateId: channelTemplate.outboundCommTemplateId!
              }
            ]
          })
        );
      });
      await Promise.all(promises);
    }

    return outboundCommTemplate;
  }

  async patchOutboundCommTemplate(
    outboundCommTemplateDto: Omit<PatchOutboundCommTemplateDto, "eTag">
  ): Promise<OutboundCommTemplate> {
    return await patchModel(
      outboundCommTemplateDto,
      req => this.gateway.patchOutboundCommTemplate(req),
      {
        modelMap: this.outboundCommTemplatesMap
      }
    );
  }

  async addContactPreferences(request: AddContactPreferencesDto) {
    return await this.gateway
      .addContactPreferences(request)
      .then(this.mergeContactPreferences);
  }

  async patchContactPreferences(
    contactPreferenceDto: PatchContactPreferencesDto
  ): Promise<ContactPreferencesDto> {
    return await patchModel(
      contactPreferenceDto,
      req => this.gateway.patchContactPreferences(req),
      {
        modelMap: this.contactPreferencesMap
      }
    );
  }

  async getContactPreferences(): Promise<ContactPreferencesDto[]> {
    const result = await this.gateway.getCommPreferences();
    return result.map(this.mergeContactPreferences);
  }

  @sharePendingPromise()
  async getContactPreference(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingContactPreference = this.contactPreferencesMap.get(id);
      if (existingContactPreference) {
        return existingContactPreference;
      }
    }

    return await this.gateway
      .getContactPreference(id)
      .then(this.mergeContactPreferences)
      .catch(catchNotFoundError);
  }

  async renderOutboundCommTemplate(
    request: RenderOutboundCommTemplateDto
  ): Promise<TemplateRenderResponseDto> {
    return await this.gateway.renderOutboundCommTemplate(request);
  }

  async addOutboundCommTemplateReplyActions(
    campaignId: string,
    args: ReplyActionsArgs
  ) {
    const outbundCommTemplateReplyAction = await this.gateway
      .addOutboundCommTemplateReplyActions(campaignId, args)
      .then(x => x.map(this.mergeOutboundCommTemplateReplyAction));

    return outbundCommTemplateReplyAction;
  }

  async addCommPreference(request: AddCommPreferenceDto) {
    const commPreference = await this.gateway
      .addCommPreference(request)
      .then(this.mergeCommPreference);

    return commPreference;
  }

  async patchCommPreference(
    commPreferenceDto: Omit<PatchCommPreferenceDto, "eTag">
  ): Promise<CommPreference> {
    const commPreference = await patchModel(
      commPreferenceDto,
      req => this.gateway.patchCommPreference(req),
      {
        modelMap: this.commPreferencesMap
      }
    );

    return commPreference;
  }

  async getCommPreferences(): Promise<CommPreference[]> {
    try {
      const result = await this.gateway.getCommPreferences();
      return result.map(this.mergeCommPreference);
    } catch (e) {
      return catchNotFoundError(e) ?? [];
    }
  }

  async getOutboundComm(id: string) {
    return this.gateway.getOutboundComm(id).catch(catchNotFoundError);
  }

  getMessageCreditBalance = async (): Promise<MessageCreditBalanceDto> => {
    const result = await this.gateway.getMessageCreditBalance();
    return result;
  };

  @action
  private mergeContactPreferences = (dto: ContactPreferencesDto) =>
    mergeModel({
      dto,
      getNewModel: () => new ContactPreferences(dto),
      map: this.contactPreferencesMap
    });

  @action
  private mergeCommPreference = (dto: CommPreferenceDto) =>
    mergeModel({
      dto,
      getNewModel: () => new CommPreference(dto),
      map: this.commPreferencesMap
    });

  @action
  private mergeOutboundCommTemplate = (dto: OutboundCommTemplateDto) =>
    mergeModel({
      dto,
      getNewModel: () => new OutboundCommTemplate(this.root, dto),
      map: this.outboundCommTemplatesMap
    });

  @action
  private mergeOutboundCommTemplateReplyAction = (
    dto: OutboundCommTemplateReplyActionDto
  ) =>
    mergeModel({
      dto,
      getNewModel: () => new OutboundCommTemplateReplyAction(this.root, dto),
      map: this.outboundCommTemplateReplyActionsMap
    });

  async getAllOutboundCommTemplates() {
    const dtoResult = await this.gateway.getAllOutboundCommTemplates();
    return dtoResult.map(this.mergeOutboundCommTemplate);
  }

  async deleteOutboundCommTemplate(id: string) {
    return await this.gateway
      .deleteOutboundCommTemplate(id)
      .then(() => this.outboundCommTemplatesMap.delete(id));
  }

  getAllMasterTemplates = async (): Promise<
    OutboundCommTemplateMasterDto[]
  > => {
    const dtoResult = await this.gateway.getAllMasterTemplates();
    return dtoResult;
  };

  async getOutboundCommContents(
    args: OutboundCommContentGetByArgs & PagingOptions = { outboundCommIds: [] }
  ) {
    return this.gateway.getOutboundCommContents(args);
  }
}
