import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import { Heading, Stack, Tile } from "@bps/fluent-ui";
import { DidNotArriveItem } from "@modules/booking/screens/did-not-arrive/DidNotArriveListScreen.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DidNotArriveActionsButton } from "./components/DidNotArriveActionsButton.tsx";
import { DidNotArriveDetailFilter } from "./components/DidNotArriveDetailFilter.tsx";
import { DidNotArriveManualRescheduleModal } from "./components/DidNotArriveManualRescheduleModal.tsx";
import { DidNotArriveTable } from "./components/DidNotArriveTable.tsx";
import { getDidNotArriveColumns, loadDidNotArriveListItems } from "./utils.tsx";

interface DidNotArriveListProps {
  selectedUsers: string[];
  loading?: boolean;
  error?: Error;
  loadingMore?: boolean;
}

const DidNotArriveListScreenBase: FC<DidNotArriveListProps> = observer(() => {
  const root = useStores();

  const [selectedItem, setSelectedItem] = useState<DidNotArriveItem | null>(
    null
  );

  const columns = getDidNotArriveColumns({
    renderActions: (item: DidNotArriveItem) => (
      <DidNotArriveActionsButton
        item={item}
        onMarkAsRescheduled={setSelectedItem}
      />
    ),
    core: root.core
  });

  return (
    <Tile styles={{ root: { flexGrow: 1 } }}>
      <Stack verticalFill tokens={{ childrenGap: 24 }}>
        <Heading variant="section-heading-light">Incomplete</Heading>
        <DidNotArriveDetailFilter>
          {state => (
            <Stack
              grow
              styles={{
                root: {
                  position: "relative",
                  height: "100%"
                }
              }}
            >
              <DataFetcher
                fetch={() => loadDidNotArriveListItems(state.values, root)}
                noExceptionsHandlers
                refetchId={JSON.stringify(state.values)}
              >
                {(items, loading, error) => (
                  <DidNotArriveTable
                    items={items}
                    columns={columns}
                    loading={loading}
                    error={error}
                  />
                )}
              </DataFetcher>
            </Stack>
          )}
        </DidNotArriveDetailFilter>
      </Stack>
      {selectedItem && (
        <DidNotArriveManualRescheduleModal
          item={selectedItem}
          onDismiss={() => setSelectedItem(null)}
        />
      )}
    </Tile>
  );
});

export const DidNotArriveListScreen = withFetch(
  x => [x.booking.getAppointmentCancellationReasons()],
  DidNotArriveListScreenBase
);
