import { useState } from "react";

import {
  Dropdown,
  IDropdownOption,
  IOverflowSetItemProps
} from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PatientPillOverflowButtonDocument } from "./PatientPillOverflowButtonDocument.tsx";
import { PatientPillOverflowButtonPatient } from "./PatientPillOverflowButtonPatient.tsx";
import { PatientPillOverflowButtonTemplate } from "./PatientPillOverflowButtonTemplate.tsx";

interface OverflowButtonProps {
  items: IOverflowSetItemProps[];
  onDocumentClick?: (
    id: string,
    patientId: string,
    encounterId?: string
  ) => void;
  onTemplateClick?: (documentId: string) => void;
}

export interface OverFlowDropDownOptions extends IDropdownOption {
  key: string;
  active?: boolean;
  record?: ClinicalRecord;
  loading?: boolean;
  type?: "patient" | "document" | "template";
  patientId?: string;
  encounterId?: string;
  documentTabStatus?: string;
  newDocumentId?: string;
}

const PatientPillOverflowButtonBase: React.FunctionComponent<
  OverflowButtonProps
> = ({ items, onDocumentClick, onTemplateClick }) => {
  let overflowOptions: OverFlowDropDownOptions[] = [];
  const overflowLength: number = items.length;
  if (items) {
    overflowOptions = items?.map(item => ({
      key: item.key,
      text: item.type,
      active: item.active ?? undefined,
      record: item.record ?? undefined,
      patientId: item.patientId ?? undefined,
      encounterId: item.encounterId ?? undefined,
      documentTabStatus: item.documentTabStatus,
      newDocumentId: item.newDocumentId
    }));
  }

  const [selectedItem, setSelectedItem] = useState<OverFlowDropDownOptions>();

  const renderOverflowDropdownTitle = () => {
    return <span>{`${overflowLength} more`}</span>;
  };

  const renderOverflowOption = (item: OverFlowDropDownOptions) => {
    if (item) {
      if (item.text === "patient") {
        return <PatientPillOverflowButtonPatient item={item} />;
      }
      if (item.text === "document") {
        return (
          <PatientPillOverflowButtonDocument
            item={item}
            onDocumentClick={onDocumentClick}
          />
        );
      }
      if (item.text === "template") {
        return (
          <PatientPillOverflowButtonTemplate
            item={item}
            onTemplateClick={onTemplateClick}
          />
        );
      }
    }
    return null;
  };

  const onPillChanged = (
    event: React.FormEvent<HTMLDivElement>,
    item: OverFlowDropDownOptions
  ): void => {
    if (item.text === "document" || item.text === "template") {
      setSelectedItem(item);
    } else {
      setSelectedItem(undefined);
    }
  };

  return (
    <Dropdown
      name="pill-dropdown"
      placeholder={`${overflowLength} more`}
      onRenderTitle={renderOverflowDropdownTitle}
      onRenderOption={renderOverflowOption}
      selectedKey={selectedItem ? selectedItem.key : null}
      options={overflowOptions}
      dropdownWidth={200}
      withNoEmptyOption={true}
      onChange={onPillChanged}
      styles={({ theme }) => ({
        title: {
          width: 87,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme!.semanticColors.menuDivider,
          borderRadius: 4,
          padding: "0px 8px",
          textAlign: "left"
        },
        dropdown: {
          selectors: {
            "&:active": {
              backgroundColor: theme!.semanticColors.buttonBackgroundPressed,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: theme!.semanticColors.menuDivider,
              borderRadius: 4
            },
            "&:focus::after": {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme!.semanticColors.menuDivider,
              borderRadius: 4
            }
          }
        }
      })}
    />
  );
};

export const PatientPillOverflowButton = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  PatientPillOverflowButtonBase
);
