import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useCallback } from "react";

import {
  DetailsListLayoutMode,
  DtoDetailsRow,
  FontIcon,
  FontSizes,
  IColumn,
  IDetailsRowProps,
  NoDataTile,
  RESET_CELLS_PADDING_CLASSNAME,
  Text
} from "@bps/fluent-ui";
import { useFormContext } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { CorrespondenceFilter } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalDocumentViewerLink } from "../../../shared-components/ClinicalDocumentViewerLink.tsx";
import { useCorrespondencesHelper } from "../correspondence/context/CorrespondencesContext.tsx";
import { SideNavDetailsList } from "../SideNavDetailsList.tsx";
import { CorrespondenceListItemContextMenu } from "./CorrespondenceListItemContextMenu.tsx";
import { CorrespondencesLabels } from "./Correspondences.types.ts";

interface CorrespondencesListProps {
  patientId: string;
}

export const CorrespondencesList: FunctionComponent<CorrespondencesListProps> =
  observer(({ patientId }) => {
    const {
      state: { values: filter }
    } = useFormContext<CorrespondenceFilter>();

    const { correspondence, core, practice } = useStores();

    const { onOpenClinicalDocumentClick } = useCorrespondencesHelper();

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const getCorrespondencePerson = (personId: string | undefined): string => {
      const contact =
        personId != null ? practice.contactsMap.get(personId) : null;

      // could be a user depending on direction
      const user = personId != null ? core.userMap.get(personId) : null;
      return contact?.name || user?.fullName || "";
    };

    const columns: IColumn[] = [];
    if (!isViewOnlyOrDischarged) {
      columns.push({
        fieldName: "contextMenu",
        key: "contextMenu",
        className: RESET_CELLS_PADDING_CLASSNAME,
        minWidth: 13,
        maxWidth: 13,
        name: "",
        onRender: (record: ClinicalDocument) => (
          <CorrespondenceListItemContextMenu record={record} />
        ),
        isResizable: false
      });
    }

    columns.push(
      {
        fieldName: "correspondenceDate",
        key: "correspondenceDate",
        minWidth: 75,
        maxWidth: 75,
        name: "Date",
        onRender: (record: ClinicalDocument) => {
          return <Text>{record.date?.toDayDefaultFormat()}</Text>;
        },
        isResizable: true
      },
      {
        fieldName: "Detail",
        key: "Detail",
        minWidth: 150,
        name: "Detail",
        onRender: (record: ClinicalDocument) => {
          const providerName = core.getUserByPrivateSecGroupId(
            record.secGroupId
          )?.fullName;

          return core.hasAccessToSecGroup(record.secGroupId) ? (
            <ClinicalDocumentViewerLink
              record={record}
              onClick={onOpenClinicalDocumentClick}
              isCorrespondence
            />
          ) : (
            <Text nowrap>{`Confidential (by ${providerName})`}</Text>
          );
        },
        isResizable: true
      },
      {
        fieldName: "from",
        key: "from",
        minWidth: 80,
        name: "From",
        onRender: (record: ClinicalDocument) => (
          <Text>
            {core.hasAccessToSecGroup(record.secGroupId) ||
            record.direction === CorrespondenceDirection.Out ? (
              getCorrespondencePerson(record.from)
            ) : (
              <FontIcon
                iconName="ShieldAlert"
                styles={{
                  root: {
                    margin: "auto 4px",
                    fontSize: FontSizes.size16
                  }
                }}
              />
            )}
          </Text>
        ),
        isResizable: true
      },
      {
        fieldName: "to",
        key: "to",
        minWidth: 80,
        name: "To",
        onRender: (record: ClinicalDocument) => (
          <Text>
            {core.hasAccessToSecGroup(record.secGroupId) ||
            record.direction === CorrespondenceDirection.In ? (
              getCorrespondencePerson(record.to)
            ) : (
              <FontIcon
                iconName="ShieldAlert"
                styles={{
                  root: {
                    margin: "auto 4px",
                    fontSize: FontSizes.size16
                  }
                }}
              />
            )}
          </Text>
        ),
        isResizable: true
      },
      {
        fieldName: "correspondenceType",
        key: "correspondenceType",
        minWidth: 100,
        name: "Type",
        onRender: (record: ClinicalDocument) => {
          return (
            <Text>
              {record.type
                ? correspondence.ref.correspondenceTypes.map.get(record.type)
                    ?.text
                : ""}
            </Text>
          );
        },
        isResizable: true
      },
      {
        fieldName: "status",
        key: "status",
        minWidth: 100,
        name: "Status",
        onRender: (record: ClinicalDocument) => {
          return (
            <Text>
              {record.status
                ? correspondence.ref.correspondenceStatuses.map.get(
                    record.status
                  )?.text
                : ""}
            </Text>
          );
        },
        isResizable: true
      }
    );

    const search = useCallback(
      async (query: PagingOptions) => {
        const correspondences = await correspondence.fetchCorrespondence({
          ...query,
          ...filter,
          patientId,
          total: true
        });
        if (!filter.confidential) {
          //fetchCorrespondence returns an observable
          runInAction(() => {
            correspondences.results = correspondences.results.filter(
              x =>
                x.secGroupId === undefined ||
                core.hasAccessToSecGroup(x.secGroupId)
            );
          });
        }
        return correspondences;
      },
      [correspondence, filter, patientId, core]
    );

    return (
      <SideNavDetailsList<ClinicalDocument>
        getItems={search}
        refreshKey={correspondence.correspondenceListRefreshKey}
        layoutMode={DetailsListLayoutMode.justified}
        columns={columns}
        checkButtonAriaLabel={CorrespondencesLabels.RowCheckbox}
        onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
        onRenderNoResults={() => (
          <NoDataTile
            textProps={{
              text: "No correspondence found"
            }}
            linkProps={{
              hidden: true
            }}
            showBoxShadow={false}
          />
        )}
      />
    );
  });
