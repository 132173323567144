import { computed } from "mobx";

import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireItem,
  QuestionnaireItemDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";

import { ClinicalDataToObservationConverter } from "../utils/ClinicalDataToObservationConverter.ts";

export type ClinicalToolsFormValues = Record<string, string> & {
  confidential?: boolean;
};
export class ClinicalToolFormModel {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private clinical: ClinicalStore,
    private options: {
      clinicalDataType: ClinicalDataType;
      onObservationSaved: (
        observation: Observation[],
        questionnaireResponse: QuestionnaireResponseDto
      ) => void;
    }
  ) {
    this.observationType =
      new ClinicalDataToObservationConverter().clinicalToObservation(
        options.clinicalDataType
      );
  }

  private readonly observationType: string;

  static getKey(idx: number) {
    return `q${idx + 1}`;
  }

  get clinicalDataArea() {
    return this.observationType.toLocaleLowerCase();
  }

  get initialValues(): ClinicalToolsFormValues | undefined {
    const stashedClinicalDataOfArea =
      this.clinicalRecord.stashedClinicalData &&
      this.clinicalRecord.stashedClinicalData[this.clinicalDataArea];

    return stashedClinicalDataOfArea?.items?.reduce(
      (acc: ClinicalToolsFormValues, i: QuestionnaireItem, idx: number) => {
        return {
          ...acc,
          confidential: !!stashedClinicalDataOfArea.secGroupId,
          [ClinicalToolFormModel.getKey(idx)]: i.answer
        };
      },
      {}
    );
  }

  public getLastUpdatedDate = () => {
    const clinicalData =
      this.clinicalRecord.clinicalData &&
      this.clinicalRecord.clinicalData[this.clinicalDataArea];

    return getClinicalDataLastUpdatedDate(clinicalData);
  };

  public getLastUpdatedUserId = () => {
    const clinicalData =
      this.clinicalRecord.clinicalData &&
      this.clinicalRecord.clinicalData[this.clinicalDataArea];

    return getClinicalDataLastUpdatedUserId(clinicalData);
  };

  @computed
  get stashedToolsClinicalData() {
    return this.clinicalRecord.stashedClinicalData
      ? this.clinicalRecord.stashedClinicalData[this.clinicalDataArea]
      : undefined;
  }

  @computed
  get toolsClinicalData() {
    return this.clinicalRecord.clinicalData
      ? this.clinicalRecord.clinicalData[this.clinicalDataArea]
      : undefined;
  }

  public getStashedClinicalData =
    (questionnaire: QuestionnaireDto) => (values: ClinicalToolsFormValues) => {
      const secGroupId = !!values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined;

      if (this.stashedToolsClinicalData) {
        const items = this.stashedToolsClinicalData?.items;

        return {
          [this.clinicalDataArea]: {
            ...this.stashedToolsClinicalData,
            questionnaireId: questionnaire.id,
            questionnaireCode: questionnaire.code,
            items: items?.map((item: QuestionnaireItem, idx: number) => ({
              ...item,
              answer: values[ClinicalToolFormModel.getKey(idx)]
                ? values[ClinicalToolFormModel.getKey(idx)].toString()
                : undefined
            })),
            secGroupId
          }
        };
      } else {
        return {
          [this.clinicalDataArea]: {
            items: questionnaire.items?.map(
              (_item: QuestionnaireItemDto, idx: number) => {
                return {
                  answer: values[ClinicalToolFormModel.getKey(idx)]
                    ? values[ClinicalToolFormModel.getKey(idx)].toString()
                    : undefined
                };
              }
            ),
            secGroupId
          }
        };
      }
    };

  onSubmit =
    (questionnaire: QuestionnaireDto) =>
    async (values: ClinicalToolsFormValues) => {
      const stashedClinicalData =
        this.getStashedClinicalData(questionnaire)(values);

      const eTag = this.toolsClinicalData?.eTag;

      const payload = {
        [this.clinicalDataArea]: {
          ...stashedClinicalData[this.clinicalDataArea],
          eTag,
          questionnaireId: questionnaire.id,
          questionnaireCode: questionnaire.code,
          items: stashedClinicalData[this.clinicalDataArea].items.map(
            (i: QuestionnaireItem, idx: number) => ({
              answer: i.answer,
              id: i.id,
              questionnaireItemId: questionnaire.items[idx].id
            })
          )
        }
      };

      await this.clinicalRecord.saveClinicalData(payload);
      const openedEncId = this.clinicalRecord.openEncounter?.id;
      const result = await this.clinical.getObservations({
        patientId: this.clinicalRecord.patient?.id,
        encounterIds: openedEncId ? [openedEncId] : undefined,
        types: [this.observationType]
      });

      const observations = result.results;

      if (observations.length === 0) {
        this.clinical.root.notification.error(
          "Unable to retrieve the total clinical tool score."
        );
      } else {
        this.clinicalRecord.updateObservations(observations);
        this.options.onObservationSaved(
          observations,
          payload[this.clinicalDataArea]
        );
      }
    };

  onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      this.options.clinicalDataType
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      this.clinicalDataArea as keyof EncounterClinicalData
    ]);
  };
}
