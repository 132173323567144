import { AppointmentEncountersDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class AppointmentEncounters extends Model<AppointmentEncountersDto> {
  get encounterId() {
    return this.dto.encounterId;
  }

  get calendarEventId() {
    return this.dto.calendarEventId;
  }

  get providerId() {
    return this.dto.providerId;
  }

  get businessRole() {
    return this.dto.businessRole;
  }
}
