import { DateTime } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export interface CalendarEventRowOpts {
  onShowCallout: (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
    event: CalendarEvent
  ) => void;
  onShowContext: (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
    event: CalendarEvent
  ) => void;
}

export interface DidNotArriveItem {
  cancellationDateTime: DateTime | undefined;
  cancellationReasonId: string | undefined;
  cancellationText: string | undefined;
  calendarEvent: CalendarEvent;
  patient: Contact | undefined;
  location: string | undefined;
  provider: User | undefined;
  cancelled: boolean;
  patientId: string;
}

export enum DidNotArriveLabel {
  Date = "Date",
  Status = "Status",
  Reason = "Reason",
  Patient = "Patient",
  Invoice = "Invoice",
  Actions = "Actions",
  Provider = "Provider",
  Location = "Location",
  NoticeBy = "Notice by",
  Scheduled = "Scheduled",
  Reschedule = "Reschedule",
  NewPayment = "New payment",
  NewInvoice = "New invoice",
  Rescheduled = "Rescheduled",
  markAsRescheduled = "Mark as rescheduled",
  markedAsRescheduled = "Marked as rescheduled"
}
