import { AxiosInstance } from "@bps/http-client";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { withoutFields } from "@libs/api/utils/paging.utils.ts";
import {
  BhbAppointmentTypeDto,
  BhbAppointmentTypeSortOrderDto,
  BhbLocationDto,
  BhbProviderDto,
  BhbProviderWithoutLocationDto,
  PatchBhbLocationDto
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { IBhbGateway } from "@libs/gateways/bhb/bhbGateway.interface.ts";

export class BhbGateway implements IBhbGateway {
  constructor(private api: AxiosInstance) {}

  async putAppointmentTypes(
    locationId: string,
    apptType: BhbAppointmentTypeDto
  ): Promise<BhbAppointmentTypeDto> {
    return (
      await this.api.put<BhbAppointmentTypeDto>(
        `location/${locationId}/appointmenttype/${apptType.id}`,
        apptType,
        {
          headers: {
            eTag: apptType.eTag
          }
        }
      )
    ).data;
  }

  async getAppointmentTypesForLocation(
    locationId: string
  ): Promise<BhbAppointmentTypeDto[]> {
    return await this.api
      .get<BhbAppointmentTypeDto[]>(`location/${locationId}/appointmenttype`)
      .then(response => {
        return response.data;
      });
  }

  async getAppointmentTypeForLocation(
    locationId: string,
    appointmentTypeId: string
  ): Promise<BhbAppointmentTypeDto> {
    return await this.api
      .get<BhbAppointmentTypeDto>(
        `location/${locationId}/appointmenttype/${appointmentTypeId}`
      )
      .then(response => {
        return response.data;
      });
  }

  async getLocation(locationId: string): Promise<BhbLocationDto> {
    return await this.api
      .get<BhbLocationDto>(`location/${locationId}`)
      .then(response => {
        return response.data;
      });
  }

  async patchLocation(location: PatchBhbLocationDto): Promise<BhbLocationDto> {
    const { id, eTag, ...data } = location;
    return await this.api
      .patch<BhbLocationDto>(`/location/${id}`, data, {
        headers: {
          eTag: location.eTag
        }
      })
      .then(x => x.data);
  }

  async getProvidersForLocation(
    locationId: string
  ): Promise<BhbProviderWithoutLocationDto[]> {
    return await this.api
      .get<BhbProviderWithoutLocationDto[]>(`location/${locationId}/providers`)
      .then(response => {
        return response.data;
      });
  }

  async updateProviderForLocation(
    provider: BhbProviderDto
  ): Promise<BhbProviderDto> {
    const { id, eTag, ...data } = provider;
    return await this.api
      .put<BhbProviderDto>(`/provider/${id}`, data, {
        headers: {
          eTag: provider.eTag
        }
      })
      .then(x => x.data);
  }

  async updateSortOrder(
    locationId: string,
    sortRequest: BhbAppointmentTypeSortOrderDto[]
  ): Promise<BhbAppointmentTypeDto[]> {
    const { data } = await this.api.put(
      `location/${locationId}/appointmenttype/sort`,
      sortRequest
    );

    return data;
  }

  uploadProviderPhoto = async (
    locationId: string,
    providerId: string,
    file: File
  ): Promise<string> => {
    const formData = new FormData();
    formData.append("fileData", file);

    const { data } = await this.api.post(
      `provider/${providerId}/location/${locationId}/uploadPhoto`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return data.link;
  };

  getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  getAreaOfInterest() {
    return this.getRef("AreaOfInterest");
  }

  uploadLogo = async (locationId: string, file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("fileData", file);
    const { data } = await this.api.post(
      `location/${locationId}/uploadLogo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return data.link;
  };
}
