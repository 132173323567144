import { action, observable } from "mobx";

import { flatten } from "@bps/fluent-ui";
import { isDefined, unique } from "@bps/utils";
import {
  ClinicalDataType,
  CustomClinicalToolClinicalDataDto,
  ObservationType,
  SOTAPSectionText
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { CustomToolTrendAnswers } from "@shared-types/clinical/custom-tool-trend-answers.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";

import { ClinicalDataToObservationConverter } from "../../clinical-tools/utils/ClinicalDataToObservationConverter.ts";
import { CustomToolFieldData } from "../SOTAP.types.ts";
import { createCustomToolDynamicObject } from "../SOTAP.utils.ts";
import { SOTAPMultiProviderClinicalDataHelper } from "./SOTAPMultiProviderClinicalDataHelper .ts";

export class SOTAPFormCommonModel {
  constructor(protected clinicalRecord: ClinicalRecord) {
    this.multiProviderHelper = new SOTAPMultiProviderClinicalDataHelper(
      this.clinicalRecord
    );
  }
  protected converter = new ClinicalDataToObservationConverter();
  multiProviderHelper: SOTAPMultiProviderClinicalDataHelper;

  filledClinicalDataType: ClinicalDataType[] = [];
  customClinicalToolData: CustomClinicalToolClinicalDataDto[] = [];

  @observable
  public currentSection: SOTAPSectionText = SOTAPSectionText.Subjective;

  @action
  public setCurrentSection = (value: SOTAPSectionText) => {
    this.currentSection = value;
  };

  private loadClinicalToolsData = async (
    encounters: Encounter[] | undefined,
    defaultTools?: ClinicalDataType[] | undefined
  ) => {
    const getEncounterObservationData = async (encounterId: string) => {
      const result = await this.clinicalRecord.clinical.getObservations({
        patientId: this.clinicalRecord.patient?.id,
        encounterIds: [encounterId],
        types: [
          ObservationType.GRCS,
          ObservationType.PSFS,
          ObservationType.DASH,
          ObservationType.CustomClinicalTool,
          ObservationType.K10,
          ObservationType.NPRS,
          ObservationType.OREBRO
        ]
      });

      return result.results;
    };

    if (encounters) {
      const linkedEncounterIds = this.clinicalRecord
        .filterEncountersOnCurrentRole(encounters)
        .map(x => x.id);

      const promises = linkedEncounterIds.map(x =>
        getEncounterObservationData(x)
      );

      const respond = await Promise.all(promises);
      const observations = flatten(respond.filter(isDefined));

      const distinctObservationTypes = unique(observations.map(x => x.type));

      const completedToolTypes = distinctObservationTypes.map(x =>
        this.converter.observationToClinical(x as ObservationType)
      );

      defaultTools?.forEach(dataType => {
        if (!completedToolTypes.find(x => x === dataType)) {
          completedToolTypes.unshift(dataType);
        }
      });
      this.filledClinicalDataType = completedToolTypes;
    }
  };

  public loadCurrentEncounterClinicalToolsData = async (
    businessRoleCode: string | undefined,
    defaultTools?: ClinicalDataType[] | undefined
  ) => {
    const linkedEncounters = this.clinicalRecord.linkedEocEncounters;

    await this.loadClinicalToolsData(linkedEncounters, defaultTools);
    this.customClinicalToolData =
      await this.loadCustomClinicalToolData(linkedEncounters);
  };

  public loadEpisodeOfCareClinicalToolsData = async (
    episodeOfCareId: string,
    businessRoleCode: string | undefined,
    defaultTools?: ClinicalDataType[] | undefined
  ) => {
    const encounters = await this.clinicalRecord.clinical.getEncounters({
      patientId: this.clinicalRecord.id,
      episodeOfCareIds: [episodeOfCareId]
    });

    await this.loadClinicalToolsData(encounters, defaultTools);
    await this.loadCustomClinicalToolData(encounters);
  };

  private loadCustomClinicalToolData = async (
    encounters: Encounter[] | undefined
  ) => {
    const encounterId = this.clinicalRecord.openEncounter?.id;

    const onlyThisEncounter =
      encounters && encounters.length === 1 && encounters[0].id === encounterId;

    if (encounters && encounters.length > 0 && !onlyThisEncounter) {
      const linkedEncounterIds = encounters
        .filter(x => x.id !== encounterId)
        .map(x => x.id);

      if (linkedEncounterIds.length > 0) {
        const promises = linkedEncounterIds.map(x =>
          this.getEncounterCustomToolData(x, this.clinicalRecord)
        );

        const respond = await Promise.all(promises);
        const allCustomToolData:
          | CustomClinicalToolClinicalDataDto[]
          | undefined[] = respond.filter(isDefined);

        return allCustomToolData.sort((a, b) =>
          a.createLog?.createdDateTime &&
          b.createLog?.createdDateTime &&
          a.createLog?.createdDateTime < b.createLog?.createdDateTime
            ? 1
            : -1
        );
      }
    }
    return [];
  };

  public getCustomClinicalTools = (
    clinicalRecord: ClinicalRecord,
    allCustomToolData: CustomClinicalToolClinicalDataDto[]
  ) => {
    // this is only the custom tool results for this encounter we need to get the custom tools results for all the linked encounters
    const customToolData = clinicalRecord.clinicalData?.customClinicalTool;
    const customClinicalTools: CustomToolFieldData[] = [];
    if (customToolData && customToolData.tools.length > 0) {
      const customToolContextData =
        clinicalRecord.clinicalData?.customClinicalToolContext;

      if (customToolContextData) {
        customToolData.tools.forEach(tool => {
          const contextData = customToolContextData.contexts.find(
            c => c.contextId === tool.contextId
          );

          if (contextData) {
            const name = contextData.name;

            const fieldData: CustomToolFieldData = {
              name,
              result: tool.result,
              contextId: contextData.contextId
            };

            customClinicalTools.push(fieldData);
          }
        });
      }
    }

    return this.getCustomClinicalToolsTrendData(
      customClinicalTools,
      allCustomToolData,
      clinicalRecord
    );
  };

  private getCustomClinicalToolsTrendData = (
    thisEncounterResults: CustomToolFieldData[],
    allCustomToolData: CustomClinicalToolClinicalDataDto[],
    clinicalRecord: ClinicalRecord
  ) => {
    let columns: { key: string; name: string }[] = [];
    let answersArray: CustomToolTrendAnswers[] = [];
    const encounterId = clinicalRecord.openEncounter?.id;
    const linkedEncounters = clinicalRecord.linkedEocEncounters;
    if (linkedEncounters && linkedEncounters.length > 0) {
      const linkedEncounterIds = linkedEncounters
        .filter(x => x.id !== encounterId)
        .map(x => x.id);

      const customToolContextData =
        clinicalRecord.clinicalData?.customClinicalToolContext;

      if (customToolContextData && allCustomToolData) {
        const linkedContexts = customToolContextData.contexts.filter(x =>
          linkedEncounterIds.includes(x.createLog?.createdEncounterId ?? "")
        );

        const result = createCustomToolDynamicObject(
          linkedContexts,
          allCustomToolData,
          thisEncounterResults
        );
        answersArray = result.answersArray;
        columns = result.columns;
      }
    } else {
      // if no custom tools have been recorded on any encounter
      if (thisEncounterResults && thisEncounterResults.length > 0) {
        for (let i = 0; i < thisEncounterResults.length; i++) {
          const questionAnswers: CustomToolTrendAnswers = {
            contextId: thisEncounterResults[i].contextId,
            name: thisEncounterResults[i].name,
            isReadOnly: true,
            thisEncounterResult: thisEncounterResults[i].result
          };

          answersArray.push(questionAnswers);
        }
      }
    }

    return { answersArray, columns };
  };

  private getEncounterCustomToolData = async (
    encounterId: string,
    clinicalRecord: ClinicalRecord
  ) => {
    const data = await clinicalRecord.clinical.getEncounterClinicalData({
      encounterId,
      types: [ClinicalDataType.CustomClinicalTool]
    });

    if (data && data.customClinicalTool) {
      return data.customClinicalTool;
    }
    return undefined;
  };
}
