import { computed } from "mobx";

import {
  BusinessRoleClasses,
  LicenceTypeCodes,
  Permission,
  SecurityRoleCode
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { User } from "@stores/core/models/User.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  BusinessRoleProfile,
  CreateUserFormValues
} from "../create-user-form-dialog/CreateUserForm.types.ts";

export class UserHelper {
  constructor(private root: RootStore) {}

  @computed
  get core() {
    return this.root.core;
  }

  @computed
  get notification() {
    return this.root.notification;
  }

  @computed
  get practice() {
    return this.root.practice;
  }

  @computed
  get routing() {
    return this.root.routing;
  }

  getRequiredLicenceCodes = (userProviderRoles: BusinessRoleProfile[]) => {
    return [
      ...new Set(
        userProviderRoles.map(x => x.profile?.requiredLicenceTypeCodes).flat()
      )
    ];
  };

  onCreateUserFormSubmit = async (values: CreateUserFormValues) => {
    const {
      phone: workPhone,
      medicareProviderNumber,
      ahpraNumber,
      ...rest
    } = values;

    const communications: CommunicationDto[] = [];
    if (workPhone) {
      communications.push({
        type: CommunicationType.WorkPhone,
        value: workPhone,
        preferred: false
      });
    }

    const newUser = await this.core.addUser({
      communications,
      ...rest
    });

    if (this.core.hasPermissions(Permission.ProviderWrite)) {
      await this.practice.addProvider({
        id: newUser.id,
        medicareProviderNumber,
        ahpraNumber,
        discipline: "DP"
      });
    }

    if (this.core.hasPermissions(Permission.LicencingAllowed)) {
      const userProviderRoles = this.providerBusinessRoleCodes.filter(
        providerBr =>
          !!values.businessRoles &&
          values.businessRoles.some((br: string) => br === providerBr.code)
      );

      const requiredLicenceCodes =
        this.getRequiredLicenceCodes(userProviderRoles);

      const licenceCodeToAssign = requiredLicenceCodes?.find(
        code => this.availableLicences?.some(l => l.licenceTypeCode === code)
      );

      if (!!licenceCodeToAssign) {
        await this.core.assignLicence({
          userId: newUser.id,
          licenceType: licenceCodeToAssign
        });
      }
    }

    this.notification.success(
      `${newUser.firstName} ${newUser.lastName} has been added to users.`
    );

    this.routing.push(routes.settings.users.user.path({ id: newUser.id }));

    return;
  };

  @computed
  get availableLicences() {
    return this.core.licences.filter(x => x.isValid && !x.userId);
  }

  @computed
  get providerBusinessRoleCodes() {
    return this.core.catalogBusinessRoles
      .filter(
        x =>
          x.classCode === BusinessRoleClasses.Provider &&
          x.profiles.some(
            p =>
              p.requiredLicenceTypeCodes?.length > 0 &&
              p.countryCode === this.core.tenantDetails?.country
          )
      )
      .map(x => {
        const brProfile: BusinessRoleProfile = {
          code: x.code,
          profile: x.profiles.find(
            p => p.countryCode === this.core.tenantDetails?.country
          )
        };
        return brProfile;
      });
  }

  hasActiveLicence(userId: string) {
    return this.core.licences.some(x => x.userId === userId && x.isValid);
  }

  getValidLicence(userId: string) {
    return this.core.licences.find(x => x.userId === userId && x.isValid);
  }

  assignLicence = async (user: User) => {
    const promises = [];

    // Assign the licence to the user
    promises.push(
      this.core.assignLicence({
        userId: user.id,
        licenceType: LicenceTypeCodes.AlliedNZ
      })
    );

    // Add the licence workflow contributor role to the user
    promises.push(
      this.core.updateUser({
        id: user.id,
        securityRoles: [
          ...user.securityRoles,
          SecurityRoleCode.LicensedWfContributor
        ]
      })
    );

    await Promise.all(promises);
  };

  revokeLicence = async (user: User, licenceId: string) => {
    const promises = [];
    // Remove the assigned licence
    promises.push(this.core.removeLicence(licenceId));

    // Remove the licence workflow contributor role from the user
    const securityRoles = user.securityRoles.filter(
      x => x !== SecurityRoleCode.LicensedWfContributor
    );
    promises.push(
      this.core.updateUser({
        id: user.id,
        securityRoles
      })
    );
    await Promise.all(promises);
  };

  updateLicence = async (userId: string) => {
    const user = await this.core.getUser(userId);
    if (!user) return;

    const assignedLicence = this.getValidLicence(userId);

    // Does the user have a vaild licence? if yes, revoke the assigned licence, otherwise assign a new licence the user
    assignedLicence
      ? await this.revokeLicence(user, assignedLicence.id)
      : await this.assignLicence(user);

    // Get the latest licences
    await this.core.getLicences({ isInactive: false });
  };

  hasClinViewSecurityRole = this.core.user?.hasClinViewSecurityRole;

  hasPracWorkflowOrInfoContributorRole =
    this.core.hasSecurityRoles([SecurityRoleCode.PracInfoContributor]) ||
    this.core.hasSecurityRoles([SecurityRoleCode.PracWorkflowsContributor]);
}
