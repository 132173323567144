export enum AppInsightsEventNames {
  claimLinkedToAppointment = "Claim Linked To Appointment Count",
  nextAvailableApptCount = "Next Available Appointment Count",
  appointmentFilterCount = "Appointment Filter Count",
  bookingFilterToggling = "Booking Filter Toggling",
  dateRangePickerSelection = "Dates Range Picker Selection",
  formDeployed = "Form deployed",
  encounterStarted = "Encounter Started",
  encounterUpdated = "Encounter Updated",
  encounterCompleted = "Encounter Completed"
}

export enum AppointmentStartingPoints {
  CalendarDoubleClick = "Calendar Double Click",
  NavBarAddAppointmentButton = "Nav Bar Add Appointment Button",
  CalendarAddAppointmentButton = "Calendar Add Appointment Button",
  PatientSearchAddAppointmentButton = "Patient Search Add Appointment Button",
  RescheduleAppointmentButton = "Reschedule appointment button",
  FollowupClinicalReminderActionButton = "Follow-up Clinical Reminder Action Button"
}

export enum ClaimLinkedToAppointment {
  NewClaimLinkedToAppointment = "New Claim Linked To Appointment",
  ExistingClaimLinkedToAppointment = "Existing Claim Linked To Appointment"
}

export enum AppointmentSearch {
  SearchBox = "Search Box"
}

export enum EncounterStartingPoints {
  peopleListAddEncounter = "People List",
  appointmentAddEncounter = "Appointment"
}

export enum NoteStatus {
  Incompleted = "Incompleted",
  Completed = "Completed"
}
