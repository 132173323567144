import { Spinner } from "@bps/fluent-ui";
import { ObservationType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { ClincialToolViewData } from "../clinical-tool-view/ClinicalToolViewDataFetcher.ts";
import { getClinicalToolViewData } from "../clinical-tool-view/utils.ts";
import { OREBROQuestions } from "./OREBROQuestions.tsx";

export interface OREBROQuestionnaireViewProps {
  encounterId: string;
}

export const OREBROQuestionnaireView: React.FunctionComponent<
  OREBROQuestionnaireViewProps
> = ({ encounterId }) => {
  return (
    <DataFetcher<ClincialToolViewData>
      fetch={getClinicalToolViewData(encounterId, ObservationType.OREBRO)}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <ClinicalToolView
          title="Orebro Musculoskeletal Pain Screening Questionnaire"
          summary={summary}
          isConfidential={isConfidential}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          <OREBROQuestions questionnaire={questionnaire} answers={answers} />
        </ClinicalToolView>
      )}
    </DataFetcher>
  );
};
