import { useContext } from "react";

import { ConfirmDialog } from "@bps/fluent-ui";
import { ClaimsScreenContext } from "@modules/acc/screens/claims/context/ClaimsScreenContext.ts";

interface ClaimDeleteDialogProps {
  onDismiss: () => void;
  deleteClaim: () => Promise<void>;
  text?: string;
}

export const ClaimDeleteDialog: React.FC<ClaimDeleteDialogProps> = ({
  onDismiss,
  deleteClaim,
  text
}) => {
  const { deletingClaim } = useContext(ClaimsScreenContext);

  const claimText =
    deletingClaim?.claimNumber === undefined
      ? "Do you want to permanently delete this claim?"
      : text;

  return (
    <ConfirmDialog
      hidden={false}
      confirmButtonProps={{
        text: "Delete claim"
      }}
      maxWidth={270}
      styles={({ theme }) => ({
        root: {
          ".ms-Dialog-header": {
            height: 36
          },
          ".ms-Dialog-subText": { color: theme.palette.neutralPrimary }
        }
      })}
      cancelButtonProps={{ text: "Cancel" }}
      dialogContentProps={{
        subText: claimText
      }}
      onConfirm={deleteClaim}
      onCancel={onDismiss}
    />
  );
};
