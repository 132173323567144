import { FunctionComponent, HTMLAttributes, useMemo, useState } from "react";

import {
  flatten,
  GenericSelect,
  GenericSelectProps,
  GenericSelectTypeSwitcher,
  Option,
  PersonaSize
} from "@bps/fluent-ui";
import { getUniqueObjectsByKeys, isDefined, useAsync } from "@bps/utils";
import {
  ContactResultItemDto,
  ContactStatus
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

interface InternalContactsSelectProps
  extends Pick<
      GenericSelectProps,
      | "showCountCoin"
      | "styles"
      | "label"
      | "required"
      | "calloutWidth"
      | "isAllSelected"
      | "onAllSelected"
      | "disabled"
      | "onFocus"
      | "onBlur"
      | "showAllSelected"
      | "hideSelectAllButton"
    >,
    Pick<HTMLAttributes<HTMLElement>, "id" | "datatype"> {
  contactFilter?: Omit<PatientContactFilter, "search">;
  placeholder?: string;
  take?: number;
  includeInactive?: boolean;
  includeDeceased?: boolean;
  className?: string;
  resultFilterPredicate?: (contact: ContactResultItemDto) => boolean;
}

export type ContactsSelectProps =
  GenericSelectTypeSwitcher<InternalContactsSelectProps>;

export const ContactsSelect: FunctionComponent<ContactsSelectProps> = ({
  includeDeceased,
  includeInactive,
  contactFilter,
  take = 10,
  placeholder,
  resultFilterPredicate,
  ...rest
}) => {
  const statuses: ContactStatus[] = [
    ContactStatus.Active,
    ContactStatus.Inactive,
    ContactStatus.Deceased
  ].filter(
    s =>
      !(!includeInactive && s === ContactStatus.Inactive) &&
      !(!includeDeceased && s === ContactStatus.Deceased)
  );

  const { practice } = useStores();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const getContacts = async (value: string) => {
    const res = await practice.fetchContacts({
      filter: {
        take,
        statuses,
        ...contactFilter,
        search: value
      },
      // excluded previously selected contacts
      resultFilterPredicate: dto =>
        resultFilterPredicate
          ? resultFilterPredicate(dto) && hasSelected(dto)
          : true
    });
    return res.results;
  };

  const {
    data: contacts = [],
    isLoading,
    error
  } = useAsync(async () => {
    if (!search) {
      if (!rest.selectedKeys || !rest.selectedKeys.length) return [];
      if (!!pickedOptions.length) return pickedOptions;
      return await practice.getContactsById(flatten([rest.selectedKeys]));
    }
    return await getContacts(search);
  }, [search]);

  const hasSelected = (dto: ContactResultItemDto) => {
    return !!rest.selectedKeys && !rest.selectedKeys.includes(dto.id);
  };

  const pickedOptions = useMemo(() => {
    if (!rest.selectedKeys || !practice.contactsMap.size) return [];

    const ids = flatten([rest.selectedKeys]);

    return ids.map(id => practice.contactsMap.get(id)).filter(isDefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practice.contactsMap, practice.contactsMap.size, rest.selectedKeys]);

  const contactsDataOptions: Option[] = useMemo(() => {
    const allContacts = getUniqueObjectsByKeys({
      array: [...pickedOptions, ...contacts],
      keys: ["id"]
    });

    return allContacts.map(data => ({
      key: data.id,
      text: data.reversedName,
      data
    }));
  }, [contacts, pickedOptions]);

  return (
    <GenericSelect
      searchBoxProps={{
        onSearch: setSearch
      }}
      placeholder={placeholder}
      options={contactsDataOptions}
      errorMessage={error?.message}
      loading={isLoading}
      onRenderOption={(option: Option<Contact>) => {
        return (
          <Persona
            id={option.data!.id}
            size={PersonaSize.size24}
            text={option.text}
            imageInitials={option.data!.initials}
          />
        );
      }}
      {...rest}
    />
  );
};
