import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { dataAttribute, DataAttributes, Link } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ObservationDto,
  QuestionnaireItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { Rand36ScoreModal } from "./Rand36ScoreModal.tsx";

export interface Rand36ClinicalToolListItemProps {
  observation: ObservationDto;
  clinicalRecord: ClinicalRecord;
}

export const Rand36ClinicalToolListItem: React.FC<Rand36ClinicalToolListItemProps> =
  observer(({ observation, clinicalRecord }) => {
    const [observations, setObservations] = useState<ObservationDto[]>([]);
    const [answers, setAnswers] = useState<QuestionnaireItem[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const { clinical } = useStores();

    const onClose = () => {
      setShowModal(false);
    };

    const onOutcomeClicked = async () => {
      const relatedObservations = clinicalRecord.observations.filter(
        x =>
          x.encounterId === observation.encounterId &&
          x.type === observation.type
      );

      const clinicalDataResult = await clinical.getEncounterClinicalData(
        {
          encounterId: observation.encounterId,
          types: [ClinicalDataType.RAND36]
        },
        { ignoreCache: true }
      );
      if (clinicalDataResult) {
        const values = clinicalDataResult.rand36?.items;

        if (values) {
          setObservations(relatedObservations);
          setAnswers(values);
          setShowModal(true);
        }
      }
    };

    return (
      <>
        <Link
          {...dataAttribute(DataAttributes.Element, "open-result-link")}
          onClick={() => {
            onOutcomeClicked();
          }}
        >
          Outcome
        </Link>

        {showModal && (
          <Rand36ScoreModal
            observations={observations}
            answers={answers}
            onClose={onClose}
          />
        )}
      </>
    );
  });
