export enum ClaimFormLabels {
  nhi = "NHI",
  sex = "Sex",
  name = "Name",
  date = "Date",
  side = "Side",
  spent = "Spent",
  status = "Status",
  signed = "Signed",
  address = "Address",
  employer = "Employer",
  accident = "Accident",
  provider = "Provider",
  lastName = "Last name",
  sport = "Name of sport",
  otherDiagnosis = "Other",
  firstName = "First name",
  injurySection = "Injury",
  employment = "Employment",
  middleName = "Middle name",
  description = "Description",
  undiagnosed = "Undiagnosed",
  injuryComments = "Comments",
  patientDeclaration = "Date",
  declaration = "Declaration",
  addressType = "Address type",
  serviceItem = "Service item",
  dateOfBirth = "Date of birth",
  businessName = "Business name",
  patientEmail = "Email address",
  providerType = "Provider type",
  employerName = "Employer name",
  patientAddressSuburb = "Suburb",
  authorisation = "Authorisation",
  patientAddressCity = "Town/City",
  providerDeclaration = "Declared",
  patientAddressCountry = "Country",
  patientEthnicityCode = "Ethnicity",
  initialConsult = "Initial consult",
  otherEmployment = "Please specify",
  referralReason = "Referral reason",
  paidEmployment = "Paid employment",
  physiotherapy = "e.g. Physiotherapy",
  businessAddress = "Business address",
  patientAddressPostCode = "Post code",
  patientHomePhoneNumber = "Home phone",
  patientWorkPhoneNumber = "Work phone",
  patientMobileNumber = "Mobile number",
  searchForPerson = "Search for person",
  sceneOfAccident = "Scene of accident",
  accidentLocation = "Accident location",
  basicInformation = "Basic information",
  editDemographics = "Edit demographics",
  primaryDiagnosis = "Primary diagnosis",
  occupation = "What is your occupation?",
  buildingFacility = "Building / facility",
  searchForProvider = "Search for provider",
  bookInitialConsult = "Book initial consult",
  additionalDiagnoses = "Additional diagnoses",
  describeTheAccident = "Describe the accident",
  betweenCharacter = "Between 1-255 characters",
  equipmentAndOrthotics = "Equipment & orthotics",
  workInjury = "Is the accident related to work?",
  patientAddressStreet1 = "Street name and number",
  patientAddressStreet2 = "Building / facility name",
  inPaidEmployment = "Are you in paid employment?",
  sportingInjuryPlaceholder = "e.g, Bungee Jumping",
  workType = "How would you describe the type of work?",
  NoEmployersLinked = "No employers are linked to this",
  serviceItemsInputPlaceholder = "e.g. PTE1 Crutches Hire",
  searchProvider = "Search provider type e.g. Hand therapist",
  searchCodeOrDescription = "Search for code or description",
  employmentStatus = "How would you describe your employment?",
  enableReferralsOut = "Would you like to refer this patient?",
  sceneOfAccidentPlaceholder = "e.g. Home, Farm, School, Other",
  telehealthConsultation = "Is this a Telehealth consultation?",
  sportingInjury = "Did the accident occur while playing sport?",
  describeHowTheAccidentOccurred = "Describe how the accident occurred",
  assistanceRequired = "Is further assistance required by the patient?",
  medicalTreatmentInjury = "Was the injury caused by medical treatment?",
  patientConsentForTelehealth = "Patient consent for Telehealth consults",
  accContactProvider = "Should ACC contact the provider about this claim?",
  occupationPlaceholder = "e.g. Sales & Marketing Manager, Retired, Unemployed",
  accidentDescriptionPlaceholder = "e.g. ‘fell off a ladder’, rather than ‘doing DIY’",
  involvesVehicle = "Did the accident involve a moving motor vehicle on a public road?",
  accidentLocationPlaceholder = "e.g. Waikato, Taranaki, Overseas, In the Air, At Sea...",
  gradualProcessInjury = "Is this claim for a work-related gradual process, disease, or infection?",
  patientClaimAuthorised = "The patient authorises the treatment provider to lodge the claim for the patient.",
  telehealthDeclared = "Do you declare that you have provided true and correct information and you'll tell ACC if your situation changes?",
  telehealthAuthorisedHealthProfessional = "Do you authorise me as your (name of health profession: GP, physiotherapist, etc) to lodge your claim with ACC?",
  patientConsentForTelehealthDescription = "Please read out the following statement to your patient and record your patient's response using the checkboxes below:",
  patientDeclared = "The patient declares that the information the patient has given about this claim is true and correct and that the patient has not withheld any information.",
  telehealthAuthorisedRecords = "Do you authorise your records to be collected or disclosed to ACC to help determine cover for your claim, determine what you'll be entitled to, or for research purposes (such as injury prevention, or assessment, and rehabilitation)?",
  providerDeclared = "I certify that, on the date shown, I have personally examined the patient and that in my opinion the condition is the result of an accident.  I also certify that the patient (or their representative) has signed the Patient Declaration and Consent and has authorised me to lodge the claim on their behalf.",
  patientAccContactAuthorised = "The patient authorises the ACC to contact anyone who holds relevant information, including any external agencies or service providers such as medical practitioners, specialists, New Zealand Police and Treatment Providers, IRD, WINZ, Assessment Agencies, employers and witnesses to the accident.",
  patientInfoAuthorised = "The patient authorises the collection and release of any information about the patient to the extent that this is needed to prevent future injuries, determine cover and/or assess the patient's entitlement to compensation, rehabilitation assistance, medical treatment and/or the appropriate level of care and personal attention the patient should receive."
}

export enum ClaimFormDataAttributes {
  saveDraft = "save-draft",
  patientSex = "patient-sex",
  claimNumber = "claim-number",
  claimStatus = "claim-status",
  addressType = "address-type",
  providerType = "provider-type",
  patientEmail = "patient-email",
  patientPicker = "patient-picker",
  referralField = "referral-field",
  providerPicker = "provider-picker",
  claimModalForm = "claimModal-form",
  patientLastName = "patient-last-name",
  patientNhiNumber = "patient-nhi-number",
  patientFirstName = "patient-first-name",
  patientMiddleName = "patient-middle-name",
  overviewEditButton = "overview-edit-button",
  patientDateOfBirth = "patient-date-of-birth",
  patientAddressCity = "patient-address-city",
  patientMobileNumber = "patient-mobile-number",
  patientAddressSuburb = "patient-address-suburb",
  insurerContactPicker = "insurer-contact-picker",
  patientEthnicityCode = "patient-ethnicity-code",
  patientAddressStreet1 = "patient-address-street1",
  patientAddressCountry = "patient-address-country",
  patientAddressPostCode = "patient-address-post-code",
  patientWorkPhoneNumber = "patient-work-phone-number",
  patientHomePhoneNumber = "patient-home-phone-number",
  personalDetailsMessageBar = "personal-details-message-bar"
}

export enum ClaimsCardIds {
  overview = "overview",
  referralsOut = "referralsOut",
  personalDetails = "personalDetails",
  patientDeclaration = "patientDeclaration",
  referralInformation = "referralInformation",
  providerDeclaration = "providerDeclaration",
  accidentAndEmploymentDetails = "accidentAndEmploymentDetails",
  injuryDiagnosisAndAssistance = "injuryAndDiagnosisAndAssistance",
  claimAdjustment = "claimAdjustment"
}

export enum ClaimSectionHeadings {
  consults = "Consults",
  overview = "Overview",
  discharges = "Discharges",
  claimAdjustment = "ACC32",
  referralsOut = "Referrals out",
  claimReviews = "Claim reviews",
  purchaseOrders = "Purchase orders",
  personalDetails = "Personal details",
  patientDeclaration = "Patient declaration",
  referralInformation = "Referral Information",
  providerDeclaration = "Provider declaration",
  currentStatusAndPrognosis = "Current status & prognosis",
  accidentAndEmploymentDetails = "Accident & employment details",
  injuryDiagnosisAndAssistance = "Injury diagnosis & assistance"
}

export enum ClaimFormSideRailLabels {
  referralsOut = "Referrals out",
  personalDetails = "Personal details",
  injuryDiagnosisAndAssistance = "Injury diagnosis & assistance",
  patientDeclaration = "Patient declaration",
  providerDeclaration = "Provider declaration",
  referralInformation = "Referral information",
  accidentAndEmploymentDetails = "Accident & employment details"
}

export enum ReferralInformationLabels {
  claimNumber = "Claim number",
  injuryDate = "Injury date",
  referralDate = "Referral date",
  referralProvider = "Name of referral provider"
}

export enum VisitsLabels {
  allocated = "Allocated",
  extensions = "Extensions",
  totalBooked = "Total booked",
  totalBilled = "Total billed",
  previousInitials = "Previous initials",
  previousFollowUps = "Previous follow-ups"
}

export enum ClaimManagementModalFormLabels {
  sendAccForm = "Send ACC45 form for the patient to fill out",
  referralClaimNumber = "Referral claim number",
  bookInitialConsult = "Book initial consult",
  initialConsult = "Initial consult",
  primaryDiagnosis = "Primary diagnosis",
  additionalDiagnosis = "Additional diagnoses",
  referralFrom = "Referral from",
  claimStatus = "Claim status",
  notReferred = "Not referred",
  claimNumber = "Claim number",
  accPlaceholder = "e.g. ACC",
  addInsurer = "Add insurer",
  discharged = "Discharged",
  provider = "Provider",
  referral = "Referral",
  referred = "Referred",
  insurer = "Insurer",
  private = "Private",
  none = "None"
}

export enum ModalLabels {
  heading = "Injury setup"
}

export enum ClaimPivotLinkKeys {
  management = "summary",
  acc45 = "acc45"
}

export enum ClaimManagementSideRailLabels {
  Consults = "Consults",
  overview = "Overview",
  claimAdjustment = "ACC32",
  referralInformation = "Referral information",
  PurchaseOrders = "Purchase orders"
}

export enum ClaimManagementCardIds {
  visits = "Visits",
  overview = "Overview",
  claimAdjustment = "ClaimAdjustment",
  referralInformation = "ReferralInformation",
  purchaseOrders = "PurchaseOrders"
}

export enum ClaimExtraErrorMessages {
  UpdatePracticeSettings = "Update practice settings",
  UpdateLocationSettings = "Update location settings",
  LocationAddressMissing = "Address is incomplete in location settings. This information is required to lodge an ACC45",
  HpiOrgIdMissing = "HPI organisation ID is missing from practice settings. This information is required to lodge an ACC45",
  HpiOrgNameMissing = "HPI organisation name is missing from practice settings. This information is required to lodge an ACC45"
}

export enum ClaimDeleteRequest {
  yes = "YES",
  noBecauseEncounterInProgress = "NOBECAUSEENCOUNTERINPROGRESS",
  noBecauseEncounterInFinalised = "NOBECAUSEENCOUNTERFINALISED",
  noBecauseAppointment = "NOBECAUSEAPPOINTMENT",
  noBecauseInvoice = "NOBECAUSEINVOICE"
}
