import { DetailsList, Spinner, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataTypeLabel,
  ObservationDto,
  ObservationType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

interface ClinicalToolComponentProps {
  clinicalRecord: ClinicalRecord;
}

export const ClinicalToolCardComponent: React.FunctionComponent<
  ClinicalToolComponentProps
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher
      fetch={() => clinicalRecord.loadObservationData()}
      fallback={<Spinner />}
    >
      {() => {
        const content = getContent(clinicalRecord.observations);
        return content ?? <PatientSummaryEmptyState />;
      }}
    </DataFetcher>
  );
};

const twentyFourMonthsAgo = DateTime.now().minus({ months: 24 });

const dateFilter = (observation: ObservationDto): boolean => {
  const createdDate = DateTime.fromISOOrNow(observation.changeLog?.createdDate);

  if (!createdDate.isValid)
    throw new Error(
      `Unable to parse ${
        observation.changeLog!.createdDate
      } to a valid date time.`
    );

  return createdDate.diff(twentyFourMonthsAgo, ["days"]).days >= 0;
};

export const getClinicalToolContent = (
  observations?: Observation[]
): Observation[] | undefined => {
  return observations?.filter(m => dateFilter(m));
};

const getContent = (observations?: Observation[]) => {
  const filteredObservations = getClinicalToolContent(observations);

  const showClinicalToolsObservationsOnly = filteredObservations?.filter(
    x =>
      x.type === ObservationType.GRCS ||
      x.type === ObservationType.PSFS ||
      x.type === ObservationType.NPRS ||
      x.type === ObservationType.OREBRO ||
      x.type === ObservationType.K10 ||
      x.type === ObservationType.DASH
  );

  return (
    <>
      {showClinicalToolsObservationsOnly &&
      showClinicalToolsObservationsOnly?.length > 0 ? (
        <Stack
          styles={{
            root: {
              overflowY: "auto"
            }
          }}
        >
          <DetailsList
            setKey="past consults"
            items={showClinicalToolsObservationsOnly}
            compact
            columns={[
              {
                name: "Date",
                key: "date",
                minWidth: 75,
                maxWidth: 75,
                onRender: (item: ObservationDto) => (
                  <div>
                    {DateTime.fromISOOrNow(
                      item.changeLog?.createdDate
                    ).toDayDefaultFormat()}
                  </div>
                )
              },
              {
                name: "Tool",
                key: "tool",
                minWidth: 60,
                maxWidth: 60,
                onRender: (item: ObservationDto) =>
                  item.type === ObservationType.DASH
                    ? ClinicalDataTypeLabel.DASH11
                    : item.type
              },
              {
                name: "Result",
                key: "result",
                minWidth: 60,
                isMultiline: true,
                onRender: (item: ObservationDto) => item.summary
              }
            ]}
            cellStyleProps={{
              cellExtraRightPadding: 24,
              cellRightPadding: 8,
              cellLeftPadding: 2
            }}
          />
        </Stack>
      ) : (
        <PatientSummaryEmptyState />
      )}
    </>
  );
};
