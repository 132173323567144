// eslint-disable-next-line import/extensions
import isEqual from "lodash/isEqual";

import { DateTime, FormMethods } from "@bps/utils";

import {
  AccInvoicesFilter,
  AccInvoicesFilterQuery,
  defaultAccInvoicesFilter
} from "./AccInvoiceTransactionsListFilter.types.ts";

export const onFilterChange = (
  options: {
    field: string | number | symbol;
    values: AccInvoicesFilter;
    actions: Pick<FormMethods<AccInvoicesFilter>, "setValue" | "reset">;
  },
  setFilter: (newFilter: Partial<AccInvoicesFilterQuery>) => void
) => {
  const { field, actions, values } = options;
  if (field === "actionRequired") {
    if (values[field]) {
      const { actionRequired, ...otherDefaultValues } =
        defaultAccInvoicesFilter;

      // on setting actionRequired to true, clear all other filters
      Object.entries(otherDefaultValues).forEach(([key, value]) => {
        if (!isEqual(values[key], value)) {
          actions.setValue(key, value);
        }
      });
    } else {
      // on untoggle, set value to undefined so URL doesn't contain ?actionRequired=false
      return setFilter({ actionRequired: undefined });
    }
  } else {
    // if user changes a filter, clear actionRequired if it is currently true
    if (
      !isEqual(values[field], defaultAccInvoicesFilter[field]) &&
      values["actionRequired"]
    ) {
      actions.setValue("actionRequired", undefined);
    }
  }

  if (field === "serviceStartTime" || field === "serviceEndTime") {
    const value = values[field];
    return setFilter({
      [field]: value ? DateTime.jsDateToISODate(value) : undefined
    });
  }

  // on untoggle, set value to undefined so URL doesn't contain ?isOwing=false
  if (field === "isOwing") {
    const value = values[field];
    return setFilter({
      [field]: value || undefined
    });
  }

  if (field === "hasStatusReason") {
    const value = values[field];
    return setFilter({
      [field]: value || undefined
    });
  }

  return setFilter({ [field]: values[field] });
};

export const extractIndexAndField = (
  path: string
): {
  index: number;
  fieldName: string;
} => {
  // field message from response - "lines[0].lineDetails.serviceCodes"
  const indexMatch = path.match(/\[(\d+)\]/);
  const index = indexMatch ? parseInt(indexMatch[1], 10) : 0;

  const keys = path.split(".");
  const key = keys[keys.length - 1];

  return {
    index,
    fieldName: key
  };
};
