import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";

export type AvailabilitySlotResponseDto = {
  date: string;
  availableTimes: string[];
  userId: string;
  orgUnitId: string;
};

export type GetAvailabilitySlotDto = {
  userId: string;
  orgUnitIds: string[];
  duration: string;
  startDate: string;
  endDate: string;
  excludeCalendarEvent: string;
};

export interface ExternalPatient {
  firstName?: string;
  lastName?: string;
  mobile?: string;
  email?: string;
  address?: string;
  dateOfBirth?: string;
  postcode?: string;
  suburb?: string;
  country?: Country;
  state?: string;
  city?: string;
  sourceId?: string;
  matchedId?: string;
}

export interface CalendarEventAppointmentDto {
  id: string;
  calendarEventId: string;
  providerComment?: string;
  withProviderTime?: string;
  rollbackAppointmentStatus?: string;
}

export interface CalendarEventDto
  extends Omit<AddCalendarEventDto, "cancellationReason | receiverArgs"> {
  calendarEventRecurrenceId?: string;
  cancellationReasonId?: string;
  invoiceStatus?: InvoiceStatus;
  cancellationDateTime?: string;
  isPartOfRecurrence?: boolean;
  changeLog?: ChangeLogDto;
  purpose?: string;
  eTag: string;
  id: string;
  externalPatient?: ExternalPatient;
  appointment?: CalendarEventAppointmentDto;
  sourceId?: string;
  matchedId?: string;
  rescheduledAppointmentId?: string;
  fromRescheduledAppointmentId?: string;
  manuallyRescheduledComment?: string;
  manuallyRescheduledBy?: string;
}

export type AppointmentCancellationReasonDto = {
  id: string;
  eTag: string;
  text: string;
  isDeleted?: boolean;
  isInactive?: boolean;
  isSystemManaged: boolean;
};

export type GetAppointmentCancellationReasonDto = {
  text?: string;
  isInactive?: boolean;
};

export interface AddAppointmentCancellationReasonDto {
  text: string;
  isInactive: boolean;
  isSystemManaged: boolean;
}

export interface UpdateAppointmentCancellationReasonDto
  extends AddAppointmentCancellationReasonDto {
  id: string;
  eTag: string;
  isDeleted: boolean;
}

export type ValidateRecurrenceSeriesConflictDto = {
  id: string;
  startTime: string;
  endTime: string;
  calendarEventRecurrenceId?: string;
};

export interface SingleCalendarEventReminder
  extends SingleCalendarEventReminderDto {}

export type SingleCalendarEventReminderDto = {
  calendarEventId: string;
  contactId?: string;
  content: string;
  campaignId?: string;
  commType: string;
};
export interface CalendarEventAttendeeDto {
  arrivedTime?: string;
  attendeeId: string;
  attendeeStatus?: AppointmentStatusCode;
  cancellationDateTime?: string;
  cancellationReasonId?: string;
  cancellationText?: string;
  noChargeComment?: string;
  status?: CalendarEventStatus;
  type: AttendeeType;
  attendanceStatus?: CalendarEventReminderStatus;
}

export enum BookedByTypeEnum {
  onlineBooking = "OLB",
  user = "USR"
}

export interface AddCalendarEventDto {
  priority?: CalendarEventPriority;
  providerContractType?: string;
  status?: CalendarEventStatus;
  cancellationReason?: string;
  cancellationText?: string;
  bookedBy?: string;
  bookedByType?: BookedByTypeEnum;
  startTime: string;
  endTime: string;
  content?: string;
  orgUnitId: string;
  type: CalendarEventType;
  appointmentTypeId?: string;
  reason?: AppointmentReasonDto;
  attendees: CalendarEventAttendeeDto[];
  appointmentStatus?: AppointmentStatusCode;
  receiverArgs?: AddCalendarEventReceiverArgsDto;
  maxParticipants?: number;
  groupDescription?: string;
  rescheduledAppointmentId?: string;
  fromRescheduledAppointmentId?: string;
}

export interface AddCalendarEventReceiverArgsDto {
  encounterId?: string;
  invoiceId?: string;
  invoiceItemIds?: string[];
}

export interface UpdateCalendarEventDto
  extends Patch<Omit<CalendarEventDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface ValidateCalendarEventResponse {
  hasError: boolean;
  errors: string[];
}
export interface PatchUserAvailabilityDto extends Patch<UserAvailabilityDto> {
  userId: string;
}

export interface PatchOrgUnitAvailabilityDto
  extends Patch<OrgUnitAvailabilityDto> {
  orgUnitId: string;
}

export interface UserTimeRangeDto {
  userTimeRangeGroup: UserTimeRangeGroupDto[];
}

interface UserTimeRangeGroupDto {
  userId: string;
  orgUnitId: string;
  timeRange: TimeRangeDto[];
}
export interface TimeRangeDto {
  startTime: string;
  endTime: string;
}

export interface OrgUnitAvailabilityDto {
  orgUnitId: string;
  orgUnitOpeningHours: OrgUnitOpeningHoursDto[];
  orgUnitOpeningHoursOverrides: OrgUnitOpeningHoursOverrideDto[];
}

export interface OrgUnitOpeningHoursDto {
  id?: string;
  eTag: string;
  recurrenceRule: RecurrenceRuleDto;
  orgUnitId: string;
  startTime: string;
  endTime: string;
  changeLog?: ChangeLogDto;
}

export interface CalendarEventRecurrenceDto
  extends Omit<RecurrenceDto, "id" | "eTag"> {
  id?: string;
  eTag?: string;
}

export interface RecurrenceDto extends Omit<AddRecurrenceDto, "linkingArgs"> {
  id: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddRecurrenceDto {
  orgUnitId: string;
  bookedBy: string;
  purpose?: string;
  type: CalendarEventType;
  attendees: CalendarEventAttendeeDto[];
  startDate: string;
  endDate?: string;
  startTime: string;
  endTime: string;
  recurrenceRule: RecurrenceRuleDto;
  appointmentTypeId?: string;
  seriesId?: string;
  linkingArgs?: {
    episodeOfCareId?: string;
    action: RecurrenceLinkingActionType;
  };
  maxParticipants?: number;
  groupDescription?: string;
}

export interface PatchRecurrenceDto
  extends Patch<Omit<RecurrenceDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface UserAvailabilityDto {
  userId: string;
  bookingSchedules: ScheduleDto[];
  bookingScheduleOverrides: ScheduleOverrideDto[];
}

export interface UserAvailabilityRequestDto {
  userId: string[];
}

export interface ScheduleDto
  extends Omit<ScheduleOverrideDto, "reason" | "endDate"> {
  reasonForNotWorking?: string;
  recurrenceRule: RecurrenceRuleDto;
  usePracticeBaseInterval?: boolean;
  defaultAppointmentDuration?: string;
}

export interface ScheduleOverrideDto {
  id?: string;
  eTag?: string;
  userId?: string;
  orgUnitId: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  isAvailable: boolean;
  reason?: string;
  changeLog?: ChangeLogDto;
}

export interface PatchUserUnavailabilityDto
  extends Patch<UserUnavailabilityDto> {
  userId: string;
}

export interface UserUnavailabilityDto {
  userId: string;
  calendarEventRecurrences: CalendarEventRecurrenceDto[];
}

export interface RecurrenceRuleDto {
  interval: number;
  frequency: Frequency;
  count?: number;
  until?: string;
  dayRecur?: number[];
  monthRecur?: number[];
  monthDayRecur?: number[];
  weekPosition?: number;
}

export interface OrgUnitOpeningHoursOverrideDto {
  id?: string;
  eTag?: string;
  orgUnitId: string;
  startDate: string;
  endDate: string;
  isClosed: boolean;
  startTime?: string;
  endTime?: string;
  reason?: string;
  changeLog?: ChangeLogDto;
}

export type AttendeeType = "PATIENT" | "USER" | "CONTACT";

export type GetCalendarEventsDto = PagingOptions & {
  startTime?: string;
  endTime?: string;
  statuses?: string[];
  attendees?: string[];
  calendarEventRecurrenceId?: string;
  appointmentStatuses?: string[];
  name?: string;
  appointmentTypes?: string[];
  calendarEventIds?: string[];
  orgUnitIds?: string[];
};

export interface GetRecurringAppointmentConflictDto {
  orgUnitId: string;
  startTime: string;
  endTime: string;
  interval: number;
  frequency: Frequency;
  count?: number;
  until?: string;
  dayRecur?: number[];
  monthRecur?: number[];
  monthDayRecur?: number[];
  weekPosition?: number;
  seriesId?: string;
}

export interface RecurringAppointmentHelperTextDto {
  recurringAppointmentConflicts?: RecurringAppointmentConflictDto[];
  nextAppointmentDate?: string;
  lastAppointmentDate?: string;
}

interface RecurringAppointmentConflictDto {
  conflictDateTime: string;
}

export interface WaitingListItemDto {
  id: string;
  attendees: CalendarEventAttendeeDto[];
  appointmentTypeId?: string;
  startTime?: string;
  endTime?: string;
  until: string;
  priority: number;
  anyProvider: boolean;
  anyLocation: boolean;
  content?: string;
  bookedBy?: string;
  orgUnitId: string;
  eTag: string;
  title?: string;
  changeLog?: ChangeLogDto;
  purpose?: string;
  isDeleted?: boolean;
  duration: string | undefined | number;
  primaryContact?: string;
  appointmentDateTime?: Date;
  listExpiry?: Date;
  calenderEventId?: string;
}

export type UpdateWaitingListItemDto = PatchWithId<WaitingListItemDto>;

export type GetWaitingListDto = {
  orgUnitIds?: string[];
  attendees?: string[];
  anyProvider?: boolean;
  anyLocation?: boolean;
  calenderEventId?: string;
  durations?: string | string[];
  priorities?: string | string[];
  appointmentTypeIds?: string | string[];
  expiryDate?: string;
  name?: string;
};

export type AddWaitingListItemDto = Pick<
  WaitingListItemDto,
  | "orgUnitId"
  | "attendees"
  | "anyProvider"
  | "anyLocation"
  | "calenderEventId"
  | "calenderEventId"
  | "duration"
  | "bookedBy"
  | "appointmentTypeId"
  | "startTime"
  | "endTime"
  | "priority"
  | "until"
> & {};

export enum AttendeeTypeEnum {
  patient = "PATIENT",
  user = "USER",
  contact = "CONTACT"
}

export type GetUserTimeRangeDto = {
  orgUnitId?: string;
  dateTimeTo: string;
  dateTimeFrom: string;
  isStandardHours?: boolean;
  userIds?: string[];
};

export interface GetOrgUnitAvailableTimeRangeDto {
  orgUnitId: string;
  dateTimeTo: string;
  dateTimeFrom: string;
}

export interface CalendarEventTypeRefDto extends RefDataDto<CalendarEventType> {
  duration: number;
}

export interface PatchCalendarEventDto
  extends Patch<Omit<CalendarEventDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface CalendarEventAppointmentStatusChangeDto {
  appointmentStatus: AppointmentStatusCode;
  calendarEventId: string;
}

export interface AppointmentStatusDto extends RefDataDto {}

export type CalendarSeriesDto = {
  id: string;
  eTag: string;
  recurrences: RecurrenceDto[];
};

export type CancelCalendarSeriesDto = {
  cancelDateTime: string;
  cancellationReasonId?: string;
  cancellationText?: string;
};

export enum CalendarEventPriority {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
  Urgent = "URGENT"
}

export enum RecurrenceLinkingActionType {
  LinkAll = "LINKALL",
  UnlinkAll = "UNLINKALL",
  LinkFirst = "LINKFIRST"
}

export enum CalendarEventStatus {
  Confirmed = "CONFIRMED",
  Cancelled = "CANCELLED"
}

export enum CalendarEventStatusText {
  CONFIRMED = "Confirmed",
  CANCELLED = "Cancelled"
}

export enum CalendarEventType {
  Appointment = "APPT",
  Meeting = "MEETING",
  Unavailable = "UNAVAILABLE",
  TemporaryReservation = "TEMPRESERVE",
  ClosedException = "CLOSEDEXCEPTION",
  AnotherLocation = "ANOTHERLOCATION"
}

export enum Frequency {
  Week = 5,
  Month = 6,
  Year = 7
}

export enum AppointmentStatusCode {
  Booked = "BO",
  Waiting = "W",
  WithProvider = "WP",
  Finalised = "BILL",
  Completed = "CPL",
  DidNotAttend = "NA",
  UrgentBooked = "UBO",
  UrgentWaiting = "UW"
}

export enum AppointmentTypeCode {
  RecordUpdate = "RECUPDATE"
}

export enum CalendarEventPosition {
  NoPermission = "NoPermission",
  ProviderWorkingHours = "ProviderWorkingHours",
  PracticeWorkingHours = "PracticeWorkingHours",
  Available = "Available"
}

export enum ApptInfoCardColumns {
  provider = "Provider",
  date = "Date",
  time = "Time",
  duration = "Duration"
}

export interface EventReminderDto {
  id: string;
  eTag: string;
  status: string;
  provider: string;
  date: string;
  time: string;
  endTime: string;
  appointmentType: string;
  patient: PatientInfoDto;
  phoneNumber: string;
  attendance: string;
  location: string;
  deliveryStatus: string;
  appointmentStatus: string;
}

interface PatientInfoDto {
  id: string;
  firstName: string;
  lastName: string;
}

export enum OutboundCommStatusesEnumText {
  PENDING = "Pending",
  FAILED = "Failed",
  SENT = "Sent",
  NOTSENT = "Not sent",
  DELIVERED = "Delivered",
  BOOOKED = "Booked"
}

export type CalendarEventReminderDto = {
  id: string;
  outboundCommId?: string;
  deliveryStatusCode: string;
  replyStatusCode: string;
  calendarEventId: string;
  contactId: string;
  orgUnitId: string;
  eTag: string;
  changeLog?: ChangeLogDto;
  calendarEventStartTime: string;
};
export interface GetCalendarEventReminderArgs {
  calendarEventId: string;
  reminderId: string;
}

export type AddCalendarEventReminderDto = {
  outboundCommId?: string;
  deliveryStatusCode: string;
  calendarEventId: string;
  contactId: string;
  orgUnitId: string;
};

export interface UpdateCalendarEventReminderDto
  extends Patch<Omit<CalendarEventReminderDto, "changeLog">> {
  id: string;
  eTag: string;
}

export type CalendarEventReminderMessageDto = {
  id: string;
  orgUnitId: string;
  calendarEventReminderId?: string;
  content?: string;
  deliveryStatusCode?: string;
  deliveryStatusUpdatedDateTime?: string;
  replyStatusCode?: string;
  deliveryChannelCode?: string;
  outboundCommContentId?: string;
  deliveryAddress?: string;
  contactHasOptedOut?: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
};

export type CalendarEventReminderReplyDto = {
  id: string;
  orgUnitId: string;
  calendarEventReminderMessageId?: string;
  calendarEventReminderId?: string;
  content?: string;
  outboundCommContentId?: string;
  outboundCommReplyId?: string;
  replyDateTime?: string;
  replyAction?: string;
  eTag: string;
  changeLog?: ChangeLogDto;
};

export interface CalendarEventReminderSearchDto {
  patientId: string;
  providerId: string;
  calendarEvent: CalendarEventDto;
  reminder: CalendarEventReminderDto;
  message: CalendarEventReminderMessageDto;
}

export enum DateType {
  CalendarEvent = "CalendarEvent",
  Reply = "Reply",
  Sent = "Sent"
}

export enum AppointmentReminderJobScheduleBehavior {
  SpanUntilScheduledRunDate = "0", //default
  OffsetOnly = "1" //advanced
}

export type CalendarEventReminderSearchArgs = {
  dateType: DateType;
  orgUnitId?: string;
  startDateTime?: string;
  endDateTime?: string;
};

export interface AppointmentReminderJobScheduleDto {
  daysOfWeek: number[];
  hour: number;
  minute: number;
}

export type AppointmentConfirmationCampaignDto = {
  id: string;
  name: string;
  newAppointmentCampaignId: string;
  rescheduleAppointmentCampaignId: string;
  cancelAppointmentCampaignId: string;
  eTag: string;
  changeLog: ChangeLogDto;
};

export type AddAppointmentConfirmationCampaignDto = {
  name: string;
  newAppointmentCampaignId: string;
  rescheduleAppointmentCampaignId: string;
  cancelAppointmentCampaignId: string;
};

export interface PatchAppointmentConfirmationCampaignDto
  extends Patch<Omit<AppointmentConfirmationCampaignDto, "changeLog">> {
  id: string;
  eTag: string;
}

export type AppointmentReminderJobDto = {
  id: string;
  orgUnitId: string;
  jobName: string;
  jobSchedule: AppointmentReminderJobScheduleDto;
  appointmentReminderOptions: AppointmentReminderJobOptionsDto;
  status: string;
  eTag: string;
  changeLog: ChangeLogDto;
};

export type AppointmentReminderJobPreviewArgs = {
  numberOfDaysInAdvanceToSend: number;
  selectedDaysOfWeek: number[];
  scheduleBehaviour: AppointmentReminderJobScheduleBehavior | undefined;
};

export type AppointmentReminderJobPreviewDto = {
  daysOfWeek: AppointmentReminderJobPreviewDayDto[];
};

export type AppointmentReminderJobRunSummaryDto = {
  id: string;
  appointmentReminderJobId: string;
  calendarEventArgs?: string;
  calendarEventCount?: number;
  nextScheduledRun?: string;
  errorMessages?: string;
  eTag: string;
  changeLog: ChangeLogDto;
};

export type AppointmentReminderJobPreviewDayDto = {
  jobDayOfWeek: number;
  appointmentDaysOfWeek?: number[];
};

export type AddAppointmentReminderJobDto = {
  orgUnitId: string;
  jobName: string;
  jobSchedule: AppointmentReminderJobScheduleDto;
  appointmentReminderOptions: AppointmentReminderJobOptionsDto;
  status: string;
};

export type AppointmentReminderJobOptionsDto = {
  appointmentTypes: string[];
  sendBefore: number;
  templateId: string;
};

export interface PatchAppointmentReminderJobDto
  extends Patch<Omit<AppointmentReminderJobDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface AppointmentReminderJobRunArgsDto {
  rerunAppointmentReminderJobRunId?: string;
}

export interface CalendarEventFormInstanceDTO {
  calendarEventId: string;
  formInstanceId: string;
  formTemplateTypeCode: string;
}

export enum CalendarEventReminderStatus {
  NOREPLY = "NOREPLY",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  REQUIRESREVIEW = "REQUIRESREVIEW"
}

export enum CalendarEventConfirmationType {
  Confirmation = "CONFIRMATION",
  Reschedule = "RESCHEDULE",
  Cancelled = "CANCELLED"
}
export interface CalendarEventConfirmationDto {
  id: string;
  type: CalendarEventConfirmationType;
  calendarEventId: string;
  contactId: string;
  campaignId?: string;
  eTag: string;
  changeLog: ChangeLogDto;
}

export interface AppointmentTypeDto {
  eTag: string;
  id: string;
  text: string;
  length?: number;
  isInactive?: boolean;
  icon?: string;
  defaultServiceIds?: string[];
  isSystemManaged: boolean;
  isInternalStatic?: boolean;
  code?: string;
  maxParticipants?: number;
  onlineName?: string;
}

export interface AddAppointmentTypeDto
  extends Omit<AppointmentTypeDto, "id" | "eTag" | "code"> {}

export type GetContactsDto = PagingOptions & {
  ids?: string[];
  search?: string;
  types?: string[];
  categories?: string[];
  statuses?: string[];
  birthday?: string;
  phoneNumber?: string;
};

export type GetAppointmentTypesDto = {
  search?: string;
  isInactive?: boolean;
  startDurations?: string | string[];
  standardInterval?: number;
  isInternalStatic?: boolean;
};

export type PatchAppointmentTypeDto = PatchWithId<AppointmentTypeDto>;

export interface AppointmentReasonDto {
  episodeOfCareId?: string | null;
}

export interface AddAppointmentEncountersDto {
  calendarEventId: string;
  encounterId: string;
  providerId?: string;
  businessRole?: string;
}

export interface AppointmentEncountersDto extends AddAppointmentEncountersDto {
  id: string;
  eTag: string;
  changeLog: ChangeLogDto;
}

export interface GetAppointmentEncountersRequest {
  calendarEventId?: string;
  encounterId?: string;
}

export interface AppointmentAttendeeDto {
  type: AttendeeType;
  attendeeId: string;
  attendeeStatus?: AppointmentStatusCode;
  cancellationReasonId?: string;
  cancellationText?: string;
  status?: CalendarEventStatus;
  cancellationDateTime?: string;
}

export interface BookingTenantSettingsDto {
  appointmentConfirmationSettings?: AppointmentConfirmationSettings;
  eTag: string;
  changeLog: ChangeLogDto;
}

export interface AppointmentConfirmationSettings {
  isActive: boolean;
}

export interface AddBookingTenantSettingsDto
  extends Omit<BookingTenantSettingsDto, "eTag" | "changeLog"> {}

export interface PatchBookingTenantSettingsDto
  extends Omit<BookingTenantSettingsDto, "changeLog"> {}

export interface AddProviderCommentDto {
  calendarEventId: string;
  etag: string;
  providerComment: string | undefined;
}
