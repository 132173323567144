import { FC } from "react";

import {
  Heading,
  Persona,
  PersonaSize,
  Stack,
  StackItem,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { RelationshipDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { ContactCategoryText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { CompoundButtonWithHoveredIcon } from "../../shared-components/view/CompoundButtonWithHoveredIcon.tsx";

interface PatientAccountPersonaCardProps {
  accountHolderFor?: RelationshipDto;
  includeRelationships?: boolean;
  headingText: string;
  patient: Contact;
  badgeText?: string;
}

export const PatientAccountPersonaCard: FC<PatientAccountPersonaCardProps> = ({
  patient,
  headingText,
  accountHolderFor,
  includeRelationships,
  badgeText
}) => {
  const {
    practice: {
      ui: { showContactDetails }
    }
  } = useStores();

  const theme = useTheme();
  const { labelSmall } = getPeopleScreenStylesSet(theme);

  return (
    <ContactFetcher
      contactId={
        accountHolderFor ? accountHolderFor?.relatedContactId! : patient.id!
      }
      options={
        includeRelationships ? { includeRelationships: true } : undefined
      }
    >
      {billToContact =>
        billToContact.isPerson && (
          <Stack tokens={{ childrenGap: 8 }}>
            {headingText && (
              <Heading variant="section-sub-heading">{headingText}</Heading>
            )}
            <CompoundButtonWithHoveredIcon
              onClick={() =>
                showContactDetails(
                  accountHolderFor
                    ? accountHolderFor?.relatedContactId!
                    : patient.id!
                )
              }
              styles={{ flexContainer: { border: "none" } }}
            >
              <Persona
                size={PersonaSize.size48}
                styles={{
                  root: {
                    height: "auto"
                  }
                }}
                imageInitials={billToContact?.initials}
                text={billToContact?.name}
                onRenderPrimaryText={() => (
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Stack.Item>{billToContact?.name}</Stack.Item>
                    {badgeText && (
                      <TextBadge
                        badgeSize={TextBadgeSize.small}
                        hasBorder
                        badgeColor={TextBadgeColor.blue}
                      >
                        {badgeText}
                      </TextBadge>
                    )}
                  </Stack>
                )}
                onRenderSecondaryText={
                  billToContact
                    ? () => (
                        <Stack>
                          <Stack
                            horizontal
                            tokens={{ childrenGap: 4 }}
                            styles={{
                              root: {
                                whiteSpace: "normal",
                                wordBreak: "break-word"
                              }
                            }}
                          >
                            <ContactCategoryText
                              code={billToContact.categories}
                            />
                          </Stack>
                          <StackItem styles={labelSmall}>
                            {billToContact.primaryCommunication &&
                              billToContact.primaryCommunication.value}
                          </StackItem>
                        </Stack>
                      )
                    : undefined
                }
              />
            </CompoundButtonWithHoveredIcon>
          </Stack>
        )
      }
    </ContactFetcher>
  );
};
