import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Text } from "@bps/fluent-ui";
import { customFormatDuration } from "@bps/utils";

import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";

export const DidNotArriveNoticeBy: FC<{ item: DidNotArriveItem }> = observer(
  ({ item }) => {
    let noticeBy: string | undefined = "N/A";
    if (item.cancellationReasonId && item.cancellationDateTime) {
      const duration = item.calendarEvent.startDateTime.diff(
        item.cancellationDateTime
      );
      if (duration.milliseconds >= 0) {
        noticeBy = `${customFormatDuration(
          item.calendarEvent.startDateTime.diff(item.cancellationDateTime),
          true
        )}`;
      } else {
        noticeBy = ` + ${customFormatDuration(
          item.cancellationDateTime.diff(item.calendarEvent.startDateTime),
          true
        )}`;
      }
    }
    return <Text>{noticeBy}</Text>;
  }
);
