import { FunctionComponent } from "react";

import {
  FontIcon,
  FontSizes,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ActivityDescriptionCellProps {
  activity: ClinicalActivity;
}
export const ActivityDescriptionCell: FunctionComponent<
  ActivityDescriptionCellProps
> = ({ activity }) => {
  const { clinical } = useStores();
  const theme = useTheme();
  const activityDescriptions = clinical.activityDescriptionMapValues;
  const { comment, descriptionId, freeText } = activity;
  const activityDescription = activityDescriptions.find(
    x => x.id === descriptionId
  );

  const descriptionText = activityDescription
    ? activityDescription.reasonText
    : freeText;
  return (
    <Stack horizontal tokens={{ childrenGap: 4 }}>
      {activityDescription && activityDescription.clinicallySignificant && (
        <Stack>
          <FontIcon
            iconName="UserOptional"
            styles={{
              root: {
                fontSize: FontSizes.size16,
                verticalAlign: "middle",
                color: theme.palette.themePrimary
              }
            }}
          />
        </Stack>
      )}
      <Stack
        styles={{
          root: {
            flexGrow: 1,
            flexWrap: "wrap",
            display: "flex",
            whiteSpace: "wrap"
          }
        }}
      >
        {descriptionText && descriptionText.length > 0
          ? descriptionText
          : "Other"}
      </Stack>
      {comment && comment?.length > 0 && (
        <Stack verticalAlign="start" horizontalAlign="end">
          <TooltipHost content={comment} styles={{ root: { display: "flex" } }}>
            <FontIcon
              iconName="CommentSolid"
              styles={{
                root: {
                  fontSize: FontSizes.size16,
                  color: theme.palette.themePrimary
                }
              }}
            />
          </TooltipHost>
        </Stack>
      )}
    </Stack>
  );
};
