import { DateTime, ordinalNumber } from "@bps/utils";
import {
  ObservationDto,
  ObservationType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export class PSFSHelper {
  private psfsObservations?: ObservationDto[];

  get isInitialAssessment() {
    return (
      !this.psfsObservations ||
      this.psfsObservations.length === 0 ||
      (this.psfsObservations &&
        this.psfsObservations[this.psfsObservations.length - 1].encounterId ===
          this.encounterId)
    );
  }

  private numberInSeries() {
    let value = 0;
    let index = -1;

    if (this.psfsObservations) {
      index = this.psfsObservations.findIndex(
        m => m.encounterId === this.encounterId
      );
      value =
        index !== -1
          ? this.psfsObservations.length - index
          : this.psfsObservations.length + 1;
    }

    return value;
  }

  generateSubheadingText(date: DateTime) {
    return `${
      this.isInitialAssessment
        ? "Baseline"
        : ordinalNumber(this.numberInSeries())
    } Assessment ${date.toDayDefaultFormat()}`;
  }

  constructor(
    clinicalRecord: ClinicalRecord,
    private contextId?: string,
    private encounterId?: string
  ) {
    if (clinicalRecord.observations) {
      this.psfsObservations = clinicalRecord.observations.filter(
        m =>
          m.type === ObservationType.PSFS && m.values[0].contextId === contextId
      );
    }
  }
}
