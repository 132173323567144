import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  isDateBeforeField,
  isNotFutureDate,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class GoalCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();
    const goalValidator = new GoalValidator();
    this.forArrayField(sotapNameOf("goals"), goalValidator.validate);
  }
}

export class GoalValidator extends Validator<GoalDataItem> {
  readonly nameOf = nameOfFactory<GoalDataItem>();

  constructor() {
    super();
    this.forField(this.nameOf("goal"), [
      predicate((value, parent: GoalDataItem, allValues?: SOTAPFormValues) => {
        if (!allValues || !allValues.goals) return false;

        return (
          !!parent.startDate ||
          !!parent.endDate ||
          parent.isAchieved ||
          !!parent.achievedDate
        );
      }, required())
    ]);
    this.forField(this.nameOf("achievedDate"), [
      isDateBeforeField(
        ValidationMessages.dateIsBeforeGoalStart,
        this.nameOf("startDate"),
        true
      ),
      isNotFutureDate(),
      predicate(
        (value, parent: GoalDataItem) => !!parent.isAchieved && !value,
        required()
      )
    ]);
  }
}
