import { FormApi } from "final-form";
import React, { PropsWithChildren, ReactNode, useState } from "react";

import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  ObservationDto,
  QuestionnaireDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ConfidentialShield } from "../shared-components/ConfidentialShield.tsx";
import {
  ClinicalToolFormModel,
  ClinicalToolsFormValues
} from "./ClinicalToolFormModel.ts";
import { ClinicalToolScoreModal } from "./ClinicalToolScoreModal.tsx";

export interface ClinicalToolFormProps
  extends PropsWithChildren<{
    clinicalRecord: ClinicalRecord;
    clinicalDataType: ClinicalDataType;
    questionnaire: QuestionnaireDto;
    formHeading: string;
    summaryText?: string;
    boldDiagnosis?: boolean;
    formName: string;
    onRenderModalContent?: (
      diagnosis: string,
      observations?: ObservationDto[],
      questionnaireResponse?: QuestionnaireResponseDto
    ) => ReactNode;
    shouldSubmitBeDisabled?: (
      values: Partial<ClinicalToolsFormValues>
    ) => boolean;
  }> {}

export const ClinicalToolForm: React.FunctionComponent<
  ClinicalToolFormProps
> = ({
  clinicalRecord,
  clinicalDataType,
  questionnaire,
  formHeading,
  summaryText,
  formName,
  children,
  shouldSubmitBeDisabled,
  onRenderModalContent
}) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const [summary, setSummary] = useState<string>("");

  const [closeOnSubmit, setCloseOnSubmit] = useState<boolean>(false);

  const [observations, setObservations] = useState<
    ObservationDto[] | undefined
  >(undefined);

  const [questionnaireResponseAnswers, setQuestionnaireResponse] = useState<
    QuestionnaireResponseDto | undefined
  >(undefined);

  const onObservationSaved = (
    observation: ObservationDto[],
    questionnaireResponse?: QuestionnaireResponseDto
  ) => {
    setSummary(
      observation[0].summary ?? observation[0].values[0].value.toString()
    );
    setObservations(observation);

    if (questionnaireResponse) {
      setQuestionnaireResponse(questionnaireResponse);
    }
  };

  const model = new ClinicalToolFormModel(clinicalRecord, clinical, {
    clinicalDataType,
    onObservationSaved
  });

  const onSubmitSucceeded = (
    values: ClinicalToolsFormValues,
    form: FormApi<ClinicalToolsFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: clinicalDataType
      });
      setCloseOnSubmit(false);
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      setCloseOnSubmit(true);
    }
  };

  const shouldBeDisabled = (values: Partial<ClinicalToolsFormValues>) => {
    if (shouldSubmitBeDisabled) {
      return shouldSubmitBeDisabled(values);
    }

    const numOfQuestions = questionnaire.items.length;

    const isFullyFilled: boolean | undefined =
      values &&
      Object.keys(values).filter(v => v !== "confidential").length ===
        numOfQuestions;

    return !isFullyFilled;
  };

  const OREBRO_TITLE = "Orebro";
  return (
    <>
      <ClinicalSubmissionForm<ClinicalToolsFormValues>
        formName={formName}
        onSubmit={model.onSubmit(questionnaire)}
        onSubmitSucceeded={onSubmitSucceeded}
        hideButtons
        initialValues={model.initialValues}
        readOnly={isViewOnlyOrDischarged}
        disableRoutePrompt
        heading={formHeading}
        onCancel={model.onCancel}
        promptOnCancel={true}
        additionalDisableCheck={form => shouldBeDisabled(form)}
        disableButtonCondition={stashedData => {
          return !stashedData?.dirtyAreas?.get(
            model.clinicalDataArea as keyof EncounterClinicalDataDto
          );
        }}
        lastUpdatedDate={model.getLastUpdatedDate()}
        lastUpdatedUserId={model.getLastUpdatedUserId()}
        confidentialShield={<ConfidentialShield name="confidential" />}
        noGap
        hasSeparator
      >
        <>
          <StashedClinicalDataFormSpy<ClinicalToolsFormValues>
            clinicalRecord={clinicalRecord}
            getData={model.getStashedClinicalData(questionnaire)}
            areasToObserve={{
              [model.clinicalDataArea]: [clinicalDataType]
            }}
          />
          {children}
        </>
      </ClinicalSubmissionForm>

      {summary && (
        <ClinicalToolScoreModal
          title={
            clinicalDataType === ClinicalDataType.OREBRO
              ? OREBRO_TITLE
              : formHeading
          }
          onClose={() => {
            if (closeOnSubmit) {
              model.onCancel();
            } else {
              setSummary("");
            }
          }}
          diagnosis={summary}
          sentenceText={summaryText}
          onRenderModalContent={onRenderModalContent}
          questionnaireResponse={questionnaireResponseAnswers}
          observations={observations}
        />
      )}
    </>
  );
};
