import React from "react";

import {
  FontIcon,
  FontSizes,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { BusinessRole } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getConditionsButtonStyles } from "./ConditionsSidePanel.styles.ts";

interface ConsultsRemainingBadgesProps {
  appointmentVisits: AppointmentVisitDto[];
  initialConsultDate?: string;
}

const ConsultsRemainingBadgesBase: React.FC<ConsultsRemainingBadgesProps> = ({
  appointmentVisits,
  initialConsultDate
}) => {
  const { practice, userExperience } = useStores();
  const theme = useTheme();

  const styles = getConditionsButtonStyles(theme);

  const getDetailedConsultsRemainingText = (
    appointmentVisitDto: AppointmentVisitDto
  ): string => {
    if (!appointmentVisitDto.contractType) {
      return "";
    }

    let businessRoleAbbreviation: string | undefined;

    if (appointmentVisitDto.providerType) {
      const accProviderContractType =
        practice.ref.accProviderContractTypes.values.find(
          x =>
            appointmentVisitDto.contractType ===
              x.accPracticeContractTypeCode &&
            appointmentVisitDto.providerType === x.providerTypeCode
        );

      businessRoleAbbreviation =
        userExperience.ref.businessRoleAbbreviations.values.find(
          x => x.businessRoleCode === accProviderContractType?.businessRoleCode
        )?.text;
    }

    const visitsRemaining = isDefined(appointmentVisitDto.totalRemaining)
      ? `${appointmentVisitDto.totalRemaining} remain`
      : "No consults remain";

    const providerInfo = businessRoleAbbreviation
      ? `${businessRoleAbbreviation} ${appointmentVisitDto.contractType}`
      : appointmentVisitDto.contractType;

    return `${providerInfo}: ${visitsRemaining}`;
  };

  return (
    <>
      {appointmentVisits.map(av => {
        const text = getDetailedConsultsRemainingText(av);

        const accProviderContractType =
          practice.ref.accProviderContractTypes.values.find(
            x =>
              av.contractType === x.accPracticeContractTypeCode &&
              av.providerType === x.providerTypeCode
          );

        return (
          <TextBadge
            badgeColor={TextBadgeColor.lightGrey}
            styles={styles.visitsRemaining}
            badgeSize={TextBadgeSize.medium}
            key={text}
          >
            <Stack horizontal>
              {text}
              {accProviderContractType?.businessRoleCode ===
                BusinessRole.Acupuncturist &&
                initialConsultDate && (
                  <TooltipHost
                    content={initialConsultDate}
                    styles={{
                      root: { display: "flex", alignItems: "center" }
                    }}
                  >
                    <FontIcon
                      iconName="Info"
                      styles={{
                        root: {
                          fontSize: FontSizes.size12,
                          marginLeft: 4
                        }
                      }}
                    />
                  </TooltipHost>
                )}
            </Stack>
          </TextBadge>
        );
      })}
    </>
  );
};

export const ConsultsRemainingBadges = withFetch(
  x => [
    x.practice.ref.accProviderContractTypes.load(),
    x.userExperience.ref.businessRoleAbbreviations.load()
  ],
  ConsultsRemainingBadgesBase
);
