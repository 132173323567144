import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  CenteredLargeSpinner,
  DefaultButton,
  IContextualMenuItem,
  Stack
} from "@bps/fluent-ui";
import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { SecurityRoleCode } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { TemplatePickerFilter } from "@modules/clinical/screens/correspondence/components/TemplatePickerFilter.tsx";
import { TemplatePivot } from "@modules/clinical/screens/correspondence/components/TemplatePivot.tsx";
import { TemplateListItem } from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { TemplatePreview } from "@modules/clinical/screens/correspondence/components/TemplatePreview.tsx";
import { TemplateScrollablePane } from "@modules/clinical/screens/correspondence/components/TemplateScrollablePane.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { TemplatePickerFormValues } from "../../correspondence/components/TemplatePickerForm.types.ts";
import { useQuickAccessContext } from "../../patient-record/components/quickAccess/context/QuickAccessContext.tsx";
import { useTemplateManagerFormContext } from "../context/TemplateManagerFormContext.ts";
import { DeleteTemplateDialog } from "./DeleteTemplateDialog.tsx";
import { TemplateUsingElseWhereDialog } from "./TemplateUsingElseWhereDialog.tsx";
import { TemplateWriterDialog } from "./TemplateWriterDialog.tsx";

type TemplateManagementFormProps = {
  items: TemplateListItem[];
  favourites: string[];
  onCancel: () => void;
};

export const TemplateManagementForm: React.FC<TemplateManagementFormProps> =
  observer(({ items, favourites, onCancel }) => {
    const [showCreateTemplateDialog, setShowCreateTemplateDialog] =
      useState<boolean>(false);

    const [
      showTemplateUsingSomewhereDialog,
      setshowTemplateUsingSomewhereDialog
    ] = useState<boolean>(false);

    const {
      document: { setSelectedTemplateId, templateMap },
      core
    } = useStores();

    const { quickAccessSettingsDocumentIds } = useQuickAccessContext();

    const hasPracWorkFlowAdminAccess = core.hasSecurityRoles([
      SecurityRoleCode.PracWorkflowsAdmin
    ]);

    const hasPracWorkFlowContributorAccess = core.hasSecurityRoles([
      SecurityRoleCode.PracWorkflowsContributor
    ]);

    const hasClinContributorAccess = core.hasSecurityRoles([
      SecurityRoleCode.ClinicalContributor
    ]);

    const model = useTemplateManagerFormContext();

    const { setShowDeleteTemplateDialog, setIsEditAsCopy } = model;

    const hasPublishToEveryoneAccess = (selectedTemplate?: {
      documentStatus?: string;
    }): boolean =>
      selectedTemplate?.documentStatus ===
        SubmitActionCode.PublishToEveryoneCode || false;

    const hasClinContributorAccessWithoutAdminAccess =
      hasClinContributorAccess && !hasPracWorkFlowAdminAccess;

    const hasPracContribAccessWithoutAdminAccess =
      hasPracWorkFlowContributorAccess && !hasPracWorkFlowAdminAccess;

    const isAccessDisabled = (selectedTemplate?: {
      documentStatus?: string;
    }): boolean | undefined => {
      if (!selectedTemplate) {
        return undefined;
      }

      const hasAccessToSecurityRole =
        (hasPracContribAccessWithoutAdminAccess &&
          hasPublishToEveryoneAccess(selectedTemplate)) ||
        (hasClinContributorAccessWithoutAdminAccess &&
          hasPublishToEveryoneAccess(selectedTemplate));

      return hasAccessToSecurityRole ? true : undefined;
    };

    const extraActionsBefore = () => {
      return (
        <DefaultButton
          text="New template"
          onClick={() => setShowCreateTemplateDialog(true)}
          styles={{ root: { marginRight: 523 } }}
        />
      );
    };

    useEffect(() => {
      model.setUpInitialState(favourites, items);
      // initial value only loading once
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteTemplate = (templateId: string) => {
      setshowTemplateUsingSomewhereDialog(false);
      setShowDeleteTemplateDialog(true);
      setSelectedTemplateId(templateId);
    };

    const setSelectedTemplate = (
      form: FormApi<TemplatePickerFormValues, Partial<TemplatePickerFormValues>>
    ) => {
      setSelectedTemplateId(form.getState().values.templateId);
    };

    const getMenuItems = (
      form: FormApi<TemplatePickerFormValues, Partial<TemplatePickerFormValues>>
    ): IContextualMenuItem[] => {
      const templateId = form.getState().values.templateId;
      const selectedTemplate = templateMap.get(templateId ?? "");
      const isDisabled = isAccessDisabled(selectedTemplate);
      return [
        {
          key: "editTemplate",
          text: "Edit as a copy",
          onClick: () => {
            setShowCreateTemplateDialog(true);
            setSelectedTemplate(form);
            setIsEditAsCopy(true);
          }
        },
        {
          key: "deleteTemplate",
          text: "Delete template",
          disabled: isDisabled,
          onClick: () => {
            const deletingTemplateId = form.getState().values.templateId;
            if (quickAccessSettingsDocumentIds?.includes(deletingTemplateId)) {
              setshowTemplateUsingSomewhereDialog(true);
              return;
            }
            onDeleteTemplate(deletingTemplateId);
          }
        }
      ];
    };

    return (
      <SubmissionFormDialog
        Fallback={
          <CenteredLargeSpinner
            centeredBoxProps={{ styles: { root: { minHeight: 485 } } }}
          />
        }
        onSubmit={model.onTemplateSelected}
        disablePrompt={true}
        dialogName="Template management dialog"
        dialogProps={{
          onDismiss: onCancel,
          minWidth: 1000,
          title: "Templates"
        }}
        buttonsProps={form => {
          const templateId = form.getState().values.templateId;
          const selectedTemplate = templateMap.get(templateId ?? "");
          const isDisabled = !templateId || isAccessDisabled(selectedTemplate);
          return {
            hideButtonsSeparator: true,
            disableSubmitOnPristine: true,
            submitButtonProps: {
              text: "Edit template",
              disabled: isDisabled,
              items: getMenuItems(form),
              onClick: () => {
                form.submit();
              },
              iconProps: {
                iconName: "EditNote"
              }
            },
            extraActionsBefore
          };
        }}
      >
        {form => {
          const templateId = form.values.templateId;
          const selectedTemplate = templateMap.get(templateId ?? "");

          return (
            <>
              <TemplatePivot items={items} />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack.Item grow>
                  <TemplatePickerFilter>
                    {() => <TemplateScrollablePane templateListItems={items} />}
                  </TemplatePickerFilter>
                </Stack.Item>
                <TemplatePreview templateId={form.values.templateId} />
              </Stack>

              <TemplateWriterDialog
                isDialogHidden={!showCreateTemplateDialog}
                onDismiss={() => {
                  setShowCreateTemplateDialog(false);
                  setSelectedTemplateId(undefined);
                  model.setIsEditAsCopy(false);
                }}
                model={model}
              />

              <DeleteTemplateDialog
                lauchFrom="templateManagementForm"
                items={items}
              />
              <TemplateUsingElseWhereDialog
                onDelete={() => {
                  onDeleteTemplate(templateId);
                }}
                onDismiss={() => {
                  setshowTemplateUsingSomewhereDialog(false);
                }}
                hidden={!showTemplateUsingSomewhereDialog}
                templateName={selectedTemplate?.name ?? ""}
              />
            </>
          );
        }}
      </SubmissionFormDialog>
    );
  });
