import { ObservationType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { ClinicalToolViewDataFetcher } from "./ClinicalToolViewDataFetcher.ts";

export const getClinicalToolViewData =
  (encounterId: string, type: ObservationType) =>
  async ({ clinical }: RootStore) => {
    const clinicalToolFetcher = new ClinicalToolViewDataFetcher();
    return clinicalToolFetcher.getData(clinical, encounterId, type);
  };
