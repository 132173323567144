export enum SecondColumnContent {
  nextAvailable = "nextAvailable",
  repeat = "repeat",
  conditionDetails = "conditionDetails",
  patientNotices = "patientNotices",
  groupAttendees = "groupAttendees",
  selectedGroupAttendee = "selectedGroupAttendee",
  nextGroupAppt = "nextGroupAppt",
  statementRun = "statementRun"
}
