import { FontSizes } from "@bps/fluent-ui";

export const contextMenuStyles = {
  root: { height: "36px", marginTop: 2, marginBottom: 2 },
  flexContainer: { height: "36px" },
  menuIcon: { fontSize: FontSizes.size16 },
  rootDisabled: {
    backgroundColor: "transparent"
  }
};

export const subMarginStyle = {
  root: {
    marginTop: 8,
    marginBottom: 0,
    paddingLeft: 75,
    paddingRight: 14
  }
};
