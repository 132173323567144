import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  PivotItem,
  PivotTabs
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimPivotLinkKeys } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AccBreadcrumbs } from "../claim-management/components/AccBreadcrumbs.tsx";
import { ClaimPages } from "../claim/types/claim.constants.ts";

export const ClaimPivotTabs: React.FC = () => {
  const { routing } = useStores();
  const claimContext = useContext(ClaimContext);
  const claimAdjustmentContext = useClaimAdjustmentContext();

  const claim = claimContext?.claim || claimAdjustmentContext.claim;

  const referred = claim.referralIn;
  const isImported = claim.isImported;
  const hideAcc45Tab = referred && !isImported;

  const getCurrentPage = () => {
    if (!!routes.claimAdjustment.edit.match(routing.location.pathname)) {
      return ClaimPages.claimAjustmentEdit;
    } else if (!!routes.claims.edit.match(routing.location.pathname)) {
      return ClaimPages.claimEdit;
    } else {
      return ClaimPages.managementEdit;
    }
  };

  const currentPage = getCurrentPage();

  let selectedKey: string | null = null;

  if (currentPage === ClaimPages.claimEdit) {
    selectedKey = ClaimPivotLinkKeys.acc45;
  } else if (currentPage === ClaimPages.managementEdit) {
    selectedKey = ClaimPivotLinkKeys.management;
  }

  const handlePivotClicked = (item: PivotItem): void => {
    const selectedValue = item.props && item.props.itemKey;
    if (
      selectedValue === selectedKey &&
      currentPage !== ClaimPages.claimAjustmentEdit
    ) {
      return;
    }

    switch (selectedValue) {
      case ClaimPivotLinkKeys.management: {
        routing.pushRetainingState(
          routes.claims.management.edit.path({ id: claim.id })
        );
        break;
      }
      case ClaimPivotLinkKeys.acc45: {
        routing.pushRetainingState(routes.claims.edit.path({ id: claim.id }));
        break;
      }
    }
  };

  return (
    <>
      <AccBreadcrumbs claim={claim} />
      <PivotTabs selectedKey={selectedKey} onLinkClick={handlePivotClicked}>
        <PivotItem
          {...dataAttribute(DataAttributes.Element, "management-button")}
          headerText="Summary"
          itemKey={ClaimPivotLinkKeys.management}
        />
        {!hideAcc45Tab && (
          <PivotItem
            {...dataAttribute(DataAttributes.Element, "acc45-button")}
            headerText="ACC45"
            itemKey={ClaimPivotLinkKeys.acc45}
          />
        )}
      </PivotTabs>
    </>
  );
};
