import { HttpStatusCode } from "@bps/http-client";
import { BhacPatientDto } from "@libs/gateways/bhac/bhacGateway.dtos.ts";
import { IBhacGateway } from "@libs/gateways/bhac/bhacGateway.interface.ts";
import { Store } from "@stores/types/store.type.ts";

export class BhacStore implements Store<BhacStore> {
  constructor(private gateway: IBhacGateway) {}

  async addPatient(patient: BhacPatientDto): Promise<string | undefined> {
    return await this.gateway.addPatient(patient);
  }

  async updatePatient(patient: BhacPatientDto): Promise<string | undefined> {
    return await this.gateway.updatePatient(patient);
  }

  async addOrUpdatePatient(
    patient: BhacPatientDto
  ): Promise<string | undefined> {
    const addResult = await this.gateway.addPatient(patient).catch(error => {
      // Patient already matched, so try to update them instead
      if (error.httpStatusCode !== HttpStatusCode.Conflict) {
        throw error;
      }
    });
    if (addResult) {
      return addResult;
    }

    return this.gateway.updatePatient(patient);
  }
}
