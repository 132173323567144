import { observer } from "mobx-react-lite";

import { FontSizes, IBreadcrumbItem } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { displayClaimHeading } from "../../claim/components/utils.ts";

interface AccBreadcrumbsProps {
  claim: Claim;
}

export const AccBreadcrumbs: React.FC<AccBreadcrumbsProps> = observer(
  ({ claim }) => {
    const { routing } = useStores();
    const { claimAdjustment } = useClaimAdjustmentContext();

    const goToClaimsScreen = () => {
      routing.push({
        pathname: routes.claims.basePath.pattern,
        search: routing.location.state?.claimsFilter || ""
      });
    };

    const goToClaim = () => {
      routing.pushRetainingState(
        routes.claims.management.edit.path({ id: claim.id })
      );
    };

    const isAcc45View = !routes.claimAdjustment.edit.match(
      routing.location.pathname
    );

    const isAcc32View = routes.claimAdjustment.edit.match(
      routing.location.pathname
    );

    const breadcrumbs: IBreadcrumbItem[] = [
      {
        onClick: goToClaimsScreen,
        text: "Claims",
        key: "1"
      },
      {
        text: displayClaimHeading(claim.patientFullName, claim.claimNumber),
        key: "2",
        isCurrentItem: isAcc45View,
        onClick: isAcc45View ? undefined : goToClaim
      }
    ];

    if (isAcc32View) {
      breadcrumbs.push({
        text: claimAdjustment.formattedNumber,
        key: "3",
        isCurrentItem: true
      });
    }

    return (
      <BreadCrumb
        styles={{
          root: { fontSize: FontSizes.size18 }
        }}
        routes={breadcrumbs}
      />
    );
  }
);
