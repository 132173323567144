import { action, computed, observable, reaction, runInAction } from "mobx";

import { AccountInfo as MSALAccountInfo } from "@azure/msal-browser";
import { DateSettings } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AddOrgUnitDto,
  AddSecGroupAccessRequestDto,
  AddUserDto,
  AssignLicenceDto,
  BusinessRoleClasses,
  CatalogBusinessRoleDto,
  CatalogSecurityRoleDto,
  EntityType,
  GetLicencesDto,
  LicenceDto,
  LicenceTypeCodes,
  OrgUnitDto,
  OrgUnitHierarchyType,
  PatchOrgUnitDto,
  PatchUserDto,
  Permission,
  ResendArgsDto,
  SecGroupAccessRequestDto,
  SecGroupMemberDto,
  SecurityRoleCode,
  TenantDto,
  UpdateSecGroupAccessRequestStatusDto,
  UserDto,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ICoreGateway } from "@libs/gateways/core/CoreGateway.interface.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { capitalizeSentence, wildCardCheck } from "@libs/utils/utils.ts";
import { HasPermissions } from "@shared-types/core/has-permission.type.ts";
import { UsersFilter } from "@shared-types/core/users-filter.interface.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { User } from "@stores/core/models/User.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { CoreRef } from "./CoreRef.ts";
import { Licence } from "./models/Licence.ts";
import { OrgUnit } from "./models/OrgUnit.ts";
import { SecGroupAccessRequest } from "./models/SecGroupAccessRequest.ts";

interface OrgUnitsFilter {
  hierarchyType: OrgUnitHierarchyType;
  includeChildren?: boolean;
}

interface AccountInfo {
  profile: {
    name: string;
  };
  username: string;
  authTime: number | undefined;
}

type HasBusinessRoleClasses = (
  businessRoleClasses: string[] | string,
  operator?: "and" | "or"
) => boolean;

export class CoreStore implements Store<CoreStore, CoreRef> {
  constructor(
    private gateway: ICoreGateway,
    public hub: IHubGateway
  ) {
    // ⚠️ Set time zone as tenant timezone.
    reaction(
      () => this.timezone,
      zone => {
        DateSettings.defaultZone = zone;
      },
      // to skip mobx error while loading location
      { onError: () => undefined }
    );
    this.ref = new CoreRef(this.gateway);
  }

  root: IRootStore;
  ref: CoreRef;

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.User, this.onUserUpdateEvent);
    this.hub.onEntityEvent(
      Entity.SecGroupAccessRequest,
      this.onSecGroupAccessRequestEvent
    );
    this.hub.onEntityEvent(Entity.CoreOrgUnit, this.onAddNewOrgUnitEvent);
  }

  @observable
  accountInfo?: AccountInfo;

  userMap = observable.map<string, User>();
  catalogBusinessRoles: CatalogBusinessRoleDto[] = [];
  catalogSecurityRoles: CatalogSecurityRoleDto[] = [];
  orgUnitsMap = observable.map<string, OrgUnit>();
  userSecGroupMembershipsMap = observable.array<SecGroupMemberDto>();
  secGroupAccessRequestMap = observable.map<string, SecGroupAccessRequest>();
  licenceMap = observable.map<string, Licence>();

  @observable
  tenantDetails?: TenantDto = undefined;

  @observable.ref
  userOrgUnits: string[] = [];

  // using observable.ref for performance
  // there can be a lot of permissions, and we don't need to track the items in the array
  // because the entire array gets replaced
  @observable.ref
  permissions: string[] = [];

  /**
   * Contains a promise resolving to the current user
   */
  @observable
  userId: string;

  get isNZTenant() {
    return this.tenantDetails?.country === Country.NewZealand;
  }

  @computed
  get isLoggedIn() {
    try {
      return !!(this.user && this.location);
    } catch (e) {
      return false;
    }
  }

  @computed
  get name(): string {
    return this.user ? this.user.fullName : "Anonymous";
  }

  @computed
  get userTitles() {
    return this.ref.titles.values
      .filter(x => x.userCommon)
      .concat(this.ref.titles.values.filter(x => !x.userCommon));
  }

  /**
   * The currently authenticated user
   */
  @computed
  get user() {
    return this.userMap.get(this.userId);
  }

  /**
   * The location id of the logged in user.
   *
   * This should not be used if core.hasMultipleActiveUsers is true as this will be an arbitrary locationId
   *
   * ⚠️ If the user is not authenticated or is not associated to any org unit location, this will throw.
   * (this function will be removed once multi location feature is finished 'hasMultipleActiveLocations')
   *
   */
  @computed
  get locationId() {
    const activeOrgUnitId = this.userOrgUnits.find(id => {
      const orgUnit = this.orgUnitsMap.get(id);
      return !orgUnit?.isInactive;
    });

    if (activeOrgUnitId) {
      return activeOrgUnitId;
    }

    if (this.userOrgUnits.length > 0) {
      return this.userOrgUnits[0];
    }

    throw Error("User requires to be associated with a location");
  }

  /**
   * The location of the user.
   *
   * This should not be used if core.hasMultipleActiveUsers is true as this will be an arbitrary location
   *
   * (this concept will be removed once multi location feature is finished 'hasMultipleActiveLocations')
   */
  @computed
  get location() {
    const location: OrgUnit | undefined = this.orgUnitsMap.get(this.locationId);
    if (!location) {
      throw Error("User required to be associated with a location");
    }
    return location;
  }

  /**
   * This getter should NOT be widely used - only on screens where we need to differentiate active locations from inactive locations
   */
  @computed
  get hasMultipleLocations() {
    const hasPermission = this.hasPermissions([
      Permission.PreReleaseMultiLocationAllowed
    ]);

    const hasMultiple =
      Array.from(this.orgUnitsMap.values()).filter(
        l => l.hierarchyType === OrgUnitHierarchyType.Location
      ).length > 1;
    return hasPermission && hasMultiple;
  }

  @computed
  get hasMultipleActiveLocations() {
    const hasPermission = this.hasPermissions([
      Permission.PreReleaseMultiLocationAllowed
    ]);

    const hasMultiple =
      Array.from(this.orgUnitsMap.values()).filter(
        l => l.hierarchyType === OrgUnitHierarchyType.Location && !l.isInactive
      ).length > 1;
    return hasPermission && hasMultiple;
  }

  @computed
  get timezone() {
    return (
      this.location?.timezone ??
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  @computed
  get practiceAndUserHaveSameTimezone() {
    return DateSettings.systemTimeZone === this.timezone;
  }

  @computed
  get hasUserSettingWritePermission() {
    return this.hasPermission(Permission.UserSettingWrite);
  }

  @computed
  get primaryBusinessRole() {
    let businessRole = this.catalogBusinessRoles.find(
      x =>
        this.user?.businessRoles.includes(x.code) &&
        x.classCode === BusinessRoleClasses.Provider
    );

    if (!businessRole) {
      businessRole = this.catalogBusinessRoles.find(
        x => this.user?.businessRoles.includes(x.code)
      );
    }

    return businessRole;
  }

  @computed get licences() {
    return (
      Array.from(this.licenceMap.values())?.filter(
        x => x.licenceTypeCode === LicenceTypeCodes.AlliedNZ
      ) ?? []
    );
  }

  hasAccessToSecGroup(secGroupId?: string) {
    return (
      !secGroupId ||
      this.user?.privateSecGroupId === secGroupId ||
      this.userSecGroupMembershipsMap.some(x => x.secGroupId === secGroupId)
    );
  }

  getLocationName = (orgUnitId: string) => {
    return this.orgUnitsMap.get(orgUnitId)?.name;
  };

  hasInactiveFlag = (orgUnitId: string) => {
    return this.orgUnitsMap.get(orgUnitId)?.isInactive;
  };

  @sharePendingPromise()
  async getUser(
    id: string,
    options?: {
      ignoreCache?: boolean;
      showOnCalendar?: boolean;
    }
  ) {
    const ignoreCache = !!options?.ignoreCache;
    if (options?.showOnCalendar) {
      await this.root.userExperience.getUserSetting(id);
    }
    return (
      (!ignoreCache && this.userMap.get(id)) ||
      this.gateway.getUser(id).then(this.mergeUser)
    );
  }

  async getCatalogBusinessRoles() {
    const catalogBusinessRoles = await this.gateway.getCatalogBusinessRoles();

    // Back-end needs to return tenant spacific business roles but it returns all the business roles. To move forward, this is being filtered by tenant's country
    this.catalogBusinessRoles = catalogBusinessRoles.filter(cbr =>
      cbr.profiles.some(
        profile => profile.countryCode === this.tenantDetails?.country
      )
    );

    return this.catalogBusinessRoles;
  }

  async getCatalogSecurityRoles() {
    const catalogSecurityRoles = await this.gateway.getCatalogSecurityRoles();
    this.catalogSecurityRoles = catalogSecurityRoles;
    return catalogSecurityRoles;
  }

  async getUserSecGroupMemberships() {
    const secGroupMembers = await this.gateway.getSecGroupMembers({
      tenantId: this.tenantDetails?.id,
      entityId: this.user?.id,
      entityType: EntityType.User
    });
    runInAction(() => {
      this.userSecGroupMembershipsMap.replace(secGroupMembers);
    });
  }

  async getUserSecGroupAccessRequests() {
    if (this.user?.id) {
      const results = await this.gateway.getSecGroupAccessRequests({
        userId: this.user.id
      });

      results.map(this.mergeSecGroupAccessRequest);
    }
  }

  @sharePendingPromise()
  async getUserByUserName(
    userName: string,
    options?: { ignoreCache: boolean }
  ) {
    const getUserFromApi = () =>
      this.gateway.getUserByUserName(userName).then(this.mergeUser);

    if (!!options?.ignoreCache) {
      return this.gateway.getUserByUserName(userName).then(this.mergeUser);
    }

    const existingUser = Array.from(this.userMap.values()).find(
      user => user.username === userName
    );

    return existingUser || getUserFromApi();
  }

  getUserByPrivateSecGroupId(secGroupId: string | undefined) {
    return Array.from(this.userMap.values()).find(
      user => user.privateSecGroupId === secGroupId
    );
  }

  @action
  async loadCurrentUser() {
    const [user, authorisation] = await Promise.all([
      this.gateway.getCurrentUser(),
      this.gateway.getUserAuthorisation()
    ]);
    runInAction(() => {
      this.userOrgUnits = user.orgUnits;
      this.permissions = authorisation.permissions;
      this.tenantDetails = authorisation.tenant;
      this.userId = user.id;
    });
    this.mergeUser(user);
  }

  async addUser(data: AddUserDto) {
    const dataWithCapitalisation: AddUserDto = {
      ...data,
      firstName: capitalizeSentence(data.firstName, { allWords: true }),
      middleName:
        data.middleName &&
        capitalizeSentence(data.middleName, { allWords: true }),
      lastName: capitalizeSentence(data.lastName, { allWords: true }),
      preferredName:
        data.preferredName &&
        capitalizeSentence(data.preferredName, { allWords: true })
    };

    return await this.gateway
      .addUser(dataWithCapitalisation)
      .then(this.mergeUser);
  }

  updateUser(request: Omit<PatchUserDto, "eTag">) {
    return patchModel(request, req => this.gateway.updateUser(req), {
      modelMap: this.userMap
    });
  }

  async getOrgUnit(
    orgUnitId: string,
    options?: { ignoreCache?: boolean }
  ): Promise<OrgUnit> {
    if (!options?.ignoreCache) {
      const orgUnit = this.orgUnitsMap.get(orgUnitId);
      if (orgUnit) return orgUnit;
    }

    const orgUnitDto = await this.gateway.getOrgUnit(orgUnitId);
    return this.mergeOrgUnit(orgUnitDto);
  }

  updateOrgUnit(request: Omit<PatchOrgUnitDto, "eTag">) {
    return patchModel(request, req => this.gateway.updateOrgUnit(req), {
      modelMap: this.orgUnitsMap
    });
  }

  addOrgUnit(data: AddOrgUnitDto) {
    return this.gateway.addOrgUnit(data).then(this.mergeOrgUnit);
  }

  @action
  loadOrgUnits = (): PromiseLike<QueryResult<OrgUnit>> => {
    return this.getOrgUnits({
      hierarchyType: OrgUnitHierarchyType.Group,
      includeChildren: true
    });
  };

  @action
  async getOrgUnits(filter: OrgUnitsFilter): Promise<QueryResult<OrgUnit>> {
    const { results, ...rest } = await this.gateway.getOrgUnits(filter);

    return {
      results: results.map(this.mergeOrgUnit),
      ...rest
    };
  }

  async fetchUsers(filter: UsersFilter = {}) {
    const { showOnCalendar, requiredPermissions, ...restFilter } = filter;
    const search = filter.search && wildCardCheck(filter.search);

    const dtoResult = await this.gateway.getUsers({
      ...restFilter,
      search
    });

    const results = dtoResult;
    const mergedUsers = results.map(this.mergeUser);

    if (showOnCalendar) {
      await this.root.userExperience.getUserSettingsByUserIds(
        mergedUsers.map(user => user.id)
      );
    }

    if (requiredPermissions && requiredPermissions.length > 0) {
      const results = await Promise.all(
        mergedUsers.map(async x => {
          return {
            user: x,
            hasPermission: await this.checkUserPermissionsFromAPI(
              x.username,
              requiredPermissions
            )
          };
        })
      );

      return results.filter(x => x.hasPermission).map(x => x.user);
    } else {
      return mergedUsers;
    }
  }

  async getUsersByIds(ids: string[]): Promise<User[]> {
    const usersToFetch = ids.filter(id => !this.userMap.has(id));
    if (usersToFetch.length > 0) {
      const usersDtos = await this.gateway.getUsersByIds(ids);
      usersDtos.map(this.mergeUser);
    }

    return ids.reduce((users: User[], id) => {
      const user = this.userMap.get(id);
      if (user) users.push(user);
      return users;
    }, []);
  }

  async checkUserPermissionsFromAPI(
    userName: string,
    permissions: Permission[]
  ): Promise<boolean> {
    if (permissions.length < 1) return false;
    try {
      return await this.gateway.checkUserPermissionsFromAPI(
        userName,
        permissions
      );
    } catch (error) {
      throw Error(error.message);
    }
  }

  private computedCondition(
    conditions: string[] | string,
    predicate: (value: string) => boolean,
    operator: "and" | "or" = "and"
  ) {
    if (typeof conditions === "string") {
      return predicate(conditions);
    }

    if (operator && operator === "or") {
      return computed(() => conditions.some(x => predicate(x))).get();
    }

    return computed(() => conditions.every(x => predicate(x))).get();
  }

  hasBusinessRoleClasses: HasBusinessRoleClasses = (
    businessRoleClasses: string[] | string,
    operator: "and" | "or" = "and"
  ) => {
    return this.computedCondition(
      businessRoleClasses,
      this.hasBusinessRoleClass,
      operator
    );
  };

  private hasBusinessRoleClass = (businessRoleClass: string) => {
    return computed(
      () =>
        !!businessRoleClass &&
        !!this.catalogBusinessRoles.find(
          x =>
            this.user?.businessRoles.includes(x.code) &&
            x.classCode === businessRoleClass
        )
    ).get();
  };

  hasSecurityRoles = (
    securityRoles: string[] | string,
    operator: "and" | "or" = "and"
  ) => {
    return this.computedCondition(
      securityRoles,
      this.hasSecurityRole,
      operator
    );
  };

  private hasSecurityRole = (securityRole: string) => {
    const securityRoles = this.user?.securityRoles;
    return computed(
      () => !!securityRoles && securityRoles.includes(securityRole)
    ).get();
  };

  hasPermissions: HasPermissions = (
    permission: string[] | string,
    operator: "and" | "or" = "and"
  ) => {
    return this.computedCondition(permission, this.hasPermission, operator);
  };

  private hasPermission = (permission: string) => {
    return computed(
      () => !!this.permissions && this.permissions.includes(permission)
    ).get();
  };

  @action
  public setAccountInfo = (account?: MSALAccountInfo) => {
    this.accountInfo = account && {
      profile: { name: account.name! },
      username: account.username,
      authTime: Number(account.idTokenClaims?.auth_time) ?? undefined
    };
  };
  @observable
  lastUpdatedUserETag: string | undefined;
  /**
   * onUserUpdateEvent is called when a user update event is received from the SignalR hub
   */
  private onUserUpdateEvent = async (event: EntityEventData) => {
    // retrieve the new/updated user unless the store already has the latest version
    try {
      const user = this.userMap.get(event.id);
      const isCurrentUser = user?.id === this.user?.id;

      // check for updated permissions and updated user dto
      if (isCurrentUser) {
        await this.loadCurrentUser();
      } else if (!user || user.eTag !== event.etag) {
        // load updated user dto
        await this.getUser(event.id, {
          ignoreCache: true
        });

        runInAction(() => {
          this.lastUpdatedUserETag = event.etag;
        });
      }
    } catch (error) {
      this.root.notification.error(error.message);
    }
  };

  @observable
  recentlyUpdatedOrgUnitKey?: string;

  private onAddNewOrgUnitEvent = (event: EntityEventData) => {
    const orgUnit = this.orgUnitsMap.get(event.id);
    if (!orgUnit || orgUnit.eTag !== event.etag) {
      runInAction(() => {
        this.recentlyUpdatedOrgUnitKey = event.key;
      });
    }
  };

  private onSecGroupAccessRequestEvent = async (event: EntityEventData) => {
    try {
      const secGroupAccessRequest = this.secGroupAccessRequestMap.get(event.id);
      if (!secGroupAccessRequest || secGroupAccessRequest.eTag !== event.etag) {
        await this.getSecGroupAccessRequest(event.id);
      }
    } catch (error) {
      this.root.notification.error(error.message);
    }
  };

  @action
  private mergeUser = (dto: UserDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new User(this.root, dto),
      map: this.userMap
    });
  };

  @action
  private mergeOrgUnit = (dto: OrgUnitDto) =>
    mergeModel({
      dto,
      getNewModel: () => new OrgUnit(this.root, dto),
      map: this.orgUnitsMap
    });

  @action
  async loadAllUsers() {
    return this.fetchUsers();
  }

  @action
  private mergeSecGroupAccessRequest = (dto: SecGroupAccessRequestDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new SecGroupAccessRequest(dto),
      map: this.secGroupAccessRequestMap
    });
  };

  @action
  async resendInvite(userId: string) {
    const args = { userId } as ResendArgsDto;
    return await this.gateway.resendInvite(args);
  }

  @action
  private mergeLicence = (dto: LicenceDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Licence(dto),
      map: this.licenceMap
    });
  };

  async otherUserAdminsExist(userId: string) {
    const userAdmins = await this.fetchUsers({
      securityRoles: SecurityRoleCode.UserManagementAdmin,
      statusCodes: UserStatus.Active
    });

    return userAdmins.some(x => x.id !== userId);
  }

  async lastUserAdmin(user: User) {
    if (this.hasSecurityRoles([SecurityRoleCode.UserManagementAdmin])) {
      const otherAdminsExist = await this.otherUserAdminsExist(user.id);

      return !otherAdminsExist;
    }

    return false;
  }

  addSecGroupAccessRequestStatus = async (
    data: AddSecGroupAccessRequestDto
  ) => {
    try {
      await this.gateway
        .addSecGroupAccessRequestStatus(data)
        .then(this.mergeSecGroupAccessRequest);
    } catch (error) {
      this.root.notification.error(
        `Error requesting access - ${error.message}.`
      );
    }

    this.root.notification.success(
      "A request to access this user's confidential data has been sent."
    );
  };

  getSecGroupAccessRequest = async (
    id: string
  ): Promise<SecGroupAccessRequestDto | undefined> => {
    return await this.gateway
      .getSecGroupAccessRequest(id)
      .then(this.mergeSecGroupAccessRequest);
  };

  updateSecGroupAccessRequestStatus = async (
    data: UpdateSecGroupAccessRequestStatusDto
  ) => {
    try {
      await this.gateway.updateSecGroupAccessRequestStatus(data);
      this.getSecGroupAccessRequest(data.id);
      this.root.notification.success(
        `Access request ${data.status.toLowerCase()}.`
      );
    } catch (error) {
      this.root.notification.error(
        `Error updating access request - ${error.message}.`
      );
    }
  };

  getLicence = async (id: string): Promise<Licence | undefined> => {
    const licenceDto = await this.gateway.getLicence(id);
    return this.mergeLicence(licenceDto);
  };

  getLicences = async (
    request?: GetLicencesDto
  ): Promise<Licence[] | undefined> => {
    const liceneDtos = await this.gateway.getLicences(request);
    return liceneDtos.map(this.mergeLicence);
  };

  assignLicence = async (request: AssignLicenceDto) => {
    try {
      await this.gateway.assignLicence(request);
      this.root.notification.success("Licence successfully assigned.");
    } catch (error) {
      this.root.notification.error(error, {
        messageOverride: "Failed to assign licence."
      });
    }
  };

  removeLicence = async (id: string) => {
    try {
      await this.gateway.removeLicence(id);
      this.root.notification.success("Licence successfully revoked.");
    } catch (error) {
      this.root.notification.error(error, {
        messageOverride: "Failed to revoke licence."
      });
    }
  };
}
