import { FunctionComponent, memo } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  Link,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  ClinicalNotesSubHeadings,
  TodaysNotesHeading
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { FreeTextNote } from "./FreeTextNote/FreeTextNote.tsx";

export interface TodaysNotesSectionProps {
  heading: TodaysNotesHeading;
  onChange: (value: string) => void;
  setShowImages: (value: boolean, key: string) => void;
  showImages: boolean;
  showDMRImages: boolean;
  showCNSImages: boolean;
}

// TODO: Once the feature is available, please remove the old code around fullBody
// `heading.structuredNotes` works this way
//    - `type` holds heading content for example Body, Full Body
//    - `note` holds the actual data for example comments typed by the user or the image data
// It might be worth refactoring this component.

export const TodaysNoteHeading: FunctionComponent<TodaysNotesSectionProps> =
  memo(
    ({
      heading,
      onChange,
      setShowImages,
      showImages,
      showDMRImages,
      showCNSImages
    }) => {
      const theme = useTheme();
      const imageKey = heading.code + heading.name;

      const fullBodyImages = heading.structuredNotes?.find(
        x => x.type === ClinicalNotesSubHeadings.FullBodyImages
      );

      const bodyExaminationImages = heading.structuredNotes?.find(
        x => x.type === ClinicalNotesSubHeadings.BodyExaminationImages
      );

      const bodyDiagramImages = heading.structuredNotes?.find(
        x => x.type === ClinicalNotesSubHeadings.BodyDiagramImages
      );

      const dmrImages = heading.structuredNotes?.find(
        x => x.type === ClinicalNotesSubHeadings.DMRImages
      );

      const cnsImages = heading.structuredNotes?.find(
        x => x.type === ClinicalNotesSubHeadings.CNSImages
      );

      const images =
        (fullBodyImages && fullBodyImages.note.includes("id='images'")) ||
        (bodyDiagramImages && bodyDiagramImages.note.includes("id='images'")) ||
        (bodyExaminationImages &&
          bodyExaminationImages.note.includes("id='images'")) ||
        (dmrImages && dmrImages.note.includes("id='images'"));

      const structuredNotes =
        heading.structuredNotes?.filter(
          x => x.type !== ClinicalNotesSubHeadings.FullBodyImages
        ) || [];

      // To show vertical stack items
      const verticalItems = [
        ClinicalNotesSubHeadings.PostureObservations,
        ClinicalNotesSubHeadings.OtherMovementsJoints
      ];

      return (
        <Stack
          tokens={{ childrenGap: 8 }}
          {...dataAttribute(DataAttributes.Element, heading.code)}
        >
          <Stack tokens={{ childrenGap: 16 }}>
            {!!structuredNotes.length && (
              <Heading variant="section-sub-heading">{heading.name}</Heading>
            )}
            <Stack tokens={{ childrenGap: 8 }}>
              {structuredNotes.map(value => {
                const showVertical = verticalItems.some(x => x === value.type);
                const typeIsFullBody = Boolean(
                  value.type === ClinicalNotesSubHeadings.FullBody && images
                );

                const typeIsBodyDiagram = Boolean(
                  value.type === ClinicalNotesSubHeadings.BodyDiagramImages &&
                    images
                );

                const typeIsBodyExamImages = Boolean(
                  value.type ===
                    ClinicalNotesSubHeadings.BodyExaminationImages && images
                );

                const typeIsBodyExamText = Boolean(
                  value.type === ClinicalNotesSubHeadings.Body
                );

                const typeIsDMRImages =
                  value.type === ClinicalNotesSubHeadings.DMRImages;

                const typeIsCNSImages =
                  value.type === ClinicalNotesSubHeadings.CNSImages;

                const typeIsOther =
                  !typeIsFullBody &&
                  !typeIsBodyDiagram &&
                  !typeIsBodyExamImages &&
                  !typeIsBodyExamText &&
                  !typeIsDMRImages &&
                  !typeIsCNSImages;

                return (
                  <Stack
                    horizontal={!showVertical}
                    key={value.sectionCode}
                    styles={{
                      root: {
                        borderLeftWidth: 1,
                        borderLeftColor: theme.palette.neutralLight,
                        borderLeftStyle: "solid",
                        paddingLeft: "20px",
                        overFlowY: "auto"
                      }
                    }}
                  >
                    {typeIsFullBody && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <Stack>
                          <div
                            key={value.sectionCode}
                            dangerouslySetInnerHTML={{ __html: value.note }}
                          />
                          <Link
                            onClick={() => setShowImages(!showImages, imageKey)}
                          >
                            {showImages ? "Hide images" : "Show images"}
                          </Link>
                          {showImages && (
                            <div
                              key="fullBody"
                              dangerouslySetInnerHTML={{
                                __html: fullBodyImages!.note
                              }}
                            />
                          )}
                        </Stack>
                      </>
                    )}

                    {typeIsDMRImages && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <Stack>
                          <Link
                            onClick={() =>
                              setShowImages(
                                !showDMRImages,
                                ClinicalNotesSubHeadings.DMRImages
                              )
                            }
                          >
                            {showDMRImages ? "Hide images" : "Show images"}
                          </Link>
                          {showDMRImages && (
                            <div
                              key="fullBody"
                              dangerouslySetInnerHTML={{
                                __html: dmrImages?.note || ""
                              }}
                            />
                          )}
                        </Stack>
                      </>
                    )}
                    {typeIsCNSImages && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <Stack>
                          <Link
                            onClick={() =>
                              setShowImages(
                                !showCNSImages,
                                ClinicalNotesSubHeadings.CNSImages
                              )
                            }
                          >
                            {showCNSImages ? "Hide images" : "Show images"}
                          </Link>
                          {showCNSImages && (
                            <div
                              key="fullBody"
                              dangerouslySetInnerHTML={{
                                __html: cnsImages?.note || ""
                              }}
                            />
                          )}
                        </Stack>
                      </>
                    )}

                    {typeIsBodyExamImages && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <Stack>
                          <Link
                            onClick={() => setShowImages(!showImages, imageKey)}
                          >
                            {showImages ? "Hide images" : "Show images"}
                          </Link>
                          {showImages && (
                            <div
                              key="fullBody"
                              dangerouslySetInnerHTML={{
                                __html: bodyExaminationImages!.note
                              }}
                            />
                          )}
                        </Stack>
                      </>
                    )}
                    {typeIsBodyDiagram && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <Stack>
                          <Link
                            onClick={() => setShowImages(!showImages, imageKey)}
                          >
                            {showImages ? "Hide images" : "Show images"}
                          </Link>
                          {showImages && (
                            <div
                              key="fullBody"
                              dangerouslySetInnerHTML={{
                                __html: bodyDiagramImages!.note
                              }}
                            />
                          )}
                        </Stack>
                      </>
                    )}

                    {typeIsBodyExamText && (
                      <>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              width: showVertical ? undefined : "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <div
                          key={value.sectionCode}
                          dangerouslySetInnerHTML={{ __html: value.note }}
                        />
                      </>
                    )}

                    {typeIsOther && (
                      <Stack horizontal>
                        <Heading
                          variant="section-sub-heading"
                          styles={{
                            root: {
                              color: theme.palette.themeDarker,
                              minWidth: showVertical ? undefined : "128px",
                              overflowWrap: "break-word"
                            }
                          }}
                        >
                          {value.type}
                        </Heading>
                        <div
                          key={value.sectionCode}
                          dangerouslySetInnerHTML={{ __html: value.note }}
                          style={{
                            width: "100%",
                            wordBreak: "break-all"
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>

          <FreeTextNote
            defaultValue={heading.freeText ?? ""}
            heading={
              !!structuredNotes.length
                ? `Append ${heading.name.toLowerCase()}`
                : heading.name
            }
            boldHeading={!structuredNotes.length}
            onChange={onChange}
          />
        </Stack>
      );
    }
  );
