import { computed } from "mobx";

import { RefData } from "@libs/api/ref-data/RefData.ts";
import {
  BusinessRoleClasses,
  SecurityRoleCode,
  UserDto
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { findCommValue } from "@stores/practice/utils/practice.utils.ts";

export class User extends Model<UserDto> {
  constructor(
    private store: IRootStore,
    dto: UserDto
  ) {
    super(dto);
  }

  static System: string = "System";

  @computed get availableOrgUnitIds() {
    return (
      this.store.booking.userAvailabilityMap.get(this.id)
        ?.availableOrgUnitIds ?? []
    );
  }

  @computed
  get username() {
    return this.dto.username;
  }

  @computed
  get middleName() {
    return this.dto.middleName;
  }

  @computed
  get pronounSubjective() {
    return this.dto.pronounSubjective || undefined;
  }

  @computed
  get pronounObjective() {
    return this.dto.pronounObjective || undefined;
  }

  @computed
  get pronounPossessive() {
    return this.dto.pronounPossessive || undefined;
  }

  @computed
  get communications() {
    return this.dto.communications || [];
  }

  @computed
  get homePhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.HomePhone);
  }

  @computed
  get mobilePhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.Mobile);
  }

  @computed
  get workPhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.WorkPhone);
  }

  @computed
  get email() {
    return this.username; // not sure whether this will be a permanent solution
  }

  @computed
  get title() {
    return this.dto.title;
  }

  @computed
  get titleRef(): RefData | undefined {
    return this.title ? this.store.core.ref.titles.get(this.title) : undefined;
  }

  @computed
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get fullName() {
    let name = `${this.firstName} ${this.lastName}`;

    if (this.titleRef !== undefined) {
      name = `${this.titleRef.text} ${name}`;
    }

    return name;
  }

  @computed
  get initials() {
    return `${this.firstName && this.firstName[0]}${
      this.lastName && this.lastName[0]
    }`.toUpperCase();
  }

  @computed
  get status() {
    return this.dto.status;
  }

  @computed
  get firstName() {
    return this.dto.firstName;
  }

  @computed
  get lastName() {
    return this.dto.lastName;
  }

  @computed
  get securityRoles() {
    return this.dto.securityRoles || [];
  }

  @computed
  get businessRoles() {
    return this.dto.businessRoles || [];
  }

  @computed
  get privateSecGroupId() {
    return this.dto.privateSecGroupId;
  }

  @computed
  get businessRoleClasses() {
    return this.dto.businessRoleClasses || [];
  }

  @computed get firstNameWithTitle() {
    return this.titleRef?.text
      ? `${this.titleRef.text} ${this.firstName}`
      : this.firstName;
  }

  get changeLog() {
    return this.dto.changeLog;
  }

  @computed get userSetting() {
    return this.store.userExperience.userSettingMap.get(this.id);
  }

  @computed
  get isProviderClass() {
    return this.businessRoleClasses.includes(BusinessRoleClasses.Provider);
  }

  @computed
  get gender() {
    return this.dto.gender;
  }
  @computed
  get preferredName() {
    return this.dto.preferredName;
  }
  @computed
  get orgUnits() {
    return this.dto.orgUnits;
  }

  @computed
  get hasClinViewSecurityRole() {
    return this.securityRoles.some(x => x === SecurityRoleCode.ClinicalRead);
  }
}
