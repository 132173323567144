import { observer } from "mobx-react-lite";

import { Option, TagsSelect, TagsSelectProps, useTheme } from "@bps/fluent-ui";
import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DetailsRowHelper } from "@ui-components/ShimmeredDetailsList/DetailsRowWrapper.tsx";

interface AppointmentTypeTagPickerProps {
  bhbProvider: BhbProvider;
  apptTypes: Option<BhbAppointmentType>[];
  detailsRowHelper: DetailsRowHelper;
}

export const AppointmentTypeTagPicker: React.FC<AppointmentTypeTagPickerProps> =
  observer(({ bhbProvider, apptTypes, detailsRowHelper }) => {
    const { bhb } = useStores();
    const { appointmentTypes, providerOnlineStatus } = bhbProvider;
    const theme = useTheme();

    const apptTypesOptions: Option<string>[] = apptTypes.map(
      ({ key, text }) => ({
        key,
        name: text,
        text
      })
    );

    const renderMessageText = () => {
      if (appointmentTypes.length > 0) {
        return "Change selection";
      } else {
        if (providerOnlineStatus === ProviderOnlineStatus.NO) {
          return "Click to add appointment types";
        } else {
          return "No appointment types selected to be booked. Click to Add";
        }
      }
    };

    const regularLabel: TagsSelectProps["fieldStyles"] = {
      fieldPlaceholder: !(appointmentTypes && appointmentTypes.length > 0) && {
        fontStyle: "italic",
        color:
          providerOnlineStatus === ProviderOnlineStatus.NO
            ? theme.palette.neutralSecondaryAlt
            : theme.semanticColors.errorText
      }
    };
    return (
      <TagsSelect
        value={appointmentTypes?.map(a => a.id)}
        options={apptTypesOptions}
        placeholder={renderMessageText()}
        fieldStyles={regularLabel}
        onChange={(keys: string[]) => {
          const updatedApptTypes = Array.from(apptTypes.values())
            .flatMap(a => (a.data !== undefined ? a.data.dto : []))
            .filter(a => keys.includes(a.id));

          if (updatedApptTypes) {
            const updateProvider = {
              ...bhbProvider.dto,
              appointmentTypes: updatedApptTypes
            };
            detailsRowHelper.setPromise(bhb.updateProvider(updateProvider));
          }
        }}
        borderless={true}
      />
    );
  });
