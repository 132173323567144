import { PropsWithChildren, ReactNode } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { FormHeaderWrapper } from "../../clinical-form/FormHeaderWrapper.tsx";

export interface ClinicalToolViewProps
  extends PropsWithChildren<{
    title: string;
    summary?: string;
    subtitle?: string;
    encounterId?: string;
    isConfidential: boolean;
    lastUpdatedDate?: DateTime;
    lastUpdatedUserId?: string;
    onRenderSummary?: () => ReactNode;
  }> {}

export const ClinicalToolView: React.FunctionComponent<
  ClinicalToolViewProps
> = ({
  title,
  summary,
  children,
  isConfidential,
  lastUpdatedDate,
  lastUpdatedUserId,
  onRenderSummary
}) => {
  const { clinical } = useStores();

  const confidentialShield = () => {
    return (
      <When permission={Permission.ConfidentialDataAllowed}>
        <IconButton
          iconProps={{ iconName: isConfidential ? "ShieldAlert" : "Shield" }}
          disabled
        />
      </When>
    );
  };

  return (
    <FormHeaderWrapper
      heading={title}
      confidentialShield={confidentialShield()}
      lastUpdatedDate={lastUpdatedDate}
      lastUpdatedUserId={lastUpdatedUserId}
      onClose={clinical.ui.tabs.currentPatientRecordTab!.hideActive}
      noGap
      hasSeparator
    >
      <Stack
        tokens={{ childrenGap: 16 }}
        styles={{
          root: { overflowY: "auto" }
        }}
      >
        {onRenderSummary
          ? onRenderSummary()
          : summary && (
              <MessageBar
                {...dataAttribute(
                  DataAttributes.Element,
                  "observation-summary"
                )}
                messageBarType={MessageBarType.info}
              >
                {summary}
              </MessageBar>
            )}

        {children}
      </Stack>
    </FormHeaderWrapper>
  );
};
