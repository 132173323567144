import {
  FontWeights,
  GridDetailsListColumn,
  ITheme,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";

import { ObservationRow } from "./ObservationsExpandedTable.tsx";
import {
  EN_DASH,
  formatObservations,
  formatTimeWithoutSeconds,
  GroupedObservation,
  isClinicalTool
} from "./utils.ts";

interface ObservationsObservationColumnsProps {
  dates: string[];
  groupedByDateAndType: GroupedByDateAndType;
  theme: ITheme;
}

type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedObservation[]>
>;

export interface FormattedObservation {
  systolic?: string;
  diastolic?: string;
  label?: string;
  pulse?: string;
  value?: string;
}

export type FormattedObservations = Record<string, FormattedObservation>;

export const generateObservationColumns = (
  { dates, groupedByDateAndType, theme }: ObservationsObservationColumnsProps,
  getObservationShortenedText: (type: string) => string
): GridDetailsListColumn<ObservationRow>[] => {
  const renderObservations = (
    formattedObservations: Record<string, FormattedObservation>,
    type: string
  ) => {
    const numOfObs = Object.keys(formattedObservations).length;
    return (
      <Stack styles={{ root: { alignSelf: "flex-start" } }}>
        {Object.keys(formattedObservations).map(timestamp => {
          const observation = formattedObservations[timestamp];
          return (
            <Stack key={timestamp}>
              <Stack horizontal>
                {isClinicalTool(type) ? (
                  <TooltipHost
                    content={observation.label}
                    styles={{
                      root: {
                        alignSelf: "center"
                      }
                    }}
                  >
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          fontWeight: FontWeights.semibold,
                          paddingRight: "5px",
                          color: theme.palette.neutralPrimary,
                          whiteSpace: "normal"
                        }
                      }}
                    >
                      {observation.value}
                    </Text>
                  </TooltipHost>
                ) : (
                  <Text
                    variant="medium"
                    styles={{
                      root: {
                        fontWeight: FontWeights.semibold,
                        paddingRight: "5px",
                        color: theme.palette.neutralPrimary
                      }
                    }}
                  >
                    {observation.value}
                  </Text>
                )}

                {numOfObs > 1 && (
                  <Stack>
                    <Text
                      variant="medium"
                      styles={{
                        root: { color: theme.palette.neutralPrimaryAlt }
                      }}
                    >
                      {EN_DASH}
                      {formatTimeWithoutSeconds(timestamp)}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return [
    {
      key: "type",
      header: "Type",
      minWidth: "100px",
      automationAttribute: "type",
      isResizable: true,
      onRender: (item: ObservationRow) => {
        return (
          <Stack styles={{ root: { alignSelf: "flex-start" } }}>
            <Text
              styles={{
                root: {
                  color: theme.palette.neutralPrimary
                }
              }}
              variant="medium"
            >
              {getObservationShortenedText(item.type)}
            </Text>
          </Stack>
        );
      }
    },
    ...dates.map(date => ({
      key: date,
      header: date,
      automationAttribute: date,
      minWidth: "150px",
      isResizable: true,
      onRender: (item: ObservationRow) => {
        const observationsForTypeOnDate =
          groupedByDateAndType[date]?.[item.type] || [];

        if (observationsForTypeOnDate.length > 0) {
          const formattedObservations: FormattedObservations =
            formatObservations(observationsForTypeOnDate);
          return renderObservations(formattedObservations, item.type);
        }
        return EN_DASH;
      }
    }))
  ];
};
