import { observer } from "mobx-react-lite";

import { CollapsibleCard, Heading, Separator, Stack } from "@bps/fluent-ui";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ApplyPracticeLogo } from "./ApplyPracticeLogo.tsx";
import { BhbOnlineSettingsCancellationSection } from "./BhbOnlineSettingsCancellationSection.tsx";
import { bhbOnlineSettingsFormNameOf } from "./BhbOnlineSettingsCard.types.ts";
import { BhbOnlineSettingsFormModel } from "./BhbOnlineSettingsFormModel.ts";
import { BhbOnlineSettingsFormValidator } from "./BhbOnlineSettingsFormValidator.ts";
import { BhbOnlineSettingsTimeframesSection } from "./BhbOnlineSettingsTimeframesSection.tsx";

const PLACEHOLDER_TEXT = "Nothing will be displayed if not specified";

interface BhbOnlineSettingsCardProps {
  location: BhbLocation;
  appointmentTypes: BhbAppointmentType[];
}

const BhbOnlineSettingsCardInner = observer(
  ({ location, appointmentTypes }: BhbOnlineSettingsCardProps) => {
    const root = useStores();

    const model = new BhbOnlineSettingsFormModel(
      root,
      location,
      appointmentTypes
    );

    return (
      <SubmissionForm
        formName="bhb-online-settings"
        initialValues={model.getInitialValues()}
        onSubmit={model.onSubmit}
        autoFocus={false}
        validate={values =>
          new BhbOnlineSettingsFormValidator().validate(values)
        }
      >
        {() => (
          <Stack
            styles={{ root: { maxWidth: 700 } }}
            tokens={{ childrenGap: 8 }}
          >
            <Heading variant="section-heading-light">
              Practice information
            </Heading>
            <ApplyPracticeLogo />
            <TextInputField
              name={bhbOnlineSettingsFormNameOf("url")}
              label="Practice website URL"
            />
            <TextEditorField
              name={bhbOnlineSettingsFormNameOf("policy")}
              label="Booking policy"
              placeholder={PLACEHOLDER_TEXT}
            />

            <Stack>
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
              >
                <Heading labelPaddings>Emergency message</Heading>
                <ToggleField
                  name={bhbOnlineSettingsFormNameOf("emergencyMessageEnabled")}
                  onText="Showing"
                  offText="Show"
                  styles={{ root: { marginBottom: 0 } }}
                />
              </Stack>
              <TextEditorField
                name={bhbOnlineSettingsFormNameOf("emergencyMessage")}
              />
            </Stack>
            <Separator />
            <BhbOnlineSettingsCancellationSection />
            <Separator />
            <BhbOnlineSettingsTimeframesSection />
          </Stack>
        )}
      </SubmissionForm>
    );
  }
);

export const BhbOnlineSettingsCard = observer(() => {
  return (
    <CollapsibleCard
      heading="Online booking settings"
      headingLevel="modal-heading"
      openOnRender
    >
      <DataFetcher
        fetch={({ bhb, core }) =>
          Promise.all([
            bhb.getLocation(core.locationId),
            bhb.getAppointmentTypesForLocation()
          ])
        }
      >
        {([location, appointmentTypes]) => (
          <BhbOnlineSettingsCardInner
            location={location}
            appointmentTypes={appointmentTypes ?? []}
          />
        )}
      </DataFetcher>
    </CollapsibleCard>
  );
});
