import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";

export const DidNotArriveReason: FC<{ item: DidNotArriveItem }> = observer(
  ({ item }) => {
    let reasonText: string | undefined = "N/A";

    const { booking } = useStores();
    if (item.cancellationReasonId) {
      const cancellationReason = booking.appointmentCancellationReasonsMap.get(
        item.cancellationReasonId
      );

      if (cancellationReason) {
        if (cancellationReason.text === "Other")
          reasonText = item.cancellationText;
        else reasonText = cancellationReason.text;
      }
    }
    return <Text>{reasonText}</Text>;
  }
);
