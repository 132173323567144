import { ButtonsGroupOption, Theme } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  CodedText,
  ObservationType,
  PhysitrackType,
  PSFSContextClinicalDataItemDto,
  QuestionnaireAnswerOptionDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export const MAX_INPUT = 10;

export const getClinicalToolsListOptions = () => {
  const clinicalToolsListOptions = [
    {
      key: ClinicalDataType.DASH,
      text: "DASH 11"
    },
    {
      key: ClinicalDataType.DASS21,
      text: "DASS 21"
    },
    {
      key: ClinicalDataType.EPDS,
      text: ObservationType.EPDS
    },
    {
      key: ClinicalDataType.GRCS,
      text: ObservationType.GRCS
    },
    {
      key: ClinicalDataType.K10,
      text: ObservationType.K10
    },
    {
      key: ClinicalDataType.NPRS,
      text: ObservationType.NPRS
    },
    {
      key: ClinicalDataType.OREBRO,
      text: ObservationType.OREBRO
    },
    {
      key: ClinicalDataType.PSFS,
      text: ObservationType.PSFS
    },
    {
      key: PhysitrackType.Key,
      text: PhysitrackType.Text
    },
    {
      key: ClinicalDataType.RAND36,
      text: "RAND 36"
    }
  ];

  return clinicalToolsListOptions;
};

export const getCodedTextsFromContexts = (
  contexts: PSFSContextClinicalDataItemDto[] | undefined
): CodedText[] => {
  const codedTexts: CodedText[] = [];

  if (contexts) {
    contexts.forEach(context => {
      codedTexts.push({
        code: context?.diagnosis.code || "",
        text: context?.diagnosis.originalText || ""
      });
    });
  }

  return codedTexts;
};

export const getQuestionnaireAnswerTexts = (
  answerOptions: QuestionnaireAnswerOptionDto[],
  options: {
    disabled: boolean;
    showIndex?: boolean;
    displayIndexOnly?: boolean;
  }
): ButtonsGroupOption<string>[] => {
  const { disabled, showIndex, displayIndexOnly } = options;

  const getAnswerText = (item: QuestionnaireAnswerOptionDto, index: number) => {
    const indexNum = (index + 1).toString();
    if (displayIndexOnly) {
      return indexNum;
    }
    return showIndex ? `${indexNum} - ${item.text}` : item.text;
  };

  const mapped: ButtonsGroupOption<string>[] = answerOptions.map((x, index) => {
    return {
      key: x.value,
      text: getAnswerText(x, index),
      disabled,
      tooltipContent: displayIndexOnly ? x.text : ""
    };
  });

  return mapped;
};

export const getBgColour = (
  index: number,
  theme: Theme,
  invertedColorOrder?: boolean
) => {
  const modifier = invertedColorOrder ? 1 : 0;

  return index % 2 === 0 + modifier
    ? theme.palette.neutralLighterAlt
    : theme.palette.white;
};
