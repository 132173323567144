import { DateTime } from "@bps/utils";
import {
  ObservationType,
  QuestionnaireDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";

import { ClinicalDataToObservationConverter } from "../utils/ClinicalDataToObservationConverter.ts";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswerText
} from "../utils/ClinicalToolAnswerFinder.ts";

export interface ClincialToolViewData {
  observations?: Observation[];
  questionnaire: QuestionnaireDto;
  answers: ClinicalToolAnswer[];
  summary: string;
  isConfidential: boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
}

export class ClinicalToolViewDataFetcher {
  getData = async (
    clinical: ClinicalStore,
    encounterId: string,
    observationType: ObservationType
  ): Promise<ClincialToolViewData> => {
    const converter = new ClinicalDataToObservationConverter();

    const savedData = (
      await clinical.getEncounterClinicalData({ encounterId })
    )[
      converter.observationToClinical(observationType).toLocaleLowerCase()
    ] as QuestionnaireResponseDto;

    const questionnaire = await clinical.getQuestionnaires(
      converter.observationToQuestionnaire(observationType)
    );

    const answers = savedData
      ? savedData.items.map(i => ({
          questionId: `q${i.questionnaireItemId}`,
          answerValue: i.answer,
          answerText: findClinicalToolAnswerText(
            i.questionnaireItemId,
            i.answer!,
            questionnaire
          )
        }))
      : [];

    const result = await clinical.getObservations({
      patientId: clinical.activeRecordPatientId,
      encounterIds: [encounterId],
      types: [observationType]
    });

    const observations = result.results;

    if (observations.length === 0) {
      throw new Error(`Unable to retrieve ${observationType} Observation data`);
    }

    const summary =
      observations[0].summary ?? observations[0].values[0].value.toString();

    const isConfidential = !!savedData.secGroupId;
    const lastUpdatedDate = getClinicalDataLastUpdatedDate(savedData);
    const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(savedData);

    return {
      observations,
      answers,
      summary,
      questionnaire,
      isConfidential,
      lastUpdatedDate,
      lastUpdatedUserId
    };
  };
}
