import { TextBadgeColor, TextBadgeProps, TextBadgeSize } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  GstMethod,
  ServiceInstanceDto,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { FeeActiveLabel } from "@modules/settings/screens/schedules/labels.ts";
import { feeFormChangesBadgeStyles } from "@modules/settings/screens/schedules/SchedulesScreen.styles.ts";
import { getFeeIncludingGst } from "@modules/settings/screens/shared-components/fees.utils.ts";

import { FeeInstanceListDataItem } from "./fee-view.types";

export const getFeeText = (
  item: Omit<ServiceInstanceDto, "effectiveDate">,
  gstMethod: GstMethod,
  gstPercent: number
): string => {
  const userDefinedAmount = item.rules.find(
    r => r.ruleType === ServiceRuleType.UserDefinedAmount
  );

  if (!item.isActive) {
    return "—";
  }

  if (
    userDefinedAmount &&
    userDefinedAmount.max === undefined &&
    item.fee === undefined
  ) {
    return "At cost";
  }

  return currencyFormat(
    getFeeIncludingGst(
      userDefinedAmount?.max ?? item.fee ?? 0,
      gstMethod,
      gstPercent
    ),
    { currency: "" }
  );
};

export const getInstanceListData = (
  instances: ServiceInstanceDto[],
  gstMethod: GstMethod,
  gstPercent: number
): FeeInstanceListDataItem[] =>
  instances
    .map(instance => ({
      ...instance,
      effectiveDate: DateTime.fromISO(instance.effectiveDate)
    }))
    .map((feeInstance, index, instances) => {
      const nextInstance =
        index === instances.length - 1 ? undefined : instances[index + 1];

      const isNextInstanceInEffect =
        nextInstance?.effectiveDate.isBeforeToday ||
        nextInstance?.effectiveDate.isToday;

      const badge: TextBadgeProps = {
        children: "Historic",
        badgeColor: TextBadgeColor.lightGrey,
        badgeSize: TextBadgeSize.small,
        styles: feeFormChangesBadgeStyles
      };

      const isEffectiveDateInTheFuture =
        !feeInstance.effectiveDate.isBeforeToday &&
        !feeInstance.effectiveDate.isToday;

      if (!isNextInstanceInEffect && !isEffectiveDateInTheFuture) {
        badge.children = "Current";
        badge.badgeColor = TextBadgeColor.blue;
      } else if (isEffectiveDateInTheFuture) {
        badge.children = "Future";
        badge.badgeColor = TextBadgeColor.yellow;
      }

      let date = "";

      const startDate = feeInstance.effectiveDate.toDayDefaultFormat();

      if (!nextInstance) {
        date = `${startDate} - ongoing`;
      } else {
        const endDate = nextInstance.effectiveDate
          .minus({ days: 1 })
          .toDayDefaultFormat();

        date = `${startDate} - ${endDate}`;
      }

      const amount = getFeeText(feeInstance, gstMethod, gstPercent);

      let state = feeInstance.isActive
        ? FeeActiveLabel.active
        : FeeActiveLabel.inactive;
      if (badge.children === "Historic") {
        state = feeInstance.isActive
          ? FeeActiveLabel.previouslyActive
          : FeeActiveLabel.previouslyInactive;
      }
      if (badge.children === "Future") {
        state = feeInstance.isActive
          ? FeeActiveLabel.willBeActive
          : FeeActiveLabel.willBeInactive;
      }
      return { badge, date, fee: amount, state };
    })
    .reverse();
