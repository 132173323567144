import { FormApi } from "final-form";
import React, { useState } from "react";

import { Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ObservationDto,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ConfidentialShield } from "../shared-components/ConfidentialShield.tsx";
import { DASHBaselineForm } from "./DASHBaselineForm.tsx";
import { DASHFormProps, DashFormValues } from "./DASHForm.types.ts";
import { DASHFormModel } from "./DASHFormModel.ts";
import { DASHScoreModal } from "./DASHScoreModal.tsx";
import { DASHFormValidator } from "./validators/DASHFormValidator.ts";

const validator = new DASHFormValidator();

const DASHFormBase: React.FunctionComponent<DASHFormProps> = ({
  clinicalRecord,
  questionnaire
}) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
  const [observations, setObservations] = useState<ObservationDto[]>([]);
  const FORM_HEADING = "Disability of the Arm, Shoulder, and Hand (DASH) Score";
  const onObservationSaved = (observation: ObservationDto[]) => {
    setObservations(observation);
  };

  const [closeOnSubmit, setCloseOnSubmit] = useState<boolean>(false);

  const nameOf = nameOfFactory<DashFormValues>();

  const model = new DASHFormModel(clinicalRecord, clinical, onObservationSaved);

  const onSubmitSucceeded = (
    values: DashFormValues,
    form: FormApi<DashFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.DASH
      });
      setCloseOnSubmit(false);
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      setCloseOnSubmit(true);
    }
  };

  return (
    <>
      <ClinicalSubmissionForm<DashFormValues>
        formName="dash"
        onSubmit={model.onSubmit(questionnaire)}
        onSubmitSucceeded={onSubmitSucceeded}
        hideButtons
        validate={value => validator.validate(value.items)}
        initialValues={model.initialValues}
        readOnly={isViewOnlyOrDischarged}
        disableRoutePrompt
        heading={FORM_HEADING}
        onCancel={model.onCancel}
        promptOnCancel={true}
        disableButtonCondition={stashedData =>
          !stashedData?.dirtyAreas?.get("dash")
        }
        lastUpdatedDate={getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.dash
        )}
        lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
          clinicalRecord.clinicalData?.dash
        )}
        confidentialShield={
          <ConfidentialShield name={nameOf("confidential")} />
        }
        noGap
        hasSeparator
      >
        <>
          <StashedClinicalDataFormSpy<DashFormValues>
            clinicalRecord={clinicalRecord}
            getData={model.getStashedClinicalData(questionnaire)}
            areasToObserve={{
              dash: [ClinicalDataType.DASH]
            }}
          />
          <DASHBaselineForm questionnaire={questionnaire} model={model} />
        </>
      </ClinicalSubmissionForm>
      {observations.length > 0 && (
        <DASHScoreModal
          onClose={() => {
            if (closeOnSubmit) {
              model.onCancel();
            } else {
              setObservations([]);
            }
          }}
          observations={observations}
        />
      )}
    </>
  );
};

export const DASHForm: React.FunctionComponent<
  Omit<DASHFormProps, "questionnaire">
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.DASHV1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <DASHFormBase
          clinicalRecord={clinicalRecord}
          questionnaire={questionnaire}
        />
      )}
    </DataFetcher>
  );
};
