import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useCallback } from "react";

import {
  DetailsListLayoutMode,
  DtoDetailsRow,
  IColumn,
  IDetailsRowProps,
  NoDataTile,
  RESET_CELLS_PADDING_CLASSNAME,
  Text
} from "@bps/fluent-ui";
import { useFormContext } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useCorrespondencesHelper } from "@modules/clinical/screens/patient-record/components/correspondence/context/CorrespondencesContext.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalDocumentViewerLink } from "../../../shared-components/ClinicalDocumentViewerLink.tsx";
import { CorrespondencesLabels } from "../correspondence/Correspondences.types.ts";
import { SideNavDetailsList } from "../SideNavDetailsList.tsx";
import { ClinicalImageFilter } from "./ClinicalImagesFilter.tsx";
import { ClinicalImagesListItemContextMenu } from "./ClinicalImagesListItemContextMenu.tsx";

interface ClinicalImagesListProps {
  patientId: string;
}

export const ClinicalImagesList: FunctionComponent<ClinicalImagesListProps> =
  observer(({ patientId }) => {
    const { correspondence, core } = useStores();
    const { onOpenClinicalDocumentClick } = useCorrespondencesHelper();
    const {
      state: { values: filter }
    } = useFormContext<ClinicalImageFilter>();

    const { isViewOnly } = usePatientRecordScreenContext();

    const columns: IColumn[] = [];
    if (!isViewOnly) {
      columns.push({
        fieldName: "contextMenu",
        key: "contextMenu",
        className: RESET_CELLS_PADDING_CLASSNAME,
        minWidth: 13,
        maxWidth: 13,
        name: "",
        onRender: (record: ClinicalDocument) => (
          <ClinicalImagesListItemContextMenu record={record} />
        ),
        isResizable: false
      });
    }

    columns.push(
      {
        fieldName: "clinicalImageDate",
        key: "clinicalImageDate",
        minWidth: 75,
        maxWidth: 75,
        name: "Date",
        onRender: (record: ClinicalDocument) => {
          return <Text>{record.date?.toDayDefaultFormat()}</Text>;
        },
        isResizable: true
      },
      {
        fieldName: "title",
        key: "title",
        minWidth: 150,
        name: "Title",
        onRender: (record: ClinicalDocument) => {
          const providerName = core.getUserByPrivateSecGroupId(
            record.secGroupId
          )?.fullName;

          return core.hasAccessToSecGroup(record.secGroupId) ? (
            <ClinicalDocumentViewerLink
              record={record}
              onClick={onOpenClinicalDocumentClick}
            />
          ) : (
            <Text nowrap>{`Confidential (by ${providerName})`}</Text>
          );
        },
        isResizable: true
      }
    );

    const search = useCallback(
      async (query: PagingOptions) => {
        const clinicalImages = await correspondence.fetchClinicalImages({
          ...query,
          ...filter,
          patientId,
          total: true
        });
        clinicalImages.results = clinicalImages.results.filter(
          x =>
            x.secGroupId === undefined || core.hasAccessToSecGroup(x.secGroupId)
        );

        return clinicalImages;
      },
      [correspondence, filter, patientId, core]
    );

    return (
      <SideNavDetailsList<ClinicalDocument>
        getItems={search}
        refreshKey={correspondence.correspondenceListRefreshKey}
        layoutMode={DetailsListLayoutMode.justified}
        columns={columns}
        checkButtonAriaLabel={CorrespondencesLabels.RowCheckbox}
        onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
        onRenderNoResults={() => (
          <NoDataTile
            textProps={{
              text: "No clinical image found"
            }}
            linkProps={{
              hidden: true
            }}
            showBoxShadow={false}
          />
        )}
      />
    );
  });
