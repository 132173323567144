import { observer } from "mobx-react-lite";
import { useCallback, useContext } from "react";
import { useFormState } from "react-final-form";

import {
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  Text
} from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { StatementItemsContext } from "../context/StatementItemsContext.ts";
import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";

export const StatementSecondColumn = observer(() => {
  const { searchAccountIds, selection } = useContext(StatementItemsContext);
  const { values } = useFormState<StatementGenerateFormValues>();

  const search = useCallback(
    async (query?: PagingOptions) => {
      return await searchAccountIds(values, query);
    },
    [searchAccountIds, values]
  );

  const columns = [
    {
      name: "Recipients",
      key: "date",
      minWidth: 79,
      maxWidth: 79,
      onRender: (props: { id: string }) => (
        <ContactFetcher contactId={props.id}>
          {contact => <Text>{contact.name}</Text>}
        </ContactFetcher>
      )
    }
  ];

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <InfiniteScrollList
          setKey="statements-recipients-list"
          getItems={search}
          detailsListStyles={{
            root: {
              ".ms-DetailsRow-cell": { alignSelf: "center" }
            }
          }}
          columns={columns}
          selection={selection}
          selectionMode={SelectionMode.multiple}
          displayDefaultCheckBox
          onRenderNoResults={() => (
            <NoDataTile
              showBoxShadow={false}
              textProps={{ text: "No statements" }}
              linkProps={{ hidden: true }}
            />
          )}
        />
      </ScrollablePane>
    </div>
  );
});
