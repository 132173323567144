import { FC } from "react";

import {
  confirm,
  DefaultButton,
  Heading,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { AttendeeTypeEnum } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";

export interface ManualRescheduleFormValues {
  manuallyRescheduledBy: string;
  manuallyRescheduledComment?: string;
}

interface ManualRescheduleFormProps {
  onDismiss: () => void;
  item: DidNotArriveItem;
}

export const DidNotArriveManualRescheduleModal: FC<
  ManualRescheduleFormProps
> = ({ onDismiss, item }) => {
  const { booking, core } = useStores();

  const theme = useTheme();

  const handleMarkAsRescheduled = async (
    values: ManualRescheduleFormValues
  ) => {
    booking.updateCalendarEvent({
      ...item.calendarEvent.dto,
      manuallyRescheduledComment: values.manuallyRescheduledComment,
      bookedBy: core.user?.id,
      manuallyRescheduledBy: core.user?.id,
      rescheduledAppointmentId: item.calendarEvent.id
    });
  };

  const removeMarkAsRescheduled = async () => {
    booking.updateCalendarEvent({
      ...item.calendarEvent.dto,
      manuallyRescheduledComment: undefined,
      manuallyRescheduledBy: undefined,
      rescheduledAppointmentId: undefined
    });
  };

  const getExtraActionsBefore = (patient: Contact) => {
    return (
      <Stack horizontal styles={{ root: { width: "100%" } }}>
        <DefaultButton
          text="Undo"
          onClick={async () => {
            const isConfirmed = await confirm({
              confirmButtonProps: {
                text: "Undo reschedule"
              },
              cancelButtonProps: {
                text: "Cancel"
              },
              dialogContentProps: {
                subText: `The appointment for ${patient.fullName} has been cancelled/DNA'd and not rescheduled`
              }
            });

            if (isConfirmed) {
              await removeMarkAsRescheduled();
              onDismiss();
            }
          }}
        />
      </Stack>
    );
  };

  const attendeeId = item.calendarEvent.attendees.find(
    attendee => attendee.type === AttendeeTypeEnum.contact
  )?.attendeeId;

  if (!attendeeId) return null;

  return (
    <ContactFetcher contactId={attendeeId}>
      {patient => (
        <SubmissionFormDialog<ManualRescheduleFormValues>
          dialogName="MarkAsRescheduled"
          initialValues={{
            manuallyRescheduledBy: item.calendarEvent.manuallyRescheduledBy,
            manuallyRescheduledComment:
              item.calendarEvent.manuallyRescheduledComment
          }}
          onSubmit={handleMarkAsRescheduled}
          onSubmitSucceeded={onDismiss}
          buttonsProps={{
            submitButtonProps: { iconProps: { hidden: true } },
            disableSubmitOnPristine: false,
            extraActionsBefore: () =>
              item.calendarEvent.manuallyRescheduledBy
                ? getExtraActionsBefore(patient)
                : undefined
          }}
          dialogProps={{
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">
                  {`Reschedule ${patient.name}'s appointment`}
                </Heading>
              ),
              subText: `${item.calendarEvent.startDateTime.toFormat(
                DATE_FORMATS.DETAILED_DATE_TIME
              )}
                    ${
                      item.provider?.fullName
                        ? `with ${item.provider?.fullName} `
                        : ""
                    }`
            },
            onDismiss,
            minWidth: 600,
            modalProps: {
              layerProps: {
                eventBubblingEnabled: false
              }
            }
          }}
        >
          {({ values }) => (
            <Stack tokens={{ childrenGap: 8 }}>
              <TextInputField
                multiline
                label="Comment"
                name="manuallyRescheduledComment"
              />
              {values.manuallyRescheduledBy && (
                <UserFetcher userId={values.manuallyRescheduledBy}>
                  {user => (
                    <Text
                      styles={{
                        root: { color: theme.semanticColors.bodySubtext }
                      }}
                      variant="small"
                    >
                      By {user?.fullName}
                    </Text>
                  )}
                </UserFetcher>
              )}
            </Stack>
          )}
        </SubmissionFormDialog>
      )}
    </ContactFetcher>
  );
};
