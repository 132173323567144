import { useState } from "react";

import {
  FontWeights,
  Link,
  NoDataTile,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ReactionClinicalDataDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { RecordUpdateCheckedLog } from "@modules/clinical/screens/shared-components/RecordUpdateCheckedLog.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { ReactionFormDialog } from "./ReactionFormDialog.tsx";

interface IRenderNilKnownReactionStateProps {
  clinicalRecord: ClinicalRecord;
  onDismiss: () => void;
}

export const RenderNilKnownReactionState: React.FunctionComponent<
  IRenderNilKnownReactionStateProps
> = ({ clinicalRecord, onDismiss }) => {
  const theme = useTheme();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const onAddReaction = () => {
    setIsDialogVisible(true);
  };

  const onReactionDialogDismiss = () => {
    setIsDialogVisible(false);
  };

  const reactionsChecked =
    clinicalRecord.clinicalData?.reactionsChecked?.reactionsCheckedAndUpdated;

  const { createdById: updatedById, createdDateTime: updatedDateTime } =
    reactionsChecked?.createLog || {};

  const handleClinicalData = (
    event: React.MouseEvent<
      HTMLElement | HTMLAnchorElement | HTMLButtonElement,
      MouseEvent
    >,
    nilKnown: boolean
  ) => {
    event.preventDefault(); // Prevent default link behavior
    markClinicalData(nilKnown);
  };

  const markClinicalData = async (nilKnown: boolean) => {
    const nilKnownReset =
      !nilKnown && clinicalRecord.clinicalData?.reaction?.nilKnown;
    await updateClinicalData({
      reactions: [],
      agents: [],
      nilKnown,
      nilKnownReset
    });
    onDismiss();
  };

  const updateClinicalData = async (reaction: ReactionClinicalDataDto) => {
    reaction.eTag = clinicalRecord.clinicalData?.reaction?.eTag;

    await clinicalRecord.saveClinicalData({
      reaction
    });
  };

  const reactionClinicalData = clinicalRecord.clinicalData?.reaction;
  const nilKnown = reactionClinicalData?.nilKnown;
  const nilKnownText = nilKnown
    ? "Revert to 'No reaction recorded'"
    : "Record 'Nil known' reactions";

  const recordedText = (
    <RecordUpdateCheckedLog
      createdBy={reactionClinicalData?.createLog?.createdById}
      createdDate={reactionClinicalData?.createLog?.createdDateTime}
      updatedBy={updatedById}
      updatedDate={updatedDateTime}
    />
  );

  return (
    <>
      <Stack
        grow
        horizontalAlign="center"
        styles={{
          root: {
            background: theme.palette.neutralLighterAlt,
            padding: 30
          }
        }}
      >
        <Text
          styles={{
            root: {
              fontSize: 18,
              fontWeight: FontWeights.semibold,
              textAlign: "center",
              backgroundColor: theme.palette.neutralLighterAlt
            }
          }}
        >
          Nil known
        </Text>
        <NoDataTile
          textProps={{
            text: recordedText,
            styles: { root: { fontStyle: "normal" } }
          }}
          linkProps={{
            onRender: () => (
              <Stack horizontalAlign="center" tokens={{ childrenGap: 12 }}>
                <Link onClick={onAddReaction}>Add a reaction</Link>
                <Text
                  styles={{
                    root: {
                      fontStyle: "italic"
                    }
                  }}
                >
                  or
                </Text>
                <Link onClick={event => handleClinicalData(event, false)}>
                  {nilKnownText}
                </Link>
              </Stack>
            )
          }}
          showBoxShadow={false}
          greyView={true}
        />
      </Stack>
      {isDialogVisible && (
        <ReactionFormDialog
          clinicalRecord={clinicalRecord}
          onDismiss={onReactionDialogDismiss}
          reaction={undefined}
        />
      )}
    </>
  );
};
