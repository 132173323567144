import { FC } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { ClinicalReminderCommCreateBatchArgs } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalActivityTableRow } from "./types/clinical-activity-table.type.ts";

type FollowupReminderActionMenuProps = {
  item: ClinicalActivityTableRow;
};

export const FollowupReminderActionMenu: FC<
  FollowupReminderActionMenuProps
> = ({ item }) => {
  const { clinical, core, notification } = useStores();
  const dto = {
    ...item.activity.dto
  };

  const menuItems: IContextualMenuItem[] = [];

  if (core.hasPermissions(Permission.ClinActivityWrite)) {
    menuItems.push(
      ...[
        {
          key: "markAsOptions",
          className: "mark_as",
          text: "Mark as",
          subMenuProps: {
            items: [
              {
                key: "markAsCompleted",
                className: "completed",
                text: "Completed",
                onClick: () => {
                  if (dto) {
                    clinical.updateClinicalActivity(dto.patientId, [
                      {
                        ...dto,
                        activityStatus: ClinicalActivityStatus.Completed
                      }
                    ]);
                  }
                }
              }
            ]
          }
        },
        {
          key: "resend",
          className: "resend",
          text: "Resend",
          onClick: () => {
            const sendingRequest: ClinicalReminderCommCreateBatchArgs = {
              orgUnitId: core.locationId,
              activities: [dto.id]
            };
            clinical.createSendClinicalReminderBatch(sendingRequest);
            notification.info("Resending reminder...");
          }
        }
      ]
    );
  }

  return (
    <Stack horizontalAlign="center" verticalAlign="center">
      <TooltipHost content="More">
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            "followupReminder-list-actions-button"
          )}
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: menuItems.map(i => ({
              ...dataAttribute(
                DataAttributes.Element,
                "followupReminder-list-actions-list-item"
              ),
              ...i
            })),
            calloutProps: {
              ...dataAttribute(
                DataAttributes.Element,
                "followupReminder-list-actions-list"
              )
            }
          }}
          styles={{
            root: { width: "32px", height: "36px", padding: 0 },
            flexContainer: { width: "32px", height: "36px" }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
