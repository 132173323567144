import React from "react";

import { Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  ObservationType,
  PSFSMultipleQuestionnaireResponseDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { PSFSHelper } from "./context/PSFSHelper.ts";
import { PSFSViewModel } from "./context/PSFSViewModel.ts";
import { PSFSBaselineView } from "./PSFSBaselineView.tsx";
import { PSFSSeriesForm } from "./PSFSSeriesForm.tsx";

interface PSFSViewProps {
  encounterId: string;
  clinicalRecord: ClinicalRecord;
  contextId?: string;
}

export const PSFSView: React.FunctionComponent<PSFSViewProps> = ({
  encounterId,
  clinicalRecord,
  contextId
}) => {
  const getPSFSViewData =
    () =>
    async ({ clinical }: RootStore): Promise<PSFSViewModel> => {
      // this is a temp workaround - to be resolved when we figure out
      // Product Backlog Item 18938: Tech Debt: Review GetEncounterClinicalData() Usage in Front-End
      const multiResponseData = (
        await clinical.getEncounterClinicalData({
          encounterId,
          types: [ClinicalDataType.PSFS]
        })
      )[
        ObservationType.PSFS.toLocaleLowerCase()
      ] as PSFSMultipleQuestionnaireResponseDto;

      const context = clinicalRecord.clinicalData?.psfsContext?.contexts.find(
        c => c.contextId === contextId
      )!;

      const response = multiResponseData.responses.find(
        r => r.contextId === context?.contextId
      )!;

      const questionnaire = await clinical.getQuestionnaires(
        QuestionnaireType.PSFSV1
      );

      const answers = response
        ? response.items.map(i => ({
            questionId: `q${i.questionnaireItemId}`,
            answerValue: i.answer
          }))
        : [];

      const result = await clinical.getObservations({
        patientId: clinical.activeRecordPatientId,
        contextId: context.contextId,
        encounterIds: [encounterId],
        types: [ObservationType.PSFS]
      });

      const observations = result.results;

      if (observations.length !== 1)
        throw new Error(
          `Unable to retrieve ${ObservationType.PSFS} Observation data`
        );

      const summary =
        observations[0].summary ?? observations[0].values[0].value.toString();

      const isConfidential = !!response.secGroupId;

      const lastUpdatedDate = getClinicalDataLastUpdatedDate(response);

      const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(response);

      return {
        answers,
        summary,
        questionnaire,
        context,
        response,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      };
    };

  const helper = new PSFSHelper(clinicalRecord, contextId, encounterId);

  return (
    <DataFetcher<PSFSViewModel>
      fetch={getPSFSViewData()}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        questionnaire,
        context,
        response,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <ClinicalToolView
          title="Patient Specific Function Scale"
          summary={summary}
          subtitle={helper.generateSubheadingText(
            DateTime.fromISOOrNow(response.createLog?.createdDateTime)
          )}
          encounterId={encounterId}
          isConfidential={isConfidential}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          {helper.isInitialAssessment ? (
            <PSFSBaselineView
              questionnaire={questionnaire}
              answers={answers}
              context={context}
            />
          ) : (
            <PSFSSeriesForm
              questionnaire={questionnaire}
              answers={answers}
              context={context}
              response={response}
            />
          )}
        </ClinicalToolView>
      )}
    </DataFetcher>
  );
};
