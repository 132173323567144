import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import {
  Card,
  confirm,
  IPivotProps,
  Pivot,
  PivotItem,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UsersLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FORM_PROMPT_DEFAULT_MESSAGE } from "@ui-components/form/FinalForm.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserProfileSection } from "../UserBreadcrumbs.tsx";
import { UserOnlineBookingProfileForm } from "./UserOnlineBookingProfileForm.tsx";
import { UserOnlineBookingProfileFormContext } from "./UserOnlineBookingProfileFormContext.ts";
import { UserOnlineBookingProfileFormHelper } from "./UserOnlineBookingProfileFormHelper.ts";
import { UserOnlineBookingProfileSettings } from "./UserOnlineBookingProfileSettings.tsx";

interface UserOnlineBookingProfileCardProps {
  bhbProviders: BhbProvider[];
  user: User;
  userAvailability: UserAvailabilityModel | undefined;
}

enum PivotKeys {
  ProviderDetails = "1",
  Settings = "2"
}

export const UserOnlineBookingProfileCard = observer(
  ({
    bhbProviders,
    user,
    userAvailability
  }: UserOnlineBookingProfileCardProps) => {
    const root = useStores();
    const [pivotKey, setPivotKey] = useState<string>(PivotKeys.ProviderDetails);
    const dirtyRef = useRef(false);

    const getIsFormDirty = async (): Promise<boolean> => {
      if (!dirtyRef.current) return false;

      const isConfirmed = await confirm({
        dialogContentProps: {
          subText: FORM_PROMPT_DEFAULT_MESSAGE
        },
        confirmButtonProps: {
          text: defaultButtonsText.confirm
        },
        cancelButtonProps: {
          text: defaultButtonsText.cancel
        }
      });

      if (isConfirmed) {
        dirtyRef.current = false;
      }

      return !isConfirmed;
    };

    const changePivot: IPivotProps["onLinkClick"] = async item => {
      if (!item?.props.itemKey) return;

      const isDirty = await getIsFormDirty();

      if (!isDirty) {
        setPivotKey(item.props.itemKey);
      }
    };

    const userOnlineBookingProfileFormHelper =
      new UserOnlineBookingProfileFormHelper({
        user,
        bhbProviders,
        userAvailability,
        root
      });

    return (
      <When permission={[Permission.BhbConfigAllowed, Permission.BhbRead]}>
        <Card
          iconName="LaptopSelected"
          headingLevel="section-heading"
          heading={UsersLabels.onlineBookingProfile}
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: "16px 0 0 0"
                }
              }
            }
          }}
          id={UserProfileSection.OnlineBookingProfile}
        >
          <Stack styles={{ root: { margin: "0 64px" } }}>
            <Pivot selectedKey={pivotKey} onLinkClick={changePivot}>
              <PivotItem
                itemKey={PivotKeys.ProviderDetails}
                headerText="Provider details"
              />
              <PivotItem itemKey={PivotKeys.Settings} headerText="Settings" />
            </Pivot>
          </Stack>
          <UserOnlineBookingProfileFormContext.Provider
            value={userOnlineBookingProfileFormHelper}
          >
            {pivotKey === PivotKeys.ProviderDetails && (
              <UserOnlineBookingProfileForm
                bhbProviders={bhbProviders}
                setIsDirty={dirty => {
                  dirtyRef.current = dirty;
                }}
              />
            )}
            {pivotKey === PivotKeys.Settings && (
              <UserOnlineBookingProfileSettings
                bhbProviders={bhbProviders}
                user={user}
              />
            )}
          </UserOnlineBookingProfileFormContext.Provider>
        </Card>
      </When>
    );
  }
);
