import { observer } from "mobx-react-lite";

import { Card, useTheme } from "@bps/fluent-ui";
import {
  OrgUnitHierarchyType,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AppointmentSettingsValues,
  nameOf
} from "@modules/settings/screens/practice/components/appointment-settings/AppointmentSettings.types.ts";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

export const AppointmentSettingsCard: React.FC = observer(() => {
  const { core, notification, userExperience } = useStores();
  const { apptTypeBaseInterval } =
    userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

  const onSubmitAppointmentSetting = async (
    values: AppointmentSettingsValues
  ) => {
    const { apptTypeBaseInterval } = values;
    // sync across all org units
    const locations = Array.from(core.orgUnitsMap.values()).filter(
      l => l.hierarchyType === OrgUnitHierarchyType.Location
    );
    await Promise.all(
      locations.map(location =>
        userExperience.updateOrgUnitSetting({
          orgUnitId: location.id,
          appointmentSetting: {
            apptTypeBaseInterval
          },
          eTag: userExperience.orgUnitSettingMap.get(location.id)?.eTag || ""
        })
      )
    );
  };

  const theme = useTheme();
  const { formFooter, formFields } = getUserStylesSet(theme);
  return (
    <Card
      iconName="calendar"
      headingLevel="section-heading"
      heading={SettingsLabels.appointmentsSettings}
      styles={{ subComponentStyles: { tile: { content: { padding: 0 } } } }}
    >
      <SubmissionForm<AppointmentSettingsValues>
        formName="appointment-settings"
        onSubmitSucceeded={() => {
          notification.success("Appointment settings has been updated.");
        }}
        readOnly={!core.hasPermissions(Permission.TenantSettingWrite)}
        autoFocus={false}
        onSubmit={onSubmitAppointmentSetting}
        initialValues={{ apptTypeBaseInterval: apptTypeBaseInterval || "" }}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
        styles={{ fields: formFields }}
      >
        {() => {
          return (
            <ComboBoxField
              name={nameOf("apptTypeBaseInterval")}
              label="Base appointment interval"
              options={userExperience.ref.apptTypeBaseIntervals.keyTextValues}
              styles={{ root: { width: 200 } }}
              useComboBoxAsMenuWidth
              required
            />
          );
        }}
      </SubmissionForm>
    </Card>
  );
});
