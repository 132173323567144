import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { StatementItemsContext } from "@modules/billing/screens/statement/context/StatementItemsContext.ts";
import { ExpanderButton } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/ExpanderButton.tsx";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const StatementExpanderButton = observer(() => {
  const { total, selectedIds } = useContext(StatementItemsContext);
  const { billing } = useStores();
  const { palette } = useTheme();
  return (
    <ExpanderButton
      {...dataAttribute(DataAttributes.Element, "statement-expander-button")}
      fields={[]}
      errorMessage=""
      onClick={() =>
        billing.ui.setStatementSecondColumn(
          billing.ui.statementSecondColumn !== SecondColumnContent.statementRun
            ? SecondColumnContent.statementRun
            : undefined
        )
      }
      isOpened={
        billing.ui.statementSecondColumn === SecondColumnContent.statementRun
      }
      styles={{
        root: {
          maxWidth: "100%",
          margin: 0
        }
      }}
    >
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <FontIcon
          iconName="Filter"
          styles={{
            root: {
              color: palette.themePrimary,
              fontSize: FontSizes.size16
            }
          }}
        />
        <Text>
          Statements ({selectedIds?.length}/{total ?? 0})
        </Text>
      </Stack>
    </ExpanderButton>
  );
});
