import React from "react";

import {
  dataAttribute,
  DataAttributes,
  PickerQueryResult
} from "@bps/fluent-ui";
import { AccTerminologyDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  GenericPickerField,
  GenericPickerFieldProps
} from "@ui-components/form/GenericPickerField.tsx";

interface TerminologyPickerBaseProps
  extends Omit<
    GenericPickerFieldProps<AccTerminologyDto>,
    | "selectedKey"
    | "onResolveSuggestionItem"
    | "onFetchItem"
    | "onSearchItems"
    | "keyAccessor"
  > {
  filter?: (searchResults: AccTerminologyDto[]) => AccTerminologyDto[];
  terminology?: ClaimDiagnosisFormValues;
}

export const TerminologyPicker: React.FC<TerminologyPickerBaseProps> = ({
  filter,
  terminology,
  ...props
}) => {
  const { acc } = useStores();

  const handleExcludeItemsFilter = (items: AccTerminologyDto[]) => {
    return filter ? filter(items) : items;
  };

  const handleOnSearchItems = async (
    filter: string,
    previousSearchResult?: PickerQueryResult<AccTerminologyDto> | undefined
  ) =>
    await acc.fetchAccTerminologies({
      search: filter,
      take: 25,
      skip: previousSearchResult?.take ?? 0,
      total: true
    });

  return (
    <GenericPickerField<AccTerminologyDto>
      {...dataAttribute(DataAttributes.Element, props.name)}
      required
      keyAccessor={dto => dto.diagnosisKey}
      minimumCharactersToSearch={2}
      onResolveSuggestionItem={dto => ({ key: dto.code, name: dto.text })}
      onSearchItems={handleOnSearchItems}
      onFetchItem={key => {
        const terminologyFromMap: AccTerminologyDto =
          acc.getAccTerminologyFromMap(key) ?? {
            code: "",
            text: "",
            diagnosisKey: ""
          };
        return terminology && key === terminology.diagnosisKey
          ? {
              code: terminology.diagnosisCode!,
              text: terminology.diagnosisDescription!,
              diagnosisKey: terminology.diagnosisKey!,
              codeSystem: terminology.codeSystem,
              version: terminology.version
            }
          : terminologyFromMap;
      }}
      excludeItemsFilter={handleExcludeItemsFilter}
      {...props}
    />
  );
};
