import { action, observable } from "mobx";

import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  BhbAppointmentTypeDto,
  BhbAppointmentTypeSortOrderDto,
  BhbLocationDto,
  BhbProviderDto,
  PatchBhbLocationDto,
  UpdateBhbAppointmentTypeDto
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { IBhbGateway } from "@libs/gateways/bhb/bhbGateway.interface.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { getOrThrow } from "@libs/utils/utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { permission } from "@stores/decorators/permission.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { BhbRef } from "./BhbRef.ts";
import { BhbAppointmentType } from "./models/BhbAppointmentType.ts";
import { BhbLocation } from "./models/BhbLocation.ts";
import { BhbProvider } from "./models/BhbProvider.ts";

export class BhbStore implements Store<BhbStore, BhbRef> {
  constructor(private gateway: IBhbGateway) {
    this.ref = new BhbRef(this.gateway);
  }

  root: IRootStore;
  private get notification() {
    return this.root.notification;
  }

  ref: BhbRef;

  locationMap = observable.map<string, BhbLocation>();
  appointmentTypesMap = observable.map<string, BhbAppointmentType>();
  providersMap = observable.map<string, BhbProvider>();

  //Appt types
  @action
  private mergeApptType = (dto: BhbAppointmentTypeDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new BhbAppointmentType(dto),
      map: this.appointmentTypesMap
    });
  };

  @permission([Permission.BhbRead])
  async getAppointmentTypesForLocation(): Promise<
    BhbAppointmentType[] | undefined
  > {
    try {
      const apptTypes = await this.gateway.getAppointmentTypesForLocation(
        this.root.core.locationId
      );

      return apptTypes
        .map(x => {
          x.newPatientMinuteDuration = x.newPatientDuration
            ? x.newPatientDuration / 60
            : undefined;
          return x;
        })
        .map(this.mergeApptType);
    } catch (error) {
      this.notification.error(error);
    }

    return;
  }

  @permission([Permission.BhbRead])
  async getAppointmentTypeForLocation(
    appointmentTypeId: string
  ): Promise<BhbAppointmentType | undefined> {
    const apptType = this.appointmentTypesMap.get(appointmentTypeId);
    if (apptType) {
      return apptType;
    }

    const result = await this.gateway.getAppointmentTypeForLocation(
      this.root.core.locationId,
      appointmentTypeId
    );

    return this.mergeApptType(result);
  }
  async putAppointmentTypeForLocation(
    appointmentType: Omit<UpdateBhbAppointmentTypeDto, "eTag">
  ) {
    try {
      const appointmentTypeItem = getOrThrow(
        this.appointmentTypesMap,
        appointmentType.id
      );

      const updated = await this.gateway.putAppointmentTypes(
        this.root.core.locationId,
        {
          ...appointmentType,
          newPatientDuration: appointmentType.newPatientMinuteDuration
            ? appointmentType.newPatientMinuteDuration * 60
            : null,
          eTag: appointmentTypeItem.eTag
        }
      );

      return this.mergeApptType(updated);
    } catch (error) {
      this.notification.error(error);
    }
    return;
  }

  async updateAppointmentTypeSortOrderForLocation(
    sortRequest: BhbAppointmentTypeSortOrderDto[]
  ) {
    try {
      const updated = await this.gateway.updateSortOrder(
        this.root.core.locationId,
        sortRequest
      );

      return updated.map(this.mergeApptType);
    } catch (error) {
      this.notification.error(error);
    }
    return;
  }

  //Location
  @action
  private mergeLocation = (dto: BhbLocationDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new BhbLocation(dto),
      map: this.locationMap
    });
  };

  @sharePendingPromise()
  async getLocation(locationId: string): Promise<BhbLocation> {
    const cachedLocation = this.locationMap.get(locationId);
    if (cachedLocation) {
      return cachedLocation;
    }

    const location = await this.gateway.getLocation(locationId);
    return this.mergeLocation(location);
  }

  patchLocation(location: PatchBhbLocationDto) {
    return this.gateway.patchLocation(location).then(this.mergeLocation);
  }

  @action
  private mergeProvider = (dto: BhbProviderDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new BhbProvider(dto),
      map: this.providersMap,
      id: dto.id + dto.locationId // id is the provider ID which won't be unique if there is multiple locations
    });
  };

  async getProvidersForLocation(locationId: string): Promise<BhbProvider[]> {
    try {
      const result = await this.gateway.getProvidersForLocation(locationId);
      // we add the locationId onto the dto as otherwise there is no way to differentiate two dtos for the same provider
      return result.map(provider =>
        this.mergeProvider({ ...provider, locationId })
      );
    } catch (error) {
      this.notification.error(error);
    }
    return [];
  }

  async updateProvider(
    provider: BhbProviderDto
  ): Promise<BhbProvider | undefined> {
    try {
      const result = await this.gateway.updateProviderForLocation(provider);

      const model = this.mergeProvider({
        ...result,
        locationId: provider.locationId
      });

      return model;
    } catch (error) {
      this.notification.error(error);
    }
    return;
  }

  async uploadPhoto(locationId: string, providerId: string, file: File) {
    try {
      return await this.gateway.uploadProviderPhoto(
        locationId,
        providerId,
        file
      );
    } catch (error) {
      this.notification.error(error);
    }

    return;
  }

  @permission([Permission.BhbWrite])
  async uploadLogo(file: File): Promise<string> {
    const result = await this.gateway.uploadLogo(
      this.root.core.locationId,
      file
    );
    return result;
  }
}
