import { ChangeLogDto } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";

export interface OutboundCommTemplateDto {
  id: string;
  outboundCommTypeCode: string;
  name: string;
  channelTemplates: OutboundCommTemplateChannelDto[];
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface OutboundCommTemplateChannelDto {
  outboundCommChannelCode: string;
  template: string;
  outboundCommTemplateId?: string;
}

export interface AddOutboundCommTemplateDto {
  outboundCommTypeCode: string;
  channelTemplates: OutboundCommTemplateChannelDto[];
  name: string;
}

export interface OutboundCommContentDto {
  outboundCommId: string;
  channelProperties: string;
}

export interface PatchOutboundCommTemplateDto
  extends Patch<Omit<OutboundCommTemplateDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface RenderOutboundCommTemplateDto {
  outboundCommTypeCode: string;
  template: string;
  templateParameters: string;
}

export interface OutboundCommTemplateReplyActionDto {
  id: string;
  outboundCommTypeReplyActionCode: string;
  responsePatterns: string[];
  outboundCommTemplateId: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddOutboundCommTemplateReplyActionDto {
  outboundCommTypeReplyActionCode: string;
  responsePatterns: string[];
  outboundCommTemplateId: string;
}

export interface ReplyActionsArgs {
  outboundCommChannelCode: string;
  replyActions: AddOutboundCommTemplateReplyActionDto[];
}

export interface TemplateRenderResponseDto {
  content: string;
}

export interface OutboundCommTemplateMasterDto {
  id: string;
  name: string;
  outboundCommTypeCode: string;
  template: string;
}

export interface CommPreferenceDto {
  id: string;
  preferenceData?: PreferenceDto;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddCommPreferenceDto {
  preferenceData: PreferenceDto;
}

export interface PatchCommPreferenceDto
  extends Patch<Omit<CommPreferenceDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface PreferenceDto {
  appointmentConfirmation?: PreferenceDataItemDto;
}

export interface PreferenceDataItemDto {
  isActive: boolean;
}

export enum OutboundCommStatuses {
  Pending = "PENDING",
  Failed = "FAILED",
  Sent = "SENT",
  Delivered = "DELIVERED",
  NotSent = "NOTSENT"
}

export enum OutboundCommChannel {
  Sms = "SMS",
  Email = "EMAIL"
}

export enum OutboundCommTypeReplyAction {
  Confirmed = "CONFIRMED",
  Declined = "DECLINED"
}

export enum OutboundCommType {
  ApptConfirmation = "APPTCONFIRM",
  ApptReminder = "APPTREM",
  ApptFree = "APPTFREE",
  FormNotify = "FORMNOTIFY",
  ApptReschedule = "APPTRESCHEDULE",
  ApptCancel = "APPTCANCEL",
  Invoice = "INVOICE",
  Receipt = "RECEIPT",
  Statement = "STATEMENT",
  ClinicalReminder = "CLINREM"
}

export interface OutboundCommChannelDto extends RefDataDto {}
export interface OutboundCommTypeDto extends RefDataDto {}

export enum CommsSectionText {
  Schedule = "Schedule",
  Templates = "Templates"
}

export enum CommsSectionViews {
  schedule = "schedule",
  templates = "templates"
}

export enum AppointmentReminderJobStatus {
  Scheduled = "SCHEDULED",
  Paused = "PAUSED",
  Expired = "EXPIRED"
}

export interface CommTypePreferencesDto {
  commTypeCode?: string;
  preferredCommChannelTypeCode?: string;
  relatedContactId?: string;
  preferredCommAddressValue?: string;
  contactHasOptedOut?: boolean;
}

export interface ContactPreferencesDto {
  id: string;
  commTypePreferences?: CommTypePreferencesDto[];
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddContactPreferencesDto
  extends Omit<ContactPreferencesDto, "eTag" | "changeLog"> {}

export interface PatchContactPreferencesDto
  extends Patch<Omit<ContactPreferencesDto, "changeLog">> {
  id: string;
  eTag: string;
}

export const OPTOUT = "OPTOUT";

export enum OutboundCommChannelMapping {
  EMAIL = "EMAIL",
  SMS = "MOBILE"
}

export interface OutboundCommDto {
  id: string;
  contactId: string;
  orgUnitId: string;
  outboundCommTypeCode: string;
  campaignId?: string;
  commParameters?: string;
  sourceId: string;
  sourceTypeCode: string;
  batchId?: string;
  notAfter?: string;
  outboundCommChannelCode?: string;
  outboundCommAddressValue?: string;
  statusCode: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface MessageCreditBalanceDto {
  units: number;
  changeLog?: ChangeLogDto;
}

export interface OutboundCommContentGetByArgs {
  outboundCommIds: string[];
}
