import { observer } from "mobx-react-lite";
import React from "react";

import {
  CheckboxVisibility,
  IColumn,
  NoDataTile,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode
} from "@bps/fluent-ui";
import { DateTime, useFormContext } from "@bps/utils";
import {
  CorrespondenceType,
  SubmitActionCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { getValueWithUnits } from "@libs/utils/utils.ts";
import { useTemplatePickerFormContext } from "@modules/clinical/screens/correspondence/components/context/TemplatePickerFormContext.tsx";
import { FavouriteStar } from "@modules/clinical/screens/correspondence/components/FavouriteStar.tsx";
import {
  TemplateListItem,
  TemplatePivotItemKeys
} from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { useTemplateManagerFormContext } from "@modules/clinical/screens/template-writer/context/TemplateManagerFormContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DetailsListField } from "@ui-components/form/DetailsListField.tsx";

import {
  TemplateFilter,
  TemplatePickerFormModel
} from "./context/TemplatePickerFormModel.ts";

type TemplateListProps = {
  isPickerFrom?: boolean;
  templateListItems?: TemplateListItem[];
};

export const TemplateScrollablePane: React.FC<TemplateListProps> = observer(
  ({ isPickerFrom, templateListItems }) => {
    const { core, userExperience, notification, correspondence } = useStores();

    const {
      state: { values: filter },
      actions: { reset }
    } = useFormContext<TemplateFilter>();

    const model = isPickerFrom
      ? useTemplatePickerFormContext()
      : useTemplateManagerFormContext();

    const onRenderFavourites = (item: TemplateListItem) => {
      const templateId = item.key;
      const isFavourited = model.favourites.includes(templateId);
      const onClick = async (isFilled: boolean) => {
        const updatedFavourites = isFilled
          ? model.favourites.filter(id => id !== templateId)
          : [...model.favourites, templateId];
        model.setFavourites(updatedFavourites);

        try {
          const userStorage = userExperience.userStorageMap.get(
            UserStorageKeys.FavouriteTemplates
          );
          if (!userStorage) {
            await userExperience.addUserStorage(
              UserStorageKeys.FavouriteTemplates,
              {
                key: UserStorageKeys.FavouriteTemplates,
                userId: core.userId,
                jsonData: updatedFavourites
              }
            );
          } else {
            await userExperience.updateUserStorage(
              UserStorageKeys.FavouriteTemplates,
              {
                key: userStorage.key,
                userId: userStorage.userId,
                jsonData: updatedFavourites,
                eTag: userStorage.eTag,
                id: userStorage.id
              }
            );
          }
        } catch (error) {
          notification.error(error.message);
        }
      };

      return <FavouriteStar filled={isFavourited} onClick={onClick} />;
    };

    const selectedKey = model.selectedKey;

    const favouriteItems = templateListItems?.filter(item =>
      model.favourites.includes(item.key)
    );

    const filterItemList = () => {
      switch (selectedKey) {
        case TemplatePivotItemKeys.All:
          return templateListItems;

        case TemplatePivotItemKeys.Favourites:
          return favouriteItems;
        case TemplatePivotItemKeys.Custom:
          return templateListItems?.filter(x => x.isCustom);

        case TemplatePivotItemKeys.Autofills:
          return templateListItems?.filter(
            x => x.documentType === CorrespondenceType.Autofill
          );

        default:
          return templateListItems?.filter(x => x.documentType === selectedKey);
      }
    };

    const filterItems = filterItemList();

    const items = TemplatePickerFormModel.getItemsByFilter(
      filterItems ?? [],
      filter,
      core.accountInfo?.username
    );

    const columns: IColumn[] = [
      {
        key: "favourite",
        name: "",
        minWidth: 20,
        maxWidth: 20,
        onRender: onRenderFavourites
      },
      {
        key: "details",
        name: "Details",
        fieldName: "details",
        minWidth: 100
      },
      {
        key: "documentStatus",
        name: "",
        fieldName: "details",
        minWidth: 100,
        onRender: (item: TemplateListItem) => {
          if (item.documentStatus === SubmitActionCode.DraftCode) {
            return "Draft";
          } else {
            return item.documentStatus ===
              SubmitActionCode.PublishToEveryoneCode
              ? "Shared"
              : "Private";
          }
        }
      },
      {
        key: "documentType",
        name: "Type",
        fieldName: "type",
        minWidth: 100,
        onRender: (item: TemplateListItem) => {
          if (item.documentType === CorrespondenceType.Autofill)
            return "Autofill";

          const name =
            correspondence.ref.correspondenceTypes.keyTextValues.find(
              x => x.key === item.documentType
            );
          return name?.text ?? "";
        }
      },
      {
        key: "updated",
        name: "Updated",
        minWidth: 100,
        onRender: (item: TemplateListItem) => {
          if (!item.updated) {
            return "N/A";
          }

          const days = Math.floor(
            DateTime.now().diff(item.updated, "days").days
          );
          return `${getValueWithUnits(days, "day")} ago`;
        }
      }
    ];

    const areMultipleFiltersSelected =
      (filter.documentStatus && filter.documentStatus.length > 0) ||
      (filter.documentTypes && filter.documentTypes.length > 0) ||
      (filter.documentAuthor && filter.documentAuthor.length > 0);

    const getNoDataTitle = () => {
      if (areMultipleFiltersSelected) {
        return "No matches for your filters";
      }

      if (filter.searchText) {
        return `No matches for "${filter.searchText}"`;
      }

      return "No items can be found for this tab.";
    };

    return (
      <div style={{ height: 344, position: "relative" }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          {model.selectedKey === TemplatePivotItemKeys.Favourites &&
          !model.favourites.length ? (
            <NoDataTile
              textProps={{
                text: "No favourite templates "
              }}
              linkProps={{
                hidden: true
              }}
              showBoxShadow={false}
              greyView={true}
              styles={{
                root: {
                  height: "100%"
                }
              }}
            />
          ) : (
            <>
              {items.length > 0 ? (
                <DetailsListField
                  name="templateId"
                  required
                  items={items ?? []}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  checkboxVisibility={CheckboxVisibility.always}
                  fullRowSelection
                  setKey="filtered-detail-list"
                />
              ) : (
                <NoDataTile
                  textProps={{
                    text: getNoDataTitle()
                  }}
                  linkProps={{
                    text: "Clear filters",
                    onClick: () => reset()
                  }}
                  showBoxShadow={false}
                  greyView={true}
                  styles={{
                    root: {
                      height: "100%"
                    }
                  }}
                />
              )}
            </>
          )}
        </ScrollablePane>
      </div>
    );
  }
);
