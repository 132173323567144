import { ReactNode } from "react";
import * as ReactDOM from "react-dom";

import {
  defaultTheme,
  IButtonProps,
  IDialogProps,
  scopedSettings,
  ThemeProvider
} from "@bps/fluent-ui";

import { ConfirmMultiDialog } from "./confirmMultiDialog.tsx";

export interface ConfirmMultiDialogProps
  extends Omit<
    IDialogProps,
    "onDismiss" | "onCancel" | "onConfirm" | "confirming"
  > {
  buttons?: Array<Omit<IButtonProps, "onClick"> & { onClick?: () => void }>;
  children?: ReactNode;
  focusOnPrimaryButton?: boolean;
}

export function confirmMulti(
  options: ConfirmMultiDialogProps
): Promise<string> {
  return new Promise(resolve => {
    const div = document.createElement("div");
    const { buttons = [], ...dialogProps } = options;

    const handleClick = (text: string) => {
      resolve(text);
      ReactDOM.unmountComponentAtNode(div);
    };

    // Attach onClick dynamically if not provided
    const formattedButtons = buttons.map(button => ({
      ...button,
      onClick: button.onClick || (() => handleClick(button.text || ""))
    }));

    ReactDOM.render(
      <ThemeProvider theme={{ ...defaultTheme, components: scopedSettings }}>
        <ConfirmMultiDialog
          {...(dialogProps as any)}
          buttons={formattedButtons}
          hidden={false}
          children={options.children}
        />
      </ThemeProvider>,
      div
    );
  });
}
