export enum ClinicalReminderTableLabels {
  Action = "Action",
  Patient = "Patient",
  Reminder = "Reminder",
  Due = "Due",
  Provider = "Provider",
  PatientDetails = "Patient details",
  NextAppt = "Next appt.",
  Created = "Created",
  CurrentStatus = "Current status",
  NotBooked = "Not booked"
}
