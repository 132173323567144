import React from "react";

import {
  DateRangeType,
  IColumn,
  RESET_CELLS_PADDING_CLASSNAME,
  Shimmer,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import {
  AppointmentStatusCode,
  CalendarEventAttendeeDto,
  CalendarEventStatus,
  CalendarEventType,
  GetCalendarEventsDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { DidNotArriveListFilter } from "./components/DidNotArriveDetailFilter.tsx";
import { DidNotArriveNoticeBy } from "./components/DidNotArriveNoticeBy.tsx";
import { DidNotArrivePatient } from "./components/DidNotArrivePatient.tsx";
import { DidNotArriveReason } from "./components/DidNotArriveReason.tsx";
import { DidNotArriveRescheduledLabel } from "./components/DidNotArriveRescheduledLabel.tsx";
import { DidNotArriveStatusBadge } from "./components/DidNotArriveStatusBadge.tsx";
import {
  DidNotArriveItem,
  DidNotArriveLabel
} from "./DidNotArriveListScreen.types.ts";

export const getDidNotArriveListItems = (
  calendarEvents: CalendarEvent[],
  statuses: string[],
  root: RootStore
): DidNotArriveItem[] => {
  const items: DidNotArriveItem[] = [];
  const includeCancelled = statuses.includes(CalendarEventStatus.Cancelled);
  const includeDna = statuses.includes(AppointmentStatusCode.DidNotAttend);
  const includeAll = !statuses.length;

  calendarEvents.forEach(calendarEvent => {
    if (calendarEvent.type === CalendarEventType.Appointment) {
      const getDidNotArriveItem = (
        attendee: CalendarEventAttendeeDto
      ): DidNotArriveItem => {
        const patient = root.practice.contactsMap.get(attendee.attendeeId);
        const location = root.core.getLocationName(calendarEvent.orgUnitId);

        return {
          patientId: attendee.attendeeId,
          patient,
          cancelled:
            attendee.status === CalendarEventStatus.Cancelled ||
            calendarEvent.status === CalendarEventStatus.Cancelled,
          cancellationReasonId:
            attendee.cancellationReasonId ?? calendarEvent.cancellationReasonId,
          cancellationDateTime: attendee.cancellationDateTime
            ? DateTime.fromISO(attendee.cancellationDateTime)
            : calendarEvent.cancellationDateTime,
          cancellationText:
            attendee.cancellationText ?? calendarEvent.cancellationText,
          provider: calendarEvent.user,
          calendarEvent,
          location
        };
      };

      if (calendarEvent.isGroupAppointment) {
        const dnaAttendees = [];
        if (includeDna) dnaAttendees.push(...calendarEvent.dnaAttendees);
        if (includeCancelled)
          dnaAttendees.push(...calendarEvent.cancelledAttendees);
        if (includeAll)
          dnaAttendees.push(
            ...[
              ...calendarEvent.dnaAttendees,
              ...calendarEvent.cancelledAttendees
            ]
          );

        items.push(...dnaAttendees.map(a => getDidNotArriveItem(a)));
      } else {
        if (
          (calendarEvent.appointmentStatus ===
            AppointmentStatusCode.DidNotAttend &&
            (includeAll || includeDna)) ||
          (calendarEvent.status === CalendarEventStatus.Cancelled &&
            (includeAll || includeCancelled))
        ) {
          items.push(
            ...calendarEvent.attendeesPatientAndContact.map(a =>
              getDidNotArriveItem(a)
            )
          );
        }
      }
    }
  });

  return items;
};

export const loadDidNotArriveListItems = async (
  filterSearch: DidNotArriveListFilter,
  root: RootStore
) => {
  const calendarEventsFilter: GetCalendarEventsDto = {
    startTime:
      filterSearch.dateRange === DateRangeType.Week.toString()
        ? DateTime.fromJSDate(filterSearch.apptDate).startOf("week").toISO()
        : DateTime.fromJSDate(filterSearch.apptDate).startOf("day").toISO(),
    endTime:
      filterSearch.dateRange === DateRangeType.Week.toString()
        ? DateTime.fromJSDate(filterSearch.apptDate)
            .startOf("week")
            .plus({ days: 7 })
            .toISO()
        : DateTime.fromJSDate(filterSearch.apptDate)
            .startOf("day")
            .plus({ days: 1 })
            .toISO(),
    statuses: [CalendarEventStatus.Cancelled, CalendarEventStatus.Confirmed],
    name: filterSearch.patientName,
    attendees: filterSearch.providers,
    orgUnitIds: filterSearch.locations
  };

  const events = await root.booking.getCalendarEvents(calendarEventsFilter, {
    loadCalendarEventExtensions: true,
    loadCalendarEventUsers: true,
    loadCalendarEventContacts: true
  });

  const results = events.results;
  const statuses = filterSearch.status ?? [];
  return getDidNotArriveListItems(results, statuses, root);
};

export const getDidNotArriveColumns = ({
  renderActions,
  core
}: {
  renderActions?: (item: DidNotArriveItem) => React.ReactNode;
  core: CoreStore;
}): IColumn[] => {
  const baseColumns: IColumn[] = [
    {
      key: DidNotArriveLabel.Date,
      minWidth: 79,
      maxWidth: 79,
      name: DidNotArriveLabel.Date,
      onRender: (item: DidNotArriveItem) => (
        <Text>{item.calendarEvent.startDateTime.toDayDefaultFormat()}</Text>
      )
    },
    {
      key: DidNotArriveLabel.Scheduled,
      minWidth: 74,
      maxWidth: 74,
      name: DidNotArriveLabel.Scheduled,
      styles: {
        root: { textAlign: "end" },
        cellTitle: { justifyContent: "flex-end" }
      },
      onRender: (item: DidNotArriveItem) => (
        <Stack styles={{ root: { textAlign: "end" } }}>
          <Text>
            {item.calendarEvent.startDateTime.toFormat(
              TIME_FORMATS.DEFAULT_TIME_FORMAT
            )}
          </Text>
        </Stack>
      )
    },
    {
      key: DidNotArriveLabel.Patient,
      minWidth: 160,
      maxWidth: 160,
      name: DidNotArriveLabel.Patient,
      onRender: (item: DidNotArriveItem) => <DidNotArrivePatient item={item} />
    },
    {
      key: DidNotArriveLabel.Status,
      minWidth: 121,
      maxWidth: 121,
      className: RESET_CELLS_PADDING_CLASSNAME,
      name: DidNotArriveLabel.Status,
      onRender: (item: DidNotArriveItem) => (
        <DidNotArriveStatusBadge item={item} />
      )
    },
    {
      key: DidNotArriveLabel.Rescheduled,
      minWidth: 125,
      maxWidth: 125,
      name: "",
      onRender: item => <DidNotArriveRescheduledLabel item={item} />
    },
    {
      key: DidNotArriveLabel.NoticeBy,
      minWidth: 94,
      maxWidth: 155,
      name: DidNotArriveLabel.NoticeBy,
      styles: {
        root: { textAlign: "end" },
        cellTitle: { justifyContent: "flex-start" }
      },
      onRender: (item: DidNotArriveItem) => <DidNotArriveNoticeBy item={item} />
    },
    {
      key: DidNotArriveLabel.Provider,
      minWidth: 160,
      maxWidth: 200,
      name: DidNotArriveLabel.Provider,
      onRender: (item: DidNotArriveItem) =>
        item.provider ? <Text>{item.provider.fullName}</Text> : <Shimmer />
    }
  ];

  // Add location column if multiple locations are active
  if (core.hasMultipleLocations) {
    baseColumns.push({
      key: DidNotArriveLabel.Location,
      minWidth: 160,
      maxWidth: 200,
      name: DidNotArriveLabel.Location,
      onRender: (item: DidNotArriveItem) =>
        item.location ? <Text>{item.location}</Text> : <Shimmer />
    });
  }

  // Add reason column
  baseColumns.push({
    key: DidNotArriveLabel.Reason,
    minWidth: 150,
    maxWidth: 583,
    name: DidNotArriveLabel.Reason,
    onRender: item => <DidNotArriveReason item={item} />
  });

  // Add actions column if permissions allow and renderActions is provided
  if (
    renderActions &&
    core.hasPermissions(
      [Permission.PaymentCreate, Permission.InvoiceCreate],
      "or"
    )
  ) {
    return [
      {
        key: DidNotArriveLabel.Actions,
        className: RESET_CELLS_PADDING_CLASSNAME,
        minWidth: 56,
        maxWidth: 56,
        name: DidNotArriveLabel.Actions,
        onRender: renderActions
      },
      ...baseColumns
    ];
  }

  return baseColumns;
};
