import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  FontSizes,
  Spinner,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface ClaimConsultBadgesProps {
  condition?: ICondition;
  clinicalRecord: ClinicalRecord;
  encounter?: Encounter;
  hideConsultOnNoClaim?: boolean;
  showConsultsRemaining?: boolean;
  showConsultIndex?: boolean;
  claimBadgeText: string;
}

export const ClaimConsultBadges: React.FC<ClaimConsultBadgesProps> = observer(
  props => {
    const { clinical, core } = useStores();

    const condition = props.condition;
    const claim = condition?.claim;
    const claimNumber = claim?.claimNumber;

    const hideConsultBadge = props.hideConsultOnNoClaim && !claimNumber;

    const appointmentVisits = claim?.appointmentVisits;

    const displayOnHoldClaimIcon =
      claim?.isOnHold && core.hasPermissions(Permission.ClaimRead);

    const getConsultsRemainingText = () => {
      if (appointmentVisits) {
        if (appointmentVisits.length > 1) return "Multiple provider types";
        if (appointmentVisits.length === 1)
          return `${appointmentVisits[0].totalRemaining} consults remain`;
      }
      return "No consults remain";
    };

    const getConsultNumber = async () => {
      if (!props.showConsultIndex || hideConsultBadge) {
        return;
      }

      if (
        !claim ||
        !appointmentVisits ||
        appointmentVisits.length === 0 ||
        !props.encounter
      ) {
        return;
      }

      if (props.clinicalRecord.patient?.id && props.encounter) {
        const eoc = props.encounter.episodeOfCareId;

        const encounters = await clinical.getEncounters({
          patientId: props.clinicalRecord.patient?.id,
          episodeOfCareIds: eoc ? [eoc] : undefined
        });

        // Reorganize encounters by their date time.
        const encountersOrdered = encounters.sort(
          (a, b) => a.startDateTime.toMillis() - b.startDateTime.toMillis()
        );

        const thisEncounter = encountersOrdered.find(
          x => x.id === props.encounter?.id
        );
        if (thisEncounter) {
          const encounterIndex = encountersOrdered.indexOf(props.encounter);

          return `Consult ${encounterIndex + 1}/${encountersOrdered.length}`;
        }
      }

      return undefined;
    };

    const OnHoldClaimBadge = ({
      claimBadgeText
    }: {
      claimBadgeText: string;
    }) => (
      <TooltipHost content="On hold">
        <TextBadge
          badgeColor={TextBadgeColor.lightGrey}
          badgeSize={TextBadgeSize.medium}
          horizontal
          horizontalAlign="center"
          tokens={{ childrenGap: 5 }}
          styles={{ root: { flex: 1, width: 150 } }}
        >
          <FontIcon
            iconName="Clock"
            styles={{
              root: {
                fontSize: FontSizes.size12
              }
            }}
          />
          <span>{claimBadgeText}</span>
        </TextBadge>
      </TooltipHost>
    );

    const RegularClaimBadge = ({
      claimBadgeText
    }: {
      claimBadgeText: string;
    }) => (
      <TextBadge
        badgeColor={TextBadgeColor.blue}
        badgeSize={TextBadgeSize.medium}
        styles={{ root: { flex: 1 } }}
      >
        <span>{claimBadgeText}</span>
      </TextBadge>
    );

    return (
      <Stack horizontal>
        <DataFetcher
          fetch={getConsultNumber}
          fallback={
            <Stack horizontalAlign="center">
              <Spinner />
            </Stack>
          }
        >
          {data => (
            <>
              {displayOnHoldClaimIcon ? (
                <OnHoldClaimBadge claimBadgeText={props.claimBadgeText} />
              ) : (
                <RegularClaimBadge claimBadgeText={props.claimBadgeText} />
              )}
              {!hideConsultBadge && (
                <TextBadge
                  badgeColor={TextBadgeColor.lightGrey}
                  badgeSize={TextBadgeSize.medium}
                  styles={{ root: { flex: 1 } }}
                >
                  {props.showConsultsRemaining && getConsultsRemainingText()}
                  {props.showConsultIndex && data}
                </TextBadge>
              )}
            </>
          )}
        </DataFetcher>
      </Stack>
    );
  }
);
