import { Heading, Stack } from "@bps/fluent-ui";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { DeleteClaimDialogProps } from "./ClaimDeleteConfirmDialog.tsx";

interface FormValues {
  makeTheConditionPrivate: boolean;
}

export const ClaimCannotDeleteDialog: React.FC<DeleteClaimDialogProps> = ({
  text,
  onDismiss,
  makePrivate,
  deletingClaim,
  isDemographic
}) => {
  const onSubmit = async () => {
    await makePrivate();
  };

  return (
    <SubmissionFormDialog<FormValues>
      dialogName="Make Claim Private"
      onSubmit={onSubmit}
      onSubmitSucceeded={onDismiss}
      buttonsProps={{
        submitButtonProps: {
          iconProps: undefined,
          text: "Change to private"
        }
      }}
      dialogProps={{
        minWidth: 480,
        maxWidth: 480,
        onDismiss,
        dialogContentProps: {
          showCloseButton: false,
          title: (
            <Heading variant="modal-heading">
              {isDemographic
                ? "Cannot delete claim"
                : `Cannot delete claim for ${deletingClaim?.patientFullName}`}
            </Heading>
          )
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack>{text}</Stack>
          <CheckboxField
            label="Change it to a private condition, and keep the appt. links"
            name={nameof<FormValues>("makeTheConditionPrivate")}
          />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};
