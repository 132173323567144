import {
  dataAttribute,
  DataAttributes,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime, isToday } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ConsultsRemainingBadges } from "@modules/clinical/screens/patient-record/components/claims/ConsultsRemainingBadges.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import {
  ConditionListItem,
  EpisodeOfCareBadgesLabels
} from "../condition-modal/Condition.types.ts";
import { convertClinicalSideToDiagnosisSide } from "./active-condition-list.utils.ts";
import { getSubheadingStyles } from "./ActiveConditionList.styles.ts";

type ActiveClaimListItemProps = {
  conditionListItem: ConditionListItem;
};

export const ActiveConditionListItem: React.FC<ActiveClaimListItemProps> = ({
  conditionListItem
}) => {
  const { palette } = useTheme();
  const { core } = useStores();

  const claimAppointmentVisits = conditionListItem.claim?.appointmentVisits;

  const initialDate = conditionListItem.claim?.dto.initialConsultDate
    ? `Initial ${DateTime.fromISO(
        conditionListItem.claim?.dto.initialConsultDate
      ).toDayDefaultFormat()}`
    : `Created ${
        isToday(conditionListItem.createdDate)
          ? "today"
          : conditionListItem.createdDate.toDayDefaultFormat()
      }`;

  const shouldShowBadges =
    !conditionListItem.isPrivate &&
    !conditionListItem.discharged &&
    conditionListItem.claim &&
    claimAppointmentVisits &&
    claimAppointmentVisits.length > 0;

  return (
    <Stack
      styles={{ root: { color: palette.neutralPrimary } }}
      {...dataAttribute(
        DataAttributes.Data,
        conditionListItem.claim
          ? conditionListItem.claim.id
          : conditionListItem.episodeOfCareId
      )}
    >
      <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign="center" wrap>
        <Text>
          {conditionListItem.primaryDiagnosis}
          {convertClinicalSideToDiagnosisSide(conditionListItem.diagnosisSide)}
        </Text>

        <When permission={Permission.ClaimRead}>
          {shouldShowBadges && (
            <ConsultsRemainingBadges
              initialConsultDate={initialDate}
              appointmentVisits={claimAppointmentVisits}
            />
          )}
        </When>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            marginTop: 4
          }
        }}
      >
        <Text styles={getSubheadingStyles}>{initialDate}</Text>
        {conditionListItem.injuryDate && (
          <>
            <Text styles={getSubheadingStyles}>|</Text>
            <Text styles={getSubheadingStyles}>
              {`Injury date ${conditionListItem.injuryDate?.toDayDefaultFormat()}`}
            </Text>
          </>
        )}
        {core.hasPermissions(Permission.ClaimRead) &&
          !conditionListItem.isPrivate &&
          conditionListItem.claim?.claimNumber && (
            <>
              <Text styles={getSubheadingStyles}>|</Text>
              <Text
                styles={getSubheadingStyles}
              >{`Claim number ${conditionListItem.claim.claimNumber}`}</Text>
            </>
          )}
        {core.hasPermissions(Permission.ClaimRead) &&
          conditionListItem.isPrivate && (
            <>
              <Text styles={getSubheadingStyles}>|</Text>
              <Text styles={getSubheadingStyles}>
                {EpisodeOfCareBadgesLabels.Private}
              </Text>
            </>
          )}
      </Stack>
    </Stack>
  );
};
