import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode, useCallback, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DetailsListLayoutMode,
  DtoDetailsRow,
  IColumn,
  IDetailsRowProps,
  NoDataTile,
  ScrollablePane
} from "@bps/fluent-ui";
import { useFormContext } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { CorrespondencesLabels } from "@modules/clinical/screens/patient-record/components/correspondence/Correspondences.types.ts";
import { InvestigationsFilter } from "@modules/clinical/screens/patient-record/components/medical-history/InvestigationFilter.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { InvestigationFilter } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

export enum InvestigationType {
  Request = "request",
  Report = "report"
}
export interface InvestigationListProps {
  onRenderContextMenu: (record: ClinicalDocument) => ReactNode;
  onRenderTitle: (record: ClinicalDocument) => ReactNode;
  correspondenceDirection: CorrespondenceDirection;
  investigationType: InvestigationType;
}

const InvestigationListBase: FunctionComponent<InvestigationListProps> =
  observer(
    ({
      onRenderContextMenu,
      onRenderTitle,
      correspondenceDirection,
      investigationType
    }) => {
      const {
        isViewOnly,
        onCreateRequestClick,
        onCreateReportClick,
        clinicalRecord,
        handleOtherConfidentialDocOnInvType
      } = useContext(InvestigationSidePanelContext);

      const { correspondence } = useStores();

      const {
        state: { values: filter }
      } = useFormContext<InvestigationFilter>();

      const getItems = useCallback(
        async (query: PagingOptions) => {
          const investigations = await correspondence.fetchInvestigations({
            ...query,
            ...filter,
            patientId: clinicalRecord.id,
            direction: correspondenceDirection
          });

          investigations.results = handleOtherConfidentialDocOnInvType(
            investigations.results,
            investigationType,
            filter
          );

          return investigations;
        },
        [
          correspondence,
          filter,
          clinicalRecord.id,
          correspondenceDirection,
          investigationType,
          handleOtherConfidentialDocOnInvType
        ]
      );

      const columns: IColumn[] = [];
      if (!isViewOnly)
        columns.push({
          fieldName: "contextMenu",
          key: "contextMenu",
          minWidth: 36,
          maxWidth: 36,
          name: "",
          onRender: (record: ClinicalDocument) => onRenderContextMenu(record),
          isResizable: false
        });
      columns.push({
        fieldName: "title",
        key: "title",
        minWidth: 150,
        name: "",
        isResizable: false,
        onRender: (record: ClinicalDocument) => onRenderTitle(record)
      });

      const handleNoResults = () => {
        if (investigationType === InvestigationType.Request) {
          return (
            <NoDataTile
              textProps={{
                text: "No requests have been made"
              }}
              linkProps={{
                text: "Create a request",
                onClick: onCreateRequestClick,
                hidden: isViewOnly
              }}
              showBoxShadow={false}
            />
          );
        }

        return (
          <NoDataTile
            {...dataAttribute(
              DataAttributes.Element,
              "investigations-reports-not-found"
            )}
            textProps={{
              text: "No reports received for this patient"
            }}
            linkProps={{
              text: "Create a report",
              onClick: onCreateReportClick,
              hidden: isViewOnly
            }}
            showBoxShadow={false}
          />
        );
      };

      return (
        <div style={{ position: "relative", flexGrow: 1 }}>
          <ScrollablePane
            styles={{
              root: {
                height: "100%"
              }
            }}
          >
            <InfiniteScrollList
              refreshKey={correspondence.investigationListRefreshKey}
              getItems={getItems}
              columns={columns}
              onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={false}
              checkButtonAriaLabel={CorrespondencesLabels.RowCheckbox}
              errorMessage={
                clinicalRecord.nextCorrespondencePromise.error?.message
              }
              onRenderNoResults={handleNoResults}
            />
          </ScrollablePane>
        </div>
      );
    }
  );

export const InvestigationList: FunctionComponent<InvestigationListProps> = ({
  ...props
}) => {
  return (
    <InvestigationsFilter investigationType={props.investigationType}>
      {() => {
        return <InvestigationListBase {...props} />;
      }}
    </InvestigationsFilter>
  );
};
