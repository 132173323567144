import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

enum ReminderButtonLabels {
  Add = "Add a reminder",
  Send = "Send",
  Delete = "Delete"
}

export interface RemindersListButtonsProps {
  isFollowUpScreen?: boolean;
}

export const RemindersListButtons: React.FC<RemindersListButtonsProps> =
  observer(({ isFollowUpScreen }) => {
    const {
      setActivityDialogVisible,
      activityDialogVisible,
      selectedClinicalActivities,
      sendSelectedReminders
    } = useContext(InboxScreenContext);

    const { clinical } = useStores();

    return (
      <>
        {!isFollowUpScreen && (
          <When
            permission={[
              Permission.ClinActivityWrite,
              Permission.PatientReminderWrite
            ]}
          >
            <Stack.Item>
              <DefaultButton
                disabled={clinical.ui.clinicalActivityActionDisabled}
                onClick={() =>
                  setActivityDialogVisible({
                    ...activityDialogVisible,
                    add: true
                  })
                }
                text={ReminderButtonLabels.Add}
                iconProps={{ iconName: "Add" }}
              />
            </Stack.Item>
          </When>
        )}

        <When permission={[Permission.ReminderAdminWrite]}>
          <DefaultButton
            disabled={selectedClinicalActivities.length === 0}
            onClick={sendSelectedReminders}
            text={ReminderButtonLabels.Send}
            iconProps={{ iconName: "Send" }}
          />
        </When>

        {!isFollowUpScreen && (
          <When permission={[Permission.PatientReminderDelete]}>
            <Stack.Item>
              <DefaultButton
                disabled={
                  clinical.ui.clinicalActivityActionDisabled ||
                  selectedClinicalActivities.length === 0
                }
                onClick={() =>
                  setActivityDialogVisible({
                    ...activityDialogVisible,
                    delete: true
                  })
                }
                text={ReminderButtonLabels.Delete}
              />
            </Stack.Item>
          </When>
        )}
      </>
    );
  });
