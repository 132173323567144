import { GroupByDateObservationsView } from "./GroupByDateObservationsView.tsx";
import { GroupByExaminationObservationsView } from "./GroupByExaminationObservationsView.tsx";
import { GroupedObservation, ObservationGroup } from "./utils.ts";

interface GroupByObservationsViewProps {
  pivotKey: string;
  groupedObservations: Record<string, GroupedObservation[]>;
}

export const ObservationsGroupedView: React.FC<
  GroupByObservationsViewProps
> = ({ groupedObservations, pivotKey }) => {
  return (
    <>
      {Object.keys(groupedObservations).map(primaryKey => {
        const observations = groupedObservations[primaryKey];

        const ObservationComponent =
          pivotKey === ObservationGroup.GroupByDateAndTypeKey
            ? GroupByDateObservationsView
            : GroupByExaminationObservationsView;

        return (
          <ObservationComponent
            key={primaryKey}
            primaryKey={primaryKey}
            observations={observations}
          />
        );
      })}
    </>
  );
};
