export const splitNameFromSummary = (summary?: string) => {
  if (!summary) {
    return undefined;
  }

  const splitString = summary.split(" = ");

  return {
    heading: splitString[0],
    observation: splitString[1]
  };
};
