import { FontIcon, mergeStyles, Stack } from "@bps/fluent-ui";
import { DocumentTabStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { OverFlowDropDownOptions } from "./PatientPillOverFlowButton.tsx";

interface PatientPillOverflowButtonTemplateProps {
  item: OverFlowDropDownOptions;
  onTemplateClick?: (documentId: string) => void;
}

export const PatientPillOverflowButtonTemplate: React.FunctionComponent<
  PatientPillOverflowButtonTemplateProps
> = ({ item, onTemplateClick }) => {
  const { correspondence } = useStores();

  const handleTemplateClick = (temp: Template) => {
    if (!onTemplateClick || !temp) {
      return;
    }
    onTemplateClick(temp.id);
  };

  const getTemplateText = (temp: Template) => {
    const docType = correspondence.ref.correspondenceTypes.get(
      temp.documentType
    );
    return `${docType?.altText} - Template`;
  };

  return (
    <DataFetcher<Template | undefined>
      fetch={async ({ document }) => {
        let key: string = item.key;
        if (
          item.documentTabStatus === DocumentTabStatus.New &&
          !document.templateMap.get(item.key)
        ) {
          // If template is new and not in template map the screen has been refreshed and we cannot show the template so remove from the openDocumentTabs array
          await document.closeTemplate(item.key);
          return undefined;
        }
        if (
          item.documentTabStatus === DocumentTabStatus.Edit &&
          item.newDocumentId
        ) {
          document.updateTemplateTab(item.key, item.newDocumentId, false);
          key = item.newDocumentId;
        }

        return document.getTemplateByDocumentId(key).catch(catchNotFoundError);
      }}
    >
      {(temp: Template | undefined) => {
        if (temp) {
          return (
            <Stack horizontal onClick={() => handleTemplateClick(temp)}>
              <FontIcon iconName="TextDocument" />
              <span
                className={mergeStyles({
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  paddingLeft: 8,
                  paddingRight: 8
                })}
              >
                {getTemplateText(temp)}
              </span>
            </Stack>
          );
        }
        return undefined;
      }}
    </DataFetcher>
  );
};
