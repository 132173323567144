import { observer } from "mobx-react-lite";

import { Modal } from "@bps/fluent-ui";
import { DeleteCheckDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimDeleteRequest } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimCannotDeleteDialog } from "./ClaimCannotDeleteDialog.tsx";
import { ClaimDeleteAppointmentDialog } from "./ClaimDeleteAppointmentDialog.tsx";
import { ClaimDeleteDialog } from "./ClaimDeleteDialog.tsx";

interface ClaimDeleteConfirmDialogBaseProps {
  deleteCheck: DeleteCheckDto;
  deletingClaim: Claim | undefined;
  invoices: Invoice[];
  setDeletingClaim: (claim: Claim | undefined) => void;
  isDemographic?: boolean;
}

interface ClaimDeleteConfirmDialogProps {
  deletingClaim: Claim | undefined;
  setDeletingClaim: (claim: Claim | undefined) => void;
  isDemographic?: boolean;
}

export interface DeleteClaimDialogProps {
  text?: string;
  onDismiss: () => void;
  makePrivate: () => Promise<void>;
  deletingClaim: Claim | undefined;
  isDemographic?: boolean;
}

const ClaimDeleteConfirmDialogBase: React.FC<ClaimDeleteConfirmDialogBaseProps> =
  observer(
    ({
      deleteCheck,
      invoices,
      deletingClaim,
      setDeletingClaim,
      isDemographic
    }) => {
      const { acc } = useStores();

      if (!deletingClaim) {
        return null;
      }

      const deleteClaim = async () => {
        const claimId = deletingClaim.id;

        //Check claimEpisodesOfCare
        const deleteClaimEocs = async () => {
          const claimEpisodesOfCare = await acc.getClaimEpisodesOfCare({
            claimId
          });

          const claimEOC = claimEpisodesOfCare[0];
          await acc.updateClaimEpisodeOfCare({
            ...claimEOC,
            isActive: false
          });
        };

        // Unlink claimAppt before delete claim
        const deleteCliamAppts = async () => {
          const claimAppts = await acc.getClaimAppointmentDtos({
            claimIds: [claimId]
          });

          await Promise.all(
            claimAppts.map(async claimAppt => {
              acc.deleteClaimAppointmentDto(claimAppt.id);
            })
          );
        };

        //Check linked encounters
        const deleteClaimEncounter = async () => {
          const claimEncounters = await acc.getClaimEncounters(
            claimId,
            deletingClaim.patientId ?? ""
          );
          await Promise.all(
            claimEncounters.map(claimEncounter =>
              acc.deleteClaimEncounter(deletingClaim.id, claimEncounter?.id)
            )
          );
        };

        await Promise.all([
          deleteClaimEocs(),
          deleteCliamAppts(),
          deleteClaimEncounter()
        ]);

        await acc.deleteBulkClaims([deletingClaim.id]);
        setDeletingClaim(undefined);
      };

      const { claimDeleteCheckResponseText, claimDeleteRequestResponseCode } =
        deleteCheck;

      const claimDeleteCheckCode =
        isDemographic && invoices.length > 0
          ? ClaimDeleteRequest.noBecauseInvoice
          : claimDeleteRequestResponseCode;

      const responseText = (checkCode: string) => {
        switch (checkCode) {
          case ClaimDeleteRequest.noBecauseEncounterInProgress:
            return `There is a consult in progress with ${deletingClaim.patientFullName}, and the claim cannot be deleted`;
          case ClaimDeleteRequest.noBecauseInvoice:
            return "There are invoiced consults linked to this claim, and the claim cannot be deleted";
          default:
            return undefined;
        }
      };

      const deleteCheckResponseText =
        isDemographic && responseText(claimDeleteCheckCode)
          ? responseText(claimDeleteCheckCode)
          : claimDeleteCheckResponseText;

      const onDismiss = () => {
        setDeletingClaim(undefined);
      };

      const makePrivate = async () => {
        const claimEpisodesOfCare = await acc.getClaimEpisodesOfCare({
          claimId: deletingClaim.id
        });
        await Promise.all([
          acc.transitionClaimEpisodeOfCareToPrivate(
            deletingClaim.id,
            claimEpisodesOfCare[0].episodeOfCareId
          ),
          acc.patchClaim({
            id: deletingClaim.id,
            private: true
          })
        ]);
      };

      switch (claimDeleteCheckCode) {
        case ClaimDeleteRequest.noBecauseAppointment:
          return (
            <ClaimDeleteAppointmentDialog
              onDismiss={onDismiss}
              makePrivate={makePrivate}
              text={deleteCheckResponseText}
              deleteClaim={deleteClaim}
              deletingClaim={deletingClaim}
            />
          );
        case ClaimDeleteRequest.noBecauseEncounterInProgress:
        case ClaimDeleteRequest.noBecauseEncounterInFinalised:
          return (
            <ClaimCannotDeleteDialog
              onDismiss={onDismiss}
              makePrivate={makePrivate}
              text={deleteCheckResponseText}
              deletingClaim={deletingClaim}
            />
          );

        case ClaimDeleteRequest.noBecauseInvoice:
          return (
            <ClaimCannotDeleteDialog
              onDismiss={onDismiss}
              makePrivate={makePrivate}
              text={deleteCheckResponseText}
              deletingClaim={deletingClaim}
            />
          );
        default:
          return (
            <ClaimDeleteDialog
              onDismiss={onDismiss}
              text={deleteCheckResponseText}
              deleteClaim={deleteClaim}
            />
          );
      }
    }
  );

export const ClaimDeleteConfirmDialog: React.FC<
  ClaimDeleteConfirmDialogProps
> = ({ ...props }) => {
  return (
    <DataFetcher
      fetch={async ({ acc, billing }) => {
        const [invoices, deleteCheckDto] = await Promise.all([
          billing.fetchInvoicesNew({
            claimId: props.deletingClaim?.id
          }),
          acc.deleteCheck(props.deletingClaim?.id ?? "")
        ]);

        return { invoices, deleteCheckDto };
      }}
      fallback={
        <Modal
          styles={{
            main: {
              minWidth: 450,
              minHeight: 120,
              display: "grid",
              alignItems: "center",
              justifyContent: "center"
            }
          }}
          isOpen={true}
        >
          Checking claim can be deleted...
        </Modal>
      }
    >
      {({ invoices, deleteCheckDto }) => (
        <ClaimDeleteConfirmDialogBase
          invoices={invoices.results}
          deleteCheck={deleteCheckDto}
          {...props}
        />
      )}
    </DataFetcher>
  );
};
