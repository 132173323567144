import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";

export class StatementBillToValidator extends Validator<StatementGenerateFormValues> {
  constructor() {
    super();

    this.forField("contactType", required());
    this.forField("startDate", required());
    this.forField("endDate", required());
  }
}
