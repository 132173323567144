import { observer } from "mobx-react-lite";
import { useField, useForm } from "react-final-form";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  FontIcon,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { IconSelectField } from "@ui-components/form/IconSelectField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { AppointmentTypeFormValues } from "./AppointmentTypeForm.types.ts";
import { appointmentTypeIcons } from "./appointmentTypeIcons.tsx";
import { ServicePickerField } from "./ServicePicker/ServicePickerField.tsx";

interface AppointmentTypeFormFieldsProps {
  isSystemManaged?: boolean;
}

const nameOf = nameOfFactory<AppointmentTypeFormValues>();

export const AppointmentTypeFormFields: React.FC<AppointmentTypeFormFieldsProps> =
  observer(({ isSystemManaged }) => {
    const form = useForm();
    const { userExperience, core } = useStores();
    const { apptTypeBaseInterval } =
      userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

    const DEFAULT_STEP = "5";
    let min = 1;
    let step = parseInt(DEFAULT_STEP);
    if (core.hasPermissions(Permission.ApptTypeBaseIntervalAllowed)) {
      min = parseInt(apptTypeBaseInterval || "1");
      step = parseInt(apptTypeBaseInterval || DEFAULT_STEP);
    }

    const { input: isInactiveInput } = useField(nameOf("isInactive"), {
      subscription: { value: true }
    });

    const { fields: defaultServiceIds } = useFieldArray(
      nameOf("defaultServiceIds")
    );

    const disabled = isInactiveInput.value || isSystemManaged;
    const theme = useTheme();

    return (
      <Fieldset verticalFill>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            autoFocus
            required
            label="Type"
            name={nameOf("name")}
            disabled={disabled}
          />
          <IconSelectField
            name={nameOf("icon")}
            label="Icon"
            placeholder="No icon"
            options={appointmentTypeIcons}
            disabled={isInactiveInput.value}
            styles={{
              root: { width: 110 },
              fieldContent: { justifyContent: "center" }
            }}
            onRenderFieldContent={options => {
              const [firstOption] = options;
              return <FontIcon iconName={firstOption.key} />;
            }}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 24 }}>
          <SpinNumberInputField
            fieldItemStyles={{
              suffix: { alignSelf: "center" },
              root: { minWidth: 0 },
              item: { minWidth: 0 }
            }}
            required={true}
            name={nameOf("duration")}
            min={min}
            max={1439}
            step={step}
            label="Duration"
            disabled={disabled}
            suffix="minutes"
          />
          <When permission={Permission.GroupAppointmentsAllowed}>
            <SpinNumberInputField
              required={true}
              name={nameOf("maxParticipants")}
              min={1}
              max={1000}
              step={1}
              label="Max participants"
              disabled={disabled}
              suffix="patient(s)"
              fieldItemStyles={{
                suffix: { alignSelf: "center" },
                root: { minWidth: 0 },
                item: { minWidth: 0 }
              }}
            />
          </When>
          <BoolButtonGroupField
            label="Status"
            options={[
              { key: false, text: "Active" },
              { key: true, text: "Inactive" }
            ]}
            name={nameOf("isInactive")}
          />
        </Stack>
        <ToggleField
          name={nameOf("hasDefaultServices")}
          label="Default fees to invoice"
          styles={{ root: { marginBottom: 0 } }}
          onText="Yes"
          offText="No"
        />
        <FieldCondition when={nameOf("hasDefaultServices")} is={true}>
          <Stack styles={{ root: { maxHeight: 300, overflow: "auto" } }}>
            {defaultServiceIds.map((name, index) => {
              return (
                <Stack
                  key={name}
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                  styles={{
                    root: {
                      border: `1px solid ${theme.palette.neutralLight}`,
                      padding: "2px 6px"
                    }
                  }}
                >
                  <ServicePickerField
                    name={name}
                    placeholder="Search for a fee by name or item #"
                    disabled={isInactiveInput.value}
                    fieldItemStyles={{ root: { width: 456 } }}
                  />
                  <DeleteButton
                    {...dataAttribute(
                      DataAttributes.Element,
                      "delete-service-id-button"
                    )}
                    onClick={() => defaultServiceIds.remove(index)}
                  />
                </Stack>
              );
            })}
          </Stack>
          <ActionButton
            {...dataAttribute(DataAttributes.Element, "add-service-id-button")}
            text="Add another"
            iconProps={{ iconName: "Add" }}
            onClick={() => defaultServiceIds.push(undefined)}
            styles={{ root: { alignSelf: "flex-start" } }}
          />
        </FieldCondition>
        <FieldSpy
          name={nameOf("hasDefaultServices")}
          onChange={value => {
            if (!value) {
              form.change(nameOf("defaultServiceIds"), []);
            } else {
              form.change(nameOf("defaultServiceIds"), [undefined]);
            }
          }}
        />
        <FieldSpy
          name={nameOf("defaultServiceIds")}
          onChange={array => {
            if (array.length === 0) defaultServiceIds.push(undefined);
          }}
        />
      </Fieldset>
    );
  });
