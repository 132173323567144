import { action, observable } from "mobx";

import { InvoiceDocumentPreviewData } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";

import { Statement } from "./models/Statement.ts";

interface StatementDocumentPreviewData {
  statementId?: string;
  contactId?: string;
  documentId?: string;
  statement?: Statement;
}

export class BillingUi {
  @observable currentInvoiceDetails: { contactId?: string } | undefined;
  @observable lastUpdatedInvoiceETag?: string;
  @observable lastAddedTransactionEtag?: string;
  @observable lastUpdatedStatementETag?: string;
  @observable isStatementModalVisible?: boolean;
  @observable statementDocumentPreviewData?:
    | StatementDocumentPreviewData
    | undefined;
  @observable lastDraftInvoiceItemChange?: string;

  @observable previewDocumentData?: InvoiceDocumentPreviewData | undefined;

  @observable statementSecondColumn?:
    | SecondColumnContent.statementRun
    | undefined;

  @action setStatementSecondColumn = (
    secondColumn?: SecondColumnContent.statementRun
  ) => {
    this.statementSecondColumn = secondColumn;
  };

  @action
  setPreviewDocumentData = (data: InvoiceDocumentPreviewData | undefined) => {
    this.previewDocumentData = data;
  };

  @action
  showInvoiceDetailsDialog = (contactId?: string) => {
    this.currentInvoiceDetails = { contactId };
  };

  @action
  hideInvoiceDetailsDialog = () => {
    this.currentInvoiceDetails = undefined;
  };

  @action
  onToggleStatementModal = (showStatementModal: boolean) => {
    this.isStatementModalVisible = showStatementModal;
  };

  @action
  setStatementDocumentPreviewData = (
    data: StatementDocumentPreviewData | undefined
  ) => {
    this.statementDocumentPreviewData = data;
  };

  @observable isStatementEmailDialogVisible: boolean = false;

  @action setStatementEmailDialogVisible = (value: boolean) => {
    this.isStatementEmailDialogVisible = value;
  };
}
