import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ActionButton,
  Card,
  confirm,
  Image,
  Spinner,
  Stack,
  Text,
  Toggle
} from "@bps/fluent-ui";
import {
  OrgUnitCompanyDataType,
  OrgUnitEnabledPhysitrackDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { pluralizeString } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import physitrackLogo from "../assets/physitrack_logo.svg";
import { useIntegrationScreenContext } from "../context/IntegrationScreenContext.ts";

const getPhysitrackLogo = () => {
  return (
    <Image
      width="154.54"
      height="27"
      src={physitrackLogo}
      alt="Physitrack logo"
    />
  );
};

export const PhysitrackCard: React.FC = observer(() => {
  const { practice, core, notification } = useStores();
  const [isProviderListLoading, setIsProviderListLoading] = useState(false);
  const { setShowPhysitrackProviderList, showPhysitrackProviderList } =
    useIntegrationScreenContext();

  const patientLabel = usePatientLabel(false);

  const patientLabelPlural = pluralizeString(patientLabel);

  const getOrgUnit = async () => {
    const orgUnit = await practice.getOrgUnit(core.location?.id);
    const isEnabled = orgUnit?.orgUnitEnabledPhysitrack?.enabled;
    setShowPhysitrackProviderList(!!isEnabled);
  };

  const onChangeHandle = async (checked: boolean | undefined) => {
    if (checked) {
      try {
        setIsProviderListLoading(true);
        await practice.updateOrgUnit({
          id: core.location?.id,
          orgUnitCompanyData: [
            {
              orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.Physitrack,
              enabled: checked
            } as OrgUnitEnabledPhysitrackDto
          ]
        });
        setShowPhysitrackProviderList(!!checked);
      } catch (error) {
        notification.error(error.message);
      } finally {
        setIsProviderListLoading(false);
      }
    } else {
      const isConfirmed = await confirm({
        children: (
          <>
            <Text
              block
              variant="medium"
              styles={{ root: { paddingBottom: 16 } }}
            >
              Do you want to disable the Physitrack integration?
            </Text>

            <Text block variant="medium">
              Any configured providers will be disabled as part of this process.
            </Text>
          </>
        ),
        minWidth: 560,
        dialogContentProps: {
          title: "Disabling Integration"
        },
        confirmButtonProps: {
          text: "Disable"
        },
        cancelButtonProps: {
          text: "Cancel"
        }
      });
      if (isConfirmed) {
        try {
          await practice.updateOrgUnit({
            id: core.location?.id,
            orgUnitCompanyData: [
              {
                orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.Physitrack,
                enabled: checked
              } as OrgUnitEnabledPhysitrackDto
            ]
          });
          setShowPhysitrackProviderList(!!checked);
        } catch (error) {
          notification.error(error.message);
        }
      }
    }
  };

  return (
    <Card
      heading={
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: { width: "100%" }
          }}
        >
          {getPhysitrackLogo()}
          <Stack horizontal>
            <ActionButton
              href="https://kb.bpsoftware.net/bpomni/Integrations/PhysitrackConfig.htm"
              target="_blank"
              iconProps={{ iconName: "OpenInNewTab" }}
            >
              Omni knowledge base
            </ActionButton>
          </Stack>
        </Stack>
      }
      styles={{
        heading: { width: "100%" },
        subComponentStyles: {
          tile: {
            content: { padding: 32 }
          }
        }
      }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 16 }}
      >
        <Stack grow verticalAlign="start" tokens={{ childrenGap: 4 }}>
          <Text variant="medium">{`Open ${patientLabelPlural}
           and log into Physitrack automatically. Automatically import programs assigned in Physitrack to the ${patientLabel} correspondence in Omni`}</Text>
        </Stack>
        {!isProviderListLoading ? (
          <DataFetcher
            fetch={getOrgUnit}
            renderError={error => notification.error(error.message)}
            refetchId={practice.ui.recentlyUpdatedOrgUnitEtag}
            fallback={<Spinner />}
          >
            {() => (
              <Toggle
                onText="Enabled"
                offText="Disabled"
                automationAttribute="enable-activated-providers"
                checked={showPhysitrackProviderList}
                onChange={(_evt, checked) => onChangeHandle(checked)}
              />
            )}
          </DataFetcher>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Card>
  );
});
