import {
  ClinicalDataType,
  CodedFieldClinicalDataItemDto,
  ObservationType,
  QuestionnaireDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";

import { GRCSFormValues } from "./GRCSForm.types.ts";

export class GRCSFormModel {
  private clinicalRecord: ClinicalRecord;
  private clinical: ClinicalStore;
  private onObservationSaved: (observation: Observation) => void;

  constructor(
    clinicalRecord: ClinicalRecord,
    clinicalStore: ClinicalStore,
    onObservationSaved: (observation: Observation) => void
  ) {
    this.clinicalRecord = clinicalRecord;
    this.clinical = clinicalStore;
    this.onObservationSaved = onObservationSaved;
  }

  get initialValues() {
    const savedValues = this.getSavedValues();
    if (!savedValues.diagnosisKey && !savedValues.side) {
      const primaryDiagnosis = this.clinicalRecord.episodeOfCare?.diagnoses
        ? this.clinicalRecord.episodeOfCare?.diagnoses.find(
            x => x.isPrimaryDiagnosis
          )
        : this.clinicalRecord.clinicalData?.diagnoses?.diagnoses?.find(
            x => x.isPrimaryDiagnosis
          );

      const hasDiagnosisKey =
        !!primaryDiagnosis?.diagnosisCode?.code &&
        !!primaryDiagnosis?.diagnosisCode?.originalText;

      return {
        diagnosisKey: hasDiagnosisKey
          ? `${primaryDiagnosis?.diagnosisCode?.code}${primaryDiagnosis?.diagnosisCode?.originalText}`
          : undefined,
        side: primaryDiagnosis?.diagnosisSide,
        ...savedValues
      };
    } else {
      return savedValues;
    }
  }

  get stashedGprsClinicalData() {
    return this.clinicalRecord.stashedClinicalData?.grcs;
  }

  get gprsClinicalData() {
    return this.clinicalRecord.clinicalData?.grcs;
  }

  public getStashedClinicalData =
    (questionnaire: QuestionnaireDto) =>
    (values: GRCSFormValues): Pick<EncounterClinicalData, "grcs"> => {
      let diagnosis: CodedFieldClinicalDataItemDto | undefined;

      const previousDiagnosis = this.gprsClinicalData?.diagnosis;

      const secGroupId = !!values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined;
      if (
        previousDiagnosis &&
        values.diagnosisKey?.startsWith(previousDiagnosis?.code)
      )
        diagnosis = previousDiagnosis;
      else if (values && values.diagnosisKey) {
        const item = this.clinical.getTerminologyFromMap(values.diagnosisKey);
        if (item)
          diagnosis = {
            code: item?.code,
            originalText: item?.text,
            codeSystem: item?.codeSystem,
            version: item?.version
          };
      }

      if (this.gprsClinicalData) {
        return {
          grcs: {
            questionnaireId: questionnaire.id,
            questionnaireCode: questionnaire.code,
            items: questionnaire.items.map(i => ({
              id: this.gprsClinicalData
                ? this.findSavedQuestionGuid(this.gprsClinicalData, i.id)
                : undefined,
              answer: values.answer,
              questionnaireItemId: i.id
            })),
            diagnosis,
            side: values?.side,
            secGroupId
          }
        };
      } else {
        return {
          grcs: {
            items: questionnaire.items.map(() => ({
              answer: values.answer
            })),
            diagnosis,
            side: values?.side,
            secGroupId
          }
        } as Pick<EncounterClinicalData, "grcs">;
      }
    };

  onSubmit = async (
    values: GRCSFormValues,
    questionnaire: QuestionnaireDto
  ) => {
    const items = this.stashedGprsClinicalData?.items ?? [];
    const payload: Partial<EncounterClinicalData> = {
      grcs: {
        ...this.stashedGprsClinicalData,
        eTag: this.gprsClinicalData?.eTag,
        questionnaireId: questionnaire.id,
        questionnaireCode: questionnaire.code,
        items: items.map((i, idx) => ({
          ...i,
          id: i.id,
          questionnaireItemId: questionnaire.items[idx].id,
          answer: values.answer ?? 0
        }))
      }
    };
    await this.clinicalRecord.saveClinicalData(payload);
    const openedEncId = this.clinicalRecord.openEncounter?.id;
    const result = await this.clinical.getObservations({
      patientId: this.clinicalRecord.patient?.id,
      encounterIds: openedEncId ? [openedEncId] : undefined,
      types: [ObservationType.GRCS]
    });

    const observations = result.results;

    if (observations && observations.length === 1) {
      this.clinicalRecord.updateObservations(observations);
      this.onObservationSaved(observations[0]);
    } else {
      this.clinical.root.notification.error("Unable to retrieve GRCS rating.");
    }
  };

  onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.GRCS
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["grcs"]);
  };

  private findSavedQuestionGuid = (
    savedResponses: QuestionnaireResponseDto | undefined,
    questionnaireItemId: number
  ) => {
    if (!!savedResponses) {
      const item = savedResponses.items.find(
        i => i.questionnaireItemId === questionnaireItemId
      );
      return item?.id ?? "";
    }
    return "";
  };

  private getSavedValues = (): GRCSFormValues => {
    let savedValues: GRCSFormValues = { answer: "0" }; //default to zero - no change
    if (this.stashedGprsClinicalData) {
      savedValues = {
        answer: this.stashedGprsClinicalData.items[0].answer,
        side: this.stashedGprsClinicalData.side,
        diagnosisKey:
          this.stashedGprsClinicalData.diagnosis &&
          this.clinical.getTerminologyKey(
            this.stashedGprsClinicalData.diagnosis?.code,
            this.stashedGprsClinicalData.diagnosis?.originalText
          ),
        confidential: !!this.stashedGprsClinicalData.secGroupId
      };
    }

    return savedValues;
  };
}
