import { observer } from "mobx-react-lite";
import { FC, Fragment, useEffect } from "react";

import {
  Heading,
  mergeStyles,
  mergeStyleSets,
  PersonaSize,
  Spinner,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { ContactCategoryText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { CompoundButtonWithHoveredIcon } from "../../shared-components/view/CompoundButtonWithHoveredIcon.tsx";

interface PatientFieldProps {
  patient: Contact;
}

export const PatientProvidersExternal: FC<PatientFieldProps> = observer(
  ({ patient }) => {
    const { practice } = useStores();
    const {
      ui: { showContactDetails }
    } = practice;
    useEffect(() => {
      const contactIds = patient.externalProviders.map(x => x.contactId);
      if (contactIds.length) {
        practice.getContactsById(contactIds);
      }
    }, [patient.externalProviders, practice]);

    const theme = useTheme();
    const { labelSmall, providerTitle, breakLongText, personaText } =
      getPeopleScreenStylesSet(theme);

    const secondaryText = mergeStyles({
      ...breakLongText,
      color: theme.palette.neutralSecondary,
      fontSize: theme.fonts.small.fontSize
    });

    const externalProviders = patient.externalProviders.map((x, index) => {
      const extProv = practice.contactsMap.get(x.contactId);

      return {
        contactId: x.contactId,
        key: `EXT${index}`,
        new: false,
        name: extProv ? extProv.name : x.contactId,
        initials: extProv ? extProv.initials : "",
        categories: extProv ? extProv.categories : "",
        communications:
          extProv && extProv.primaryCommunication
            ? extProv.primaryCommunication.value
            : "",
        status: extProv ? extProv.status : ContactStatus.Active
      };
    });

    return (
      <Stack>
        {patient.externalProviders.length > 0 && (
          <Stack>
            <Heading className={providerTitle} variant="section-sub-heading">
              {Labels.externalProviders}
            </Heading>
            <Stack tokens={{ childrenGap: 8 }}>
              {externalProviders.map((prov, idx) => (
                <Fragment key={`${prov.contactId}-${idx.toString()}`}>
                  <CompoundButtonWithHoveredIcon
                    onClick={() => showContactDetails(prov.contactId)}
                  >
                    <ContactFetcher
                      fallback={<Spinner />}
                      contactId={prov.contactId}
                    >
                      {contact => (
                        <Persona
                          styles={mergeStyleSets(
                            {
                              root: {
                                height: "auto"
                              }
                            },
                            personaText
                          )}
                          size={PersonaSize.size48}
                          imageInitials={contact.initials}
                          text={contact.name}
                          status={contact.status}
                          onRenderSecondaryText={() => (
                            <Stack.Item>
                              <Stack verticalAlign="center" verticalFill>
                                <Stack.Item>
                                  <div className={secondaryText}>
                                    {(contact.categories.length && (
                                      <ContactCategoryText
                                        code={prov.categories}
                                      />
                                    )) ||
                                      Labels.noCategoryRecorded}
                                  </div>
                                </Stack.Item>
                                <Stack.Item styles={labelSmall}>
                                  {prov.communications}
                                </Stack.Item>
                              </Stack>
                            </Stack.Item>
                          )}
                        />
                      )}
                    </ContactFetcher>
                  </CompoundButtonWithHoveredIcon>
                </Fragment>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }
);
