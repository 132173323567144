import { AxiosInstance } from "@bps/http-client";

import { BhacPatientDto } from "./bhacGateway.dtos.ts";
import { IBhacGateway } from "./bhacGateway.interface.ts";

export class BhacGateway implements IBhacGateway {
  constructor(private api: AxiosInstance) {}

  async addPatient(patient: BhacPatientDto): Promise<string> {
    return this.api
      .post<string>(
        `patient?accountId=${patient.accountId}&tenantId=${patient.tenantId}&patientId=${patient.patientId}`
      )
      .then(x => x.data);
  }
  async updatePatient(patient: BhacPatientDto): Promise<string> {
    return this.api
      .put<string>(
        `patient?accountId=${patient.accountId}&tenantId=${patient.tenantId}&patientId=${patient.patientId}`
      )
      .then(x => x.data);
  }
}
