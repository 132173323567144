import { IColumn, ScrollablePane } from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { renderCalendarEventWrapper } from "../../booking-calendar/components/utils.tsx";
import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";
import { DidNotArriveNoData } from "./DidNotArriveNoData.tsx";

interface DidNotArriveTableProps {
  items: DidNotArriveItem[] | undefined;
  columns: IColumn[];
  loading?: boolean;
  error?: Error;
}

export const DidNotArriveTable: React.FC<DidNotArriveTableProps> = ({
  items,
  columns,
  loading,
  error
}) => {
  if (!error && !loading && items && items.length === 0) {
    return <DidNotArriveNoData />;
  }

  return (
    <ScrollablePane styles={{ root: { height: "100%" } }}>
      <ShimmeredDetailsList
        setKey="didNotArriveList"
        enableShimmer={loading}
        errorMessage={error?.message}
        onRenderRow={props => renderCalendarEventWrapper(props)}
        items={items ?? []}
        columns={columns}
      />
    </ScrollablePane>
  );
};
