import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontSizes,
  Heading,
  IconButton,
  LabeledText,
  Stack,
  TextBadgeSize,
  Tile,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DraftItemsTotalsDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { AccountBadge } from "@modules/billing/screens/shared-components/AccountBadge.tsx";
import { AccountStatementDate } from "@modules/billing/screens/shared-components/AccountStatementDate.tsx";
import { PatientAccountBalanceValue } from "@modules/practice/screens/contact-details/patient/view/PatientAccountBalanceValue.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { AccountTotalDataFetcher } from "./patient-warnings/AccountTotalDataFetcher.tsx";
import { PatientAccountPersonaCard } from "./PatientAccountPersonaCard.tsx";

export enum BalanceStatusTotals {
  totalOwing = "Owing",
  unallocatedCredit = "Credit",
  draftItemsTotals = "Draft items"
}

interface AccountsInfoCardProps {
  cardId: string;
  contact: Contact;
  onHandleEditModal: (id?: string) => void;
  hideStatement?: boolean;
}

export const AccountsInfoCard: FunctionComponent<AccountsInfoCardProps> =
  observer(({ onHandleEditModal, cardId, contact, hideStatement }) => {
    const { core, routing } = useStores();
    const theme = useTheme();
    const onClickEdit = (cardId: string) => () => {
      onHandleEditModal(cardId);
    };

    const canOpenAccountHistory = core.hasPermissions([
      Permission.AccountHistoryAllowed
    ]);

    const isPatient = contact.type === ContactType.Patient;
    const canOpenEdit = core.hasPermissions([
      Permission.ContactRead,
      Permission.ContactWrite,
      Permission.PatientRead,
      Permission.PatientWrite
    ]);

    const accountHolderFor = contact.relationships.filter(
      x => x.relationship === RelationshipType.AccountHolderFor
    );

    const hasAccountHolderFor = accountHolderFor.length > 0;
    const accountHolders = contact.relationships.filter(
      x => x.relationship === RelationshipType.AccountHolder
    );

    const sortedAccountHolders = [
      ...accountHolders.filter(x => x.metadata?.isPrimary),
      ...accountHolders.filter(x => !x.metadata?.isPrimary)
    ];

    const { iconButtonStyles } = getPeopleScreenStylesSet(theme);
    return (
      <div id={cardId}>
        <AccountTotalDataFetcher accountId={contact.id}>
          {({ accountTotals }) => (
            <Tile
              styles={{ root: { padding: "16px 14px", width: "100%" } }}
              header={
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  styles={{
                    root: {
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 16
                    }
                  }}
                >
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Heading
                      styles={{
                        root: {
                          fontSize: FontSizes.xLarge
                        }
                      }}
                    >
                      {Labels.account}
                    </Heading>

                    <TooltipHost content="Account history">
                      <IconButton
                        iconProps={{ iconName: "Share" }}
                        styles={iconButtonStyles}
                        onClick={() => {
                          routing.push(
                            routes.accounts.account.path({
                              id: contact.id
                            })
                          );
                        }}
                      />
                    </TooltipHost>
                  </Stack>
                  <Stack horizontal styles={{ root: { gap: 8 } }}>
                    <AccountBadge
                      balance={accountTotals}
                      hideAccountText
                      badgeSize={TextBadgeSize.small}
                    />
                    {canOpenEdit && isPatient && (
                      <TooltipHost content="Edit">
                        <IconButton
                          iconProps={{ iconName: "Edit" }}
                          styles={iconButtonStyles}
                          onClick={onClickEdit(cardId)}
                          ariaLabel="Edit"
                        />
                      </TooltipHost>
                    )}
                  </Stack>
                </Stack>
              }
            >
              <Stack
                styles={{
                  root: {
                    margin: "0 10px"
                  }
                }}
                tokens={{ childrenGap: 8 }}
              >
                <Stack tokens={{ childrenGap: 8 }}>
                  <Stack
                    horizontal
                    styles={{
                      root: {
                        marginBottom: 8,
                        justifyContent: "flex-start",
                        gap: 24
                      }
                    }}
                  >
                    <PatientAccountBalanceValue
                      showDotIfPositive
                      value={accountTotals.totalOwing}
                      text={BalanceStatusTotals.totalOwing}
                    />
                    {contact.draftItemsEnabled && (
                      <DataFetcher<DraftItemsTotalsDto>
                        fetch={({ billing }) =>
                          billing.getAccountDraftItemsTotals(contact.id)
                        }
                      >
                        {draftItemsTotals => (
                          <PatientAccountBalanceValue
                            showDotIfPositive
                            value={draftItemsTotals.total ?? 0}
                            text={BalanceStatusTotals.draftItemsTotals}
                          />
                        )}
                      </DataFetcher>
                    )}

                    <PatientAccountBalanceValue
                      value={accountTotals.unallocatedCredit}
                      text={BalanceStatusTotals.unallocatedCredit}
                    />
                    {!hideStatement && (
                      <AccountStatementDate
                        extraLinkText
                        accountContactId={contact.id}
                      />
                    )}
                  </Stack>
                  {contact.draftItemsEnabled && (
                    <LabeledText
                      noSemicolon
                      label={BalanceStatusTotals.draftItemsTotals}
                    >
                      Enabled
                    </LabeledText>
                  )}
                </Stack>
                {contact.relationships &&
                  hasAccountHolderFor &&
                  accountHolderFor.map((x, i) => (
                    <PatientAccountPersonaCard
                      key={x.relatedContactId}
                      patient={contact}
                      headingText={i === 0 ? Labels.accountHolderFor : ""}
                      includeRelationships={true}
                      accountHolderFor={x}
                    />
                  ))}
                {isPatient &&
                  canOpenAccountHistory &&
                  sortedAccountHolders &&
                  sortedAccountHolders.map((x, i) => {
                    return (
                      <PatientAccountPersonaCard
                        key={x.relatedContactId}
                        patient={contact}
                        headingText={i === 0 ? Labels.billedTo : ""}
                        badgeText={
                          x.metadata?.isPrimary ? "Primary" : undefined
                        }
                        accountHolderFor={x}
                      />
                    );
                  })}
              </Stack>
            </Tile>
          )}
        </AccountTotalDataFetcher>
      </div>
    );
  });
