import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext } from "react";
import { FormSpy } from "react-final-form";

import { NoDataTile, Stack, StackItem, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { BhbProviderProfilePhotoPicker } from "@modules/settings/screens/users/components/user-online-booking-profile/BhbProviderProfilePhotoPicker.tsx";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";
import { UserOnlineBookingProfileFormContext } from "./UserOnlineBookingProfileFormContext.ts";
import { UserOnlineBookingProfileFormValidator } from "./UserOnlineBookingProfileFormValidator.tsx";

export const onlineBookingProfileNameOf =
  nameOfFactory<UserOnlineBookingProfileFormValues>();
const validator = new UserOnlineBookingProfileFormValidator();

interface UserOnlineBookingProfileFormBaseProps {
  setIsDirty: (dirty: boolean) => void;
}

interface UserOnlineBookingProfileFormProps
  extends UserOnlineBookingProfileFormBaseProps {
  bhbProviders: BhbProvider[];
}

export const UserOnlineBookingProfileFormBase = observer(
  ({ setIsDirty }: UserOnlineBookingProfileFormBaseProps) => {
    const { core, notification, bhb, practice } = useStores();

    const { user, bhbProvider, initialValues, onSubmit } = useContext(
      UserOnlineBookingProfileFormContext
    );

    const theme = useTheme();

    const { formFooter, formFields } = getUserStylesSet(theme);

    return (
      <SubmissionForm<UserOnlineBookingProfileFormValues>
        formName="user-online-settings"
        autoFocus={false}
        styles={{ main: { borderRadius: 4 } }}
        validate={(values: UserOnlineBookingProfileFormValues) =>
          validator.validate(values)
        }
        readOnly={!core.hasPermissions(Permission.BhbWrite)}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          notification.success(`${user.fullName} has been updated.`);
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          disableSubmitOnFormInvalid: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {() => {
          return (
            <Fieldset
              styles={{
                root: formFields
              }}
            >
              <Stack horizontal>
                {bhbProvider && (
                  <BhbProviderProfilePhotoPicker
                    id={bhbProvider.id}
                    firstName={bhbProvider.firstName}
                    lastName={bhbProvider.lastName}
                  />
                )}
                <StackItem styles={{ root: { width: "100%" } }}>
                  <TextInputField
                    name={onlineBookingProfileNameOf("displayName")}
                    label="Display name"
                    placeholder="'Title'. 'Preferred name' 'Last name' if not specified"
                  />
                  <OptionsSelectField
                    name={onlineBookingProfileNameOf("language")}
                    label="Languages spoken"
                    placeholder="Select languages"
                    multiSelect
                    options={practice.ref.languages.keyTextValues}
                    showAllSelected
                    styles={{
                      fieldContent: { justifyContent: "flex-start" },
                      root: { flexGrow: 1, flexBasis: 0 }
                    }}
                  />
                  <StaticTagPickerField
                    name={onlineBookingProfileNameOf("areaOfInterest")}
                    label="Areas of interest"
                    fetchDataSource={() =>
                      bhb.ref.areasOfInterest.keyNameValues
                    }
                  />
                </StackItem>
              </Stack>
              <TextEditorField
                name={onlineBookingProfileNameOf("profileDetail")}
                label="Biography"
                placeholder="Introduce yourself to patients"
              />
              <FormSpy
                subscription={{ dirty: true }}
                onChange={({ dirty }) => setIsDirty(dirty)}
              />
            </Fieldset>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const UserOnlineBookingProfileForm: FunctionComponent<UserOnlineBookingProfileFormProps> =
  observer(({ bhbProviders, setIsDirty }) => {
    if (bhbProviders.length === 0) {
      return (
        <NoDataTile
          textProps={{
            text: "Online booking profile not found. Please refresh."
          }}
          linkProps={{}}
        />
      );
    }

    return <UserOnlineBookingProfileFormBase setIsDirty={setIsDirty} />;
  });
