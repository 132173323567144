import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Shimmer } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";

export const DidNotArrivePatient: FC<{ item: DidNotArriveItem }> = observer(
  ({ item }) => {
    const { core, practice } = useStores();
    const handleNavigateClicked = (id: string) => {
      const hasPatientWritePermission = core.hasPermissions([
        Permission.PatientWrite
      ]);

      if (hasPatientWritePermission)
        practice.ui.showEditContact(PatientCardIds.patientHeader, id);
      else practice.ui.showContactDetails(id);
    };

    return item.patient ? (
      <Navigate
        onClick={() => handleNavigateClicked(item.patient?.id!)}
      >{`${item.patient.firstName} ${item.patient.lastName}`}</Navigate>
    ) : (
      <Shimmer />
    );
  }
);
