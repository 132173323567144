import { maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";

export class UserOnlineBookingProfileFormValidator extends Validator<UserOnlineBookingProfileFormValues> {
  constructor() {
    super();

    this.forField("displayName", maxLength(250));
    this.forField("profileDetail", maxLength(2000));
  }
}
