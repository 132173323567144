import { FC } from "react";

import RemindersScreen from "./RemindersScreen.tsx";

export const FollowUpRemindersScreen: FC = () => {
  return <RemindersScreen showFollowUpReminders />;
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default FollowUpRemindersScreen;
