import {
  ClinicalDataType,
  ObservationType,
  QuestionnaireDto,
  QuestionnaireItem,
  QuestionnaireItemDto,
  Rand36ClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";

import { Rand36FormValues } from "./Rand36Form.types.ts";

export class Rand36FormModel {
  private clinicalRecord: ClinicalRecord;
  private clinical: ClinicalStore;
  private readonly onObservationSaved: (
    observation: Observation[],
    rand36Data?: Rand36ClinicalDataDto
  ) => void;

  constructor(
    clinicalReacord: ClinicalRecord,
    clinicalStore: ClinicalStore,
    onObservationSaved: (
      observation: Observation[],
      rand36Data: Rand36ClinicalDataDto
    ) => void
  ) {
    this.clinicalRecord = clinicalReacord;
    this.clinical = clinicalStore;
    this.onObservationSaved = onObservationSaved;
  }

  static getKey(idx: number) {
    return `q${idx + 1}`;
  }

  get stashedRand36ClinicalData() {
    return this.clinicalRecord.stashedClinicalData?.rand36;
  }

  get rand36ClinicalData() {
    return this.clinicalRecord.clinicalData?.rand36;
  }

  get initialValues(): Rand36FormValues | undefined {
    return this.stashedRand36ClinicalData
      ? {
          confidential: !!this.stashedRand36ClinicalData.secGroupId,
          items: this.stashedRand36ClinicalData?.items?.reduce(
            (
              acc: Record<string, string>,
              i: QuestionnaireItem,
              idx: number
            ) => {
              return {
                ...acc,
                [Rand36FormModel.getKey(idx)]: i.answer
              };
            },
            {}
          )
        }
      : undefined;
  }

  public getStashedClinicalData =
    (questionnaire: QuestionnaireDto) =>
    (values: Rand36FormValues): Pick<EncounterClinicalData, "rand36"> => {
      const secGroupId = !!values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined;
      if (this.rand36ClinicalData) {
        const items = this.stashedRand36ClinicalData?.items ?? [];

        return {
          rand36: {
            ...this.stashedRand36ClinicalData,
            questionnaireId: questionnaire.id,
            questionnaireCode: questionnaire.code,
            items: items?.map((item: QuestionnaireItem, idx: number) => ({
              ...item,
              answer: values.items[Rand36FormModel.getKey(idx)]
            })),
            secGroupId
          }
        };
      } else {
        return {
          rand36: {
            items: questionnaire.items?.map(
              (_item: QuestionnaireItemDto, idx: number) => {
                return {
                  answer: values.items[Rand36FormModel.getKey(idx)]
                };
              }
            ),
            secGroupId
          }
        } as Pick<EncounterClinicalData, "rand36">;
      }
    };

  onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.RAND36
    );
    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "rand36"
    ]);
  };

  onSubmit = async (
    values: Rand36FormValues,
    questionnaire: QuestionnaireDto
  ) => {
    const stashedClinicalData =
      this.getStashedClinicalData(questionnaire)(values);

    const eTag = this.rand36ClinicalData?.eTag;
    const items = stashedClinicalData.rand36?.items ?? [];

    const payload: Partial<EncounterClinicalData> = {
      rand36: {
        ...stashedClinicalData.rand36,
        eTag,
        questionnaireId: questionnaire.id,
        questionnaireCode: questionnaire.code,
        items: items.map((i: QuestionnaireItem, idx: number) => {
          return {
            answer: i.answer,
            id: i.id,
            questionnaireItemId: questionnaire.items[idx].id
          };
        })
      }
    };

    await this.clinicalRecord.saveClinicalData(payload);
    const openedEncId = this.clinicalRecord.openEncounter?.id;
    const result = await this.clinical.getObservations({
      patientId: this.clinicalRecord.patient?.id,
      encounterIds: openedEncId ? [openedEncId] : undefined,
      types: [ObservationType.RAND36]
    });

    const observation = result.results;

    if (observation && observation.length > 0) {
      this.clinicalRecord.updateObservations(observation);
      this.onObservationSaved(observation, payload.rand36);
    } else {
      this.clinical.root.notification.error(
        "Unable to retrieve the total clinical tool scores."
      );
    }
  };
}
