import React from "react";

import { Heading, MessageBar, Stack, useTheme } from "@bps/fluent-ui";
import { ObservationDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { splitNameFromSummary } from "./utils.ts";

export interface DASS21ScoreProps {
  observations?: ObservationDto[];
}

export const DASS21Score: React.FC<DASS21ScoreProps> = ({ observations }) => {
  const theme = useTheme();

  if (!observations) {
    return null;
  }

  const mappedObservationAndHeading = observations.map(x => {
    return {
      splitContent: splitNameFromSummary(x.summary),
      observation: x
    };
  });

  const depression = mappedObservationAndHeading.find(
    x => x.splitContent?.heading === "Depression"
  );

  const anxiety = mappedObservationAndHeading.find(
    x => x.splitContent?.heading === "Anxiety"
  );

  const stress = mappedObservationAndHeading.find(
    x => x.splitContent?.heading === "Stress"
  );

  const sortedObservations = [depression, anxiety, stress];

  return (
    <>
      {sortedObservations?.map(x => {
        return (
          <Stack
            key={x?.observation.id}
            styles={{
              root: {
                border: "solid",
                borderWidth: 1,
                padding: 16,
                color: theme.palette.neutralLight
              }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <Heading>{x?.splitContent?.heading}</Heading>
            <MessageBar>
              {x?.splitContent?.observation} - {x?.observation.values[0].value}
            </MessageBar>
          </Stack>
        );
      })}
    </>
  );
};
