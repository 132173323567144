import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import {
  GridDetailsList,
  PivotItem,
  PivotTabs,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalDataTypeLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { generateObservationColumns } from "./generateObservationColumns.tsx";
import { NoObservationDataTile } from "./NoObservationDataTile.tsx";
import { ObservationMatrixPivotBar } from "./ObservationMatrixPivotBar.tsx";
import { ObservationsHeaderWrapper } from "./ObservationsHeaderWrapper.tsx";
import {
  generateObservationRows,
  groupObservationsByDateAndType,
  reverseObsDateColumns
} from "./utils.ts";

interface ObservationsProps {
  clinicalRecord: ClinicalRecord;
}

export interface ObservationRow {
  key: string;
  type: string;
  [key: string]: string;
}

export enum ObservationPivotName {
  DashBoard = "Dashboard",
  Matrix = "Matrix",
  All = "All"
}

export const ObservationsExpandedTableBase: React.FC<ObservationsProps> =
  observer(({ clinicalRecord }) => {
    const { clinical, userExperience, core } = useStores();
    const theme = useTheme();

    const currentPatientRecordTab = clinical.ui.tabs.currentPatientRecordTab;

    const observationTypes = clinical.ref.observationTypes.values;

    const defaultPivotFromObsTreeView =
      currentPatientRecordTab?.activeTab?.defaultPivot;

    const defaultSelectedPivotKey =
      defaultPivotFromObsTreeView ?? ObservationPivotName.Matrix;

    const [selectedKey, setSelectedKey] = useState<string>(
      defaultSelectedPivotKey
    );

    const onSwitchDateOrder = async () => {
      const reverseDatesSetting = await userExperience.getUserStorage(
        UserStorageKeys.ReverseObservationMatrixDates
      );

      if (reverseDatesSetting) {
        const valueAsBool = reverseDatesSetting.jsonData as boolean;

        return await userExperience.updateUserStorage(
          UserStorageKeys.ReverseObservationMatrixDates,
          {
            id: reverseDatesSetting.id,
            key: UserStorageKeys.ReverseObservationMatrixDates,
            userId: core.userId,
            jsonData: !valueAsBool,
            eTag: reverseDatesSetting.eTag
          }
        );
      }

      return await userExperience.addUserStorage(
        UserStorageKeys.ReverseObservationMatrixDates,
        {
          key: UserStorageKeys.ReverseObservationMatrixDates,
          userId: core.userId,
          jsonData: false
        }
      );
    };

    const handlePivotItemClick = (item?: any) => {
      if (item?.props.itemKey) {
        setSelectedKey(item.props.itemKey);
      }
    };

    // Not put in a Util as to not parse the observations through several different functions.
    const getObservationShortenedText = (type: string) => {
      const observation = observationTypes.filter(x => x.code === type);
      if (observation.length > 0) {
        return observation[0].abbreviatedName ?? observation[0].text;
      }

      return type;
    };

    const fetchObservations = async () => {
      const allObservationResults = await clinicalRecord.loadObservationData();

      const observations = allObservationResults.results;
      const groupedByDateAndType = groupObservationsByDateAndType(observations);

      const dates = Object.keys(groupedByDateAndType);

      // Order the rows based on the order of the observation types.
      const relevantObservationTypes = observationTypes.filter(o =>
        observations.some(obType => obType.type === o.code)
      );

      const uniqueTypes = new Set(relevantObservationTypes.map(m => m.code));

      const sortedRows = generateObservationRows(
        uniqueTypes,
        dates,
        groupedByDateAndType
      );

      const rowsWithLabels = sortedRows.map(row => ({
        ...row,
        typeLabel: row.type
      }));

      const columns = generateObservationColumns(
        {
          dates,
          groupedByDateAndType,
          theme
        },
        getObservationShortenedText
      );

      return {
        columns,
        rowsWithLabels
      };
    };

    return (
      <ObservationsHeaderWrapper
        heading={ClinicalDataTypeLabel.Observations}
        onClose={clinical.ui.tabs.currentPatientRecordTab!.hideActive}
        pivotTab={
          <PivotTabs
            selectedKey={selectedKey}
            onLinkClick={handlePivotItemClick}
          >
            {core.hasPermissions(Permission.NewObservationEnitity) && (
              <PivotItem
                headerText={ObservationPivotName.Matrix}
                itemKey={ObservationPivotName.Matrix}
              />
            )}
          </PivotTabs>
        }
        noGap
        hasSeparator
      >
        {selectedKey === ObservationPivotName.Matrix && (
          <DataFetcher fetch={fetchObservations} noExceptionsHandlers>
            {(data, loading, error) => {
              const rows = data?.rowsWithLabels.length;

              if ((rows && rows > 0) || loading || error) {
                return (
                  <Stack>
                    <ObservationMatrixPivotBar
                      onSwitch={async () => {
                        await onSwitchDateOrder();
                      }}
                      selectedKey={selectedKey}
                    />
                    <GridDetailsList
                      styles={{ root: { height: "600px" } }}
                      columns={
                        userExperience.reverseObservationMatrixDates
                          ? reverseObsDateColumns(data?.columns ?? [])
                          : data?.columns ?? []
                      }
                      items={data?.rowsWithLabels || []}
                      stickyFirstColumn
                      stickyHeader
                      isLoading={loading}
                      error={error?.message}
                    />
                  </Stack>
                );
              } else {
                return <NoObservationDataTile />;
              }
            }}
          </DataFetcher>
        )}
      </ObservationsHeaderWrapper>
    );
  });

export const ObservationsExpandedTable = withFetch(
  x => [
    x.clinical.ref.observationTypes.load(),
    x.userExperience.getUserStorage(
      UserStorageKeys.ReverseObservationMatrixDates
    )
  ],
  ObservationsExpandedTableBase
);
