import { action } from "mobx";

import { IGroup } from "@bps/fluent-ui";
import { DateTime, groupBy } from "@bps/utils";
import {
  CodedText,
  ObservationDto,
  ObservationType,
  PSFSContextClinicalDataItemDto,
  PSFSMultipleQuestionnaireResponseDto,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nullifyAnyUndefined } from "@libs/utils/utils.ts";
import { getSideOfBodyText } from "@modules/clinical/utils/clinical.utils.ts";
import { PatientClinicalRecordTab } from "@stores/clinical/models/clinical-tab/PatientClinicalRecordTab.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Observation } from "@stores/clinical/models/Observation.ts";

export class ClinicalToolsListModel {
  private _clinicalItemHeaders: ClinicalItemHeader[] = [];
  private contexts: PSFSContextClinicalDataItemDto[] | undefined;

  constructor(
    private clinicalRecord: ClinicalRecord,
    private codedTexts?: CodedText[]
  ) {
    this.clinicalRecord = clinicalRecord;
    this.codedTexts = codedTexts;
    this.contexts = this.clinicalRecord.clinicalData?.psfsContext?.contexts;
  }

  headerDate = (key: string | undefined): string => {
    if (key) {
      const header = this._clinicalItemHeaders.find(h => h.key === key);
      if (header) return header.latestDate;
    }
    return "";
  };

  getHeaderText = (code: string) => {
    if (ObservationType[code] === ObservationType.DASH) {
      return "DASH 11";
    }

    if (ObservationType[code] === ObservationType.RAND36) {
      return "RAND 36";
    }

    if (ObservationType[code] === ObservationType.DASS21) {
      return "DASS 21";
    }

    return ObservationType[code];
  };

  generateGroups = (
    currentPatientRecordTab: PatientClinicalRecordTab,
    observations: Observation[]
  ) => {
    let startIndex = 0;
    const groups: IGroup[] = [];
    const observationCode = Object.keys(ObservationType);
    const orderedObservations: Observation[] = [];
    const psfsContexts =
      this.clinicalRecord.clinicalData?.psfsContext?.contexts;

    for (const code of observationCode) {
      const items = observations?.filter(x => x.type === code) || [];
      const itemDates = items.map(i =>
        DateTime.fromISOOrNow(i.changeLog?.createdDate)
      );

      const latestDate =
        itemDates.length > 0
          ? itemDates.reduce((p, v) => (p > v ? p : v))
          : undefined;

      // if the items have contextIds it means there is an additional
      // layer of subgrouping we can do
      const subGroups: IGroup[] = [];
      let subGroupStartIndex = startIndex;
      if (
        code !== ObservationType.DASH &&
        code !== ObservationType.RAND36 &&
        code !== ObservationType.DASS21
      ) {
        const contextGroups = groupBy(items, item => item.values[0].contextId);

        // for each contextGroup we need to make a new IGroup object with
        // all the items related to that context
        contextGroups.forEach(group => {
          const [contextId, groupItems] = group;
          if (contextId && groupItems.length > 0) {
            const context = psfsContexts?.find(c => c.contextId === contextId);
            const side =
              context?.side! === SideOfBody.Neither
                ? ""
                : `, ${getSideOfBodyText(context?.side!)}`;

            const terminology = this.codedTexts?.find(
              t => t.code === context?.diagnosis.code
            );

            const termname = terminology ? terminology.text : "";
            const subGroupName = `${termname}${side}`;

            // add this new context group to the list of subgroups
            subGroups.push({
              key: contextId,
              name: subGroupName,
              count: groupItems.length,
              isCollapsed:
                currentPatientRecordTab.state.collapseClinicalTools.hasOwnProperty(
                  subGroupName
                )
                  ? !currentPatientRecordTab.state.collapseClinicalTools[
                      subGroupName
                    ]
                  : true,
              startIndex: subGroupStartIndex,
              level: 2,
              data: {
                type: groupItems[0].type,
                observationId: groupItems[0].id,
                secGroupId: groupItems[0].secGroupId
              } // add Observation's props as extra data to reuse elsewhere
            });
            orderedObservations.push(...groupItems);
            subGroupStartIndex += groupItems.length;
          }
        });
      }

      const subGroupOperation = subGroupStartIndex > startIndex;

      this.addClinicalItemHeader(code, latestDate);

      // Creates a new IGroup
      // With all the context subgroups as children of this group

      if (items.length > 0) {
        groups.push({
          key: code,
          name: this.getHeaderText(code),
          count: items.length,
          isCollapsed:
            currentPatientRecordTab.state.collapseClinicalTools.hasOwnProperty(
              code
            )
              ? !currentPatientRecordTab.state.collapseClinicalTools[code]
              : true,
          startIndex,
          children: subGroups,
          level: 1
        });
      }

      if (!subGroupOperation) {
        orderedObservations.push(...items);
      }

      startIndex += items.length;
    }
    return {
      groups,
      items: orderedObservations
    };
  };

  private addClinicalItemHeader(code: string, latestDate?: DateTime) {
    this._clinicalItemHeaders.push({
      key: code,
      latestDate: latestDate?.toDayDefaultFormat() || ""
    });
  }

  checkIsSeriesClosed = (contextId: string) => {
    let contextItemsDisabled = false;

    if (this.contexts) {
      const index = this.contexts.findIndex(c => c.contextId === contextId);

      if (index !== -1) {
        const existingContext = this.contexts[index];
        contextItemsDisabled = existingContext.isClosed;
      }
    }

    return contextItemsDisabled;
  };

  @action
  closePSFSSeries = async (
    contextId: string,
    reason: string,
    comment?: string | undefined
  ) => {
    const contextData = this.clinicalRecord.clinicalData?.psfsContext;

    if (contextData) {
      const existingContexts = contextData.contexts;

      if (existingContexts) {
        const index = existingContexts.findIndex(
          c => c.contextId === contextId
        );

        if (index !== -1) {
          const existingContext = existingContexts[index];
          existingContext.isClosed = true;
          existingContext.reasonForClose = reason;
          existingContext.closedComment = comment;
          existingContexts[index] = existingContext;
        }

        contextData.contexts = existingContexts;

        await this.clinicalRecord.saveClinicalData({
          psfsContext: contextData
        });
      }
    }
  };

  private assignSecGroupIdToPSFSresponses = (
    observation: ObservationDto,
    psfsResponses: PSFSMultipleQuestionnaireResponseDto
  ) => {
    const responses = psfsResponses.responses;
    const userSecGroupId = this.clinicalRecord.core.user?.privateSecGroupId;

    return responses.map(r => {
      if (r.contextId === observation.values[0].contextId) {
        return { ...r, secGroupId: r.secGroupId ? undefined : userSecGroupId };
      } else {
        return r;
      }
    });
  };

  adjustObservationConfidentiality = async (observation: ObservationDto) => {
    const clinicalRecord = this.clinicalRecord;
    const core = clinicalRecord.core;
    const userSecGroupId = core.user?.privateSecGroupId;
    const clinical = clinicalRecord.clinical;

    if (core.hasAccessToSecGroup(observation.secGroupId)) {
      const chosenEncounter = await clinical.getEncounter(
        observation.encounterId
      );

      const currentResponses =
        clinicalRecord.clinicalData?.[observation.type.toLowerCase()];

      //update through clinicalData for currently opened encounter
      if (chosenEncounter === clinicalRecord.openEncounter) {
        let updatedResponse: any;
        //handle PSFS's extra layer data structure
        if (observation.type === ObservationType.PSFS) {
          const responses = this.assignSecGroupIdToPSFSresponses(
            observation,
            currentResponses
          );

          updatedResponse = {
            ...currentResponses,
            responses
          };
        } else {
          updatedResponse = {
            ...currentResponses,
            secGroupId:
              currentResponses && currentResponses.secGroupId
                ? undefined
                : userSecGroupId
          };
        }

        const payload = { [observation.type.toLowerCase()]: updatedResponse };
        await clinicalRecord.saveClinicalData(payload);
        clinicalRecord.stashedClinicalData?.updateFromPatch(
          nullifyAnyUndefined(payload)
        );

        //check for update then update cache
        const updatedObservation = await clinical.getObservation(
          observation.id
        );

        if (updatedObservation) {
          clinicalRecord.updateObservations([updatedObservation]);
        }
      } else {
        //update directly to Observation for closed/other user's encounter
        const updatedObservation: ObservationDto = {
          id: observation.id,
          type: observation.type,
          patientId: observation.patientId,
          summary: observation.summary,
          eTag: observation.eTag,
          encounterId: observation.encounterId,
          timestamp: observation.timestamp,
          values: observation.values,
          secGroupId: !!observation.secGroupId ? undefined : userSecGroupId
        };

        const dto = await clinical.updateObservation(updatedObservation);

        //update cache
        if (dto) {
          clinicalRecord.updateObservations([dto]);
        }
      }
    }
  };
}

export interface ClinicalItemHeader {
  key: string;
  latestDate: string;
}
