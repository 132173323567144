import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { ClaimManagementModalFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { convertClinicalSideToDiagnosisSide } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/active-condition-list/active-condition-list.utils.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";

interface DiagnosisDetailsProps {
  condition?: ICondition;
}

export const DiagnosisDetails: React.FC<DiagnosisDetailsProps> = ({
  condition
}) => {
  const theme = useTheme();
  const primaryDiagnosis = condition?.primaryDiagnosis;
  const diagnosisSide = condition?.diagnosisSide;
  const additionalDiagnosesText = condition?.claim?.dto?.claimDiagnosis
    ?.filter(item => item.terminology?.text !== primaryDiagnosis)
    .map(item => {
      const diagnosisText = item.terminology?.text;
      const diagnosisSide = item?.diagnosisSide
        ? capitalizeSentence(item.diagnosisSide)
        : "";
      return `${diagnosisText} (${diagnosisSide})`.trim();
    })
    .join(", ");

  return (
    <Stack
      styles={{
        root: {
          border: "1px solid",
          borderColor: theme.palette.neutralLight,
          padding: "8px 16px",
          marginTop: 8
        }
      }}
      tokens={{ childrenGap: 8 }}
    >
      <Stack tokens={{ childrenGap: 4 }}>
        <Text
          variant="xSmall"
          styles={{ root: { color: theme.palette.neutralSecondary } }}
        >
          {ClaimManagementModalFormLabels.primaryDiagnosis}
        </Text>
        <Text>
          {primaryDiagnosis}
          {convertClinicalSideToDiagnosisSide(diagnosisSide)}
        </Text>
      </Stack>
      {additionalDiagnosesText && (
        <Stack tokens={{ childrenGap: 4 }}>
          <Text
            variant="xSmall"
            styles={{ root: { color: theme.palette.neutralSecondary } }}
          >
            {ClaimManagementModalFormLabels.additionalDiagnosis}
          </Text>
          <Text>{additionalDiagnosesText}</Text>
        </Stack>
      )}
    </Stack>
  );
};
