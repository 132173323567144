export enum ClaimAdjustmentFormLabels {
  nhi = "NHI",
  side = "Side",
  provider = "Provider",
  lastName = "Last name",
  vendroId = "Vendor ID",
  followUp = "Follow up",
  addACC32 = "New ACC32",
  copyGoals = "Copy goals",
  firstName = "First name",
  noConsults = "No consults",
  providerId = "Provider ID",
  injuryDate = "Onset date",
  claimNumber = "Claim number",
  dateOfBirth = "Date of birth",
  newDiagnosis = "New diagnosis",
  noAcc32 = "No ACC32 requested",
  contactNumber = "Contact number",
  copyFromACC45 = "Copy from ACC45",
  initialAssessment = "Initial assessment",
  diagnosisToChange = "Diagnosis to change",
  searchForProvider = "Search for provider",
  treatmentsRequired = "Treatments required",
  providerContractType = "Provider type (contract)",
  treatmentStartDate = "When will the treatment start?",
  treatmentRequestDetails = "Treatment request details",
  splintingHandCost = "Splinting of hand cost, excluding GST",
  originalAccidentEvent = "What was the original accident event?",
  proposedTreatment = "Proposed treatment - including self-management",
  howManyTreatmentsNeed = "How many treatments does your client need?",
  managementPlanPlaceholder = "For example, onward referral, investigation.",
  managementPlan = "What is your management plan if treatment goals are not met?",
  currentConditionOrDiagnosis = "What is the patient’s current condition or diagnosis?",
  expectedTreatmentCompletionDate = "When is the requested treatment expected to be completed?",
  causeOfCurrentCondition = "How is the patient's current condition caused by the accident above?",
  causeOfNotResolvedCondition = "Why has the condition not resolved? Please include pre-existing factors",
  firstAdditionalTreatmentRequested = "Is this the first additional treatment request that the clinic has made post injury or surgery?",
  signAndSymptom = "Goals - signs and symptoms (e.g. increase strength to 90% of uninjured side) and/or functional (e.g. run for 30 minutes without pain)",
  causeOfCurrentConditionPlaceholder = "A causal link needs to be established for ACC to consider if the condition requiring treatment is related to the covered injury.",
  currentConditionOrDiagnosisPlaceholder = "Include a precise description of the current condition and if appropriate, why the current diagnosis differs from the original injury."
}

export enum ModalLabels {
  heading = "ACC32 options for claim"
}

export enum ClaimsAdjustmentCardIds {
  overview = "Overview",
  documentation = "Documentation",
  patientDetails = "Patient",
  addDiagnosis = "Diagnosis",
  changeSide = "ChangeSide",
  providerDetails = "Provider",
  treatmentDetails = "Treatment",
  currentStatusAndPrognosis = "CurrentStatusAndPrognosis",
  patientProviderDeclarations = "PatientProviderDeclaration",
  changeDiagnosis = "ChangeDiagnosis"
}

export enum ClaimAdjustmentSideRailLabels {
  overview = "Overview",
  documentation = "Documentation",
  patientDetails = "details",
  addADiagnosis = "Add a diagnosis",
  changeSide = "Change side",
  changeDiagnosis = "Change diagnosis",
  providerDetails = "Provider details",
  treatmentDetails = "Treatment details",
  currentStatusAndPrognosis = "Status & prognosis",
  patientProviderDeclarations = "Patient & provider declarations"
}

export enum ClaimAdjustmentSectionHeading {
  overview = "ACC32 overview",
  documentation = "Documentation",
  patientDetails = "details",
  addADiagnosis = "Add a diagnosis",
  changeSide = "Change side",
  providerDetails = "Provider details",
  treatmentDetails = "Treatment details",
  patientProviderDeclarations = "Patient & provider declarations",
  confirmDeclaration = "Do you confirm the declaration?",
  changeDiagnosis = "Change diagnosis"
}

export enum ClaimAdjustmentModalLabels {
  iNeedTo = "I need to",
  addADiagnosis = "Add a diagnosis",
  changeSide = "Change the side of a diagnosis",
  hasTreatmentDetails = "Request additional treatments",
  isVoluntarySubmission = "Is this a voluntary submission?",
  hasDiagnosisChange = "Change diagnosis (i.e. lodgement error)"
}

export enum ClaimAdjustmentModalDescriptions {
  description1 = "You can select descriptions / codes in this list that are within your scope of practice only to add or change a diagnosis.",
  description2 = "Note - If the code you wish to select (within your scope) is not in this pre-populated list, simply manually enter the code in this text box and you will be prompted for supporting information as part of our 'non-standard' process."
}
