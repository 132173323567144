import { action, computed, observable, runInAction } from "mobx";
import { FULFILLED } from "mobx-utils";

import { DateTime, getUniqueObjectsByKeys, newGuid } from "@bps/utils";
import { GetStagingInfoDto } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import {
  AddClaimAdjustmentDto,
  ClaimAdjustmentDocumentDto,
  ClaimAdjustmentDocumentStatuses,
  ClaimAdjustmentDto,
  ClaimAdjustmentReasons,
  ClaimAdjustmentRequests,
  ClaimAdjustmentRequestType,
  ClaimAdjustmentStatus,
  ClaimDiagnosisDto,
  MultiProviderErrorCode,
  TreatmentRequestDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  CorrespondenceDirection,
  CorrespondenceType,
  DocumentCreateOptions,
  DocumentSource,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import { isAllUndefined } from "@libs/utils/utils.ts";
import { ErrorMessageWithAction } from "@modules/acc/screens/shared-components/types/error-message-with-action.interface.ts";
import { getAddDocumentDto } from "@modules/clinical/screens/patient-record/components/clinical-form/utils.ts";
import { ClaimAdjustmentDocumentUploadFormValue } from "@shared-types/acc/claim-adjustment-document-upload-value.types.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { ClaimDiagnosisChangeFormValues } from "@shared-types/acc/claim-diagnosis-change-values.type.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { User } from "@stores/core/models/User.ts";
import { Provider } from "@stores/practice/models/Provider.ts";

import { SubmitOptions } from "../../claim-management/components/ClaimManagementForm.tsx";
import {
  getEmptyDiagnosis,
  getOriginalCurrentDiagnoses,
  mapToClaimDiagnosisFormValues
} from "../../utils.ts";
import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import {
  toClaimAdjustmentRequestsDeclinedDto,
  toClaimAdjustmentRequestsDto
} from "../components/claim-adjustment-overview-section/utils.ts";
import { ClaimAdjustmentResponseModalFormValues } from "../components/ClaimAdjustmentResponseModalForm.types.ts";
import {
  calculateResponseStatus,
  calculateTreatmentsRequestApproved,
  getAddClaimAdjustmentDto,
  getClaimAdjustmentFormValues,
  getClaimAdjustmentModalDto,
  getCurrentStatusAndPrognosisFormValues,
  getNewClaimAdjustmentSideChange,
  isDateEarliest,
  isDateGapGreaterThanAYear
} from "../components/utils.ts";
import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";
import { AddDiagnosisFormValidator } from "../validators/AddDiagnosisFormValidator.tsx";
import { CurrentStatusAndPrognosisFormValidator } from "../validators/CurrentStatusAndPrognosisFormValidator.tsx";
import { DiagnosisChangeFormValidator } from "../validators/DiagnosisChangeFormValidator.tsx";
import { DocumentSectionFormValidator } from "../validators/DocumentSectionFormValidator.tsx";
import { PatientDetailsFormValidator } from "../validators/PatientDetailsFormValidator.tsx";
import { PatientProviderDeclarationFormValidator } from "../validators/PatientProviderDeclarationFormValidator.tsx";
import { ProviderDetailsFormValidator } from "../validators/ProviderDetailsFormValidator.tsx";
import { SideChangeFormValidator } from "../validators/SideChangeFormValidator.tsx";
import { TreatmentDetailsFormValidator } from "../validators/TreatmentDetailsFormValidator.tsx";
import { ModalKeys } from "./ClaimAdjustmentHelper.types.ts";

export class ClaimAdjustmentHelper {
  constructor(
    public root: IRootStore,
    public claim: Claim,
    public options?: {
      claimAdjustmentId?: string;
      subscribeToClaimAdjustmentEvent?: boolean;
    }
  ) {
    if (options?.subscribeToClaimAdjustmentEvent) {
      this.root.acc.hub.onEntityEvent(
        Entity.AccClaimAdjustment,
        this.updateDocuments
      );
    }

    runInAction(() => {
      this.provider =
        claim.claimAdjustments.find(
          x => x.id === this.options?.claimAdjustmentId
        )?.provider || claim?.provider;
      this.claimAdjustmentId = this.options?.claimAdjustmentId;
    });
  }

  private _claimAdjustmentId: string | undefined;
  private _submitOptions: SubmitOptions = { redirect: false, lodge: false };
  private _formChange: <F extends keyof ClaimAdjustmentFormValues>(
    name: F,
    value?: ClaimAdjustmentFormValues[F]
  ) => void | undefined;
  private isLodging: boolean = false;

  private updateDocuments = async (message: EntityEventData) => {
    if (!this._formChange) {
      return;
    }
    this.loadErrorMessages();

    if (message.action === EventAction.Update) {
      const claimAdjustment = await this.root.acc.getClaimAdjustment(
        message.id
      );

      //upload documents need to be updated with a documentId when the document is created in the BE so the can be lodged
      const formUploadDocuments =
        this.claimAdjustmentFormValues?.uploadDocuments;

      if (!!formUploadDocuments?.length) {
        let uploadDocumentIdUpdated: boolean = false;

        const uploadDocuments = formUploadDocuments.map(uploadDocument => {
          const document = claimAdjustment.documents?.find(
            doc => doc.stagingId === uploadDocument.blobName
          );
          if (
            uploadDocument?.documentId !== document?.documentId ||
            uploadDocument?.status !== document?.status
          ) {
            uploadDocumentIdUpdated = true;
          }
          return {
            ...uploadDocument,
            documentId: document?.documentId,
            status: document?.status
          };
        });

        if (uploadDocumentIdUpdated) {
          this._formChange(claimAdjustmentFormNameOf("uploadDocuments"), [
            ...uploadDocuments
          ]);
        }
      }

      //documents need to be updated with a documentId when the document is created in the BE so the can be lodged
      const formDocuments = this.claimAdjustmentFormValues?.documents;

      if (formDocuments?.length) {
        let formDocumentIdUpdated: boolean = false;

        const formDocumentsWithIds = formDocuments.map(document => {
          const currentDocument = this.claimAdjustment.documents?.find(
            x =>
              x.claimAdjustmentDocumentId ===
                document.claimAdjustmentDocumentId ||
              x.documentId === document.documentId
          );

          if (!currentDocument) {
            return document;
          }

          if (
            (currentDocument?.documentId &&
              currentDocument?.documentId !== document?.documentId) ||
            (currentDocument?.status &&
              currentDocument?.status !== document.status)
          ) {
            formDocumentIdUpdated = true;
            return {
              ...document,
              documentId: currentDocument?.documentId,
              status: currentDocument?.status
            };
          }
          return document;
        });

        if (formDocumentIdUpdated) {
          this._formChange(claimAdjustmentFormNameOf("documents"), [
            ...formDocumentsWithIds
          ]);
        }
      }

      if (
        claimAdjustment.documents?.every(x => x.documentId) &&
        this.isSavingDocuments &&
        !this.isLodging
      ) {
        await this.lodge();
        runInAction(() => {
          this.isSavingDocuments = false;
        });
        this.setSubmitOptions({ redirect: true });
        this.handleSubmitSucceeded();
      }
    }
  };
  @computed
  get hasMultiProviderErrorMessage() {
    return this.claimAdjustment?.errorMessages.some(
      m => m.code === MultiProviderErrorCode.MultiProviderError
    );
  }

  //returns all the diagnosis additions and selected diagnosis changes
  private get allSelectedDiagnoses() {
    const diagnoses: ClaimDiagnosisFormValues[] = [];

    if (this.claimAdjustmentFormValues?.diagnosisAdditions) {
      diagnoses.push(...this.claimAdjustmentFormValues.diagnosisAdditions);
    }

    if (this.claimAdjustmentFormValues?.diagnosisChanges) {
      diagnoses.push(
        ...this.claimAdjustmentFormValues?.diagnosisChanges.map(x => x)
      );
    }
    return diagnoses;
  }

  private get isAddDiagnosisSectionUnToggledAndDirty() {
    return (
      this.claimAdjustment?.hasDiagnosisAddition &&
      !this.claimAdjustmentModalFormValues?.claimAdjustmentReasons?.includes(
        ClaimAdjustmentReasons.hasDiagnosisAddition
      ) &&
      !isAllUndefined(
        this.claimAdjustmentFormValues!.diagnosisAdditions.flatMap(x =>
          Object.values({ ...x, id: undefined })
        )
      )
    );
  }

  private get isChangeDiagnosisUnToggledAndDirty() {
    return (
      this.claimAdjustment?.hasDiagnosisChange &&
      !this.claimAdjustmentModalFormValues?.claimAdjustmentReasons?.includes(
        ClaimAdjustmentReasons.hasDiagnosisChange
      ) &&
      !isAllUndefined(
        this.claimAdjustmentFormValues!.diagnosisChanges.map(
          x => x.oldDiagnosisKey
        )
      )
    );
  }

  private get isChangeDiagnosisSideSectionUnToggledAndDirty() {
    return (
      this.claimAdjustment?.hasSideChange &&
      !this.claimAdjustmentModalFormValues?.claimAdjustmentReasons?.includes(
        ClaimAdjustmentReasons.hasSideChange
      ) &&
      !isAllUndefined(
        this.claimAdjustmentFormValues!.sideChanges.map(x => x.oldDiagnosisKey)
      )
    );
  }

  private get isAddTreatmentDetailsSectionUnToggledAndDirty() {
    return (
      this.claimAdjustment?.hasTreatmentDetails &&
      !this.claimAdjustmentModalFormValues?.claimAdjustmentReasons?.includes(
        ClaimAdjustmentReasons.hasTreatmentDetails
      ) &&
      !isAllUndefined([
        this.claimAdjustmentFormValues?.firstAdditionalTreatmentRequested,
        this.claimAdjustmentFormValues?.initialAssessmentsNeeded,
        this.claimAdjustmentFormValues?.followUpTreatmentsNeeded,
        this.claimAdjustmentFormValues?.treatmentsRequired,
        this.claimAdjustmentFormValues?.handSplintingCost,
        this.claimAdjustmentFormValues?.treatmentStartDate
      ])
    );
  }

  private get isCurrentStatusAndPrognosisSectionUnToggledAndDirty() {
    return (
      this.claimAdjustment?.isVoluntarySubmission &&
      !this.claimAdjustmentModalFormValues?.isVoluntarySubmission &&
      !(
        this.isSubsequentVisitRequest ||
        this.is12MonthsSinceAccidentDate ||
        this.isHandSplintingOver300 ||
        this.isNonStandardCodeSelected()
      ) &&
      !isAllUndefined([
        this.claimAdjustmentFormValues?.originalAccidentEvent,
        this.claimAdjustmentFormValues?.currentConditionOrDiagnosis,
        this.claimAdjustmentFormValues?.causeOfCurrentCondition,
        this.claimAdjustmentFormValues?.firstAdditionalTreatmentRequested,
        this.claimAdjustmentFormValues?.causeOfNotResolvedCondition,
        this.claimAdjustmentFormValues?.signAndSymptom,
        this.claimAdjustmentFormValues?.proposedTreatment,
        this.claimAdjustmentFormValues?.expectedTreatmentCompletionDate,
        this.claimAdjustmentFormValues?.managementPlan
      ])
    );
  }

  private get isCurrentStatusAndPrognosisSectionHiddenAndDirty() {
    return (
      !this.showCurrentStatusAndPrognosisSection(
        this.isNonStandardCodeSelected()
      ) &&
      !isAllUndefined([
        this.claimAdjustmentFormValues?.originalAccidentEvent,
        this.claimAdjustmentFormValues?.currentConditionOrDiagnosis,
        this.claimAdjustmentFormValues?.causeOfCurrentCondition,
        this.claimAdjustmentFormValues?.causeOfNotResolvedCondition,
        this.claimAdjustmentFormValues?.signAndSymptom,
        this.claimAdjustmentFormValues?.proposedTreatment,
        this.claimAdjustmentFormValues?.expectedTreatmentCompletionDate,
        this.claimAdjustmentFormValues?.managementPlan
      ])
    );
  }

  private updateClaimAdjustmentModal = async (
    values: ClaimAdjustmentModalValues
  ) => {
    let claimAdjustmentDto: Partial<ClaimAdjustmentDto> = {};

    await this.root.acc.patchClaimAdjustment({
      id: this.claimAdjustment?.id!,
      claimId: this.claimAdjustment?.claimId,
      eTag: this.claimAdjustment.eTag,
      statusCode: ClaimAdjustmentStatus.Incomplete,
      ...claimAdjustmentDto,
      ...getClaimAdjustmentModalDto(
        values,
        this.claimAdjustment.dto.claimAdjustmentRequests
      )
    });

    if (this.willUserLoseData()) {
      claimAdjustmentDto = this.getFormFieldsForCleanUp();
    }
  };

  //returns the fields that need to be removed as per the user un-toggling the relevant form sections
  private getFormFieldsForCleanUp() {
    const claimAdjustmentDto: Partial<ClaimAdjustmentDto> = {};

    if (this.isAddDiagnosisSectionUnToggledAndDirty) {
      claimAdjustmentDto.claimAdjustmentRequests =
        claimAdjustmentDto.claimAdjustmentRequests?.filter(
          r =>
            r.claimAdjustmentRequestTypeCode !==
            ClaimAdjustmentRequestType.DiagnosisAddition
        );
    }

    if (this.isChangeDiagnosisUnToggledAndDirty) {
      claimAdjustmentDto.claimAdjustmentRequests =
        claimAdjustmentDto.claimAdjustmentRequests?.filter(
          r =>
            r.claimAdjustmentRequestTypeCode !==
            ClaimAdjustmentRequestType.DiagnosisChange
        );
    }

    if (this.isChangeDiagnosisSideSectionUnToggledAndDirty) {
      claimAdjustmentDto.claimAdjustmentRequests =
        claimAdjustmentDto.claimAdjustmentRequests?.filter(
          r =>
            r.claimAdjustmentRequestTypeCode !==
            ClaimAdjustmentRequestType.SideChange
        );
    }

    if (this.isAddTreatmentDetailsSectionUnToggledAndDirty) {
      claimAdjustmentDto.claimAdjustmentRequests =
        claimAdjustmentDto.claimAdjustmentRequests?.filter(
          r =>
            r.claimAdjustmentRequestTypeCode !==
            ClaimAdjustmentRequestType.TreatmentRequest
        );
    }

    if (this.isCurrentStatusAndPrognosisSectionUnToggledAndDirty) {
      claimAdjustmentDto.originalAccidentEvent = undefined;
      claimAdjustmentDto.currentConditionOrDiagnosis = undefined;
      claimAdjustmentDto.causeOfCurrentCondition = undefined;
      claimAdjustmentDto.causeOfNotResolvedCondition = undefined;
      claimAdjustmentDto.signAndSymptom = undefined;
      claimAdjustmentDto.proposedTreatment = undefined;
      claimAdjustmentDto.expectedTreatmentCompletionDate = undefined;
      claimAdjustmentDto.managementPlan = undefined;
    }

    return claimAdjustmentDto;
  }

  private updateClaimAdjustmentWithModalValues = (
    initialise?: (values: ClaimAdjustmentFormValues) => void
  ) => {
    const values = this.claimAdjustmentFormValues!;
    let valuesOverride: ClaimAdjustmentFormValues = {
      diagnosisChanges: values.diagnosisChanges,
      sideChanges: values.sideChanges,
      documents: values.documents,
      diagnosisAdditions: values.diagnosisAdditions
    };

    valuesOverride.diagnosisChanges = this.emptyArrayInitializeOrKeep(
      !this.claimAdjustment.hasDiagnosisChange,
      valuesOverride.diagnosisChanges,
      getEmptyDiagnosis()
    );

    valuesOverride.sideChanges = this.emptyArrayInitializeOrKeep(
      !this.claimAdjustment.hasSideChange,
      valuesOverride.sideChanges,
      getNewClaimAdjustmentSideChange(this.claim.currentDiagnoses)
    );

    valuesOverride.diagnosisAdditions = this.emptyArrayInitializeOrKeep(
      !this.claimAdjustment.hasDiagnosisAddition,
      valuesOverride.diagnosisAdditions,
      getEmptyDiagnosis()
    );

    if (!this.claimAdjustment.hasTreatmentDetails) {
      valuesOverride = {
        ...valuesOverride,
        treatmentStartDate: undefined,
        followUpTreatmentsNeeded: undefined,
        treatmentsRequired: undefined,
        handSplintingCost: undefined,
        initialAssessmentsNeeded: undefined,
        firstAdditionalTreatmentRequested: undefined
      };
    } else {
      if (typeof valuesOverride.initialAssessmentsNeeded === "undefined")
        valuesOverride.initialAssessmentsNeeded = 0;
    }
    if (
      !this.showCurrentStatusAndPrognosisSection(
        this.isNonStandardCodeSelected()
      )
    ) {
      valuesOverride = {
        ...valuesOverride,
        ...getCurrentStatusAndPrognosisFormValues(this.claimAdjustment)
      };
    }
    initialise && initialise({ ...values, ...valuesOverride });
  };

  private emptyArrayInitializeOrKeep<T>(
    shouldEmpty: boolean,
    array: T[],
    defaultValue: T
  ) {
    if (shouldEmpty) {
      return [];
    } else if (!shouldEmpty && !array.length) return [defaultValue];
    else return array;
  }

  private updateStagedDocuments = async () => {
    const currentAdj = await this.root.acc.getClaimAdjustment(
      this.claimAdjustment.id
    );

    await this.root.acc.patchClaimAdjustment({
      id: this.claimAdjustment.id,
      eTag: currentAdj.eTag,
      claimAdjustmentDocuments: currentAdj.documents?.map(
        (doc: ClaimAdjustmentDocumentDto) => ({
          ...doc,
          status:
            doc.status === ClaimAdjustmentDocumentStatuses.Staged
              ? ClaimAdjustmentDocumentStatuses.AwaitingCorrespondence
              : doc.status
        })
      )
    });
  };

  private stageUploadDocuments = async (
    uploadDocument: ClaimAdjustmentDocumentUploadFormValue[]
  ) => {
    if (!uploadDocument.length || !this.stagingInfo?.stagingId) {
      return;
    }

    const documents = await getAddDocumentDto({
      patientId: this.claim.patientId!,
      direction: CorrespondenceDirection.In,
      to: this.claim.patientId,
      store: StoreType.Correspondence,
      claimAdjustmentId: this.claimAdjustment.id,
      files: uploadDocument.map(x => ({
        ...x,
        name: x.fileName || "",
        type:
          this.root.acc.ref.documentContentTypes.values.find(
            d => d.code === x.contentType
          )?.clinicalDocTypeCode ?? CorrespondenceType.Email,
        date: DateTime.jsDateNow()
      }))
    });

    const request: DocumentCreateOptions = {
      stagingId: this.stagingInfo?.stagingId,
      source: DocumentSource.Staging,
      documents
    };
    await this.root.correspondence.addDocumentsByPatientId(
      this.claim.patientId!,
      request
    );
  };

  private resetDocumentErrors = <
    T extends
      | ClaimAdjustmentDocumentFormValue
      | ClaimAdjustmentDocumentUploadFormValue
  >(
    documentFormValues: T[]
  ): T[] => {
    return documentFormValues.map(document => {
      const resetDocument: T = { ...document };
      if (resetDocument.status === ClaimAdjustmentDocumentStatuses.Error) {
        resetDocument.status =
          ClaimAdjustmentDocumentStatuses.SavedToCorrespondence;
      }
      return resetDocument;
    });
  };

  public stagingInfo: GetStagingInfoDto | undefined;

  public claimAdjustmentFormValues: ClaimAdjustmentFormValues | undefined;

  public claimAdjustmentModalFormValues: ClaimAdjustmentModalValues | undefined;

  @observable
  public provider: Provider | undefined;

  @observable
  public providerUser: User | undefined;

  @observable
  public providerContractType?: string;

  @observable
  public providerContactNumber?: string;

  @observable
  public currentOpenModal: ModalKeys | undefined = undefined;

  @observable public claimAdjustmentId: string | undefined;

  @observable
  public isSavingDocuments: boolean = false;

  @observable public errorMessages: ErrorMessageWithAction[] = [];

  @observable
  public purchaseOrderId?: string;

  @observable
  public showPurchaseOrderDialog?: boolean;

  @computed
  public get claimAdjustment() {
    return this.claim.claimAdjustments.find(
      x => x.id === this.claimAdjustmentId
    )!;
  }

  //returns if the current claimAdjustment was the first one to be created.
  public get isInitialClaimAdjustment() {
    const claimAdjustmentDates = this.claim.claimAdjustments.map(
      x => x.createdDate!
    );
    if (claimAdjustmentDates && claimAdjustmentDates.length > 1) {
      return isDateEarliest(
        this.claimAdjustment?.createdDate!,
        claimAdjustmentDates
      );
    }
    return true;
  }

  // see if treatment request is being made AFTER an earlier such request is approved
  public get isSubsequentVisitRequest() {
    let requestedVisitClaimAdjustment = false;
    if (this.claimAdjustment.hasTreatmentDetails) {
      requestedVisitClaimAdjustment = !!this.claim.claimAdjustments.find(
        prevClaimAdj =>
          prevClaimAdj.id !== this.claimAdjustment.id &&
          prevClaimAdj.createdDate! < this.claimAdjustment.createdDate! &&
          prevClaimAdj.approvedVisits &&
          prevClaimAdj.approvedVisits > 0
      );
    }
    return requestedVisitClaimAdjustment;
  }

  public get is12MonthsSinceAccidentDate() {
    return isDateGapGreaterThanAYear(
      this.claim.accidentDate!,
      this.claimAdjustment?.createdDate!
    );
  }

  public get isHandSplintingOver300() {
    return !!(
      this.claimAdjustmentFormValues?.handSplintingCost &&
      Number(this.claimAdjustmentFormValues?.handSplintingCost) > 300
    );
  }

  public setStagingInfo = (stagingInfo: GetStagingInfoDto) => {
    this.stagingInfo = stagingInfo;
  };

  public set formChange(
    change: <F extends keyof ClaimAdjustmentFormValues>(
      name: F,
      value?: ClaimAdjustmentFormValues[F]
    ) => void
  ) {
    this._formChange = change;
  }

  @action public setClaimAdjustmentId = (id?: string) => {
    this.claimAdjustmentId = id;
  };

  @action public setOpenModal = (key?: ModalKeys) => {
    this.currentOpenModal = key;
  };

  @action
  public setSubmitOptions = (value: SubmitOptions) => {
    this._submitOptions = value;
  };

  //returns whether filled in form sections will be cleared based on untoggling of form sections
  public willUserLoseData = () => {
    if (!this.claimAdjustmentModalFormValues) return false;
    return (
      this.isAddDiagnosisSectionUnToggledAndDirty ||
      this.isChangeDiagnosisUnToggledAndDirty ||
      this.isChangeDiagnosisSideSectionUnToggledAndDirty ||
      this.isAddTreatmentDetailsSectionUnToggledAndDirty ||
      this.isCurrentStatusAndPrognosisSectionUnToggledAndDirty
    );
  };

  public isNonStandardCodeSelected = () => {
    return !!this.allSelectedDiagnoses?.some(x => x.aCC32Acceptable === false);
  };

  @action
  public setProviderById = async (providerId: string | undefined) => {
    if (providerId) {
      const provider = await this.root.practice.getProvider(providerId);
      const providerUser = await this.root.core.getUser(providerId);
      runInAction(() => {
        this.provider = provider;
        this.providerUser = providerUser;
        this.providerContactNumber = providerUser?.workPhone;
        if (this.provider.contractTypes?.length === 1) {
          this.providerContractType = this.provider.contractTypes[0];
        } else {
          this.providerContractType = undefined;
        }
      });
    } else {
      this.providerContractType = undefined;
      this.provider = undefined;
      this.providerUser = undefined;
      this.providerContactNumber = undefined;
    }
  };

  public handleSubmitSucceeded = async (
    onSubmitSucceededOverride?: (claimAdjustment: ClaimAdjustment) => void
  ) => {
    this.setOpenModal(undefined);

    if (onSubmitSucceededOverride) {
      return onSubmitSucceededOverride(this.claimAdjustment);
    }

    if (this.isSavingDocuments) {
      return;
    }

    if (this._submitOptions.redirect) {
      if (!this._submitOptions.create) {
        this.root.routing.push(
          {
            pathname: routes.claims.management.edit.path({
              id: this.claimAdjustment.claimId
            })
          },
          { openACC32Card: true }
        );
      } else if (this.claimAdjustmentId) {
        this.root.routing.push(
          routes.claimAdjustment.edit.path({
            id: this.claimAdjustmentId,
            claimId: this.claim.id
          })
        );
        this.root.routing.getStateWithFromQuery();
      }
    } else {
      this.root.notification.success("ACC32 saved.");
    }
  };

  public saveClaimAdjustment = async (
    values: ClaimAdjustmentModalValues,
    initialise?: (values: ClaimAdjustmentFormValues) => void
  ) => {
    if (this.claimAdjustment?.id) {
      await this.updateClaimAdjustmentModal(values);
      this.updateClaimAdjustmentWithModalValues(initialise);
    } else {
      await this.addClaimAdjustment(values);
    }
  };

  public addClaimAdjustment = async (values: ClaimAdjustmentModalValues) => {
    const user = this.claim.provider
      ? await this.root.core.getUser(this.claim.provider.id)
      : undefined;

    const newClaimAdjustment = await this.root.acc.addClaimAdjustment({
      ...getClaimAdjustmentModalDto(values, []),
      claimId: this.claim.id,
      claimNumber: this.claim.claimNumber,
      patientFirstName: this.claim.patient?.firstName,
      patientLastName: this.claim.patient?.lastName,
      patientAddress: this.claim.patient?.defaultAddress,
      patientDateOfBirth: this.claim.patient?.dto.birthday,
      providerFirstName: user?.firstName,
      providerLastName: user?.lastName,
      providerContactNumber: user?.workPhone,
      providerId: this.claim.providerId,
      hpiCpn: this.claim.provider?.cpn,
      providerContractType:
        this.claim.provider?.contractTypes?.length === 1
          ? this.claim.provider.contractTypes[0]
          : undefined,
      hpiOrganisationNumber: this.claim.dto.hpiOrganisationNumber
    });

    this.setClaimAdjustmentId(newClaimAdjustment.id);
    return newClaimAdjustment;
  };

  public isVerballyApprovedClaimAdjustmentReady = (
    claimAdjustmentFormValues: ClaimAdjustmentFormValues
  ) => {
    const providerDetailsFormValidator = new ProviderDetailsFormValidator();
    const treatmentDetailsFormValidator = new TreatmentDetailsFormValidator(
      this.claimAdjustment
    );

    const valid = isDeepEmptyUtils({
      ...providerDetailsFormValidator.validate(claimAdjustmentFormValues!),
      ...treatmentDetailsFormValidator.validate(claimAdjustmentFormValues!)
    });

    return valid;
  };

  public isClaimAdjustmentFormReady = (
    claimAdjustmentFormValues: ClaimAdjustmentFormValues
  ) => {
    const documentFormValidator = new DocumentSectionFormValidator();

    if (this.isDocumentError) {
      return isDeepEmptyUtils({
        ...documentFormValidator.validate(claimAdjustmentFormValues)
      });
    }

    const patientDetailsFormValidator = new PatientDetailsFormValidator();
    const providerDetailsFormValidator = new ProviderDetailsFormValidator();
    const treatmentDetailsFormValidator = new TreatmentDetailsFormValidator(
      this.claimAdjustment,
      this.root.practice
    );

    const addDiagnosisFormValidator = new AddDiagnosisFormValidator(
      this.claimAdjustment
    );

    const sideChangeFormValidator = new SideChangeFormValidator(
      this.claimAdjustment
    );

    const diagnosisChangeFormValidator = new DiagnosisChangeFormValidator(
      this.claimAdjustment
    );

    const currentStatusAndPrognosisFormValidator =
      new CurrentStatusAndPrognosisFormValidator();

    const patientProviderDeclarationFormValidator =
      new PatientProviderDeclarationFormValidator();

    const valid = isDeepEmptyUtils({
      ...patientDetailsFormValidator.validate(claimAdjustmentFormValues!),
      ...providerDetailsFormValidator.validate(claimAdjustmentFormValues!),
      ...treatmentDetailsFormValidator.validate(claimAdjustmentFormValues!),
      ...addDiagnosisFormValidator.validate(claimAdjustmentFormValues!),
      ...sideChangeFormValidator.validate(claimAdjustmentFormValues!),
      ...diagnosisChangeFormValidator.validate(claimAdjustmentFormValues!),
      ...patientProviderDeclarationFormValidator.validate(
        claimAdjustmentFormValues!
      ),
      ...documentFormValidator.validate(this.claimAdjustmentFormValues!)
    });

    if (
      !this.claim.primaryDiagnosis?.diagnosisSide ||
      !this.claim.primaryDiagnosis?.terminology?.code
    ) {
      return false;
    }

    if (this.nonStandard) {
      return (
        valid &&
        isDeepEmptyUtils({
          ...currentStatusAndPrognosisFormValidator.validate(
            claimAdjustmentFormValues!
          )
        })
      );
    }
    return valid;
  };

  public lodge = async () => {
    if (!this.isLodging) {
      this.isLodging = true;
      await this.root.acc.lodgeClaimAdjustment(this.claimAdjustment.id);
      this.root.notification.success("Acc32 has been lodged.");
    }
  };

  public patchClaimAdjustment = async (values: ClaimAdjustmentFormValues) => {
    const claimAdjustmentDto: Partial<ClaimAdjustmentDto> = {};
    if (!values.providerId) {
      claimAdjustmentDto.providerFirstName = undefined;
      claimAdjustmentDto.providerLastName = undefined;
      claimAdjustmentDto.hpiCpn = undefined;
    } else {
      if (this.providerUser) {
        claimAdjustmentDto.providerFirstName = this.providerUser?.firstName;
        claimAdjustmentDto.providerLastName = this.providerUser?.lastName;
        claimAdjustmentDto.hpiCpn = this.provider?.cpn;
      }
    }

    if (this.isCurrentStatusAndPrognosisSectionHiddenAndDirty) {
      claimAdjustmentDto.originalAccidentEvent = undefined;
      claimAdjustmentDto.currentConditionOrDiagnosis = undefined;
      claimAdjustmentDto.causeOfCurrentCondition = undefined;
      claimAdjustmentDto.causeOfNotResolvedCondition = undefined;
      claimAdjustmentDto.signAndSymptom = undefined;
      claimAdjustmentDto.proposedTreatment = undefined;
      claimAdjustmentDto.expectedTreatmentCompletionDate = undefined;
      claimAdjustmentDto.managementPlan = undefined;
    }

    if (
      this.isClaimAdjustmentFormReady(this.claimAdjustmentFormValues!) ||
      (this.claimAdjustment.verballyApproved &&
        this.isVerballyApprovedClaimAdjustmentReady(values))
    ) {
      claimAdjustmentDto.statusCode = ClaimAdjustmentStatus.Ready;
    } else {
      claimAdjustmentDto.statusCode = ClaimAdjustmentStatus.Incomplete;
    }

    if (this.claimAdjustment) {
      const patchDto = getAddClaimAdjustmentDto({
        claimAdjustmentFormValues: values,
        currentDiagnoses: getOriginalCurrentDiagnoses(
          this.claim,
          this.claimAdjustment
        ),
        claimAdjustment: this.claimAdjustment
      });

      const currentAdj = await this.root.acc.getClaimAdjustment(values.id!);
      patchDto.claimAdjustmentDocuments = await this.mergeDocumentsWithCurrent(
        currentAdj,
        patchDto
      );

      await this.root.acc.patchClaimAdjustment({
        id: values.id!,
        eTag: currentAdj.eTag,
        ...patchDto,
        ...claimAdjustmentDto
      });

      if (values.uploadDocuments) {
        const docsToUpload = values.uploadDocuments.filter(
          u =>
            !patchDto.claimAdjustmentDocuments?.some(
              d => d.stagingId === u.blobName && d.claimAdjustmentDocumentId
            )
        );
        await this.stageUploadDocuments(docsToUpload);
        await this.updateStagedDocuments();
      }

      if (this._submitOptions.lodge) {
        const uploadDocuments = this.claimAdjustmentFormValues?.uploadDocuments
          ? (this.claimAdjustmentFormValues.uploadDocuments.filter(
              x => x
            ) as ClaimAdjustmentDocumentUploadFormValue[])
          : [];

        const documents = this.claimAdjustmentFormValues?.documents
          ? (this.claimAdjustmentFormValues.documents.filter(
              x => x
            ) as ClaimAdjustmentDocumentFormValue[])
          : [];

        const allDocuments = [...uploadDocuments, ...documents];

        if (allDocuments.length && allDocuments.find(x => !x.documentId)) {
          runInAction(() => {
            this.isSavingDocuments = true;
          });
        } else {
          await this.lodge();
        }
      }
    }
  };

  public updateClaimAdjustmentWithResponseValues = async (
    claimAdjustmentResponseValues: ClaimAdjustmentResponseModalFormValues
  ) => {
    if (this.claimAdjustment) {
      const {
        responseNotes,
        responseDate,
        purchaseOrderNumber,
        approvedVisits: treatmentsApproved,
        handSplintingCostApproved,
        diagnosisKeys,
        diagnosisChangesKeys,
        sideChangesKeys,
        initialAssessmentsApproved,
        isDeclined,
        nailSimpleRemovalTreatmentsNeededApproved,
        abscessHematomaTreatmentsNeededApproved,
        nailRemovalResectionTreatmentsNeededApproved
      } = claimAdjustmentResponseValues;

      const {
        handSplintingCost,
        requestedTreatments: treatmentsRequested,
        initialAssessmentsNeeded: initialAssessmentRequested,
        nailSimpleRemovalTreatmentsNeeded,
        nailRemovalResectionTreatmentsNeeded,
        abscessHematomaTreatmentsNeeded
      } = this.claimAdjustment;

      const treatmentRequest: TreatmentRequestDto = {
        firstAdditionalTreatmentRequested:
          this.claimAdjustment.firstAdditionalTreatmentRequested,
        initialAssessmentsNeeded: this.claimAdjustment.initialAssessmentsNeeded,
        followUpTreatmentsNeeded: this.claimAdjustment.followUpTreatmentsNeeded,
        treatmentsRequested: this.claimAdjustment.treatmentsRequired,
        treatmentStartDate:
          this.claimAdjustment.treatmentRequest?.treatmentStartDate,
        initialAssessmentsApproved,
        treatmentsApproved,
        nailSimpleRemovalTreatmentsNeeded,
        abscessHematomaTreatmentsNeeded,
        nailRemovalResectionTreatmentsNeeded,
        nailSimpleRemovalTreatmentsNeededApproved,
        abscessHematomaTreatmentsNeededApproved,
        nailRemovalResectionTreatmentsNeededApproved,
        handSplintingCost: this.claimAdjustment.handSplintingCost,
        handSplintingCostApproved,
        claimAdjustmentRequestTypeCode:
          ClaimAdjustmentRequestType.TreatmentRequest,
        requestApproved: calculateTreatmentsRequestApproved([
          {
            request: handSplintingCost,
            approved: handSplintingCostApproved
          },
          {
            request: handSplintingCost,
            approved: handSplintingCostApproved
          },
          { request: treatmentsRequested, approved: treatmentsApproved },
          {
            request: !!initialAssessmentRequested,
            approved: !!initialAssessmentsApproved
          },
          {
            request: nailSimpleRemovalTreatmentsNeeded,
            approved: nailSimpleRemovalTreatmentsNeededApproved
          },
          {
            request: abscessHematomaTreatmentsNeeded,
            approved: abscessHematomaTreatmentsNeededApproved
          },
          {
            request: nailRemovalResectionTreatmentsNeeded,
            approved: nailRemovalResectionTreatmentsNeededApproved
          }
        ])
      };

      let claimAdjustmentRequests: ClaimAdjustmentRequests[] = [];
      const diagnosisSideAdditionsChanges = {
        additions: diagnosisKeys
          ? this.claimAdjustment.diagnosisAdditions.map(x => ({
              ...x,
              id: newGuid(),
              requestApproved:
                !!x.terminology?.diagnosisKey &&
                diagnosisKeys.includes(x.terminology.diagnosisKey)
            }))
          : this.claimAdjustment.diagnosisAdditions.map(x => ({
              ...x,
              id: newGuid()
            })),
        diagnosisChanges: diagnosisChangesKeys
          ? this.claimAdjustment.diagnosisChanges.map(x => ({
              ...x,
              requestApproved:
                !!x.newDiagnosis.terminology?.diagnosisKey &&
                diagnosisChangesKeys.includes(
                  x.newDiagnosis.terminology.diagnosisKey
                )
            }))
          : this.claimAdjustment.diagnosisChanges,
        sideChanges: sideChangesKeys
          ? this.claimAdjustment.sideChanges.map(x => ({
              ...x,
              requestApproved:
                !!x.oldDiagnosis.terminology?.diagnosisKey &&
                !!sideChangesKeys?.includes(
                  x.oldDiagnosis.terminology.diagnosisKey
                )
            }))
          : this.claimAdjustment.sideChanges,
        treatmentRequest,
        currentDiagnoses: getOriginalCurrentDiagnoses(
          this.claim,
          this.claimAdjustment
        )
      };

      if (!isDeclined) {
        claimAdjustmentRequests = toClaimAdjustmentRequestsDto(
          diagnosisSideAdditionsChanges
        );
      } else {
        claimAdjustmentRequests = toClaimAdjustmentRequestsDeclinedDto(
          diagnosisSideAdditionsChanges
        );
      }

      const claimAdjustmentStatus = calculateResponseStatus(
        claimAdjustmentRequests,
        isDeclined!
      );

      await this.root.acc.patchClaimAdjustment({
        id: this.claimAdjustment.id,
        eTag: this.claimAdjustment.eTag,
        responseNotes,
        purchaseOrderNumber,
        claimAdjustmentRequests,
        statusCode: claimAdjustmentStatus,
        responseDate: responseDate && DateTime.jsDateToISODate(responseDate)
      });

      this.setOpenModal(undefined);
    }
  };

  public cancel = () =>
    this.root.routing.goToFromState(
      routes.claims.management.edit.path({ id: this.claim.id })
    );

  @action
  public showCurrentStatusAndPrognosisSection = (isNonStandard: boolean) => {
    this.nonStandard =
      this.claimAdjustment?.isVoluntarySubmission ||
      this.isSubsequentVisitRequest ||
      this.is12MonthsSinceAccidentDate ||
      this.isHandSplintingOver300 ||
      isNonStandard;
    return this.nonStandard;
  };

  @computed
  get showTreatmentsOnOverview(): boolean {
    return !!(
      this.claimAdjustment.treatmentsRequired ||
      this.claimAdjustment.initialAssessmentsNeeded ||
      this.claimAdjustment.handSplintingCost ||
      this.claimAdjustment.followUpTreatmentsNeeded
    );
  }

  @observable
  public nonStandard = false;

  @observable
  documentResults: ClinicalDocument[] | undefined;

  @action
  setDocumentResults(documents: ClinicalDocument[]) {
    this.documentResults = documents;
  }

  public get userHasDiagnosePermission() {
    return this.root.core.hasPermissions(Permission.ClaimAdjustmentDiagnose);
  }

  @action clearErrorToRelodge = () => {
    //reset status for each document that is errored
    const documentFormValues = this.claimAdjustmentFormValues?.documents;
    const uploadDocumentFormValues =
      this.claimAdjustmentFormValues?.uploadDocuments;

    if (documentFormValues?.length) {
      this._formChange(
        claimAdjustmentFormNameOf("documents"),
        this.resetDocumentErrors(documentFormValues)
      );
    }

    if (uploadDocumentFormValues?.length) {
      this._formChange(
        claimAdjustmentFormNameOf("uploadDocuments"),
        this.resetDocumentErrors(uploadDocumentFormValues)
      );
    }

    //hide all document error bars witch actions
    this.errorMessages = this.errorMessages.map(x => ({
      ...x,
      hidden: !!x.action
    }));
  };

  @action
  public loadErrorMessages = () => {
    const docsWithErrorMessages = this.claimAdjustment.documents?.filter(
      x => x.errorMessages?.length
    );

    const docErrorMessagesWithAction: ErrorMessageWithAction[] | undefined =
      docsWithErrorMessages?.flatMap(document => {
        return document.errorMessages!.map(errorMessage => {
          if (
            this.root.core.hasPermissions(Permission.ClaimWrite) &&
            !document.isDocumentAcc32Form
          ) {
            return {
              ...errorMessage,
              action: this.clearErrorToRelodge,
              hidden: false
            };
          } else {
            return errorMessage;
          }
        });
      });

    if (docErrorMessagesWithAction) {
      this.errorMessages = docErrorMessagesWithAction;
      return;
    }

    this.errorMessages = this.claimAdjustment
      .errorMessages as ErrorMessageWithAction[];
  };

  public get isDocumentError() {
    return (
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.DocumentError
    );
  }
  public isFinaliseDisabled = (values: ClaimAdjustmentFormValues) => {
    return (
      !this.isVerballyApprovedClaimAdjustmentReady(values) ||
      //  verbally approved will go straight to accepted
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Accepted
    );
  };

  public isLodgeDisabled = (values: ClaimAdjustmentFormValues) => {
    return (
      !this.isClaimAdjustmentFormReady(values) ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Pending ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Accepted ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Partial ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Declined ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.Queued ||
      this.claimAdjustment.status?.code === ClaimAdjustmentStatus.SendEmail
    );
  };

  public getSelectedFormDiagnoses(
    claimCurrentDiagnoses: ClaimDiagnosisDto[],
    formValues: ClaimAdjustmentFormValues
  ): ClaimDiagnosisFormValues[] {
    const diagnosisReducer = (
      previousValue: ClaimDiagnosisFormValues[],
      currentValue: ClaimDiagnosisChangeFormValues
    ) => {
      if (currentValue.diagnosisCode && currentValue.diagnosisSide)
        previousValue.push({
          id: newGuid(),
          diagnosisKey: currentValue.diagnosisKey,
          diagnosisCode: currentValue.diagnosisCode,
          diagnosisSide: currentValue.diagnosisSide
        });
      return previousValue;
    };

    const currentDiagnoses = claimCurrentDiagnoses?.map(
      mapToClaimDiagnosisFormValues
    );

    const diagnosisChanges = formValues.diagnosisChanges?.reduce(
      diagnosisReducer,
      []
    );

    const sideChanges = formValues.sideChanges?.reduce(diagnosisReducer, []);
    const diagnosisAdditions = formValues.diagnosisAdditions?.reduce(
      diagnosisReducer,
      []
    );

    return getUniqueObjectsByKeys({
      array: [
        ...(currentDiagnoses ?? []),
        ...(diagnosisChanges ?? []),
        ...(sideChanges ?? []),
        ...(diagnosisAdditions ?? [])
      ],
      keys: ["diagnosisSide", "diagnosisCode"]
    });
  }

  public destroy = () => {
    if (this.options?.subscribeToClaimAdjustmentEvent) {
      this.root.acc.hub.unsubscribe(
        Entity.AccClaimAdjustment,
        this.updateDocuments
      );
    }
  };

  public getModalConfirmSettings = (claimAdjustmentFormDirty: boolean) => {
    let subText = "";

    if (claimAdjustmentFormDirty && this.willUserLoseData()) {
      subText =
        "You'll lose data by changing type, and the changes will be saved. \n Are you sure?";
    } else if (claimAdjustmentFormDirty) {
      subText = "Your ACC32 will be saved if you change type.";
    } else if (this.willUserLoseData()) {
      subText =
        "You'll lose data if you change the type of ACC32. Are you sure?";
    }

    return {
      showConfirm: !!subText,
      subText,
      saveClaimAdjustmentForm: claimAdjustmentFormDirty
    };
  };

  public getInitialValues = (): ClaimAdjustmentFormValues => {
    const initialValues = getClaimAdjustmentFormValues(
      this.claimAdjustment,
      this.claim
    );

    if (this.claimAdjustmentFormValues) {
      return {
        ...initialValues,
        uploadDocuments: this.claimAdjustmentFormValues.uploadDocuments?.map(
          x => ({ ...x, uploadStatus: FULFILLED })
        ),
        documents: this.claimAdjustmentFormValues.documents,
        diagnosisAdditions: this.claimAdjustmentFormValues.diagnosisAdditions,
        diagnosisChanges: this.claimAdjustmentFormValues.diagnosisChanges,
        sideChanges: this.claimAdjustmentFormValues.sideChanges
      };
    }
    return initialValues;
  };

  public setResponseModal = (id: string) => {
    this.setClaimAdjustmentId(id);
    this.setOpenModal(ModalKeys.AdjustmentResponseModal);
  };

  @action
  public setPurchaseOrderId = (id?: string) => {
    this.purchaseOrderId = id;
  };

  @action
  public setShowPurchaseOrderDialog = (value?: boolean) => {
    this.showPurchaseOrderDialog = value;
  };

  private mergeDocumentsWithCurrent = async (
    currentAdj: ClaimAdjustment,
    patchDto: AddClaimAdjustmentDto
  ): Promise<ClaimAdjustmentDocumentDto[]> => {
    return (
      patchDto.claimAdjustmentDocuments?.map(doc => {
        const currentDoc = currentAdj.documents?.find(
          c =>
            (c.stagingId && c.stagingId === doc.stagingId) ||
            (c.documentId && c.documentId === doc.documentId)
        );
        if (currentDoc) {
          const updatedDoc: ClaimAdjustmentDocumentDto = {
            ...doc,
            claimAdjustmentDocumentId: currentDoc.claimAdjustmentDocumentId,
            documentId: currentDoc.documentId,
            status: currentDoc.status,
            errorMessages:
              doc.status ===
                ClaimAdjustmentDocumentStatuses.SavedToCorrespondence &&
              doc.errorMessages
                ? []
                : currentDoc.errorMessages
          };
          return updatedDoc;
        }
        return doc;
      }) ?? []
    );
  };
}
