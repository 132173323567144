import { Spinner } from "@bps/fluent-ui";
import { ObservationType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { ClincialToolViewData } from "../clinical-tool-view/ClinicalToolViewDataFetcher.ts";
import { getClinicalToolViewData } from "../clinical-tool-view/utils.ts";
import { NPRSQuestions } from "./NPRSQuestions.tsx";

export interface NPRSQuestionnaireViewProps {
  encounterId: string;
}

export const NPRSQuestionnaireView: React.FunctionComponent<
  NPRSQuestionnaireViewProps
> = ({ encounterId }) => {
  return (
    <DataFetcher<ClincialToolViewData>
      fetch={getClinicalToolViewData(encounterId, ObservationType.NPRS)}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <ClinicalToolView
          title="Numeric pain rating scale"
          summary={summary}
          encounterId={encounterId}
          isConfidential={isConfidential}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          <NPRSQuestions questionnaire={questionnaire} answers={answers} />
        </ClinicalToolView>
      )}
    </DataFetcher>
  );
};
