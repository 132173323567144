import { observer } from "mobx-react-lite";

import {
  FontIcon,
  NoDataTile,
  Stack,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { useFormContext } from "@bps/utils";
import { SecurityRoleCode } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { LocationListStatusBadge } from "./LocationListStatusBadge.tsx";
import { LocationsListFilterState } from "./LocationsListFilter.tsx";

interface LocationsListProps {
  locations: OrgUnit[] | undefined;
  loading: boolean | undefined;
  error: Error | undefined;
}

export const LocationsList = observer((props: LocationsListProps) => {
  const { locations, loading, error } = props;

  const {
    state: { values: filter },
    actions: { reset }
  } = useFormContext<LocationsListFilterState>();

  const theme = useTheme();
  const { core, routing } = useStores();

  if (!loading && !error && locations && locations.length === 0) {
    const filterExists =
      !!filter.locationsSearch || !!filter.statusCodes?.length;
    return (
      <NoDataTile
        textProps={{
          text: !filterExists
            ? "Omni requires at least one location to function"
            : "0 matches found"
        }}
        linkProps={{
          hidden: !core.hasSecurityRoles(
            [SecurityRoleCode.PracInfoAdmin],
            "and"
          ),
          text: !filterExists ? "Add a new location" : "Clear filter",
          onClick: () =>
            !filterExists
              ? routing.push(routes.settings.practices.locations.new.pattern)
              : reset()
        }}
        showBoxShadow={false}
      />
    );
  }

  const sortedLocations = Array.from(locations ?? []).sort(
    (locationA, locationB) => {
      if (locationA.isInactive && !locationB.isInactive) return 1;
      if (!locationA.isInactive && locationB.isInactive) return -1;

      return locationA.name > locationB.name ? 1 : -1;
    }
  );

  const defaultColor = theme.palette.neutralTertiary;

  return (
    <Stack
      styles={{
        root: {
          maxHeight: 600,
          overflow: "auto"
        }
      }}
    >
      <ShimmeredDetailsList
        detailsListStyles={{
          root: {
            overflow: "hidden"
          }
        }}
        items={sortedLocations}
        enableShimmer={loading}
        errorMessage={error?.message}
        columns={[
          {
            name: "Status",
            key: "status",
            minWidth: 100,
            maxWidth: 150,
            onRender: (item: OrgUnit) => (
              <LocationListStatusBadge
                badgeColor={
                  item.isInactive ? TextBadgeColor.grey : TextBadgeColor.green
                }
                statusText={item.isInactive ? "Inactive" : "Active"}
                hasBorder
              />
            )
          },
          {
            key: "name",
            name: "Name",
            minWidth: 340,
            maxWidth: 340,
            onRender: (item: OrgUnit) => (
              <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                verticalAlign="center"
              >
                <FontIcon
                  iconName="LocationFill"
                  styles={{
                    root: {
                      color:
                        item.locationData?.appointmentBookMarkerCode ??
                        defaultColor
                    }
                  }}
                />
                <Navigate
                  to={routes.settings.practices.locations.edit.path({
                    id: item.id
                  })}
                >
                  {item.name}
                </Navigate>
                {item.isDefaultLocation && (
                  <LocationListStatusBadge
                    statusText="Default"
                    badgeColor={TextBadgeColor.blue}
                    badgeSize={TextBadgeSize.small}
                  />
                )}
              </Stack>
            )
          },
          {
            key: "createdLocationDate",
            name: "Date added",
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: OrgUnit) =>
              item.createdLocationDate?.toDayDefaultFormat()
          }
        ]}
      />
    </Stack>
  );
});
