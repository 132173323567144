import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Field, useField } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { toRecurrenceOptions } from "@libs/utils/calendar/calendar.utils.ts";
import {
  isAfter,
  isMonth,
  isOnDate,
  isWeek,
  isYear
} from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { WeekdaysDropdownField } from "@ui-components/form/WeekdaysDropdownField.tsx";

import { getFrequencyKeyTextOptions } from "../../utils.ts";
import { ScheduleFields } from "./ScheduleFields.types.ts";

enum AtWork {
  Yes = "1",
  No = "0"
}
const notAtWorkOrUndefined = (value: string) => !value || value === "0";

interface UserWorkingHoursFormFieldProps {
  name: string;
  index: number;
  onDelete: () => void;
}

export const UserWorkingHoursFormField: FC<UserWorkingHoursFormFieldProps> =
  observer(({ name, onDelete, index }) => {
    const { core, userExperience } = useStores();
    const { apptTypeBaseInterval } =
      userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

    const { fields } = useFieldArray<ScheduleFields>("userWorkingHours");

    const {
      input: { value: location }
    } = useField<ScheduleFields["location"]>(`${name}.location`, {
      subscription: { value: true }
    });

    const {
      input: { value: atWork }
    } = useField<ScheduleFields["atWork"]>(`${name}.atWork`, {
      subscription: { value: true }
    });

    const {
      input: { value: usePracticeBaseInterval }
    } = useField<ScheduleFields["usePracticeBaseInterval"]>(
      `${name}.usePracticeBaseInterval`,
      {
        subscription: { value: true }
      }
    );

    const isDisabled =
      core.hasMultipleActiveLocations && core.hasInactiveFlag(location);

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {core.hasMultipleActiveLocations && (
          <LocationsSelectField
            name={`${name}.location`}
            label="Location"
            placeholder="Select a location"
            disabled={isDisabled}
            multiSelect={false}
            allowNull={true}
            showInactiveLocation={isDisabled} // if the input is disabled then it might be because the location was inactive
            styles={{ root: { width: 410 } }}
          />
        )}
        <Fieldset horizontal>
          <DatePickerField
            label="Start schedule"
            placeholder="Start"
            disabled={isDisabled}
            name={`${name}.startDate`}
            styles={{ root: { width: 115 } }}
          />
          <OptionsSelectField
            name={`${name}.endScheduleType`}
            label="End schedule"
            options={[
              { key: "ondate", text: "On date" },
              { key: "after", text: "After" },
              { key: "never", text: "Never" }
            ]}
            disabled={isDisabled}
            styles={{ root: { width: 92 } }}
            hideSearchOption
            hideClearButton
          />
          <FieldCondition when={`${name}.endScheduleType`} is={isOnDate}>
            <DatePickerField
              name={`${name}.until`}
              label="&nbsp;"
              placeholder="Until"
              disabled={isDisabled}
            />
          </FieldCondition>
          <FieldCondition when={`${name}.endScheduleType`} is={isAfter}>
            <Field<ScheduleFields["count"]>
              name={`${name}.count`}
              subscription={{ value: true }}
            >
              {({ input: { value: count } }) => (
                <SpinNumberInputField
                  name={`${name}.count`}
                  label={count && count > 1 ? "Occurrences" : "Occurrence"}
                  disabled={isDisabled}
                  min={1}
                  max={99}
                  step={1}
                  styles={{ root: { width: 90 } }}
                />
              )}
            </Field>
          </FieldCondition>
          <OptionsSelectField
            name={`${name}.atWork`}
            label="At work"
            options={[
              { key: AtWork.Yes, text: "Yes" },
              { key: AtWork.No, text: "No" }
            ]}
            disabled={isDisabled}
            styles={{ root: { width: 67 } }}
            hideSearchOption
            hideClearButton
          />
          <FieldCondition when={`${name}.atWork`} is={AtWork.Yes}>
            <TimePickerField
              name={`${name}.startTime`}
              label="Start time"
              disabled={isDisabled || !core.hasUserSettingWritePermission}
              suggestionInterval={30}
              styles={{
                subComponentStyles: {
                  textField: { root: { width: 95 } }
                }
              }}
            />
            <TimePickerField
              name={`${name}.endTime`}
              label="End time"
              disabled={isDisabled || !core.hasUserSettingWritePermission}
              suggestionInterval={30}
              styles={{
                subComponentStyles: {
                  textField: { root: { width: 95 } }
                }
              }}
            />
          </FieldCondition>
          <FieldCondition when={`${name}.atWork`} is={notAtWorkOrUndefined}>
            <TextInputField
              name={`${name}.reasonForNotWorking`}
              label="Reason For Not Working"
              disabled={isDisabled}
            />
          </FieldCondition>
          <DeleteButton
            text="Remove"
            onClick={onDelete}
            disabled={!core.hasUserSettingWritePermission}
            styles={{ root: { marginTop: 29 } }}
          />
        </Fieldset>
        <Fieldset horizontal>
          <SpinNumberInputField
            name={`${name}.interval`}
            label="Recur every"
            disabled={isDisabled || !core.hasUserSettingWritePermission}
            min={1}
            max={99}
            step={1}
            styles={{ root: { width: 86 } }}
          />
          <Field<ScheduleFields["interval"]>
            name={`${name}.interval`}
            subscription={{ value: true }}
          >
            {({ input: { value: interval } }) => (
              <DropdownField
                name={`${name}.frequency`}
                label="&nbsp;"
                options={getFrequencyKeyTextOptions(interval)}
                disabled={isDisabled}
                styles={{ root: { width: 100 } }}
              />
            )}
          </Field>
          <FieldCondition when={`${name}.frequency`} is={isWeek}>
            <WeekdaysDropdownField
              name={`${name}.dayRecur`}
              label="On"
              disabled={isDisabled}
              styles={{ root: { width: 180 } }}
            />
          </FieldCondition>
          <FieldCondition when={`${name}.frequency`} is={isMonth}>
            <Field<ScheduleFields["startDate"]>
              name={`${name}.startDate`}
              subscription={{ value: true }}
            >
              {({ input: { value: startDate } }) => (
                <DropdownField
                  name={`${name}.monthYearRecurrence`}
                  label="On"
                  options={toRecurrenceOptions(
                    DateTime.fromJSDate(startDate),
                    Frequency.Month
                  )}
                  disabled={isDisabled}
                />
              )}
            </Field>
          </FieldCondition>
          <FieldCondition when={`${name}.frequency`} is={isYear}>
            <Field<ScheduleFields["startDate"]>
              name={`${name}.startDate`}
              subscription={{ value: true }}
            >
              {({ input: { value: startDate } }) => (
                <DropdownField
                  name={`${name}.monthYearRecurrence`}
                  label="On"
                  options={toRecurrenceOptions(
                    DateTime.fromJSDate(startDate),
                    Frequency.Year
                  )}
                  disabled={isDisabled}
                />
              )}
            </Field>
          </FieldCondition>
        </Fieldset>

        {core.hasPermissions(Permission.ApptTypeBaseIntervalAllowed) &&
          !notAtWorkOrUndefined(atWork) && (
            <Stack>
              <Heading labelPaddings>Appointment duration</Heading>
              <Stack horizontal tokens={{ childrenGap: 14 }}>
                <DataFetcher
                  fetch={async () => {
                    await userExperience.ref.apptTypeBaseIntervals.load();
                  }}
                >
                  {() => {
                    return (
                      <ComboBoxField
                        name={`${name}.defaultAppointmentDuration`}
                        options={
                          userExperience.ref.apptTypeBaseIntervals.keyTextValues
                        }
                        styles={{ root: { width: 150 } }}
                        disabled={!!usePracticeBaseInterval}
                        calloutProps={{
                          styles: {
                            calloutMain: {
                              width: 150
                            }
                          }
                        }}
                      />
                    );
                  }}
                </DataFetcher>

                <ToggleField
                  inlineLabel
                  name={`${name}.usePracticeBaseInterval`}
                  label="Use practice base interval"
                  automationAttribute="ux-default-interval-toggle"
                  styles={{
                    label: { cursor: "pointer", fontWeight: 400, marginLeft: 8 }
                  }}
                />

                <FieldSpy
                  name={`${name}.usePracticeBaseInterval`}
                  onChange={(value: boolean) => {
                    if (value && apptTypeBaseInterval) {
                      fields.update(index, {
                        ...fields.value[index],
                        defaultAppointmentDuration: apptTypeBaseInterval
                      });
                    }
                  }}
                />
              </Stack>
            </Stack>
          )}
      </Stack>
    );
  });
