import { FormApi } from "final-form";
import React, { useState } from "react";

import { Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ObservationDto,
  QuestionnaireDto,
  QuestionnaireType,
  Rand36ClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ConfidentialShield } from "../shared-components/ConfidentialShield.tsx";
import { Rand36FormValues } from "./Rand36Form.types.ts";
import { Rand36FormModel } from "./Rand36FormModel.tsx";
import { Rand36Questions } from "./Rand36Questions.tsx";
import { Rand36ScoreModal } from "./Rand36ScoreModal.tsx";

export interface Rand36FormProps {
  clinicalRecord: ClinicalRecord;
  questionnaire: QuestionnaireDto;
}

export const Rand36FormBase: React.FC<Rand36FormProps> = ({
  clinicalRecord,
  questionnaire
}) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const FORM_HEADING = "RAND 36";

  const onObservationSaved = (
    observations: ObservationDto[],
    rand36Data: Rand36ClinicalDataDto
  ) => {
    setObservations(observations);
    setRand36FinalData(rand36Data);
  };

  const [observations, setObservations] = useState<
    ObservationDto[] | undefined
  >([]);

  const [rand36FinalData, setRand36FinalData] =
    useState<Rand36ClinicalDataDto>();

  const [closeOnSubmit, setCloseOnSubmit] = useState<boolean>(false);

  const model = new Rand36FormModel(
    clinicalRecord,
    clinical,
    onObservationSaved
  );

  const nameOf = nameOfFactory<Rand36FormValues>();
  const onSubmitSucceeded = (
    values: Rand36FormValues,
    form: FormApi<Rand36FormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.RAND36
      });
      setCloseOnSubmit(false);
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      setCloseOnSubmit(true);
    }
  };

  return (
    <>
      <ClinicalSubmissionForm<Rand36FormValues>
        formName="rand36"
        onSubmit={form => model.onSubmit(form, questionnaire)}
        onSubmitSucceeded={onSubmitSucceeded}
        hideButtons
        initialValues={model.initialValues}
        readOnly={isViewOnlyOrDischarged}
        disableRoutePrompt
        heading={FORM_HEADING}
        onCancel={model.onCancel}
        promptOnCancel={true}
        disableButtonCondition={stashedData =>
          !stashedData?.dirtyAreas?.get("rand36")
        }
        lastUpdatedDate={getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.rand36
        )}
        lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
          clinicalRecord.clinicalData?.rand36
        )}
        confidentialShield={
          <ConfidentialShield name={nameOf("confidential")} />
        }
        noGap
        hasSeparator
      >
        <>
          <div
            ref={r => {
              if (r && !element) {
                setElement(r);
              }
            }}
          />
          <StashedClinicalDataFormSpy<Rand36FormValues>
            clinicalRecord={clinicalRecord}
            getData={model.getStashedClinicalData(questionnaire)}
            areasToObserve={{
              rand36: [ClinicalDataType.RAND36]
            }}
          />
          <Rand36Questions questionnaire={questionnaire} width={panelWidth} />
        </>
      </ClinicalSubmissionForm>

      {observations && observations.length > 0 && (
        <Rand36ScoreModal
          onClose={() => {
            if (closeOnSubmit) {
              model.onCancel();
            } else {
              setObservations(undefined);
            }
          }}
          observations={observations}
          answers={rand36FinalData?.items}
        />
      )}
    </>
  );
};

export const Rand36Form: React.FunctionComponent<
  Omit<Rand36FormProps, "questionnaire">
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.RAND36)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <Rand36FormBase
          clinicalRecord={clinicalRecord}
          questionnaire={questionnaire}
        />
      )}
    </DataFetcher>
  );
};
