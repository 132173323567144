import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { useField } from "react-final-form";

import { Heading, Separator, Stack } from "@bps/fluent-ui";
import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  Permission,
  SecurityRoleCode
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { InjuryDiagnosisAndAssistanceFormValidator } from "@modules/acc/screens/claim/validators/InjuryDiagnosisAndAssistanceFormValidator.tsx";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { DiagnosisFormFields } from "@modules/acc/screens/shared-components/DiagnosisFormFields.tsx";
import { OtherDiagnosesDataFetcher } from "@modules/acc/screens/shared-components/OtherDiagnosesDataFetcher.tsx";
import { PrimaryDiagnosisFormFields } from "@modules/acc/screens/shared-components/PrimaryDiagnosisFormFields.tsx";
import { InjuryDiagnosisAndAssistanceFormValues } from "@shared-types/acc/injury-diagnosis-and-assistance-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { getEmptyDiagnosis } from "../../utils.ts";
import { claimFormNameOf } from "../claim.utils.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { DiagnosisToggleHeader } from "./DiagnosisToggleHeader.tsx";

const injuryFields: (keyof InjuryDiagnosisAndAssistanceFormValues)[] = [
  "injuryComments",
  "assistanceRequired",
  "accContactProvider",
  "hasAdditionalDiagnoses",
  "gradualProcessInjury",
  "claimDiagnosis"
];

export const InjuryDiagnosisAndAssistanceFormSectionBase: FC = observer(() => {
  const { claim } = useContext(ClaimContext);
  const { selectedMenuItemId } = useCardFormLayoutContext();

  const { core } = useStores();

  const {
    input: { value: claimDiagnoses }
  } = useField(claimFormNameOf("claimDiagnosis"), {
    subscription: { value: true }
  });

  const {
    input: { value: primaryDiagnosis }
  } = useField(claimFormNameOf("primaryDiagnosis"), {
    subscription: { value: true }
  });

  const isDiagnosisEditable = core.hasPermissions(
    Permission.MedicalHistoryDiagnosisSyncAllowed
  )
    ? core.hasSecurityRoles(
        [SecurityRoleCode.ClinicalContributor, SecurityRoleCode.ClinicalAdmin],
        "or"
      )
    : true;

  return (
    <ClaimCard
      id={ClaimsCardIds.injuryDiagnosisAndAssistance}
      openOnRender={
        selectedMenuItemId === ClaimsCardIds.injuryDiagnosisAndAssistance
      }
      errorProps={{ fields: injuryFields }}
      heading={ClaimSectionHeadings.injuryDiagnosisAndAssistance}
      iconName="Medical"
      statusValidator={new InjuryDiagnosisAndAssistanceFormValidator()}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <OtherDiagnosesDataFetcher
          claim={claim}
          currentDiagnoses={claimDiagnoses}
          primaryDiagnosis={primaryDiagnosis}
        >
          {(filteredDiagnoses: ClaimDiagnosisDto[]) => (
            <>
              <PrimaryDiagnosisFormFields
                showStatusCol={false}
                diagnoses={[...claimDiagnoses, ...filteredDiagnoses]}
                name={claimFormNameOf("primaryDiagnosis")}
                disabled={!isDiagnosisEditable}
              />
              <>
                <DiagnosisToggleHeader
                  fieldName={claimFormNameOf("claimDiagnosis")}
                  toggleName={claimFormNameOf("hasAdditionalDiagnoses")}
                  emptyDiagnosis={getEmptyDiagnosis()}
                  disabled={!isDiagnosisEditable}
                />
                <FieldCondition
                  when={claimFormNameOf("hasAdditionalDiagnoses")}
                  is={true}
                >
                  <DiagnosisFormFields
                    diagnoses={[primaryDiagnosis, ...filteredDiagnoses]}
                    maxDiagnosisCount={9}
                    showStatusCol={false}
                    arrayFieldName={claimFormNameOf("claimDiagnosis")}
                    emptyDiagnosis={getEmptyDiagnosis}
                  />
                </FieldCondition>
              </>
            </>
          )}
        </OtherDiagnosesDataFetcher>
        <TextInputField
          label={ClaimFormLabels.injuryComments}
          name={claimFormNameOf("injuryComments")}
          multiline
          resizable={false}
          rows={4}
          maxLength={255}
          disabled={!isDiagnosisEditable}
        />
        <Separator />
        <Heading variant="section-heading-light">
          {ClaimFormLabels.injurySection}
        </Heading>
        <BoolButtonGroupField
          label={ClaimFormLabels.gradualProcessInjury}
          name={claimFormNameOf("gradualProcessInjury")}
        />
        <BoolButtonGroupField
          label={ClaimFormLabels.assistanceRequired}
          name={claimFormNameOf("assistanceRequired")}
        />
        <BoolButtonGroupField
          label={ClaimFormLabels.accContactProvider}
          name={claimFormNameOf("accContactProvider")}
        />
      </Stack>
    </ClaimCard>
  );
});

export const InjuryDiagnosisAndAssistanceFormSection = withFetch(
  x => [x.acc.ref.diagnosisSides.load()],
  InjuryDiagnosisAndAssistanceFormSectionBase
);
