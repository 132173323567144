import { BhbProviderDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class BhbProvider extends Model<BhbProviderDto> {
  get providerId() {
    return this.dto.id;
  }

  get locationId() {
    return this.dto.locationId;
  }

  get title() {
    return this.dto.title;
  }

  get firstName() {
    return this.dto.firstName;
  }

  get lastName() {
    return this.dto.lastName;
  }

  get displayName() {
    return this.dto.displayName;
  }

  get appointmentTypes() {
    return this.dto.appointmentTypes;
  }

  get language() {
    return this.dto.language;
  }

  get areaOfInterest() {
    return this.dto.areaOfInterest;
  }

  get genderIdentity() {
    return this.dto.genderIdentity;
  }

  get profileDetail() {
    return this.dto.profileDetail;
  }

  get photoUrl() {
    return this.dto.photoUrl;
  }

  get providerOnlineStatus() {
    return this.dto.providerOnlineStatus;
  }
}
