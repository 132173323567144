import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Card, DefaultButton, FontWeights } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useIntegrationScreenContext } from "../context/IntegrationScreenContext.ts";
import { ApiKeyModal } from "./ApiKeyModal.tsx";
import { PhysitrackProviderList } from "./PhysitrackProviderList.tsx";

export const PhysitrackProviderCard: React.FC = observer(() => {
  const { numberOfActivatedProviders, showPhysitrackProviderList } =
    useIntegrationScreenContext();

  const [showApiKeyModal, setShowApiKeyModal] = useState(false);

  if (!showPhysitrackProviderList) {
    return null;
  }
  return (
    <>
      <Card
        heading="Activated Providers"
        iconName="Group"
        button={
          <When permission={[Permission.ManageIntegrationsAllowed]}>
            <DefaultButton
              iconProps={{ iconName: "Add" }}
              text="Add provider"
              onClick={() => setShowApiKeyModal(true)}
            />
          </When>
        }
        subHeading={
          showPhysitrackProviderList && (
            <span>{`Number of providers activated: ${numberOfActivatedProviders}`}</span>
          )
        }
        styles={{
          heading: { fontSize: 20, fontWeight: FontWeights.semibold },
          subHeading: {
            fontSize: 12
          },
          subComponentStyles: {
            tile: {
              content: { padding: 24 }
            }
          }
        }}
      >
        <PhysitrackProviderList />
      </Card>
      {showApiKeyModal && (
        <ApiKeyModal
          onDismiss={() => {
            setShowApiKeyModal(false);
          }}
        />
      )}
    </>
  );
});
