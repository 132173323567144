import { getPatientEventsData } from "app-shell/NavBar/PatientAppointmentSearch/utils.ts";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext } from "react";

import {
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  Stack,
  Tile
} from "@bps/fluent-ui";
import { DateTime, useFormContext } from "@bps/utils";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { RunQueryOptions } from "@libs/utils/promise-observable/promise-observable.types.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import {
  ClinicalReminderManagementFilter,
  ClinicalReminderManagementFilterValues
} from "@modules/clinical/screens/patient-record/components/clinical-reminder/ClinicalReminderManagementFilterBase.tsx";
import { ClinicalRemindersTable } from "@modules/clinical/screens/patient-record/components/clinical-reminder/ClinicalRemindersTable.tsx";
import { ClinicalActivityTableRow } from "@modules/clinical/screens/patient-record/components/clinical-reminder/types/clinical-activity-table.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

export interface RemindersClinicalProps {
  setSelected?: (selected: ClinicalActivity[]) => void;
  showAsFollowUp?: boolean;
}
const RemindersClinicalListBase: FC<RemindersClinicalProps> = observer(
  props => {
    const {
      state: { values: filter }
    } = useFormContext<ClinicalReminderManagementFilterValues>();

    const { setSelectedClinicalActivities } = useContext(InboxScreenContext);

    const { clinical, practice, booking } = useStores();
    const { setSelected } = props;
    const getItems = useCallback(
      async (
        options?: RunQueryOptions
      ): Promise<QueryResult<ClinicalActivityTableRow>> => {
        const clinicalReminders = await clinical.getClinicalReminders({
          ...options,
          ...filter,
          remainingVisits: filter.remainingVisits,
          startDateTime: DateTime.jsDateToISODate(filter.dueDateStartDate),
          endDateTime: DateTime.jsDateToISODate(filter.dueDateEndDate),
          providerIds: filter.providerIds,
          statuses: filter.statuses,
          reasons: filter.reasons,
          patientIds: filter.patientIds,
          urgent: filter.urgent,
          clinicallySignificant: filter.clinicallySignificant,
          withNoRecordEntries: !props.showAsFollowUp,
          withRecordEntriesOnly: props.showAsFollowUp
        });

        if (clinicalReminders.results.length === 0)
          return { skip: 0, take: 25, results: [] };

        const patientIds = Array.from(
          new Set(clinicalReminders.results.map(activity => activity.patientId))
        );

        const patients = await practice.fetchContacts({
          filter: { types: [ContactType.Patient], ids: patientIds }
        });

        const contactEvents = await booking.getCalendarEvents({
          statuses: [CalendarEventStatus.Confirmed],
          attendees: patientIds
        });

        const patientEventsData = getPatientEventsData(
          contactEvents.results,
          patients.results
        );

        const nextAppointmentMap = new Map<string, string>();
        for (const patientData of patientEventsData) {
          if (patientData.upcomingAppointment) {
            const appointmentDate =
              patientData.upcomingAppointment.startDateTime.toDayDefaultFormat();
            nextAppointmentMap.set(patientData.patient.id, appointmentDate);
          } else {
            nextAppointmentMap.set(patientData.patient.id, "Not booked");
          }
        }

        // Get all of administration reminders by args.
        const activityIds = clinicalReminders.results.map(
          activity => activity.id
        );

        const reminderComms = await clinical.getClinicalReminderComms({
          activityIds
        });

        const remindersWithPatients = clinicalReminders.results
          .filter(x => !x.isCompleted) // Temporary filter until real filter
          .map((activity): ClinicalActivityTableRow => {
            const patient = practice.contactsMap.get(activity.patientId);
            const nextAppointmentDate =
              nextAppointmentMap.get(activity.patientId) || "Not booked";

            const clinicalReminderComm = reminderComms.find(
              x => x.clinicalActivityId === activity.id
            );
            return {
              id:
                activity.id +
                (clinicalReminderComm?.contactRecords.length ?? "x"), // The list will only refresh the items if the id has changed. As we want to update the item when they're successfully sent out, include the value in the Id.
              activity,
              patient,
              nextAppointmentDate,
              reminderContactRecords:
                clinicalReminderComm?.contactRecords ?? undefined
            };
          });

        return { ...clinicalReminders, results: remindersWithPatients };
      },
      [clinical, filter, props.showAsFollowUp, practice, booking]
    );

    return (
      <ScrollablePane
        styles={{
          root: {
            height: "100%",
            padding: 8,
            position: "relative"
          }
        }}
      >
        <ClinicalRemindersTable
          showAsFollowUp={props.showAsFollowUp}
          isMultiline={true}
          setSelected={rows => {
            const selectedActivities = rows.map(row => row.activity);
            setSelectedClinicalActivities(selectedActivities);
            if (setSelected) {
              setSelected(selectedActivities);
            }
          }}
        >
          {({ selection, columns, renderRow, renderDetailsHeader }) => (
            <InfiniteScrollList
              setKey="clinical-reminders-list"
              selectionMode={SelectionMode.multiple}
              stickyHeader
              getItems={getItems}
              columns={columns}
              onRenderRow={renderRow}
              onRenderDetailsHeader={renderDetailsHeader}
              selection={selection.current}
              selectionPreservedOnEmptyClick={true}
              refreshKey={clinical.lastUpdatedClinicalActivities}
              onRenderNoResults={() => (
                <NoDataTile
                  textProps={{ text: "No information to display" }}
                  linkProps={{ hidden: true }}
                  showBoxShadow={false}
                />
              )}
            />
          )}
        </ClinicalRemindersTable>
      </ScrollablePane>
    );
  }
);

const FilteredRemindersClinicalList: FC<RemindersClinicalProps> = props => (
  <Tile
    styles={{
      root: {
        flexGrow: 1,
        height: "100%",
        position: "relative"
      }
    }}
  >
    <Stack
      styles={{
        root: { height: "100%", overflowX: "auto" }
      }}
    >
      <ClinicalReminderManagementFilter hidden={props.showAsFollowUp}>
        <RemindersClinicalListBase {...props} />
      </ClinicalReminderManagementFilter>
    </Stack>
  </Tile>
);

export const RemindersClinicalList = withFetch(
  x => [x.clinical.loadActivityDescriptions()],
  FilteredRemindersClinicalList
);
