import { FontIcon, mergeStyles, Stack } from "@bps/fluent-ui";
import { DocumentTabStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { OverFlowDropDownOptions } from "./PatientPillOverFlowButton.tsx";

interface PatientPillOverflowButtonDocumentProps {
  item: OverFlowDropDownOptions;
  onDocumentClick?: (
    id: string,
    patientId: string,
    encounterId?: string
  ) => void;
}

export const PatientPillOverflowButtonDocument: React.FunctionComponent<
  PatientPillOverflowButtonDocumentProps
> = ({ item, onDocumentClick }) => {
  const { correspondence, practice } = useStores();
  const handleDocumentClick = (
    doc: ClinicalDocument | undefined,
    encounterId?: string
  ) => {
    if (!onDocumentClick || !doc) {
      return;
    }
    onDocumentClick(doc.id, doc.patientId, encounterId);
  };

  const getDocumentText = (doc: ClinicalDocument, patient: Contact) => {
    const docType = correspondence.ref.correspondenceTypes.get(doc.type!);
    return `${docType?.altText} - ${patient!.firstName} ${patient!.lastName}`;
  };

  const fetchDocumentPillData = async () => {
    let doc: ClinicalDocument | undefined;
    let patient: Contact | undefined;
    if (
      (item.documentTabStatus === DocumentTabStatus.New ||
        !item.documentTabStatus) &&
      !correspondence.correspondenceMap.get(item.key)
    ) {
      // If document is new and not in correspondence map the screen has been refreshed and we cannot show the document so remove from the openDocumentTabs array
      await correspondence.closeDocument(item.key);
      doc = undefined;
    } else {
      doc = await correspondence.getCorrespondenceByDocumentId(
        item.patientId!,
        item.key
      );
    }

    if (doc) {
      patient = await practice.getContact(doc.patientId);
    }

    return { doc, patient };
  };

  return (
    <DataFetcher key={`${item.key}-documentPill`} fetch={fetchDocumentPillData}>
      {data => {
        if (data.doc && data.patient) {
          return (
            <Stack
              horizontal
              onClick={() => handleDocumentClick(data.doc, item.encounterId)}
            >
              <FontIcon iconName="TextDocument" />
              <span
                className={mergeStyles({
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  paddingLeft: 8,
                  paddingRight: 8
                })}
              >
                {getDocumentText(data.doc, data.patient)}
              </span>
            </Stack>
          );
        }
        return undefined;
      }}
    </DataFetcher>
  );
};
