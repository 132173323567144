import { useContext } from "react";

import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { StatementItemsContext } from "../context/StatementItemsContext.ts";

export const StatementValueFilter: React.FC = () => {
  const { statementGenerateFormNameOf } = useContext(StatementItemsContext);
  return (
    <Fieldset
      horizontal
      horizontalAlign="space-between"
      frame
      styles={{ root: { width: 214 } }}
    >
      <SpinNumberInputField
        name={statementGenerateFormNameOf("owingOver")}
        label="Owing over"
        prefix="$"
        step={0.01}
        precision={2}
        styles={{ arrowButtonsContainer: { display: "none" } }}
        fieldItemStyles={{
          root: { width: 95 }
        }}
      />
      <SpinNumberInputField
        name={statementGenerateFormNameOf("creditOver")}
        label="Credit over"
        prefix="$"
        step={0.01}
        styles={{ arrowButtonsContainer: { display: "none" } }}
        precision={2}
        fieldItemStyles={{
          root: { width: 95 }
        }}
      />
    </Fieldset>
  );
};
