import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { withoutFields, withPaging } from "@libs/api/utils/paging.utils.ts";
import {
  AddCommPreferenceDto,
  AddContactPreferencesDto,
  AddOutboundCommTemplateDto,
  CommPreferenceDto,
  ContactPreferencesDto,
  MessageCreditBalanceDto,
  OutboundCommContentDto,
  OutboundCommContentGetByArgs,
  OutboundCommDto,
  OutboundCommTemplateDto,
  OutboundCommTemplateMasterDto,
  OutboundCommTemplateReplyActionDto,
  PatchCommPreferenceDto,
  PatchOutboundCommTemplateDto,
  RenderOutboundCommTemplateDto,
  ReplyActionsArgs,
  TemplateRenderResponseDto
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { ICommsGateway } from "@libs/gateways/comms/CommsGateway.interface.ts";

export class CommsGateway implements ICommsGateway {
  constructor(private api: AxiosInstance) {}

  async getAllOutboundCommTemplates(): Promise<OutboundCommTemplateDto[]> {
    const result = await this.api
      .get<OutboundCommTemplateDto[]>("/campaign")
      .then(x => x.data);
    return result;
  }

  async addOutboundCommTemplate(
    data: AddOutboundCommTemplateDto
  ): Promise<OutboundCommTemplateDto> {
    const x = await this.api.post<OutboundCommTemplateDto>("/campaign", data);
    return x.data;
  }

  async patchOutboundCommTemplate(
    patchOutboundCommTemplateDto: PatchOutboundCommTemplateDto
  ): Promise<OutboundCommTemplateDto> {
    const { id, eTag, ...data } = patchOutboundCommTemplateDto;
    const x = await this.api.patch<OutboundCommTemplateDto>(
      `/campaign/${id}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return x.data;
  }

  async renderOutboundCommTemplate(
    data: RenderOutboundCommTemplateDto
  ): Promise<TemplateRenderResponseDto> {
    const x = await this.api.post<TemplateRenderResponseDto>("/render", data);
    return x.data;
  }

  async addOutboundCommTemplateReplyActions(
    id: string,
    args: ReplyActionsArgs
  ): Promise<OutboundCommTemplateReplyActionDto[]> {
    const x = await this.api.post<OutboundCommTemplateReplyActionDto[]>(
      `/campaign/${id}/replyActions`,
      args
    );
    return x.data;
  }

  async getAllMasterTemplates() {
    const x = await this.api.get<OutboundCommTemplateMasterDto[]>(
      "/outboundCommTemplateMaster"
    );
    return x.data;
  }

  async addCommPreference(
    data: AddCommPreferenceDto
  ): Promise<CommPreferenceDto> {
    const x = await this.api.post<CommPreferenceDto>("/commsPreferences", data);
    return x.data;
  }

  async patchCommPreference(
    patchCommPreferenceDto: PatchCommPreferenceDto
  ): Promise<CommPreferenceDto> {
    const { id, eTag, ...data } = patchCommPreferenceDto;
    const x = await this.api.patch<CommPreferenceDto>(
      `/commsPreferences/${id}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return x.data;
  }

  async getContactPreferences() {
    const x = await this.api.get<ContactPreferencesDto[]>(
      "/contactPreferences"
    );
    return x.data;
  }

  async getContactPreference(id: string): Promise<ContactPreferencesDto> {
    const x = await this.api.get<ContactPreferencesDto>(
      `/contactPreferences/${id}`
    );
    return x.data;
  }

  async addContactPreferences(
    data: AddContactPreferencesDto
  ): Promise<ContactPreferencesDto> {
    const x = await this.api.post<ContactPreferencesDto>(
      "/contactPreferences",
      data
    );
    return x.data;
  }

  async patchContactPreferences(
    patchContactPreferencesDto: PatchCommPreferenceDto
  ): Promise<ContactPreferencesDto> {
    const { id, eTag, ...data } = patchContactPreferencesDto;
    const x = await this.api.patch<ContactPreferencesDto>(
      `/contactPreferences/${id}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return x.data;
  }

  async getCommPreferences() {
    const x = await this.api.get<CommPreferenceDto[]>("/commsPreferences");
    return x.data;
  }

  async getOutboundComm(id: string): Promise<OutboundCommDto> {
    const x = await this.api.get<OutboundCommDto>(`/outboundComms/${id}`);
    return x.data;
  }

  async getMessageCreditBalance(): Promise<MessageCreditBalanceDto> {
    const x = await this.api.get<MessageCreditBalanceDto>(
      "/messageCreditsBalance"
    );
    return x.data;
  }

  private async getRef<T extends RefDataDto = RefDataDto>(name: string) {
    const x = await this.api.get<T[]>(
      `/ref/${name}?${withoutFields(excludedRefDataFields)}`
    );
    return x.data;
  }

  getOutboundCommChannels() {
    return this.getRef("outboundCommChannels");
  }

  getOutboundCommTypes() {
    return this.getRef("outboundCommTypes");
  }

  async deleteOutboundCommTemplate(id: string): Promise<void> {
    return this.api.delete(`/campaign/${id}`);
  }

  async getOutboundCommContents(
    args: OutboundCommContentGetByArgs & PagingOptions
  ): Promise<OutboundCommContentDto[]> {
    const queryString = withPaging(args);

    return this.api
      .get<OutboundCommContentDto[]>(
        `/outboundCommContent/byOutboundCommIds?${stringify(queryString)}`
      )
      .then(x => x.data);
  }
}
