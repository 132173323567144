import {
  action,
  computed,
  observable,
  ObservableMap,
  runInAction,
  values
} from "mobx";

import { NotFoundError, RequestError } from "@bps/http-client";
import { compareDatesPredicate, DateTime, isDefined } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import {
  ActivityDescriptionArgs,
  ActivityDescriptionDto,
  AddAmendmentDto,
  AddEncounterDto,
  AmendmentDto,
  ClinicalActivityDto,
  ClinicalActivityUnlockArgs,
  ClinicalDataType,
  ClinicalNoteSectionElement,
  ClinicalRecordDto,
  ClinicalReminderCommCreateBatchArgs,
  ClinicalReminderCommDto,
  ClinicalReminderCommGetByArgs,
  ClinicalTaskDto,
  ClinicalTasksUnlockArgs,
  CodedText,
  EncounterClinicalDataDto,
  EncounterClinicalNoteDto,
  EncounterDto,
  EncounterSearchDto,
  EncounterStatus,
  EncounterTimerDto,
  EncounterTimerOperationDto,
  EncounterType,
  EocInitialConsultDateAndVisitCount,
  EpisodeOfCareDto,
  GetClinicalActivitiesDto,
  GetClinicalRemindersDto,
  GetClinicalTasksDto,
  GetEncounterClinicalDataArgs,
  GetEncountersDto,
  GetEpisodeOfCareClinicalDataDto,
  GetEpisodesOfCareClinicalDataDto,
  GetReactionWarningsArgs,
  GetStructuredNoteDto,
  InteractionDto,
  InteractionsFilter,
  ObservationDto,
  ObservationsFilter,
  PatchClinicalActivitiesPreferenceDto,
  PatchEncounterDto,
  QuestionnaireDto,
  SendClinicalDocumentArgs,
  SpecialTestDto,
  SubstanceUseTypeDto,
  SubstanceUseTypeFilter,
  TerminologyConceptDto,
  TerminologyDto,
  TerminologyLookupRequest,
  TerminologySearchRequest
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.interface.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { getOrThrow } from "@libs/utils/utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { ClinicalUi } from "./ClinicalUi.ts";
import { ActivityDescription } from "./models/ActivityDescription.ts";
import { Amendment } from "./models/Amendments.ts";
import { ClinicalActivitiesPreference } from "./models/ClinicalActivitiesPreference.ts";
import { ClinicalActivity } from "./models/ClinicalActivity.ts";
import { ClinicalRecord } from "./models/ClinicalRecord.ts";
import { ClinicalTask } from "./models/ClinicalTask.ts";
import { Condition } from "./models/Condition.ts";
import { Encounter } from "./models/Encounter.ts";
import { Interaction } from "./models/Interaction.ts";
import { Observation } from "./models/Observation.ts";
import { ClinicalRef } from "./ref/ClinicalRef.ts";
import {
  clinicalRecordMapKey,
  includeEncounterId
} from "./utils/clinical.utils.ts";

export class ClinicalStore implements Store<ClinicalStore, ClinicalRef> {
  constructor(
    private gateway: IClinicalGateway,
    public hub: IHubGateway
  ) {
    this.ref = new ClinicalRef(this.gateway);
  }

  root: IRootStore;
  ref: ClinicalRef;
  ui: ClinicalUi;

  private get core() {
    return this.root.core;
  }

  private get practice() {
    return this.root.practice;
  }

  private get routing() {
    return this.root.routing;
  }

  private get notification() {
    return this.root.notification;
  }

  @observable
  clinicalActivitiesPreference?: ClinicalActivitiesPreference;

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.Interaction, this.onInteractionUpdateEvent);
    this.hub.onEntityEvent(
      Entity.EpisodeOfCare,
      this.onEpisodeOfCareUpdateEvent
    );
    this.hub.onEntityEvent(Entity.Encounter, this.onEncounterUpdateEvent);
    this.hub.onEntityEvent(Entity.Amendment, this.onAmendmentCreateEvent);
    this.hub.onEntityEvent(
      Entity.CorrespondenceMetaDataUploaded,
      this.onCorrespondenceMetaDataUploadedEvent
    );
    this.hub.onEntityEvent(Entity.UserTask, this.onUserTaskCreatedEvent);

    this.practice.ui.onContactError((id, error) => {
      if (error instanceof NotFoundError || error instanceof RequestError) {
        // need to get all encounters for this contact and close
        if (this.ui.openedRecordIds.some(x => x.patientId === id)) {
          const openRecordsForContact = this.ui.openedRecordIds.filter(
            x => x.patientId === id
          );
          openRecordsForContact.map(x =>
            this.closeRecord(x.patientId, x.encounterId)
          );
        }
      }
    });

    this.hub.onEntityEvent(
      Entity.ClinicalReminderComm,
      this.onClinicalReminderCommUpdate
    );

    this.ui = new ClinicalUi(this, this.core);
  }

  multipleEncountersRecordsMap = observable.map<string, ClinicalRecord>();
  observationMap = observable.map<string, Observation>();
  questionnaireDataMap = observable.map<string, QuestionnaireDto>();
  specialTestDataMap = observable.map<string, SpecialTestDto>();
  amendmentMap = observable.map<string, Amendment>();
  interactionMap = observable.map<string, Interaction>();
  terminologyMap = observable.map<string, TerminologyDto>();
  encounterPageSize: number = 25;
  clinicalTasksMap = observable.map<string, ClinicalTask>();
  clinicalActivitiesMap = observable.map<string, ClinicalActivity>();
  clinicalRemindersMap = observable.map<string, ClinicalActivity>();
  activityDescriptionMap = observable.map<string, ActivityDescription>();
  conditionsMap = observable.map<string, Condition>();

  @observable
  encountersDeletedElsewhere: string[] = [];
  encounterMap = observable.map<string, Encounter>();
  clinicalDataMap = observable.map<string, EncounterClinicalDataDto>();

  @observable
  recentlyFinalisedEncounterId: string | undefined;

  @observable
  lastUpdatedClinicalTasks: string;

  @observable
  lastUpdatedClinicalActivities: string;

  @computed
  get clinicalTasksMapValues() {
    return Array.from(this.clinicalTasksMap.values());
  }

  @computed
  get activityDescriptionMapValues() {
    return Array.from(this.activityDescriptionMap.values());
  }
  @computed
  get clinicalRemindersMapValues() {
    return Array.from(this.clinicalRemindersMap.values());
  }

  @computed
  get activeRecord(): string | undefined {
    const patientId = this.routing.match(routes.records.record, true)?.params
      .id;
    // for new multiple encounters patientId will no longer be unique so we have the encounter as well

    const encounterId = this.routing.match(routes.records.encounter)?.params
      .encounterId;
    if (!patientId) {
      return undefined;
    }
    return encounterId ? `${patientId}_${encounterId}` : patientId;
  }

  @computed
  get activeRecordPatientId(): string | undefined {
    return this.activeRecord?.split("_")[0];
  }

  @computed
  get activeRecordEncounterId(): string | undefined {
    const splitKey = this.activeRecord?.split("_");
    if (splitKey && splitKey.length > 1) {
      const encounterId = splitKey[1];
      return encounterId;
    }

    return undefined;
  }

  setActiveViewOnlyRecord = (id: string | undefined) => {
    if (id)
      this.routing.push({
        pathname: routes.records.recordView.path({ id })
      });
  };

  set activeRecord(data: {
    patientId: string | undefined;
    encounterId: string | undefined;
  }) {
    const id = data.patientId;
    const encounterId = data.encounterId ?? "";
    if (id) {
      const clinicalRecord = this.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(id, encounterId)
      );

      if (
        !clinicalRecord ||
        clinicalRecord?.openEncounter?.type === EncounterType.Consultation ||
        clinicalRecord?.openEncounter?.type ===
          EncounterType.PhoneConsultation ||
        clinicalRecord?.openEncounter?.type ===
          EncounterType.VideoConsultation ||
        (clinicalRecord && !clinicalRecord.openEncounter)
      ) {
        this.routing.push({
          pathname: routes.records.encounter.path({ id, encounterId })
        });
      } else if (
        clinicalRecord.openEncounter?.type === EncounterType.RecordUpdate
      ) {
        this.routing.push({
          pathname: routes.records.recordUpdate.path({ id })
        });
      }
    } else {
      this.routing.goToFromState(routes.calendarEvents.basePath.pattern);
    }
  }

  @computed
  get activeRecordIsView(): boolean {
    return (
      !!routes.records.recordView.match(this.root.routing.location.pathname) ||
      !!routes.records.appointmentView.match(
        this.root.routing.location.pathname
      ) ||
      !!routes.records.encounterView.match(
        this.root.routing.location.pathname
      ) ||
      !this.root.core.hasPermissions(Permission.EncounterWrite)
    );
  }

  @computed
  get activeRecordUpdate(): boolean {
    return !!routes.records.recordUpdate.match(
      this.root.routing.location.pathname
    );
  }

  @computed
  get activeCalendarEvent(): string | undefined {
    return this.routing.match(routes.records.appointment)?.params
      .calendarEventId;
  }

  removeDeletedElsewhereEncounter = async (
    encounterId: string,
    clinicalRecord: ClinicalRecord
  ) => {
    if (clinicalRecord.openEncounter) {
      const doomed = this.encounterMap.get(encounterId);
      await clinicalRecord.close(encounterId);

      const hasDeletedEncounterId = this.encountersDeletedElsewhere.find(
        x => x === encounterId
      );
      if (hasDeletedEncounterId) {
        runInAction(() => {
          this.encountersDeletedElsewhere =
            this.encountersDeletedElsewhere.filter(
              x => x !== hasDeletedEncounterId
            );
        });
      }

      if (
        doomed &&
        this.multipleEncountersRecordsMap.has(
          clinicalRecordMapKey(doomed.patientId, encounterId)
        )
      ) {
        runInAction(() => {
          this.multipleEncountersRecordsMap.delete(
            clinicalRecordMapKey(doomed.patientId, encounterId)
          );
        });
      }

      if (doomed)
        runInAction(() => {
          this.encounterMap.delete(doomed.id);
        });
    }
  };

  @computed
  get encounters() {
    return values(this.encounterMap);
  }

  @computed
  get amendments() {
    return values(this.amendmentMap);
  }

  @computed
  get closedEncounters() {
    return values(this.encounterMap).filter(x => x.isClosed);
  }

  @computed
  get interactions() {
    return values(this.interactionMap);
  }

  @sharePendingPromise()
  async getReactionWarnings(args: GetReactionWarningsArgs) {
    return this.gateway.getReactionWarnings(args);
  }

  @action
  async closeAllRecords() {
    const tabs = Array.from(this.ui.openedRecordIds);

    tabs.forEach(tab => {
      const record = this.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(tab.patientId, tab.encounterId)
      );

      if (
        !tab.encounterId ||
        (tab.encounterId &&
          record?.isSafeToClose &&
          !record.stashedClinicalData?.hasSomeDirtyAreas &&
          !record.areNotesEntered)
      ) {
        this.closeRecord(tab.patientId, tab.encounterId);
      }
    });
  }

  public closeRecordTab = async (recordId: string, encounterId?: string) => {
    const recordIndex = this.ui.openedRecordIds.findIndex(
      x => x.patientId === recordId && x.encounterId === encounterId
    );

    runInAction(() => {
      this.ui.openedRecordIds.splice(recordIndex, 1);
    });
  };

  public closeActiveRecord = async (recordId: string, encounterId?: string) => {
    const activeRecord = clinicalRecordMapKey(recordId, encounterId);
    // close active record and  take the provider to the appointment book
    this.activeRecord = {
      patientId: undefined,
      encounterId: undefined
    };

    this.ui.tabs.removePatientTabs(recordId, encounterId);

    // check if the record has been successfully deleted from opened records
    const isNotInOpenedRecordIds: boolean = !this.ui.openedRecordIds.some(
      x => x.patientId === recordId
    );

    if (
      isNotInOpenedRecordIds &&
      this.multipleEncountersRecordsMap.has(activeRecord)
    ) {
      // ⚠️⚠️⚠️ There is a rendering delay between opening and closing clinical tabs. The app may be
      // crashed since a clinical record has been removed already but screen has not been re-rendered properly.
      // This setTimeout will help to postpone the clinical record deletion. PBI#29941. Ilya S.
      setTimeout(() => {
        runInAction(() => {
          this.multipleEncountersRecordsMap.delete(activeRecord);
        });
      }, 1);
    }
  };

  public closeRecord = async (
    recordId: string,
    encounterId?: string,
    closeActiveRecord?: boolean
  ) => {
    await this.closeRecordTab(recordId, encounterId);
    const activeRecord = clinicalRecordMapKey(recordId, encounterId);

    if (closeActiveRecord || this.activeRecord === activeRecord) {
      await this.closeActiveRecord(recordId, encounterId);
    }
    await this.saveToUserStorage(
      UserStorageKeys.OpenPatientTabs,
      this.ui.openedRecordIds
    );
  };

  @sharePendingPromise()
  async getRecord(
    id: string,
    options?: {
      ignoreCache?: boolean;
      setNextActiveOnError?: boolean;
      encounterId?: string;
    }
  ) {
    if (!options?.ignoreCache) {
      const record = this.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(id, options?.encounterId)
      );

      if (!!record) {
        return record;
      }
    }

    try {
      const recordDto = await this.gateway.getClinicalRecord(id);

      const clinicalRecord = this.mergeEncounterClinicalRecord(
        recordDto,
        options?.encounterId
      );

      await this.fetchEncounters({
        patientId: id,
        userIds: [this.core.userId],
        statuses: [EncounterStatus.Open, EncounterStatus.Committed],
        take: this.encounterPageSize,
        total: true
      });

      return clinicalRecord;
    } catch (error) {
      const patientIdError =
        Array.isArray(error?.errors?.patientId) &&
        error.errors.patientId.filter(
          (x: string) => x === "Patient Id is required."
        ).length > 0;

      if (
        error instanceof NotFoundError ||
        error instanceof RequestError ||
        patientIdError
      ) {
        await this.closeRecord(id, includeEncounterId(options?.encounterId));
        throw NotFoundError;
      }
      throw error;
    }
  }

  getTerminologyFromMap = (key: string): TerminologyDto | undefined =>
    this.terminologyMap.get(key);

  getTerminologyKey = (key: string, text: string): string => `${key}${text}`;

  private addTerminologyMap = (terminology: TerminologyDto) => {
    const itemKey = this.getTerminologyKey(terminology.code, terminology.text);
    return (
      !this.terminologyMap.get(terminology.code) &&
      runInAction(() => {
        this.terminologyMap.set(itemKey, terminology);
      })
    );
  };

  async fetchInteractions(
    filter: InteractionsFilter & PagingOptions = {}
  ): Promise<QueryResult<Interaction>> {
    const dtoResult = await this.gateway.getInteractions(filter);
    const { results, ...rest } = dtoResult;
    await this.loadInteractionsUsers(results);
    return observable({
      results: results.map(this.mergeInteraction),
      ...rest
    });
  }

  async fetchEncounters(
    filter: GetEncountersDto
  ): Promise<QueryResult<Encounter>> {
    const dtoResult = await this.gateway.getEncounters(filter);
    const { results, ...rest } = dtoResult;

    return observable({
      results: results.map(this.mergeEncounter),
      ...rest
    });
  }

  async fetchEncounterSearch(
    filter: GetEncountersDto
  ): Promise<QueryResult<Encounter>> {
    const dtoResult = await this.gateway.getEncounterSearch(filter);
    const { results, ...rest } = dtoResult;
    const promises: Promise<void>[] = [];
    const getData = async (x: EncounterSearchDto) => {
      const cd = await this.getEncounterClinicalData(
        { encounterId: x.id },
        { ignoreCache: true }
      );
      runInAction(() => {
        this.clinicalDataMap.set(x.id, {
          ...cd,
          reasonForVisit: x.reasonForVisit
        });
      });
    };
    results.forEach(x => {
      const encounterClinicalData = this.clinicalDataMap.get(x.id);
      if (encounterClinicalData === undefined) {
        promises.push(getData(x));
      } else {
        runInAction(() => {
          this.clinicalDataMap.set(x.id, {
            ...encounterClinicalData,
            reasonForVisit: x.reasonForVisit
          });
        });
      }
    });
    await Promise.all(promises);
    const encounters = results.map(
      x =>
        ({
          id: x.id,
          eTag: x.eTag,
          status: x.status,
          userId: x.userId,
          patientId: x.patientId,
          orgUnitId: x.orgUnitId,
          visitDateTime: x.visitDateTime,
          changeLog: x.changeLog,
          encounterType: x.encounterType,
          encounterLocation: x.encounterLocation
        }) as EncounterDto
    );

    return observable({
      results: encounters.map(this.mergeEncounter),
      ...rest
    });
  }

  async fetchPatientPastReasonForVisits(
    filter: GetEncountersDto
  ): Promise<QueryResult<CodedText>> {
    const dtoResult = await this.gateway.getPatientPastReasonForVisits(filter);
    const { results, ...rest } = dtoResult;

    return observable({
      results,
      ...rest
    });
  }

  async fetchPatientPastProviders(patientId: string) {
    return await this.gateway.getPatientProviders(patientId);
  }

  /**
   * Load all users from the interactions result that are not already
   * part of the store
   */
  private loadInteractionsUsers = async (results: InteractionDto[]) => {
    const userIds = results.map(x => x.userId).filter(x => x !== undefined);
    await Promise.all(userIds.map(id => this.core.getUser(id)));
  };

  @sharePendingPromise()
  async getInteraction(
    id: string,
    { ignoreCache }: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    return await ((!ignoreCache && this.interactionMap.get(id)) ||
      this.gateway.getInteraction(id).then(this.mergeInteraction));
  }

  async addAmendment(data: AddAmendmentDto) {
    try {
      await this.gateway.addAmendment(data).then(this.mergeAmendments);
      this.notification.success("Amendment added to encounter notes.");
    } catch (error) {
      this.notification.error(error);
    }
    return;
  }

  @action
  async getAmendments(encounterId: string) {
    try {
      await this.fetchAmendments(encounterId);
    } catch (error) {
      throw Error(error.message);
    }
    return;
  }

  async fetchAmendments(encounterId: string): Promise<Amendment[]> {
    const dtoResult = await this.gateway.getAmendments(encounterId);
    return dtoResult.map(this.mergeAmendments);
  }

  @sharePendingPromise()
  getEncounter(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const encounter = this.encounterMap.get(id);
      if (encounter) {
        return Promise.resolve(encounter);
      }
    }

    return this.gateway.getEncounter(id).then(this.mergeEncounter);
  }

  async callEncounterTimer(
    dto: EncounterTimerOperationDto
  ): Promise<EncounterTimerOperationDto> {
    const timerDto = await this.gateway.updateEncounterTimer(dto);
    return timerDto;
  }

  @sharePendingPromise()
  async getEncounterTimer(id: string): Promise<EncounterTimerDto> {
    const timerDto = await this.gateway.getEncounterTimer(id);
    return timerDto;
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getEncounterClinicalData(
    args: GetEncounterClinicalDataArgs,
    options: { ignoreCache: boolean } = {
      ignoreCache: false
    }
  ) {
    const { encounterId, types } = args;
    const clinicalDataCached = this.clinicalDataMap.get(encounterId);

    if (!options.ignoreCache && !!clinicalDataCached) {
      // this is a temp workaround - to be resolved when we figure out
      // Product Backlog Item 18938: Tech Debt: Review GetEncounterClinicalData() Usage in Front-End
      // v1 is the versions of clinical data type - eg: ClinicalDataType orebrov1 or nprsv1
      const requiredKeys = types
        ? types.map(x => x.replace("v1", "").toLocaleLowerCase())
        : [];

      if (requiredKeys.length && clinicalDataCached) {
        const missingKeys = requiredKeys.filter(x => !clinicalDataCached[x]);
        if (missingKeys.length) {
          const clinicalData = await this.gateway.getEncounterClinicalData({
            encounterId
          });
          runInAction(() => {
            this.clinicalDataMap.set(encounterId, clinicalData);
          });
          return clinicalData;
        }
      }

      if (clinicalDataCached) {
        return Promise.resolve(clinicalDataCached);
      }
    }

    //we don't have this clinicalData, get it from backend and put in to map
    const clinicalData = await this.gateway.getEncounterClinicalData({
      encounterId,
      types
    });
    if (!types || types.length === 0) {
      runInAction(() => {
        this.clinicalDataMap.set(encounterId, clinicalData);
      });
    }
    return clinicalData;
  }

  @sharePendingPromise()
  async getPatientScopedClinicalData(patientId: string) {
    const dataTypes = await this.ref.clinicalDataTypes.load();
    const types = Array.from(dataTypes.values())
      .filter(t => t.isPatientScoped)
      .map(t => t.code);

    return await this.gateway.getPatientClinicalData({
      patientId,
      types
    });
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getEpisodeOfCareScopedClinicalData(
    args: GetEpisodeOfCareClinicalDataDto
  ) {
    return await this.gateway.getEpisodeOfCareClinicalData({
      ...args
    });
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getEpisodesOfCareScopedClinicalData(
    args: GetEpisodesOfCareClinicalDataDto
  ) {
    const types = await this.getEpisodeClinicalDataTypes();

    return await this.gateway.getEpisodesOfCareClinicalData({
      ...args,
      types
    });
  }

  async getEncounterEpisodeOfCareScopedClinicalData(encounterId: string) {
    const types = await this.getEpisodeClinicalDataTypes();

    return await this.gateway.getEncounterClinicalData({
      encounterId,
      types
    });
  }

  // need to get the most recent ConfirmedClinicalData to populate the updated dates in the tree view
  @sharePendingPromise()
  async getClinicalDataConfirmedClinicalData(patientId: string) {
    const types: string[] = [
      ClinicalDataType.AlcoholConfirmed,
      ClinicalDataType.TobaccoConfirmed,
      ClinicalDataType.SubstanceUseConfirmed,
      ClinicalDataType.SocialHistoryConfirmed,
      ClinicalDataType.WorkHistoryConfirmed,
      ClinicalDataType.PhysicalActivityConfirmed,
      ClinicalDataType.SleepConfirmed,
      ClinicalDataType.GeneralExamConfirmed,
      ClinicalDataType.DermatomesAndMyotomesConfirmed,
      ClinicalDataType.FamilyHistoryConfirmed,
      ClinicalDataType.SystemsReviewConfirmed,
      ClinicalDataType.ClinicalFlagsConfirmed,
      ClinicalDataType.HofPCConfirmed,
      ClinicalDataType.BodyAreaConfirmed,
      ClinicalDataType.CentralNervousSystemConfirmed,
      ClinicalDataType.MedicationsAndSupplementsConfirmed
    ];

    return await this.gateway.getPatientClinicalData({
      patientId,
      types
    });
  }

  private async getEpisodeClinicalDataTypes() {
    const dataTypes = await this.ref.clinicalDataTypes.load();
    return Array.from(dataTypes.values())
      .filter(t => t.isEpisodeScoped)
      .map(t => t.code);
  }

  @action
  private mergeObservation = (dto: ObservationDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Observation(dto),
      map: this.observationMap
    });
  };

  async updateObservation(data: ObservationDto) {
    try {
      const dto = await this.gateway.updateObservation(data);
      return this.mergeObservation(dto);
    } catch (error) {
      this.notification.error(error);
      return;
    }
  }
  @sharePendingPromise()
  getObservation = (
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) => {
    if (!options.ignoreCache) {
      const observation = this.observationMap.get(id);
      if (observation) {
        return Promise.resolve(observation);
      }
    }

    return this.gateway.getObservation(id).then(this.mergeObservation);
  };

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  getObservations = async (
    filter: ObservationsFilter & PagingOptions
  ): Promise<QueryResult<Observation>> => {
    const response = await this.gateway.getObservations(filter);
    const { results, ...rest } = response;

    const observations = results?.map(this.mergeObservation);

    return { results: observations || [], ...rest };
  };

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getEncounters(filter: GetEncountersDto): Promise<Encounter[]> {
    try {
      const result = await this.gateway.getEncounters(filter);
      return result.results.map(this.mergeEncounter);
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  async addEncounter(data: AddEncounterDto) {
    const result = await this.gateway.addEncounter(data).then(dto => {
      return this.mergeEncounter(dto);
    });

    if (data.status === EncounterStatus.Closed) {
      const patient = await this.practice.getContact(data.patientId);
      const record = this.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(patient.id, result.id)
      );
      if (record) {
        await record.loadInteractions();
        await record.loadClosedEncounters();
      }
      this.notification.success(
        `Visit for ${patient && patient.name} has been finalised.`
      );
    } else {
      this.notification.success("Encounter has been added.");
    }

    return result;
  }
  @action
  async updateEncounter(request: PatchEncounterDto) {
    const { patientId } = getOrThrow(this.encounterMap, request.id);
    const record = getOrThrow(
      this.multipleEncountersRecordsMap,
      clinicalRecordMapKey(patientId, request.id)
    );

    try {
      record.saving = true;
      record.lastSaved = DateTime.jsDateNow();

      const updatedDto = await this.gateway.updateEncounter(request);
      const encounter = this.mergeEncounter(updatedDto);

      if (request.status === EncounterStatus.Closed) {
        runInAction(() => {
          record.finalisedByClient = true;
        });

        const patient = await this.practice.getContact(patientId);
        this.notification.success(
          `Visit for ${patient && patient.name} has been finalised.`
        );
      } else {
        this.notification.success("Encounter has been updated.");
      }

      return encounter;
    } finally {
      runInAction(() => {
        record.saving = false;
      });
    }
  }

  @action
  deleteEncounter = async (
    id: string,
    reasonForDiscard: string,
    reasonForDiscardComment?: string
  ): Promise<void> => {
    const encounter = this.encounterMap.get(id);
    try {
      await this.gateway.deleteEncounter(
        id,
        reasonForDiscard,
        reasonForDiscardComment
      );
      runInAction(() => {
        this.encounterMap.delete(id);
      });

      this.notification.success("Visit has been deleted.");
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred deleting the visit."
      });
      throw error;
    }

    if (encounter) {
      // force refetching the encounter with latest clinicalData
      // to avoid rendering stale clinicalData that have been
      // discarded
      await this.getRecord(encounter.patientId, {
        ignoreCache: true,
        setNextActiveOnError: true
      });
    }
  };

  discardEpisodeClinicalData = async (encounterId: string): Promise<void> => {
    try {
      await this.gateway.discardEpisodeClinicalData(encounterId);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred discarding episode clinical data."
      });
      throw error;
    }
  };

  async getStructuredNote(request: GetStructuredNoteDto) {
    try {
      return await this.gateway.getStructuredNote(request);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred retrieving structured notes."
      });
    }
    return [];
  }

  async updateEncounterClinicalNote(
    data: ClinicalNoteSectionElement[],
    encounterId: string
  ) {
    try {
      return await this.gateway.updateEncounterClinicalNote(data, encounterId);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating encounter clinical note."
      });
    }
    return undefined;
  }

  async getEncounterClinicalNote(
    encounterId: string
  ): Promise<EncounterClinicalNoteDto> {
    try {
      return await this.gateway.getEncounterClinicalNote(encounterId);
    } catch (e) {
      if (e instanceof NotFoundError) {
        return {
          sectionElements: [],
          clinicalDataElements: [],
          sectionHeadingReferenceData: [],
          clinicalDataHeadingReferenceData: []
        };
      }
      throw e;
    }
  }

  @action
  private mergeEncounter = (dto: EncounterDto) =>
    mergeModel({
      dto,
      getNewModel: () => new Encounter(this.root, dto),
      map: this.encounterMap
    });

  @action
  private mergeAmendments = (dto: AmendmentDto) =>
    mergeModel({
      dto,
      getNewModel: () => new Amendment(dto),
      map: this.amendmentMap
    });

  async getStagingInfo() {
    return await this.gateway.getStagingInfo();
  }

  @sharePendingPromise()
  async getSpecialTests(
    code: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const specialTest = this.specialTestDataMap.get(code);
      if (specialTest) {
        return specialTest;
      }
    }

    const specialTest = await this.gateway.getSpecialTests(code);
    runInAction(() => {
      this.specialTestDataMap.set(code, specialTest);
    });

    return specialTest;
  }

  @sharePendingPromise()
  async getQuestionnaires(
    code: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    let questionnaire: QuestionnaireDto = {
      id: "",
      code: "",
      text: "",
      items: [],
      eTag: "",
      secGroupId: ""
    };

    if (!options.ignoreCache) {
      const questionnaire = this.questionnaireDataMap.get(code);
      if (questionnaire) {
        return Promise.resolve(questionnaire);
      }
    }

    try {
      questionnaire = await this.gateway.getQuestionnaires(code);
      runInAction(() => {
        this.questionnaireDataMap.set(code, questionnaire);
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading the questionnaire"
      });
    }
    return Promise.resolve(questionnaire);
  }

  private onUserTaskCreatedEvent = async (event: EntityEventData<string>) => {
    // Ensures the investigations side panel updates when investigation is created via upload with outcome
    try {
      if (event.action === EventAction.Create) {
        const task = await this.root.inbox.getUserTask(event.id);
        if (task.patientId) {
          const clinicalRecordKeys = Array.from(
            this.multipleEncountersRecordsMap.keys()
          ).filter(x => x.startsWith(task.patientId!));
          clinicalRecordKeys.forEach(x => {
            const record = this.multipleEncountersRecordsMap.get(x);
            if (record) {
              runInAction(() => {
                record.patientUserTasks = [...record.patientUserTasks, task];
              });
            }
          });
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onCorrespondenceMetaDataUploadedEvent = async (
    event: EntityEventData<{ patientId: string }>
  ) => {
    try {
      const patientId = event.id;
      if (patientId) {
        const clinicalRecordKeys = Array.from(
          this.multipleEncountersRecordsMap.keys()
        ).filter(x => x.startsWith(patientId));

        const clinicalRecords = clinicalRecordKeys
          .map(x => this.multipleEncountersRecordsMap.get(x))
          .filter(isDefined);
        await Promise.all(clinicalRecords.map(x => x.loadInteractions()));
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  /**
   * onInteractionUpdateEvent is called when a Interaction update event is received from the SignalR hub
   */
  private onInteractionUpdateEvent = async (
    event: EntityEventData<{ patientId: string }>
  ) => {
    try {
      if (this.interactionMap.get(event.id)) {
        // already stored
        return;
      }

      const patientId = event.key?.patientId;
      if (!patientId) {
        return;
      }

      const interaction = await this.getInteraction(event.id, {
        ignoreCache: true
      });

      const clinicalRecordKeys = Array.from(
        this.multipleEncountersRecordsMap.keys()
      ).filter(x => x.startsWith(patientId));
      if (clinicalRecordKeys.length === 0) {
        // not patient record in memory for this interaction, no need to fetch it
        return;
      }

      const clinicalRecords = clinicalRecordKeys
        .map(x => this.multipleEncountersRecordsMap.get(x))
        .filter(isDefined);
      await Promise.all(
        clinicalRecords.map(x => x.updateInteractionResults(interaction))
      );
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onEncounterUpdateEvent = async (event: EntityEventData) => {
    try {
      if (event.action === EventAction.Update) {
        const cachedEncounter = this.encounterMap.get(event.id);

        const openPillForEncounter = this.ui.openedRecordIds.filter(
          x => x.encounterId === event.id
        );
        if (cachedEncounter || openPillForEncounter.length > 0) {
          const isStale =
            cachedEncounter && cachedEncounter.eTag !== event.etag;

          const encounter = await this.getEncounter(event.id, {
            ignoreCache: true
          });

          const record = this.multipleEncountersRecordsMap.get(
            clinicalRecordMapKey(encounter.patientId, encounter.id)
          );
          if (record) {
            await record.loadClinicalData();
          }

          if (
            encounter.isClosed &&
            (isStale || openPillForEncounter.length > 0)
          ) {
            runInAction(() => {
              this.recentlyFinalisedEncounterId = encounter.id;
            });
          }
        }
      }
      if (event.action === EventAction.Delete) {
        const doomed = this.encounterMap.get(event.id);
        if (doomed) {
          runInAction(() => {
            this.encountersDeletedElsewhere.push(event.id);
            this.encounterMap.delete(event.id);
          });
        } else {
          const openPatientPillForEncounter = this.ui.openedRecordIds.filter(
            x => x.encounterId === event.id
          );
          if (openPatientPillForEncounter.length > 0) {
            runInAction(() => {
              this.encountersDeletedElsewhere.push(event.id);
            });
          }
        }
      }
      if (event.action === EventAction.Commit) {
        const encounter = await this.getEncounter(event.id, {
          ignoreCache: true
        });

        if (
          encounter.status === EncounterStatus.Committed ||
          encounter.status === EncounterStatus.Closed
        ) {
          if (
            this.ui.openedRecordIds.some(
              x => x.patientId === encounter.patientId
            )
          ) {
            const clinicalRecordKeys = Array.from(
              this.multipleEncountersRecordsMap.keys()
            ).filter(
              x =>
                x.startsWith(encounter.patientId) &&
                x !== `${encounter.patientId}_${encounter.id}` // dont need to update enocunterClinicalData that was just saved
            );

            const clinicalRecords = clinicalRecordKeys
              .map(x => this.multipleEncountersRecordsMap.get(x))
              .filter(isDefined);
            await Promise.all(clinicalRecords.map(x => x.loadClinicalData()));
          }
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onAmendmentCreateEvent = async (
    event: EntityEventData<{ encounterId: string }>
  ) => {
    try {
      event.key?.encounterId &&
        (await this.getAmendments(event.key.encounterId));
    } catch (error) {
      this.notification.error(error);
    }
  };

  @action
  private onClinicalReminderCommUpdate = (event: EntityEventData) => {
    if (event.action === EventAction.Update) {
      if (event.key.includes("recordUpdated")) {
        const keys = event.key.split(":");
        const clinicalActivity = keys[2];
        if (
          clinicalActivity &&
          this.clinicalRemindersMapValues &&
          this.clinicalRemindersMapValues.find(x => x.id === clinicalActivity)
        ) {
          this.lastUpdatedClinicalActivities = clinicalActivity;
        }
      }
    }
  };

  @action
  private mergeInteraction = (dto: InteractionDto) =>
    mergeModel({
      dto,
      getNewModel: () => new Interaction(this.root, dto),
      map: this.interactionMap
    });

  @action mergeEncounterClinicalRecord = (
    dto: ClinicalRecordDto,
    encounterId?: string
  ) =>
    mergeModel({
      dto,
      getNewModel: () => new ClinicalRecord(this.root, dto, encounterId),
      map: this.multipleEncountersRecordsMap,
      id: clinicalRecordMapKey(dto.id, encounterId)
    });

  private addTerminologyConceptMap = (
    terminologyConcepts: TerminologyConceptDto[]
  ): TerminologyDto[] => {
    let items: TerminologyDto[] = [];
    terminologyConcepts?.forEach(item => {
      items = items?.concat(
        item.termNames?.map(text => ({
          ...item,
          text,
          isReasonForVisit: item.reasonForVisit,
          isDiagnosis: item.diagnosis,
          isProcedure: item.procedure,
          isCauseOfDeath: item.causeOfDeath,
          isSide: item.side,
          isChronicity: item.chronicity,
          isSeverity: item.severity,
          isFracture: item.fracture,
          aCC32Acceptable: item.aCC32Acceptable,
          aCCAcceptable: item.aCC32Acceptable,
          readCode: item.readCode
        }))
      );
    });

    items?.forEach(n => this.addTerminologyMap(n));
    return items;
  };

  @action
  async getTerminologiesSearch(
    request: TerminologySearchRequest
  ): Promise<QueryResult<TerminologyDto>> {
    const dtoResult = await this.gateway.getTerminologiesSearch(request);
    const value = this.addTerminologyConceptMap(dtoResult?.results);
    return {
      ...dtoResult,
      results: value
    };
  }

  @action
  async getTerminologiesLookup(
    request: TerminologyLookupRequest[]
  ): Promise<TerminologyConceptDto[]> {
    try {
      const dtoResult = await this.gateway.getTerminologyLookup(request);
      this.addTerminologyConceptMap(dtoResult);
      return dtoResult;
    } catch (e) {
      this.notification.error(e);
      return [];
    }
  }

  @action
  getSubstanceUseTypes(
    request: SubstanceUseTypeFilter
  ): Promise<QueryResult<SubstanceUseTypeDto>> {
    return this.gateway.getSubstanceUseTypes(request);
  }

  @action
  addActiveClinicalRecord(encounterId?: string) {
    if (this.activeRecord && this.activeRecordPatientId) {
      const tabAlready = this.ui.openedRecordIds.some(
        x =>
          x.patientId === this.activeRecordPatientId &&
          x.encounterId === encounterId
      );
      if (!tabAlready) {
        this.ui.openedRecordIds.splice(0, 0, {
          patientId: this.activeRecordPatientId,
          encounterId
        });

        this.saveToUserStorage(
          UserStorageKeys.OpenPatientTabs,
          this.ui.openedRecordIds
        );
      }
    }
  }

  get isExistConfidentialClinicalTasksWithOtherUsers() {
    return this.clinicalTasksMapValues.some(x => {
      return (
        x.patientId &&
        !!x.secGroupId &&
        !this.core.hasAccessToSecGroup(x.secGroupId)
      );
    });
  }

  @action
  async updateClinicalData(
    encounterId: string,
    data: EncounterClinicalDataDto
  ) {
    let result: EncounterClinicalDataDto | undefined;

    try {
      result = await this.gateway.updateClinicalData(encounterId, data);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating clinical data."
      });
      throw error;
    }

    return result;
  }

  @sharePendingPromise()
  async getPatientClinicalTasks(patientId: string) {
    const clinicalTasks = await this.gateway.getPtClinicalTasks(patientId);
    return clinicalTasks.map(dto =>
      this.mergeClinicalTask(dto, this.clinicalTasksMap)
    );
  }

  @sharePendingPromise()
  async getPatientClinicalActivities(patientId: string) {
    const clinicalActivities =
      await this.gateway.getPtClinicalActivities(patientId);
    return clinicalActivities.map(dto =>
      this.mergeClinicalActivity(dto, this.clinicalActivitiesMap)
    );
  }

  async updateClinicalActivity(patientId: string, dtos: ClinicalActivityDto[]) {
    try {
      const response = await this.gateway.updateClinicalActivity(
        patientId,
        dtos
      );

      response.forEach(dto => {
        return this.mergeClinicalActivity(dto, this.clinicalActivitiesMap);
      });

      runInAction(() => {
        this.lastUpdatedClinicalActivities = dtos
          .map(activity => `${activity.id}${activity.eTag}`)
          .join("");
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating clinical activities."
      });
      throw error;
    }
  }

  async getClinicalActivities(
    args: GetClinicalActivitiesDto & PagingOptions = {}
  ): Promise<QueryResult<ClinicalActivity>> {
    try {
      const response = await this.gateway.getClinicalActivities(args);
      const { results, ...rest } = response;

      const activities = results.map(dto => {
        return this.mergeClinicalActivity(dto, this.clinicalActivitiesMap);
      });
      return { results: activities, ...rest };
    } catch (error) {
      throw error;
    }
  }

  async getClinicalReminders(
    args: GetClinicalRemindersDto & PagingOptions = {}
  ): Promise<QueryResult<ClinicalActivity>> {
    try {
      const response = await this.gateway.getClinicalReminders(args);
      const { results, ...rest } = response;

      const reminders = results.map(dto => {
        return this.mergeClinicalReminder(dto, this.clinicalRemindersMap);
      });
      return { results: reminders, ...rest };
    } catch (error) {
      throw error;
    }
  }

  async unlockClinicalActivities(
    unlockClinicalActivitiesArgs: ClinicalActivityUnlockArgs
  ) {
    try {
      const clinicalActivities = await this.gateway.unlockClinicalActivities(
        unlockClinicalActivitiesArgs
      );

      clinicalActivities.forEach(dto => {
        return this.mergeClinicalActivity(dto, this.clinicalActivitiesMap);
      });
      runInAction(() => {
        this.lastUpdatedClinicalActivities = clinicalActivities
          .map(activity => `${activity.id}${activity.eTag}`)
          .join("");
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred unlocking clinical activities."
      });
      throw error;
    }
  }

  async getClinicalTasks(
    args: GetClinicalTasksDto & PagingOptions = {}
  ): Promise<QueryResult<ClinicalTask>> {
    try {
      const response = await this.gateway.getClinicalTasks(args);
      const { results, ...rest } = response;

      const tasksPromise = response.results?.map(dto => {
        return this.mergeClinicalTask(dto, this.clinicalTasksMap);
      });
      return { results: tasksPromise || [], ...rest };
    } catch (error) {
      throw error;
    }
  }

  async updateClinicalTask(patientId: string, dtos: ClinicalTaskDto[]) {
    try {
      const response = await this.gateway.updateClinicalTask(patientId, dtos);

      response.map(dto => {
        return this.mergeClinicalTask(dto, this.clinicalTasksMap);
      });
      runInAction(() => {
        this.lastUpdatedClinicalTasks = dtos
          .map(task => `${task.id}${task.eTag}`)
          .join("");
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating clinical tasks."
      });
      throw error;
    }
  }

  async unlockClinicalTasks(unlockClinicalTasksArgs: ClinicalTasksUnlockArgs) {
    try {
      const clinicalTasks = await this.gateway.unlockClinicalTasks(
        unlockClinicalTasksArgs
      );

      clinicalTasks?.map(dto => {
        return this.mergeClinicalTask(dto, this.clinicalTasksMap);
      });
      runInAction(() => {
        this.lastUpdatedClinicalTasks = clinicalTasks
          .map(task => `${task.id}${task.eTag}`)
          .join("");
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred unlocking clinical tasks."
      });
      throw error;
    }
  }

  private onEpisodeOfCareUpdateEvent = async (event: EntityEventData) => {
    try {
      if (
        event.action === EventAction.Update ||
        event.action === EventAction.Create
      ) {
        const condition = this.conditionsMap.get(event.id);

        if (condition) {
          await this.getCondition(event.id, { ignoreCache: true });
        }
      }
      if (event.action === EventAction.Delete) {
        runInAction(() => {
          this.conditionsMap.delete(event.id);
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  async addEpisodeOfCare(data: Partial<EpisodeOfCareDto>) {
    try {
      return await this.gateway.addEpisodeOfCare(data);
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }

  getEpisodeOfCare(episodeOfCareId: string) {
    return this.gateway.getEpisodeOfCare(episodeOfCareId);
  }

  async getCondition(conditionId: string, options?: { ignoreCache?: boolean }) {
    const conditionInMap = this.conditionsMap.get(conditionId);
    if (conditionInMap && !options?.ignoreCache) {
      return conditionInMap;
    } else {
      const result = await this.getEpisodeOfCare(conditionId);
      return this.mergeCondition(result);
    }
  }

  async getInitialConsultDateAndVisitsCounts(
    options: {
      episodeOfCareIds: string[];
      patientId: string;
      openEncounter: Encounter;
    },
    loadedEncounters?: Encounter[]
  ) {
    const { episodeOfCareIds, patientId, openEncounter } = options;

    const encounters =
      loadedEncounters ??
      (await this.getEncounters({ episodeOfCareIds, patientId }));

    const filterEncounters = encounters.filter(
      x =>
        this.core.hasPermissions(Permission.MultiProviderClaimsAllowed) &&
        x.businessRole === openEncounter.businessRole
    );

    const eocInitialConsultDateAndVisitCounts = episodeOfCareIds.map(x => {
      const encountersOfEoCId = filterEncounters.filter(
        enc => enc.episodeOfCareId === x
      );

      const { totalVisitCount, initialConsultDate } =
        this.getInitialConsultDateAndVisitCount(encountersOfEoCId);

      const result: EocInitialConsultDateAndVisitCount = {
        episodeOfCareId: x,
        totalVisitCount,
        initialConsultDate
      };

      return result;
    });

    return eocInitialConsultDateAndVisitCounts;
  }

  private getInitialConsultDateAndVisitCount(encounters: Encounter[]) {
    if (!encounters || encounters.length === 0) {
      // No encounters
      return { totalVisitCount: 0, initialConsultDates: undefined };
    }

    const sortedEncounters = encounters.sort((a, b) =>
      compareDatesPredicate(a.startDateTime, b.startDateTime)
    );
    return {
      totalVisitCount: encounters.length,
      initialConsultDate: sortedEncounters[0].startDateTime
    };
  }

  async deleteEpisodeOfCare(episodeOfCareId: string) {
    try {
      await this.gateway.deleteEpisodeOfCare(episodeOfCareId);
      this.notification.success("Successfully deleted EpisodeOfCare");
    } catch (error) {
      this.notification.error(error.detail);
      throw error;
    }
  }

  @action mergeCondition = (dto: EpisodeOfCareDto) =>
    mergeModel({
      dto,
      getNewModel: () => new Condition(this.root, dto),
      map: this.conditionsMap
    });

  async getPatientConditions(
    patientId: string,
    options?: { loadClaims?: boolean; loadCalendarEvents?: boolean }
  ): Promise<Condition[]> {
    const eoc = await this.getPatientEpisodesOfCare(patientId);
    const results = eoc.map(this.mergeCondition);
    if (
      (options?.loadCalendarEvents || options?.loadClaims) &&
      results.length
    ) {
      const promises: Promise<any>[] = [];

      if (options.loadCalendarEvents) {
        promises.push(
          this.root.booking.getCalendarEvents({
            attendees: [patientId]
          })
        );
      }

      if (options.loadClaims) {
        promises.push(
          (async () => {
            const ceocs = await this.root.acc.getClaimEpisodesOfCare({
              episodesOfCare: results.map(x => x.id)
            });

            ceocs.forEach(ceoc => {
              const condition = results.find(
                eoc => eoc.id === ceoc.episodeOfCareId
              );
              if (condition) {
                condition.claimEpisodeOfCare = ceoc;
              }
            });

            const claimIds = ceocs.map(x => x.claimId);

            this.root.acc.fetchClaims({
              patients: [patientId],
              claimIds,
              take: claimIds.length
            });
          })()
        );
      }
      await Promise.all(promises);
    }
    return results;
  }

  async getPatientEpisodesOfCare(patientId: string) {
    try {
      return await this.gateway.getPatientEpisodesOfCare(patientId);
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }

  async updateEpisodeOfCare(
    episodeOfCare: EpisodeOfCareDto,
    episodeOfCareId: string
  ) {
    try {
      return await this.gateway.updateEpisodeOfCare(
        episodeOfCare,
        episodeOfCareId
      );
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }

  public saveToUserStorage = async (key: string, value: any) => {
    try {
      const userStorage = await this.root.userExperience.getUserStorage(key);
      if (userStorage === undefined) {
        await this.root.userExperience.addUserStorage(key, {
          key,
          userId: this.root.core.userId,
          jsonData: value
        });
      } else {
        await this.root.userExperience.updateUserStorage(key, {
          key: userStorage.key,
          userId: userStorage.userId,
          jsonData: value,
          eTag: userStorage.eTag,
          id: userStorage.id
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  @action
  private mergeClinicalTask = (
    dto: ClinicalTaskDto,
    mergeToMap: ObservableMap<string, ClinicalTask>
  ) => {
    return mergeModel({
      dto,
      getNewModel: () => new ClinicalTask(dto),
      map: mergeToMap
    });
  };

  @action
  private mergeClinicalActivity = (
    dto: ClinicalActivityDto,
    mergeToMap: ObservableMap<string, ClinicalActivity>
  ) => {
    return mergeModel({
      dto,
      getNewModel: () => new ClinicalActivity(dto),
      map: mergeToMap
    });
  };

  @action
  private mergeClinicalReminder = (
    dto: ClinicalActivityDto,
    mergeToMap: ObservableMap<string, ClinicalActivity>
  ) => {
    return mergeModel({
      dto,
      getNewModel: () => new ClinicalActivity(dto),
      map: mergeToMap
    });
  };

  sendClinicalDocument = async (args: SendClinicalDocumentArgs) => {
    await this.gateway.sendClinicalDocument(args);
    this.notification.success(notificationMessages.emailIsBeingSent);
  };

  @sharePendingPromise()
  async getClinicalActivitiesPreferenceByTenant(
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<ClinicalActivitiesPreference> {
    if (this.clinicalActivitiesPreference && !options?.ignoreCache)
      return this.clinicalActivitiesPreference;

    const result = await this.gateway.getClinicalActivitiesPreferenceByTenant();

    const clinicalActivitiesPreference = new ClinicalActivitiesPreference(
      result
    );
    runInAction(() => {
      this.clinicalActivitiesPreference = clinicalActivitiesPreference;
    });
    return clinicalActivitiesPreference;
  }

  async patchClinicalActivitiesPreference(
    patchClinicalActivitiesPreference: PatchClinicalActivitiesPreferenceDto
  ) {
    const result = await this.gateway.updateClinicalActivitiesPreference(
      patchClinicalActivitiesPreference
    );

    const clinicalActivitiesPreference = new ClinicalActivitiesPreference(
      result
    );
    runInAction(() => {
      this.clinicalActivitiesPreference = clinicalActivitiesPreference;
    });
    return clinicalActivitiesPreference;
  }

  @sharePendingPromise()
  async loadActivityDescriptions(
    requestArgs: ActivityDescriptionArgs &
      PagingOptions & { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (
      this.clinicalActivitiesMap.values.length > 0 &&
      !requestArgs.ignoreCache
    ) {
      return;
    }

    const response = await this.gateway.getActivityDescriptions(requestArgs);
    response.forEach(dto => {
      this.mergeActivityDescription(dto);
    });
  }

  @action
  private mergeActivityDescription = (dto: ActivityDescriptionDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new ActivityDescription(dto),
      map: this.activityDescriptionMap
    });
  };

  async updateActivityDescription(activityDescription: ActivityDescriptionDto) {
    try {
      const response =
        await this.gateway.updateActivityDescription(activityDescription);
      return this.mergeActivityDescription(response);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating clinical activities."
      });
      throw error;
    }
  }

  async createActivityDescription(activityDescription: ActivityDescriptionDto) {
    try {
      const response =
        await this.gateway.createActivityDescription(activityDescription);
      return this.mergeActivityDescription(response);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred creating clinical activity."
      });
      throw error;
    }
  }

  async createSendClinicalReminderBatch(
    options: ClinicalReminderCommCreateBatchArgs
  ) {
    try {
      runInAction(() => {
        this.lastUpdatedClinicalActivities = `${options.activities.join(
          "-"
        )}-sent`;
      });
      await this.gateway.createSendClinicalReminderBatch(options);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred whilst sending reminder."
      });
      throw error;
    }
  }

  async updateClinicalReminderComm(
    data: ClinicalReminderCommDto
  ): Promise<ClinicalReminderCommDto> {
    return this.gateway.updateClinicalReminderComm(data);
  }
  async getClinicalReminderComms(
    args: ClinicalReminderCommGetByArgs & PagingOptions = { activityIds: [] }
  ) {
    return this.gateway.getClinicalReminderComms(args);
  }
}
