import { observer, Observer } from "mobx-react-lite";

import {
  ButtonsGroupChoice,
  CenteredBox,
  CenteredLargeSpinner,
  DetailsList,
  IColumn,
  noWrap,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ProviderOnlineStatus,
  ProviderOnlineStatusText
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { UserOnlineBookingProfileFormHelper } from "@modules/settings/screens/users/components/user-online-booking-profile/UserOnlineBookingProfileFormHelper.ts";
import { UserProfileSection } from "@modules/settings/screens/users/components/UserBreadcrumbs.tsx";
import { BhbOnlineApptType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import {
  DetailsRowHelper,
  DetailsRowWrapper
} from "@ui-components/ShimmeredDetailsList/DetailsRowWrapper.tsx";

import { AppointmentTypeTagPicker } from "./AppointmentTypeTagPicker.tsx";
import { BhbProvidersOnlineFilter } from "./BhbProvidersCard.types.ts";
import { BhbProvidersFilter } from "./BhbProvidersFilter.tsx";

interface BhbProvidersData {
  providers: BhbProvider[];
  apptTypes: BhbOnlineApptType[];
  userSettings: UserSetting[];
  users: User[];
}

interface BhbProvidersFormProps {
  location: BhbLocation;
}

interface BhbProvidersFormInnerProps extends BhbProvidersData {
  singleProvider?: true;
}

export const BhbProvidersFormInner = observer(
  ({
    providers,
    apptTypes,
    userSettings,
    users,
    singleProvider
  }: BhbProvidersFormInnerProps) => {
    const { bhb } = useStores();
    const getItemsForProviders = (
      providerData: BhbProvider[],
      filters: BhbProvidersOnlineFilter | undefined
    ): BhbProvider[] => {
      const providers = providerData.filter(provider =>
        filterData(provider, filters)
      );

      return providers;
    };

    const filterData = (
      provider: BhbProvider,
      filters: BhbProvidersOnlineFilter | undefined
    ): boolean => {
      if (!filters) {
        return true;
      }

      const { search, onlineAvailability, providers, appointmentTypes } =
        filters;

      if (
        search &&
        !(
          provider.displayName.toLowerCase().includes(search.toLowerCase()) ||
          provider.firstName.toLowerCase().includes(search.toLowerCase()) ||
          provider.lastName.toLowerCase().includes(search.toLowerCase())
        )
      ) {
        return false;
      }

      if (onlineAvailability && onlineAvailability.length > 0) {
        const statusMap = {
          ShowAndBook: ProviderOnlineStatus.YES,
          ShowAndCall: ProviderOnlineStatus.CALL,
          DontShow: ProviderOnlineStatus.NO
        };

        const selectedStatus = onlineAvailability.find(
          status => provider.providerOnlineStatus === statusMap[status]
        );
        if (!selectedStatus) {
          return false;
        }
      }

      if (
        providers &&
        providers.length > 0 &&
        !providers.includes(provider.id)
      ) {
        return false;
      }

      if (
        appointmentTypes &&
        appointmentTypes.length > 0 &&
        !appointmentTypes.some(type =>
          provider.appointmentTypes.some(appType => appType.id === type)
        )
      ) {
        return false;
      }

      return true;
    };

    const buildColumns = (): IColumn[] => {
      const columns: IColumn[] = [];

      if (singleProvider) {
        columns.push({
          key: "location",
          name: "Location",
          fieldName: "location",
          minWidth: 160,
          maxWidth: 160,
          isResizable: true,
          onRender: (item: { data: BhbProvider }) => (
            <DataFetcher
              fetch={root => root.core.getOrgUnit(item.data.locationId)}
            >
              {location => (
                <Text styles={{ root: { ...noWrap } }}>{location.name}</Text>
              )}
            </DataFetcher>
          )
        });
      } else {
        columns.push({
          key: "name",
          name: "Provider",
          fieldName: "name",
          minWidth: 160,
          maxWidth: 160,
          isResizable: true,
          onRender: (item: { data: BhbProvider }) => (
            <Navigate
              to={{
                pathname: routes.settings.users.user.path({
                  id: item.data.id
                }),
                hash: UserProfileSection.OnlineBookingProfile
              }}
            >
              {item.data.displayName}
            </Navigate>
          )
        });
      }

      columns.push(
        {
          key: "appt-types",
          name: "Appointment types",
          fieldName: "appt-types",
          minWidth: 400,
          isResizable: true,
          onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => (
            <AppointmentTypeTagPicker
              apptTypes={apptTypes}
              bhbProvider={item.data}
              detailsRowHelper={item.helper}
            />
          )
        },

        {
          key: "accept-online-bookings",
          name: "Online availability",
          fieldName: "accept-online-bookings",
          minWidth: 354,
          isResizable: true,
          onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => {
            const setting = userSettings.find(x => x.id === item.data.id);
            const user = users.find(x => x.id === item.data.id);

            const showOnCalendarDisabled = !(
              !!setting?.showOnCalendar && user?.status === UserStatus.Active
            );

            const providerOnlineStatus = [
              {
                key: ProviderOnlineStatus.YES,
                text: ProviderOnlineStatusText.ShowAndBook,
                disabled: showOnCalendarDisabled
              },
              {
                key: ProviderOnlineStatus.CALL,
                text: ProviderOnlineStatusText.ShowAndCall
              },
              {
                key: ProviderOnlineStatus.NO,
                text: ProviderOnlineStatusText.DontShow
              }
            ];

            return (
              <Observer>
                {() => (
                  <ButtonsGroupChoice<ProviderOnlineStatus | undefined>
                    key={item.data.id}
                    equalWidth
                    options={providerOnlineStatus}
                    value={item.data.providerOnlineStatus}
                    onChange={(
                      providerOnlineStatus: ProviderOnlineStatus | undefined
                    ) => {
                      const updateProvider = {
                        ...item.data.dto,
                        providerOnlineStatus
                      };
                      item.helper.setPromise(
                        bhb.updateProvider(updateProvider)
                      );
                    }}
                  />
                )}
              </Observer>
            );
          }
        }
      );

      return columns;
    };

    return (
      <BhbProvidersFilter
        providers={providers}
        apptTypes={apptTypes}
        singleProvider={singleProvider}
      >
        {({ values }) => {
          if (providers.length > 0) {
            const _providers = Array.from(
              getItemsForProviders(providers, values)
            );

            return (
              <Stack
                styles={{
                  root: {
                    maxHeight: 500,
                    overflowY: "auto"
                  }
                }}
              >
                <DetailsList
                  items={_providers}
                  onRenderRow={props => <DetailsRowWrapper props={props} />}
                  columns={buildColumns()}
                />
              </Stack>
            );
          } else {
            return (
              <CenteredBox>
                <DefaultNoResultsTile defaultText="No records found" />
              </CenteredBox>
            );
          }
        }}
      </BhbProvidersFilter>
    );
  }
);

export const BhbProvidersForm = observer(
  ({ location }: BhbProvidersFormProps) => {
    const { bhb, core, userExperience } = useStores();

    const fetchProvidersAndUserSettings = async () => {
      const providersUnsorted =
        (await bhb.getProvidersForLocation(location.id)) ?? [];

      const userSettings = providersUnsorted.length
        ? await userExperience.getUserSettingsByUserIds(
            providersUnsorted.map(x => x.id)
          )
        : [];

      return {
        userSettings,
        providers: Array.from(providersUnsorted).sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        )
      };
    };

    const fetch = async () => {
      const [apptTypesForLocation, { providers, userSettings }, users] =
        await Promise.all([
          bhb.getAppointmentTypesForLocation(),
          fetchProvidersAndUserSettings(),
          core.fetchUsers({
            showOnCalendar: true,
            statusCodes: [UserStatus.Active],
            businessRoleClasses: [BusinessRoleClasses.Provider]
          })
        ]);

      const onlineAppointmentTypes = apptTypesForLocation
        ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
            apptTypesForLocation
          )
        : [];

      return {
        providers,
        apptTypes: onlineAppointmentTypes,
        userSettings,
        users
      };
    };

    return (
      <DataFetcher<BhbProvidersData>
        fetch={fetch}
        fallback={<CenteredLargeSpinner />}
      >
        {data => <BhbProvidersFormInner {...data} />}
      </DataFetcher>
    );
  }
);
