import { ReactNode } from "react";
import { FormSpy } from "react-final-form";

import { SideRailMenuItem } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";

import { ClaimAdjustmentOverviewSection } from "./claim-adjustment-overview-section/ClaimAdjustmentOverviewSection.tsx";
import {
  ClaimAdjustmentSideRailLabels,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { DocumentationFormSection } from "./DocumentationFormSection.tsx";

interface ClaimAdjustmentFormProps {
  header: ReactNode;
}

export const ClaimAdjustmentFormView: React.FC<ClaimAdjustmentFormProps> = ({
  header
}) => {
  const context = useClaimAdjustmentContext();

  const sideRailMenuItems: SideRailMenuItem[] = [
    {
      text: ClaimAdjustmentSideRailLabels.overview,
      id: ClaimsAdjustmentCardIds.overview
    }
  ];

  if (context.isDocumentError) {
    sideRailMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.documentation,
      id: ClaimsAdjustmentCardIds.documentation
    });
  }

  return (
    <CardFormLayout sideRailMenuItems={sideRailMenuItems} header={header}>
      <FormSpy<ClaimAdjustmentFormValues>
        subscription={{ dirty: true, values: true }}
        onChange={({ values }) => {
          context.claimAdjustmentFormValues = values;
        }}
      />
      <ClaimAdjustmentOverviewSection />
      {context.isDocumentError && <DocumentationFormSection />}
    </CardFormLayout>
  );
};
