import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { CommandBarButton, IContextualMenuItem } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BookingCalendarEventContext } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/BookingCalendarEventContext.tsx";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

const PersonaRowFormMenuButtonBase: React.FC<{ contact: Contact }> = observer(
  ({ contact }) => {
    const {
      practice: {
        ui: { showContactDetails }
      }
    } = useStores();

    const { calendarEvent, addClaimBeforeFormDeploy, claim } = useContext(
      BookingCalendarEventContext
    );

    const formMenuItems = useFormMenu(contact, {
      claimId: claim?.id,
      addContextBeforeDeployAction: async (
        selectedTemplate,
        currentContext
      ) => {
        currentContext.CalendarEventId = calendarEvent.id;
        return addClaimBeforeFormDeploy(selectedTemplate, currentContext);
      }
    });

    const additionalAction: IContextualMenuItem = {
      key: "seeResponses",
      text: "See responses",
      onClick: () => {
        showContactDetails(contact.id, PatientCardIds.forms);
      }
    };

    return (
      <CommandBarButton
        iconProps={{ iconName: "ClipboardListMirrored" }}
        menuProps={{
          items: [...formMenuItems, additionalAction]
        }}
      />
    );
  }
);

export const PersonaRowFormMenuButton = observer(() => {
  const { core } = useStores();
  const { calendarEvent } = useContext(BookingCalendarEventContext);

  if (
    !core.hasPermissions(Permission.SendForms) ||
    calendarEvent.type !== CalendarEventType.Appointment ||
    !calendarEvent.contact ||
    calendarEvent.isGroupAppointment
  ) {
    return null;
  }

  return <PersonaRowFormMenuButtonBase contact={calendarEvent.contact} />;
});
