import { observer } from "mobx-react-lite";
import { FC } from "react";

import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

import { DidNotArriveItem } from "../DidNotArriveListScreen.types.ts";

export const DidNotArriveStatusBadge: FC<{
  item: DidNotArriveItem;
}> = observer(props => {
  let badgeColor = TextBadgeColor.red;
  let badgeLabel = "DNA";
  if (props.item.cancelled) {
    badgeColor = TextBadgeColor.lightGrey;
    badgeLabel = "Cancelled";
  }
  return (
    <TextBadge
      badgeColor={badgeColor}
      badgeSize={TextBadgeSize.medium}
      hasBorder={true}
      styles={{ root: { width: "120px" } }}
    >
      {badgeLabel}
    </TextBadge>
  );
});
