import {
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

import { OverFlowDropDownOptions } from "./PatientPillOverFlowButton.tsx";

interface PatientPillOverflowButtonPatientProps {
  item: OverFlowDropDownOptions;
}

export const PatientPillOverflowButtonPatient: React.FunctionComponent<
  PatientPillOverflowButtonPatientProps
> = ({ item }) => {
  const { clinical } = useStores();
  const theme = useTheme();
  const handlePatientClick = (id: string, encounterId?: string) => {
    clinical.ui.changeRecordPosition(id, encounterId);
  };
  return (
    <>
      {item.patientId && (
        <ContactFetcher
          key={`${item.key}-patientPill`}
          contactId={item.patientId!}
          fallback={<Spinner />}
        >
          {(patient: Contact) => {
            return (
              <Persona
                onClick={() => handlePatientClick(patient.id, item.encounterId)}
                text={patient.name}
                id={patient.id}
                imageUrl={patient.profilePictureUrl}
                size={PersonaSize.size24}
                imageInitials={patient.initials}
                styles={{
                  details: {
                    paddingRight: 0
                  },
                  root: {
                    width: "100%"
                  }
                }}
                coinProps={
                  item.encounterId
                    ? {}
                    : {
                        initialsColor: theme.palette.neutralTertiary
                      }
                }
                onRenderPrimaryText={() => (
                  <span>
                    {item.loading && <Spinner size={SpinnerSize.small} />}
                    {patient && `${patient.firstName} ${patient.lastName}`}
                  </span>
                )}
              />
            );
          }}
        </ContactFetcher>
      )}
    </>
  );
};
