import { ActionButton, Grid, Stack } from "@bps/fluent-ui";
import { MedicationsAndSupplementsClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { getContainerStyles } from "./MedicationsAndSupplementsList.styles.ts";

export const MedicationsAndSupplementsList: React.FunctionComponent = () => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const { fields: medicationsAndSupplements } =
    useFieldArray<MedicationsAndSupplementsClinicalDataItemDto>(
      "medicationsAndSupplements"
    );

  const removeMedication = (index: number) => {
    medicationsAndSupplements.remove(index);
  };

  const addNewMedication = () => {
    medicationsAndSupplements.push({ product: "", description: "" });
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {medicationsAndSupplements.map((name, index) => {
        return (
          <Stack horizontal grow key={name} tokens={{ childrenGap: 8 }}>
            <Fieldset
              frame
              styles={{
                root: getContainerStyles()
              }}
            >
              <Grid
                className="med-and-sup-grid"
                childrenTokens={{ gap: 8 }}
                verticalAlign="end"
              >
                <TextInputField
                  label="Medication / supplement"
                  name={`${name}.product`}
                  className="product-grid-item"
                  disabled={isViewOnlyOrDischarged}
                />
                <TextInputField
                  label="Description"
                  name={`${name}.description`}
                  className="description-grid-item"
                  disabled={isViewOnlyOrDischarged}
                />
              </Grid>
            </Fieldset>
            {!isViewOnlyOrDischarged && (
              <DeleteButton
                onClick={() => removeMedication(index)}
                disabled={medicationsAndSupplements.length === 1}
              />
            )}
          </Stack>
        );
      })}
      {!isViewOnlyOrDischarged && (
        <ActionButton
          iconProps={{ iconName: "Add" }}
          title="Add more"
          onClick={addNewMedication}
          styles={{ root: { width: 130 } }}
        >
          Add another
        </ActionButton>
      )}
    </Stack>
  );
};
