import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  FontSizes,
  ITextStyles,
  Spinner,
  Stack,
  Text,
  TooltipHostBase
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { User } from "@stores/core/models/User.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ConfidentialByProviderTextProps {
  secGroupId: string;
  styles?: ITextStyles;
  showIcon?: boolean;
}

export const ConfidentialByProviderText: React.FC<ConfidentialByProviderTextProps> =
  observer(({ secGroupId, styles, showIcon }) => {
    const { fetchUserOnSecGroupId } = usePatientRecordScreenContext();

    return (
      <Stack horizontal>
        {showIcon && (
          <TooltipHostBase content="Confidential">
            <FontIcon
              iconName="ShieldAlert"
              styles={{
                root: {
                  margin: "auto 4px",
                  fontSize: FontSizes.size16
                }
              }}
            />
          </TooltipHostBase>
        )}

        <DataFetcher<User | undefined>
          fallback={<Spinner />}
          fetch={async () => {
            return await fetchUserOnSecGroupId(secGroupId);
          }}
        >
          {(fetchUser: User | undefined) => (
            <Text styles={styles}>{`Confidential by ${
              fetchUser ? fetchUser.fullName : undefined
            }`}</Text>
          )}
        </DataFetcher>
      </Stack>
    );
  });
