import { action, observable, runInAction } from "mobx";

import { DateTime, getUniqueObjectsByKeys } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import {
  isAllocationDto,
  isCreditNoteDto,
  isInvoiceDto,
  isPaymentDto,
  isRefundDto,
  isWriteOffDto
} from "@libs/gateways/billing/billing-gateway.utils.ts";
import {
  AddAllocationDto,
  AddCreditNoteDto,
  AddInvoiceDto,
  AddInvoiceSettingsDto,
  AddPaymentDto,
  AddRefundDto,
  AddScheduleDto,
  AddServiceDto,
  AddStatementBulkDto,
  AddStatementDto,
  AddWriteOffDto,
  AllocationDto,
  BenefitSchedule,
  BillingStatuses,
  BillingStoredDocumentDto,
  BillingStoredInfo,
  CancelTransactionRequest,
  CreditNoteDto,
  DocumentUrlDto,
  DraftItemsTotalsDto,
  GetAccountBalanceDto,
  GetAccountSearchArgs,
  GetBillingStoredDocumentArgs,
  GetInvoiceItemsArgs,
  GetSchedulesArgsDto,
  GetServiceDto,
  GetServiceSearchDto,
  GetSingleBillingStoredDocumentArgs,
  GetStatementArgs,
  GetTransactionsArgs,
  InvoiceDto,
  InvoiceEmailDto,
  InvoiceItemDto,
  InvoiceSettingsDto,
  ItemType,
  PaymentDto,
  PaymentMethod,
  PaymentStatuses,
  RefundDto,
  ScheduleDto,
  ServiceDto,
  ServiceSearchDto,
  StatementDto,
  StatementEmailDto,
  TransactionBaseDto,
  UpdateInvoiceSettingDto,
  UpdateScheduleDto,
  UpdateServiceDto,
  UpsertInvoiceItemNewRequest,
  WriteOffDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { IBillingGateway } from "@libs/gateways/billing/BillingGateway.interface.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { StateFilter } from "@shared-types/core/state-filter.enum.ts";
import { TypeFilter } from "@shared-types/core/type-filter.enum.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { getRefundUnallocatedAmountDto } from "@stores/billing/utils/billing.utils.ts";
import {
  isAllocation,
  isCreditNote,
  isInvoice,
  isPayment,
  isRefund,
  isWriteOff
} from "@stores/billing/utils/transaction.utils.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { permission } from "@stores/decorators/permission.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { BillingRef } from "./BillingRef.ts";
import { BillingUi } from "./BillingUi.ts";
import { AccountBalance } from "./models/AccountBalance.ts";
import { Allocation } from "./models/Allocation.ts";
import { CreditNote } from "./models/CreditNote.ts";
import { Invoice } from "./models/Invoice.ts";
import { InvoiceSettings } from "./models/InvoiceSettings.ts";
import { Payment } from "./models/Payment.ts";
import { Refund } from "./models/Refund.ts";
import { Schedule } from "./models/Schedule.ts";
import { Service } from "./models/Service.ts";
import { Statement } from "./models/Statement.ts";
import { TransactionBase } from "./models/Transaction.ts";
import { WriteOff } from "./models/WriteOff.ts";

export class BillingStore implements Store<BillingStore, BillingRef> {
  constructor(
    private gateway: IBillingGateway,
    public hub: IHubGateway
  ) {
    this.ref = new BillingRef(this.gateway);
  }
  root: IRootStore;

  private get notification() {
    return this.root.notification;
  }

  ref: BillingRef;
  ui = new BillingUi();

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.InvoiceDocument, this.onOpenPdf);
    this.hub.onEntityEvent(Entity.Statement, this.onStatementEvent);
    this.hub.onEntityEvent(Entity.Transaction, this.onTransactionEvent);
    this.hub.onEntityEvent(Entity.InvoiceItemDelete, this.onDraftItemsEvent);
  }

  invoiceSettingsMap = observable.map<string, InvoiceSettings>();
  schedulesMap = observable.map<string, Schedule>();
  servicesMap = observable.map<string, Service>();
  statementsMap = observable.map<string, Statement>();

  pdfIds: string[] = [];

  storedDocumentsByContactId = new Map<string, BillingStoredInfo[]>();

  billingStoredDocumentsMap = new Map<string, BillingStoredDocumentDto>();

  transactionsNewMap = observable.map<string, TransactionBase>();

  get gstPercent(): number {
    // note this isn't automatically loaded on app start
    // loadInvoiceSettings needs to be called before it is used
    if (this.invoiceSettingsMap?.size) {
      return Array.from(this.invoiceSettingsMap.values())[0].gstPercent / 100;
    }

    throw new Error("Cannot find gst rate - invoice settings are not loaded");
  }

  getInvoiceSettings = async (): Promise<InvoiceSettings[]> => {
    const promise = this.gateway.getInvoiceSettings().then(
      action(dtos => {
        return dtos.map(this.mergeInvoiceSettings);
      })
    );

    try {
      await promise;
    } catch (e) {
      this.notification.error(e);
    }

    return promise;
  };

  @action
  addInvoiceSettings = async (
    data: AddInvoiceSettingsDto
  ): Promise<InvoiceSettings> => {
    try {
      const dto = await this.gateway.addInvoiceSetting(data);
      this.notification.success(notificationMessages.invoiceSettingsSaved);
      return this.mergeInvoiceSettings(dto);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  @action
  updateInvoiceSettings = async (
    data: UpdateInvoiceSettingDto
  ): Promise<InvoiceSettings> => {
    const updatedDto = await this.gateway.updateInvoiceSetting(data);
    const invoiceSettings = this.mergeInvoiceSettings(updatedDto);
    this.notification.success(notificationMessages.invoiceSettingsUpdated);
    return invoiceSettings;
  };

  @action
  getAccountBalanceForAccountId = async (
    id: string
  ): Promise<AccountBalance> => {
    return await this.getAccountBalance({ accountId: id });
  };

  @action
  getAccountBalance = async (
    dto: GetAccountBalanceDto
  ): Promise<AccountBalance> => {
    const promise = this.gateway
      .getAccountBalance(dto)
      .then(b => new AccountBalance(b));

    try {
      await promise;
    } catch (e) {
      this.notification.error(e);
    }
    return promise;
  };

  @action
  getAccountDraftItemsTotals = async (
    accountId: string
  ): Promise<DraftItemsTotalsDto> => {
    return await this.gateway.getDraftItemsTotals(accountId);
  };

  @action
  private mergeInvoiceSettings = (dto: InvoiceSettingsDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new InvoiceSettings(dto),
      map: this.invoiceSettingsMap
    });
  };

  addInvoice = async (data: AddInvoiceDto): Promise<Invoice> => {
    return await this.gateway.addInvoice(data).then(this.mergeInvoice);
  };

  cancelInvoice = async (id: string, reason: string) => {
    return this.gateway.cancelInvoice(id, reason);
  };

  adjustInvoice = async (
    invoice: AddInvoiceDto,
    id: string,
    reason: string
  ) => {
    const adjustedInvoice = await this.gateway
      .adjustInvoice(invoice, id, reason)
      .then(this.mergeInvoice);

    this.notification.success(
      notificationMessages.invoiceAdjusted(invoice.number)
    );
    return adjustedInvoice;
  };

  openInvoicePdf = async (invoiceId: string) => {
    try {
      await this.gateway.openInvoicePdf(invoiceId);
      this.pdfIds.push(invoiceId);
      this.notification.warn(notificationMessages.pdfIsBeingGenerated);
    } catch (e) {
      this.notification.error(e);
    }
  };

  sendInvoicePdf = async (invoiceEmails: InvoiceEmailDto[]) => {
    try {
      await this.gateway.sendInvoicePdf(invoiceEmails);
      this.pdfIds.push(...invoiceEmails.map(({ invoiceId }) => invoiceId));
      this.notification.success(notificationMessages.emailIsBeingSent);
    } catch (e) {
      this.notification.error(e);
    }
  };

  openAllocationReceiptPdf = async (allocationId: string) => {
    try {
      await this.gateway.openAllocationReceiptPdf(allocationId);
      this.pdfIds.push(allocationId);
      this.notification.warn(notificationMessages.pdfIsBeingGenerated);
    } catch (e) {
      this.notification.error(e);
    }
  };

  openPaymentReceiptPdf = async (paymentId: string) => {
    try {
      await this.gateway.openPaymentReceiptPdf(paymentId);
      this.pdfIds.push(paymentId);
      this.notification.warn(notificationMessages.pdfIsBeingGenerated);
    } catch (e) {
      this.notification.error(e);
    }
  };

  openStatementPdf = (statementId: string) => {
    this.pdfIds.push(statementId);
  };

  private onOpenPdf = async (event: EntityEventData) => {
    if (this.pdfIds.includes(event.id)) {
      if (event.action === EventAction.Create) {
        try {
          this.pdfIds = this.pdfIds.filter(id => id !== event.id);
          const url: string = await this.gateway.getSasUrl(event.key);

          const invoice = await this.getInvoice(event.id);

          this.ui.setPreviewDocumentData({
            documentId: event.id,
            patientId: invoice.patientId,
            invoiceNumber: invoice.number,
            url
          });
        } catch (e) {
          this.notification.error(e, {
            messageOverride: notificationMessages.pdfCannotBeOpened
          });
        }
      } else {
        this.notification.error(notificationMessages.pdfIsNotCreated);
      }
    }
  };

  getContactWithServices = async (
    patientId: string,
    searchDate: Date,
    options?: {
      maxListLength?: number;
      maxInvoiceSearch?: number;
    }
  ): Promise<{
    patient: Contact;
    items: ServiceSearchDto[];
    previousItems: InvoiceItemDto[];
  }> => {
    const contactPromise = this.root.practice.getContact(patientId);
    const maxListLength = options?.maxListLength ?? 4;
    const maxInvoiceSearch = options?.maxInvoiceSearch ?? 3;

    const invoicesPromise = this.fetchInvoicesNew({
      patientId,
      billingStatuses: [BillingStatuses.current],
      take: maxInvoiceSearch,
      skip: 0
    });

    const [patient, invoices] = await Promise.all([
      contactPromise,
      invoicesPromise
    ]);

    const invoiceItems = getUniqueObjectsByKeys({
      array: invoices.results.flatMap(invoice => invoice.items || []),
      keys: ["code"]
    })
      .slice(0, maxListLength)
      .sort((a, b) => ((a.code || "") < (b.code || "") ? -1 : 1));

    const serviceIds = invoiceItems
      .map(({ serviceId }) => serviceId)
      .filter(x => x);

    if (serviceIds.length === 0) {
      return { patient, items: [], previousItems: [] };
    } else {
      const items = await this.getServiceSearch({
        serviceIds,
        effectiveDate: DateTime.jsDateToISODate(searchDate)
      });

      return {
        patient,
        items: items.filter(x => x) as ServiceSearchDto[],
        previousItems: invoiceItems
      };
    }
  };

  @action
  private mergeServices = (dto: ServiceDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Service(this.root, dto),
      map: this.servicesMap
    });
  };

  @action
  private mergeSchedules = (dto: ScheduleDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Schedule(this.root, dto),
      map: this.schedulesMap
    });
  };

  @action
  getService = async (
    id: string,
    options?: { ignoreCache?: boolean }
  ): Promise<Service | undefined> => {
    if (!options?.ignoreCache && this.servicesMap.has(id)) {
      return this.servicesMap.get(id);
    }

    try {
      const serviceDto = await this.gateway.getService(id);
      return this.mergeServices(serviceDto);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  @action
  getServices = async (
    request: GetServiceDto
  ): Promise<QueryResult<Service>> => {
    request.effectiveDate = DateTime.now().toISODate();

    if (request.isService) {
      request.isService = request.isService.toString() === TypeFilter.service;
    }

    if (request.isActive) {
      request.isActive = request.isActive.toString() === StateFilter.active;
    }

    return this.gateway.getServices(request).then(dtoResult => {
      const { results, ...rest } = dtoResult;
      const services = results.map(this.mergeServices);
      return {
        results: services,
        ...rest
      };
    });
  };

  addService = async (data: AddServiceDto): Promise<Service> => {
    const serviceDto = await this.gateway.addService(data);
    return this.mergeServices(serviceDto);
  };

  updateService = async (data: UpdateServiceDto): Promise<Service> => {
    const serviceDto = await this.gateway.updateService(data);
    return this.mergeServices(serviceDto);
  };

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getSchedules(
    request: Partial<GetSchedulesArgsDto> = {}
  ): Promise<Schedule[]> {
    try {
      const args = {
        ...request,
        effectiveDate: request.effectiveDate || DateTime.now().toISODate()
      };

      let schedulesDtos = await this.gateway.getSchedulesByArgs(args);

      if (
        !this.root.core.hasPermissions([
          Permission.PreReleaseAccContractServices
        ])
      ) {
        schedulesDtos = schedulesDtos.filter(
          s => s.benefitSchedule !== BenefitSchedule.ACC
        );
      }

      const schedules = schedulesDtos.map(this.mergeSchedules);

      // this filtering is a temporary measure while the API gets updated
      return getUniqueObjectsByKeys({
        array: schedules,
        keys: ["id"]
      });
    } catch (e) {
      this.notification.error(e);
    }

    return [];
  }

  @sharePendingPromise()
  async getSchedule(
    id: string,
    options: { ignoreCache?: boolean } = { ignoreCache: false }
  ): Promise<Schedule | undefined> {
    if (!options.ignoreCache && this.schedulesMap.has(id)) {
      return this.schedulesMap.get(id)!;
    }

    const scheduleDtos = await this.gateway.getSchedulesByArgs({
      scheduleIds: [id],
      effectiveDate: DateTime.now().toISODate()
    });

    return this.mergeSchedules(scheduleDtos[0]);
  }

  addSchedule = async (data: AddScheduleDto): Promise<Schedule> => {
    const scheduleDto = await this.gateway.addSchedule(data);
    return this.mergeSchedules(scheduleDto);
  };

  updateSchedule = async (
    data: UpdateScheduleDto
  ): Promise<Schedule | undefined> => {
    const scheduleDto = await this.gateway.updateSchedule(
      data,
      DateTime.now().toISODate()
    );
    return this.mergeSchedules(scheduleDto);
  };

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async loadSchedules(options?: {
    ignoreCache?: boolean;
  }): Promise<Schedule[]> {
    if (options?.ignoreCache || !this.schedulesMap.size) {
      return await this.getSchedules();
    }

    return Array.from(this.schedulesMap.values());
  }

  @action
  async getServiceSearch(
    request: GetServiceSearchDto
  ): Promise<ServiceSearchDto[]> {
    try {
      return await this.gateway.getServiceSearch(request);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  markInvoiceSent = async (ids: string[]) => {
    return this.gateway.markInvoiceSent(ids);
  };

  sendAllocationEmail = async (allocationId: string, email?: string) => {
    try {
      await this.gateway.sendAllocationEmail(allocationId, email);
      this.pdfIds.push(allocationId);
      this.notification.success(notificationMessages.emailIsBeingSent);
    } catch (e) {
      this.notification.error(e);
    }
  };

  sendPaymentEmail = async (paymentId: string, email?: string) => {
    try {
      await this.gateway.sendPaymentEmail(paymentId, email);
      this.pdfIds.push(paymentId);
      this.notification.success(notificationMessages.emailIsBeingSent);
    } catch (e) {
      this.notification.error(e);
    }
  };

  @action
  private onDraftItemsEvent = (event: EntityEventData) => {
    if (event.action === EventAction.Delete) {
      this.ui.lastDraftInvoiceItemChange = event.etag + event.id;
    }
  };

  @action
  private onStatementEvent = async (event: EntityEventData) => {
    try {
      const statement = this.statementsMap.get(event.id);

      if (event.id != null) {
        if (
          event.action === EventAction.Create ||
          event.action === EventAction.Update
        ) {
          if (!statement || statement.eTag !== event.etag) {
            await this.getStatement(event.id, { ignoreCache: true });
          }
          runInAction(() => {
            this.ui.lastUpdatedStatementETag = event.etag;
          });
        }

        if (event.action === EventAction.Delete) {
          runInAction(() => {
            this.statementsMap.delete(event.id);
            this.ui.lastUpdatedStatementETag = event.etag + event.id;
          });
        }

        // Statement rendering event handler. Setting statementDocumentPreviewData to open document previewer.
        if (event.action === EventAction.DocumentCommitted) {
          const [statementId, documentId] = event.key.split(":");
          if (statementId && documentId && this.pdfIds.includes(statementId)) {
            this.ui.setStatementDocumentPreviewData({
              contactId: statement?.accountContactId,
              statementId,
              documentId,
              statement
            });
          }

          if (statement && statement.accountContactId) {
            this.updateStoredDocuments(
              statement.accountContactId,
              statementId,
              documentId
            );
          } else {
            const newStatement = await this.getStatement(event.id, {
              ignoreCache: true
            });
            if (newStatement && newStatement.accountContactId) {
              this.updateStoredDocuments(
                newStatement.accountContactId,
                statementId,
                documentId
              );
            }
          }
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private updateStoredDocuments(
    contactId: string,
    statementId: string,
    documentId: string
  ) {
    if (this.storedDocumentsByContactId.has(contactId)) {
      const currentValue = this.storedDocumentsByContactId.get(contactId);
      currentValue?.push({ statementId, documentId });
    } else {
      this.storedDocumentsByContactId.set(contactId, [
        { statementId, documentId }
      ]);
    }
  }

  @action
  private mergeStatements = (dto: StatementDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Statement(dto),
      map: this.statementsMap
    });
  };

  @action
  getStatement = async (
    id: string,
    options?: { ignoreCache?: boolean }
  ): Promise<Statement | undefined> => {
    if (!options?.ignoreCache && this.statementsMap.has(id)) {
      return this.statementsMap.get(id);
    }

    try {
      const statementDto = await this.gateway.getStatement(id);
      return this.mergeStatements(statementDto);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  public addStatement = async (data: AddStatementDto): Promise<Statement> => {
    const result = await this.gateway.addStatement(data);
    await this.openStatementPdf(result.id);
    return new Statement(result);
  };

  public addStatementBulk = async (
    data: AddStatementBulkDto
  ): Promise<void> => {
    return this.gateway.addStatementBulk(data);
  };

  public getStatements = (
    request: GetStatementArgs
  ): Promise<QueryResult<Statement>> => {
    return this.gateway.getStatements(request).then(result => {
      const { results, ...rest } = result;
      return {
        results: results.map(this.mergeStatements),
        ...rest
      };
    });
  };

  public getAccountIds = async (
    request: GetAccountSearchArgs
  ): Promise<QueryResult<{ id: string }>> => {
    const result = await this.gateway.getAccountIds(request);

    return result;
  };

  public async getLatestAccountStatement(
    request: GetStatementArgs
  ): Promise<Statement | undefined> {
    const results = await this.gateway.getStatements(request);

    const latestStatement = results.results[0];
    if (latestStatement) {
      return this.mergeStatements(latestStatement);
    }
    return undefined;
  }

  public getLastDateStatement() {
    return this.gateway.getStatementLastRunDate();
  }

  public getBillingStoredDocumentUrl = async (
    args: GetBillingStoredDocumentArgs
  ): Promise<DocumentUrlDto> => {
    const { statementId } = args;
    // if statementId -> check for cached statementDocumentUrl in transactionsMap
    if (statementId) {
      const statement = this.statementsMap.get(statementId);
      if (statement?.statementDocumentUrl) {
        return { url: statement?.statementDocumentUrl };
      }
    }

    try {
      const data = await this.gateway.getBillingStoredDocumentUrl(args);
      // id statementId -> update Statement model with statementDocumentUrl
      if (statementId) {
        const statement = this.statementsMap.get(statementId);

        if (statement && !statement?.statementDocumentUrl) {
          statement?.setStatementDocumentUrl(data.url);
        }
      }

      return data;
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  async getBillingStoredDocument(
    request: GetSingleBillingStoredDocumentArgs
  ): Promise<BillingStoredDocumentDto> {
    try {
      const existingBillingStoredDocument = this.billingStoredDocumentsMap.get(
        request.statementId
      );
      if (existingBillingStoredDocument) {
        return existingBillingStoredDocument;
      }

      const result = await this.gateway.getBillingStoredDocument(request);

      this.billingStoredDocumentsMap.set(request.statementId, result);

      return result;
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  sendStatementEmail = async (args: StatementEmailDto) => {
    try {
      await this.gateway.sendStatementEmail(args);
      this.notification.success(notificationMessages.emailIsBeingSent);
    } catch (e) {
      this.notification.error(e);
    }
  };

  deleteStatementById = async (id: string): Promise<void> => {
    await this.gateway.deleteStatementById(id);
  };

  @action
  private onTransactionEvent = async (event: EntityEventData) => {
    if (event.id != null && event.action === EventAction.Adjusted) {
      return await this.handleInvoiceAdjustEvent(event);
    }
    if (event.id != null && event.action === EventAction.Delete) {
      runInAction(() => {
        this.transactionsNewMap.delete(event.id);
        this.ui.lastAddedTransactionEtag = event.etag + event.id;
      });
    }
    if (
      event.id != null &&
      (event.action === EventAction.Create ||
        event.action === EventAction.Update ||
        event.action === EventAction.Cancelled ||
        event.action === EventAction.UpdateAggregate)
    ) {
      const transaction = this.transactionsNewMap.get(event.id);
      if (transaction && transaction.eTag !== event.etag) {
        if (isWriteOff(transaction)) {
          await this.getWriteOff(event.id, { ignoreCache: true });
        } else if (isCreditNote(transaction)) {
          await this.getCreditNote(event.id, { ignoreCache: true });
        } else if (isInvoice(transaction)) {
          runInAction(() => {
            this.ui.lastUpdatedInvoiceETag = event.etag;
          });
          await this.getInvoice(event.id, { ignoreCache: true });
        } else if (isAllocation(transaction)) {
          await this.getAllocation(event.id, { ignoreCache: true });
        } else if (isPayment(transaction)) {
          await this.getPayment(event.id, { ignoreCache: true });
        }
        runInAction(() => {
          this.ui.lastAddedTransactionEtag = event.etag + event.id;
        });
      } else {
        runInAction(() => {
          this.ui.lastDraftInvoiceItemChange = event.etag + event.id;
        });
      }
    }
  };

  private handleInvoiceAdjustEvent = async (event: EntityEventData) => {
    const invoiceAdjustedId = event.key[0];
    const transaction = this.transactionsNewMap.get(invoiceAdjustedId);
    if (transaction && isInvoice(transaction)) {
      await this.getInvoice(invoiceAdjustedId, { ignoreCache: true });
    }
  };

  @action
  private mergeWriteOff = (dto: WriteOffDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new WriteOff(dto),
      map: this.transactionsNewMap
    }) as WriteOff;
  };

  @action
  private mergeAllocation = (dto: AllocationDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Allocation(dto),
      map: this.transactionsNewMap
    }) as Allocation;
  };

  @action
  private mergeCreditNote = (dto: CreditNoteDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new CreditNote(dto),
      map: this.transactionsNewMap
    }) as CreditNote;
  };

  @action
  private mergeRefund = (dto: RefundDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Refund(dto),
      map: this.transactionsNewMap
    }) as Refund;
  };

  @action
  private mergePayment = (dto: PaymentDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Payment(dto),
      map: this.transactionsNewMap
    }) as Payment;
  };

  @action
  private mergeInvoice = (dto: InvoiceDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Invoice(this.root, dto),
      map: this.transactionsNewMap
    }) as Invoice;
  };

  mergeTransaction = (transaction: TransactionBaseDto) => {
    if (isWriteOffDto(transaction)) {
      return this.mergeWriteOff(transaction);
    } else if (isCreditNoteDto(transaction)) {
      return this.mergeCreditNote(transaction);
    } else if (isAllocationDto(transaction)) {
      return this.mergeAllocation(transaction);
    } else if (isInvoiceDto(transaction)) {
      return this.mergeInvoice(transaction);
    } else if (isPaymentDto(transaction)) {
      return this.mergePayment(transaction);
    } else if (isRefundDto(transaction)) {
      return this.mergeRefund(transaction);
    } else {
      throw new Error("Unknown transaction type");
    }
  };

  fetchTransactionsNew = (
    filter: GetTransactionsArgs
  ): Promise<QueryResult<TransactionBase>> => {
    return this.gateway.getTransactionsNew(filter).then(dtoResult => {
      const { results, ...rest } = dtoResult;
      return {
        results: results.map(this.mergeTransaction),
        ...rest
      };
    });
  };

  fetchInvoicesNew = (
    filter: GetTransactionsArgs
  ): Promise<QueryResult<Invoice>> => {
    return this.fetchTransactionsNew({
      ...filter,
      itemTypes: [ItemType.Invoice]
    }).then(result => {
      const { results, ...rest } = result;
      return { results: results.filter(isInvoice), ...rest };
    });
  };

  @permission([Permission.WriteOffAllowed, Permission.AccountHistoryAllowed])
  public async getWriteOff(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<WriteOff> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isWriteOff(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<WriteOffDto>(id);
    return this.mergeWriteOff(result);
  }

  @permission([Permission.WriteOffAllowed, Permission.WriteOffCreate])
  public async addWriteOff(data: AddWriteOffDto): Promise<WriteOff> {
    const result = await this.gateway.addWriteOff(data);
    this.notification.success(notificationMessages.addWriteOff(result.number));

    return this.mergeWriteOff(result);
  }

  @permission([Permission.WriteOffAllowed, Permission.WriteOffCreate])
  public async generateWriteOffNumber(): Promise<string> {
    return this.gateway.generateWriteOffNumber();
  }

  @permission([Permission.WriteOffAllowed, Permission.WriteOffCancel])
  public async cancelWriteOff(
    request: CancelTransactionRequest
  ): Promise<WriteOff> {
    const result = await this.gateway.cancelWriteOffs(request);
    this.notification.success(
      notificationMessages.cancelWriteOff(result.number)
    );
    return this.mergeWriteOff(result);
  }

  @permission([Permission.RefundAllowed, Permission.RefundCreate])
  public async addRefund(data: AddRefundDto): Promise<Refund> {
    const result = await this.gateway.addRefund(data);
    this.notification.success(notificationMessages.addRefund(result.number));
    return this.mergeRefund(result);
  }

  @permission([Permission.RefundAllowed, Permission.RefundCreate])
  public async generateRefundNumber(): Promise<string> {
    return this.gateway.generateRefundNumber();
  }

  public async refundUnallocatedCredit(
    creditNote: CreditNote | Payment,
    paymentMethod?: PaymentMethod
  ) {
    const refundNumber = await this.generateRefundNumber();

    return await this.addRefund(
      getRefundUnallocatedAmountDto(creditNote, {
        refundNumber,
        paymentMethod
      })
    );
  }

  @permission([Permission.CreditAllowed, Permission.AccountHistoryAllowed])
  public async getCreditNote(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<CreditNote> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isCreditNote(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<CreditNoteDto>(id);
    return this.mergeCreditNote(result);
  }

  @permission([Permission.CreditAllowed, Permission.CreditCreate])
  public async addCreditNote(data: AddCreditNoteDto): Promise<CreditNote> {
    const result = await this.gateway.addCreditNote(data);
    this.notification.success(
      notificationMessages.addCreditNote(result.number)
    );
    return this.mergeCreditNote(result);
  }

  @permission([Permission.CreditAllowed, Permission.CreditCreate])
  public async generateCreditNoteNumber(): Promise<string> {
    const result = await this.gateway.generateCreditNoteNumber();
    return result;
  }

  public async getAllocation(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Allocation> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isAllocation(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<AllocationDto>(id);
    return this.mergeAllocation(result);
  }

  public async addAllocation(data: AddAllocationDto): Promise<Allocation> {
    const result = await this.gateway.addAllocation(data);
    this.notification.success(
      notificationMessages.addAllocation(result.number)
    );
    return this.mergeAllocation(result);
  }

  public async generateAllocationNumber(): Promise<string> {
    const result = await this.gateway.generateAllocationNumber();
    return result;
  }

  public async cancelAllocation(id: string, reason: string) {
    try {
      return this.gateway.cancelAllocation(id, reason);
    } catch (e) {
      this.notification.error(e);
    }
  }

  @permission([Permission.AccountHistoryAllowed])
  public async getPayment(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Payment> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isPayment(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<PaymentDto>(id);
    return this.mergePayment(result);
  }

  public async addPayment(data: AddPaymentDto): Promise<Payment> {
    const result = await this.gateway.addPayment(data);
    this.notification.success(notificationMessages.addPayment(result.number));
    return this.mergePayment(result);
  }

  public async cancelPayment(id: string, reason: string) {
    try {
      return this.gateway.cancelPayment(id, reason);
    } catch (e) {
      this.notification.error(e);
    }
  }

  public async getRefund(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Refund> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isRefund(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<RefundDto>(id);
    return this.mergeRefund(result);
  }

  public async cancelRefund(id: string, reason: string, number?: string) {
    try {
      const result = await this.gateway.cancelRefund(id, reason);
      number &&
        this.notification.success(
          notificationMessages.refundCancelled(result.number)
        );
    } catch (e) {
      this.notification.error(e);
    }
  }

  public getInvoiceFromMap(id: string): Invoice | undefined {
    const invoice = this.transactionsNewMap.get(id);

    if (invoice && isInvoice(invoice)) {
      return invoice;
    }

    return undefined;
  }

  public getServicesFromMap(ids: string[]): Service[] {
    return Array.from(this.servicesMap.values()).filter(s =>
      ids.includes(s.id)
    );
  }

  public async getInvoice(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Invoice> {
    if (!options.ignoreCache) {
      const transaction = this.transactionsNewMap.get(id);
      if (transaction && isInvoice(transaction)) {
        return transaction;
      }
    }

    const result = await this.gateway.getTransaction<InvoiceDto>(id);
    await this.getServices({ serviceIds: result.items.map(i => i.serviceId) });
    return this.mergeInvoice(result);
  }

  public async getOutstandingInvoices(
    accountContactId: string
  ): Promise<Invoice[]> {
    return this.fetchTransactionsNew({
      accountIds: [accountContactId],
      itemTypes: [ItemType.Invoice],
      paymentStatuses: [PaymentStatuses.unpaid, PaymentStatuses.part]
    }).then(invoicesResponse => invoicesResponse.results.filter(isInvoice));
  }

  public async getAdjustedInvoice(
    invoiceNumber: string
  ): Promise<Invoice | undefined> {
    const result = await this.fetchTransactionsNew({
      number: invoiceNumber,
      itemTypes: [ItemType.Invoice],
      billingStatuses: [BillingStatuses.current, BillingStatuses.cancelled]
    });

    const invoice = result.results.length > 0 ? result.results[0] : undefined;
    if (invoice && isInvoice(invoice)) {
      return invoice;
    }

    return undefined;
  }

  public fetchInvoiceItems(
    filter: GetInvoiceItemsArgs
  ): Promise<QueryResult<InvoiceItemDto>> {
    return this.gateway.getInvoiceItems(filter).then(dtoResult => {
      const { results, ...rest } = dtoResult;
      return {
        results,
        ...rest
      };
    });
  }

  public async upsertInvoiceItems(
    req: UpsertInvoiceItemNewRequest
  ): Promise<InvoiceItemDto[]> {
    return this.gateway.upsertInvoiceItems(req);
  }

  public async deleteBulkDraftItems(invoiceItemsIds: string[]): Promise<void> {
    try {
      await this.gateway.deleteBulkDraftItems(invoiceItemsIds);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  public fetchPreferencesAndDocument = async ({
    contactId,
    statementId
  }: GetSingleBillingStoredDocumentArgs): Promise<{
    contactPreferences: ContactPreferences | undefined;
    storedDocument: BillingStoredDocumentDto;
  }> => {
    const [contactPreferences, storedDocument] = await Promise.all([
      this.root.comms.getContactPreference(contactId),
      this.root.billing.getBillingStoredDocument({
        contactId,
        statementId
      })
    ]);
    return { contactPreferences, storedDocument };
  };

  public getDraftItems(
    calendarEventId: string,
    patientId?: string
  ): Promise<InvoiceItemDto[]> {
    return this.fetchInvoiceItems({
      calendarEventIds: [calendarEventId],
      ...(patientId ? { patientIds: [patientId] } : undefined),
      draftOnly: true
    }).then(queryResult => queryResult.results);
  }

  public async generateInvoiceNumber(): Promise<string> {
    const result = await this.gateway.generateInvoiceNumber();
    return result;
  }

  public async generatePaymentNewNumber(): Promise<string> {
    const result = await this.gateway.generateAllocationNumber();
    return result;
  }
}
