import { observer } from "mobx-react-lite";
import { useState } from "react";

import { DateRangeType } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

export interface DidNotArriveListFilterProps
  extends Pick<FilterBarProps<DidNotArriveListFilter>, "children"> {}

export interface DidNotArriveListFilter {
  search?: string;
  providers: string[];
  locations: string[];
  dateRange?: string;
  status?: string[];
  patientName?: string;
  apptDate: Date;
}

const dateRangeOptions = [
  { key: DateRangeType.Day.toString(), text: "Day" },
  { key: DateRangeType.Week.toString(), text: "Week" }
];

const statusOptions = [
  { key: "CANCELLED", text: "Cancelled" },
  { key: "NA", text: "Did not attend" }
];

const DidNotArriveDetailFilterBase: React.FC<DidNotArriveListFilterProps> =
  observer(({ children }) => {
    const { startDate } = useBookingCalendarScreenContext();
    const { core } = useStores();

    const defaultDidNotArriveListFilter: DidNotArriveListFilter = {
      apptDate: startDate.startOf("day").toJSDate(),
      dateRange: dateRangeOptions[0].key,
      providers: [],
      locations: []
    };

    const [dateRangeType, setDateRangeType] = useState<DateRangeType>(
      DateRangeType.Day
    );

    const getItems = () => {
      const items: Item<DidNotArriveListFilter>[] = [
        {
          type: "searchBox",
          name: "patientName",
          stickItem: true,
          props: {
            id: "noShow-filter-patients",
            placeholder: "Search for patient"
          }
        },
        {
          type: "optionsSelect",
          name: "dateRange",
          props: {
            id: "noShow-filter-appointmentDateRange",
            placeholder: "Type",
            options: dateRangeOptions,
            multiSelect: false,
            calloutWidth: 200,
            hideSearchOption: true,
            hideClearButton: true
          }
        },
        {
          type: "datePicker",
          name: "apptDate",
          props: {
            readOnlyInFormat: true,
            dateRangeType,
            placeholder: "Date",
            styles: { field: { fontWeight: 600 } }
          }
        },
        {
          type: "bookableUsersSelect",
          name: "providers",
          props: {
            id: "noShow-filter-providers",
            multiSelect: true,
            usersFilter: { showOnCalendar: true },
            placeholder: "Providers",
            calloutWidth: 250
          }
        },
        {
          type: "optionsSelect",
          name: "status",
          props: {
            id: "noShow-filter-appointmentStatus",
            placeholder: "Status",
            options: statusOptions,
            multiSelect: true,
            calloutWidth: 200
          }
        }
      ];

      if (core.hasMultipleActiveLocations) {
        items.push({
          type: "locationsSelect",
          name: "locations",
          props: {
            id: "noShow-filter-locations",
            multiSelect: true,
            placeholder: "Locations",
            calloutWidth: 250
          }
        });
      }
      return items;
    };

    return (
      <FilterBar<DidNotArriveListFilter>
        initialValues={{
          ...defaultDidNotArriveListFilter
        }}
        onChange={({ actions, field, value }) => {
          if (field === "apptDate" && !value) {
            actions.setValue("apptDate", startDate.toJSDate());
            return;
          }
          if (field === "dateRange") {
            if (value === DateRangeType.Day.toString()) {
              setDateRangeType(DateRangeType.Day);
            } else {
              setDateRangeType(DateRangeType.Week);
            }
          }
        }}
        maxItemWidth={130}
        items={getItems()}
        styles={{
          peoplePicker: { minWidth: 200 }
        }}
        children={children}
      />
    );
  });

export const DidNotArriveDetailFilter = withFetch(
  x => [x.booking.loadAppointmentTypes()],
  DidNotArriveDetailFilterBase
);
