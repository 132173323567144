import { isEqual } from "lodash";
import React from "react";
import { useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  IStackStyles,
  noWrap,
  Stack
} from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { AccTerminologyDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFieldArray } from "@libs/utils/name-of.utils.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { ReferralDiagnosisFormValues } from "@shared-types/acc/referral-diagnosis-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

import { ClaimsNonStandardBadge } from "./ClaimsNonStandardBadge.tsx";
import { TerminologyPicker } from "./TerminologyPicker.tsx";

interface DiagnosisFieldProps {
  name: string;
  showNonStandardBadge?: boolean;
  showStatusCol: boolean;
  button?: React.ReactNode;
  filters?: {
    terminologyFilter?: (
      searchResults: AccTerminologyDto[]
    ) => AccTerminologyDto[];
    sideFilter?: (value: KeyTextValue) => boolean;
  };
  diagnosisLabel?: string;
  sideLabel?: string;
  badgeStyles?: IStackStyles;
  disabled?: boolean;
}

const DiagnosisFieldBase: React.FC<DiagnosisFieldProps> = ({
  name,
  button,
  filters,
  showNonStandardBadge,
  showStatusCol,
  diagnosisLabel,
  sideLabel,
  badgeStyles,
  disabled
}) => {
  const { acc } = useStores();

  const fieldName = nameOfFieldArray<ReferralDiagnosisFormValues>(name);

  const form = useForm();

  const {
    input: { value: field }
  } = useField<ClaimDiagnosisFormValues>(name, { isEqual });

  const sideItems = !filters?.sideFilter
    ? acc.ref.diagnosisSides.keyTextValues
    : acc.ref.diagnosisSides.keyTextValues.filter(filters.sideFilter);

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      verticalAlign="start"
      {...dataAttribute(DataAttributes.Element, "diagnosis-field")}
    >
      {showNonStandardBadge && <ClaimsNonStandardBadge styles={badgeStyles} />}
      <TerminologyPicker
        inputProps={{
          placeholder: "Search for read code or description"
        }}
        terminology={field}
        onChange={(_, item) => {
          const diagnosisValues = {
            ...field,
            diagnosisDescription: item?.text,
            diagnosisCode: item?.code,
            diagnosisKey: item?.diagnosisKey,
            aCCAcceptable: item?.aCCAcceptable,
            aCC32Acceptable: item?.aCC32Acceptable,
            readCode: item?.readCode,
            codeSystem: item?.codeSystem,
            version: item?.version
          };

          form.change(name, diagnosisValues);
        }}
        label={diagnosisLabel}
        filter={filters?.terminologyFilter}
        name={fieldName("diagnosisKey")}
        hint={field.readCode ? ` Read code: ${field.readCode}` : undefined}
        fieldItemStyles={{ root: { flexGrow: 1, ...noWrap } }}
        disabled={disabled}
      />
      <DropdownField
        label={sideLabel}
        name={fieldName("diagnosisSide")}
        options={sideItems}
        styles={{ root: { width: 132 } }}
        disabled={disabled}
      />
      {showStatusCol && (
        <DropdownField
          name={fieldName("diagnosisStatus")}
          options={acc.ref.diagnosisStatuses.keyTextValues}
          styles={{ root: { width: 121 } }}
        />
      )}
      {button}
    </Stack>
  );
};

export const DiagnosisField = withFetch(
  x => [x.acc.ref.diagnosisSides.load(), x.acc.ref.diagnosisStatuses.load()],
  DiagnosisFieldBase
);
