import {
  ClinicalDataType,
  ObservationType,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface ClinicalDataMap {
  clinicalDataType: ClinicalDataType;
  readOnlyView: ClinicalDataType;
  questionnaireType?: QuestionnaireType;
}

export class ClinicalDataToObservationConverter {
  private _clinicalDataTypeObservationMap: Map<
    ObservationType,
    ClinicalDataMap
  >;

  private dataMap(): Map<ObservationType, ClinicalDataMap> {
    if (!this._clinicalDataTypeObservationMap) {
      this._clinicalDataTypeObservationMap = new Map();
      this._clinicalDataTypeObservationMap.set(ObservationType.K10, {
        clinicalDataType: ClinicalDataType.K10,
        readOnlyView: ClinicalDataType.K10View,
        questionnaireType: QuestionnaireType.K10V1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.NPRS, {
        clinicalDataType: ClinicalDataType.NPRS,
        readOnlyView: ClinicalDataType.NPRSView,
        questionnaireType: QuestionnaireType.NPRSV1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.OREBRO, {
        clinicalDataType: ClinicalDataType.OREBRO,
        readOnlyView: ClinicalDataType.OREBROView,
        questionnaireType: QuestionnaireType.OREBROV1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.PSFS, {
        clinicalDataType: ClinicalDataType.PSFS,
        readOnlyView: ClinicalDataType.PSFSView,
        questionnaireType: QuestionnaireType.PSFSV1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.GRCS, {
        clinicalDataType: ClinicalDataType.GRCS,
        readOnlyView: ClinicalDataType.GRCSView,
        questionnaireType: QuestionnaireType.GRCSV1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.DASH, {
        clinicalDataType: ClinicalDataType.DASH,
        readOnlyView: ClinicalDataType.DASHView,
        questionnaireType: QuestionnaireType.DASHV1
      });
      this._clinicalDataTypeObservationMap.set(
        ObservationType.CustomClinicalTool,
        {
          clinicalDataType: ClinicalDataType.CustomClinicalTool,
          readOnlyView: ClinicalDataType.CustomClinicalToolView
        }
      );
      this._clinicalDataTypeObservationMap.set(ObservationType.RAND36, {
        clinicalDataType: ClinicalDataType.RAND36,
        readOnlyView: ClinicalDataType.RAND36View,
        questionnaireType: QuestionnaireType.RAND36
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.EPDS, {
        clinicalDataType: ClinicalDataType.EPDS,
        readOnlyView: ClinicalDataType.EPDSView,
        questionnaireType: QuestionnaireType.EPDSV1
      });
      this._clinicalDataTypeObservationMap.set(ObservationType.DASS21, {
        clinicalDataType: ClinicalDataType.DASS21,
        readOnlyView: ClinicalDataType.DASS21View,
        questionnaireType: QuestionnaireType.DASS21V1
      });
    }
    return this._clinicalDataTypeObservationMap;
  }

  observationToClinical(type: ObservationType): ClinicalDataType {
    const item = this.dataMap().get(type);

    if (item) return item.clinicalDataType;

    throw new Error(
      `Can not match observation type ${type} to a clinical data type. Add an item to the ClinicalDataTypeObservation map.`
    );
  }

  observationToQuestionnaire(type: ObservationType): QuestionnaireType {
    const item = this.dataMap().get(type);

    if (item && item.questionnaireType) return item.questionnaireType;

    throw new Error(
      `Can not match observation type ${type} to a questionnaire type. Add an item to the ClinicalDataTypeObservation map.`
    );
  }

  observationToReadOnlyView(type: ObservationType): ClinicalDataType {
    const item = this.dataMap().get(type);

    if (item) return item.readOnlyView;

    throw new Error(
      `Can not match observation type ${type} to a clinical data type. Add an item to the ClinicalDataTypeObservation map.`
    );
  }

  clinicalToReadOnlyView(type: ClinicalDataType): ClinicalDataType {
    let clinicalDataType: ClinicalDataType | undefined;
    this.dataMap().forEach(value => {
      if (value.clinicalDataType === type)
        clinicalDataType = value.readOnlyView;
    });
    if (clinicalDataType) return clinicalDataType;
    else
      throw new Error(
        `Can not match clinical data type ${type}. Add an item to the ClinicalDataTypeObservation map.`
      );
  }

  readonlyOnlyToObservation(type: ClinicalDataType): ObservationType {
    let observationType: ObservationType | undefined;
    this.dataMap().forEach((value, key) => {
      if (value.readOnlyView === type) observationType = key;
    });
    if (observationType) return observationType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a observation type. Add an item to the ClinicalDataTypeObservation map.`
      );
  }

  clinicalToObservation(type: ClinicalDataType): ObservationType {
    let observationType: ObservationType | undefined;
    this.dataMap().forEach((value, key) => {
      if (value.clinicalDataType === type) observationType = key;
    });
    if (observationType) return observationType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a observation type. Add an item to the ClinicalDataTypeObservation map.`
      );
  }

  clinicalToQuestionnaire(type: ClinicalDataType): QuestionnaireType {
    let questionnaireType: QuestionnaireType | undefined;
    this.dataMap().forEach(value => {
      if (value.clinicalDataType === type)
        questionnaireType = value.questionnaireType;
    });
    if (questionnaireType) return questionnaireType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a questionnaire type. Add an item to the ClinicalDataTypeObservation map.`
      );
  }
}
