import { action, computed, observable, runInAction } from "mobx";
import { mergeModel } from "stores/utils/store.utils.ts";

import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import {
  ClinicalNoteFormat,
  PatientTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  OrgUnitHierarchyType,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddUserStorageDto,
  CalendarEventExtensionDto,
  CampaignRenderArgsDto,
  GetRecentPatientsDto,
  OrgUnitSettingDto,
  PatchUserSettingDto,
  PatientDisplayCode,
  PatientSettingDto,
  QuickAccessDto,
  QuickAccessType,
  QuickColoursDto,
  RecentPatientDto,
  TemplateExtensionDto,
  TenantSettingDto,
  UpdatePatientSettingDto,
  UpdateQuickAccessDto,
  UpdateQuickColoursDto,
  UpdateUserSettingDto,
  UserAccountVerifyPinRequestDto,
  UserSettingDto,
  UserStorageDto,
  UserStorageKeys
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { IUserExperienceGateway } from "@libs/gateways/user-experience/UserExperienceGateway.interface.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import {
  capitalizeSentence,
  catchNotFoundError,
  pluralizeAndCapitalizeString,
  pluralizeString
} from "@libs/utils/utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { settings } from "@shared-types/user-experience/localisation-settings.constant.ts";
import { LocalisationSettings } from "@shared-types/user-experience/localisation-settings.type.ts";
import { UserStorage } from "@stores/user-experience/models/UserStorage.ts";
import { UserExperienceRef } from "@stores/user-experience/UserExperienceRef.ts";

import { OrgUnitSetting } from "./models/OrgUnitSetting.ts";
import { PatientSetting } from "./models/PatientSetting.ts";
import { TemplateExtension } from "./models/TemplateExtension.ts";
import { TenantSetting } from "./models/TenantSetting.ts";
import { UserSetting } from "./models/UserSetting.ts";
import { UserExperienceUi } from "./UserExperienceUi.ts";

export class UserExperienceStore {
  constructor(
    private gateway: IUserExperienceGateway,
    public hub: IHubGateway
  ) {
    this.ui = new UserExperienceUi(this);
    this.ref = new UserExperienceRef(this.gateway);
  }

  root: IRootStore;
  ref: UserExperienceRef;
  ui: UserExperienceUi;

  userSettingMap = observable.map<string, UserSetting>();
  userStorageMap = observable.map<string, UserStorage>();
  patientSettingMap = observable.map<string, PatientSetting>();
  orgUnitSettingMap = observable.map<string, OrgUnitSetting>();
  templateExtensionMap = observable.map<string, TemplateExtension>();

  get templateExtensions() {
    return Array.from(this.templateExtensionMap.values());
  }

  @observable
  tenantSetting: TenantSetting | undefined;

  @observable
  quickAccess: QuickAccessDto | undefined;

  @observable
  quickColours: QuickColoursDto | undefined;

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.UserStorage, this.onUserStorageUpdateEvent);
    this.hub.onEntityEvent(
      Entity.TenantSetting,
      this.onTenantSettingUpdateEvent
    );
    this.hub.onEntityEvent(Entity.UserSetting, this.onUserSettingUpdateEvent);
    this.hub.onEntityEvent(
      Entity.OrgUnitSetting,
      this.onOrgUnitSettingUpdateEvent
    );
    this.root.acc.hub.onEntityEvent(
      Entity.QuickAccess,
      this.onUpdateQuickAccess
    );
    this.root.acc.hub.onEntityEvent(Entity.Template, this.onAnyTemplateEvent);
  }

  @action private onUpdateQuickAccess = async (message: EntityEventData) => {
    const isCurrentQuickAccess = this.quickAccess?.quickAccessId === message.id;
    if (
      message.action === EventAction.Update &&
      message.id &&
      isCurrentQuickAccess
    ) {
      await this.getCurrentUserQuickAccess({ ignoreCache: true });
    }
  };

  @action private onAnyTemplateEvent = async (message: EntityEventData) => {
    if (message.id) {
      await this.getAllTemplateExtensions({ ignoreCache: true });
    }
  };

  private get notification() {
    return this.root.notification;
  }

  private onUserStorageUpdateEvent = async (message: EntityEventData) => {
    // retrieve the new/updated Appointment unless the store already has the latest version
    try {
      if (Array.isArray(message.key) && message.key.length === 2) {
        const [userId, key] = message.key;
        if (userId === this.root.core.user!.id) {
          switch (key) {
            case UserStorageKeys.OpenPatientTabs:
              await this.updateOpenedRecords(message);
              break;
          }
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onTenantSettingUpdateEvent = async (message: EntityEventData) => {
    try {
      if (
        message.tenantId === this.root?.core?.tenantDetails?.id &&
        message.etag !== this.tenantSetting?.eTag
      ) {
        await this.loadTenantSetting();
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onOrgUnitSettingUpdateEvent = async (event: EntityEventData) => {
    try {
      if (
        event.action === EventAction.Update &&
        event.etag !== this.orgUnitSettingMap.get(event.id)?.eTag
      ) {
        this.loadLocationBasedOrgUnitSetting();
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onUserSettingUpdateEvent = async (event: EntityEventData) => {
    try {
      if (event.action === EventAction.Update) {
        const userSetting = this.userSettingMap.get(event.id);
        if (userSetting && userSetting.eTag !== event.etag) {
          await this.getUserSetting(event.id);
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private updateOpenedRecords = async (message: EntityEventData) => {
    const [userId, key] = message.key;
    if (
      message.action === EventAction.Update &&
      userId === this.root.core.user!.id
    ) {
      const recordsIdsUserStorage = await this.getUserStorage(key, {
        ignoreCache: true
      });

      const persistedRecordsIds = recordsIdsUserStorage?.jsonData as
        | PatientTab[]
        | undefined;

      const filteredRecordIds = persistedRecordsIds?.filter(
        x =>
          !this.root.clinical.ui.openedRecordIds.some(
            y => y.patientId === x.patientId && y.encounterId === x.encounterId
          )
      );

      let updatedOpenedRecords = Array.from(
        new Set([
          ...this.root.clinical.ui.openedRecordIds,
          ...(filteredRecordIds ?? [])
        ])
      );

      updatedOpenedRecords = this.getUpdatedOpenedRecords(updatedOpenedRecords);

      if (filteredRecordIds) {
        runInAction(() => {
          // merge any patient tab with tabs from user storage
          this.root.clinical.ui.openedRecordIds.replace(updatedOpenedRecords);
        });
      }
    }
  };

  public getUpdatedOpenedRecords = (records: PatientTab[]) => {
    return records.filter(
      x =>
        x.encounterId === undefined ||
        x.encounterId ===
          records.find(
            y => y.patientId === x.patientId && y.encounterId === x.encounterId
          )?.encounterId
    );
  };

  @sharePendingPromise()
  getCalendarEventExtension(
    calendarEventId: string
  ): Promise<CalendarEventExtensionDto | undefined> {
    return this.gateway.getCalendarEventExtension(calendarEventId);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  getCalendarEventExtensions(
    calendarEventIds: string[]
  ): Promise<CalendarEventExtensionDto[]> {
    return this.gateway.getCalendarEventExtensions(calendarEventIds);
  }

  @action
  async getCurrentUserSetting(): Promise<UserSetting | undefined> {
    const loggedInUserId = this.root.core.userId;
    const value = this.userSettingMap.get(loggedInUserId);
    if (value === undefined) {
      try {
        const userSetting = await this.gateway.getCurrentUserSetting();
        if (!userSetting) {
          const result = {
            userId: loggedInUserId
          } as UserSettingDto;
          return this.mergeUserSetting(result);
        } else {
          return this.mergeUserSetting(userSetting);
        }
      } catch (e) {
        return catchNotFoundError(e);
      }
    } else {
      return value;
    }
  }

  @action
  async getUsersSettings(): Promise<UserSetting[]> {
    try {
      const userSettings = await this.gateway.getUsersSettings();
      const userIds = userSettings.map(dto => dto.userId);
      const users = await this.root.core.getUsersByIds(userIds);
      return userSettings.map(userSetting => {
        const userName = users.find(user => user.id === userSetting.userId)
          ?.name;

        return this.mergeUserSetting({ ...userSetting, userName });
      });
    } catch (error) {
      throw Error(error.message);
    }
  }

  @action
  async getPatientsSettings(): Promise<PatientSetting[]> {
    try {
      const patientSettings = await this.gateway.getPatientsSettings();
      return patientSettings.map(patientSetting => {
        return this.mergePatientSetting({ ...patientSetting });
      });
    } catch (error) {
      throw Error(error.message);
    }
  }

  @action
  async getUserSetting(id: string): Promise<UserSetting> {
    const value = this.userSettingMap.get(id);
    if (value === undefined) {
      const userSetting = await this.gateway.getUserSetting(id);
      if (!userSetting) {
        const result = {
          userId: id
        } as UserSettingDto;
        return this.mergeUserSetting(result);
      } else {
        return this.mergeUserSetting(userSetting);
      }
    } else {
      return value;
    }
  }

  @action
  async getCurrentUserQuickAccess(
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<QuickAccessDto | undefined> {
    if (!options.ignoreCache) {
      if (this.quickAccess) {
        return this.quickAccess;
      }
    }

    const quickAccess = await this.gateway.getCurrentUserQuickAccess();
    runInAction(() => {
      this.quickAccess = quickAccess;
    });

    return quickAccess;
  }

  @action
  async updateQuickAccess(
    value: UpdateQuickAccessDto
  ): Promise<QuickAccessDto | undefined> {
    const quickAccess = await this.gateway.updateCurrentUserQuickAccess({
      ...value,
      eTag: this.quickAccess?.eTag
    });
    runInAction(() => {
      this.quickAccess = quickAccess;
    });

    return quickAccess;
  }

  @action
  async getCurrentUserQuickColours(
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<QuickColoursDto | undefined> {
    if (!options.ignoreCache) {
      if (this.quickColours) {
        return this.quickColours;
      }
    }

    const quickColours = await this.gateway.getCurrentUserQuickColours();
    runInAction(() => {
      this.quickColours = quickColours;
    });

    return quickColours;
  }

  @action
  async updateQuickColours(
    value: UpdateQuickColoursDto
  ): Promise<QuickColoursDto | undefined> {
    const quickColours = await this.gateway.updateCurrentUserQuickColours({
      ...value,
      eTag: this.quickColours?.eTag
    });
    runInAction(() => {
      this.quickColours = quickColours;
    });

    return quickColours;
  }
  @action
  async getPatientSetting(
    patientId: string
  ): Promise<PatientSetting | undefined> {
    const value = this.patientSettingMap.get(patientId);
    if (value === undefined) {
      const patientSetting = await this.gateway.getPatientSetting(patientId);
      if (!patientSetting) {
        return value;
      } else {
        return this.mergePatientSetting(patientSetting);
      }
    } else {
      return value;
    }
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getUserSettingsByUserIds(userIds: string[]): Promise<UserSetting[]> {
    const idsToFetch = userIds.filter(id => !this.userSettingMap.has(id));

    const fetchedUserSettings =
      await this.gateway.getUserSettingsByUserIds(idsToFetch);

    fetchedUserSettings.forEach(this.mergeUserSetting);

    return userIds.reduce((arr: UserSetting[], id) => {
      const userSetting = this.userSettingMap.get(id);
      if (userSetting) {
        arr.push(userSetting);
      }
      return arr;
    }, []);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getRecentPatients(
    args: Omit<GetRecentPatientsDto, "userId">
  ): Promise<QueryResult<RecentPatientDto>> {
    return await this.gateway.getRecentPatients({
      ...args,
      userId: this.root.core.userId
    });
  }

  async upsertUserSetting(data: UpdateUserSettingDto) {
    const userSetting = this.userSettingMap.get(data.userId);
    if (userSetting) {
      return await this.gateway
        .patchUserSetting({ ...data, eTag: userSetting.eTag })
        .then(this.mergeUserSetting);
    } else {
      return await this.gateway
        .addUserSetting(data, data.userId)
        .then(this.mergeUserSetting);
    }
  }

  async upsertPatientSetting(data: UpdatePatientSettingDto) {
    const patientSetting = this.patientSettingMap.get(data.patientId);
    if (patientSetting) {
      return await this.gateway
        .patchPatientSetting({ ...data, eTag: patientSetting?.eTag })
        .then(this.mergePatientSetting);
    } else {
      return await this.gateway
        .updatePatientSetting(data, data.patientId)
        .then(this.mergePatientSetting);
    }
  }

  async upsertCurrentUserSetting(data: UpdateUserSettingDto) {
    const userSetting = this.userSettingMap.get(data.userId);
    if (userSetting) {
      return await this.gateway
        .patchCurrentUserSetting({ ...data, eTag: userSetting.eTag })
        .then(this.mergeUserSetting);
    } else {
      return await this.gateway
        .addCurrentUserSetting(data)
        .then(this.mergeUserSetting);
    }
  }

  @action
  private mergeUserSetting = (dto: UserSettingDto & { userName?: string }) => {
    return mergeModel({
      dto: { ...dto, id: dto.userId },
      getNewModel: () => new UserSetting(this.root, { ...dto, id: dto.userId }),
      map: this.userSettingMap
    });
  };

  @action
  private mergePatientSetting = (dto: PatientSettingDto) => {
    return mergeModel({
      dto: { ...dto, id: dto.patientId },
      getNewModel: () =>
        new PatientSetting(this.root, { ...dto, id: dto.patientId }),
      map: this.patientSettingMap
    });
  };

  @action
  private mergeOrgUnitSetting = (dto: OrgUnitSettingDto) => {
    return mergeModel({
      dto: { ...dto, id: dto.orgUnitId },
      getNewModel: () => new OrgUnitSetting({ ...dto, id: dto.orgUnitId }),
      map: this.orgUnitSettingMap
    });
  };

  @action
  private mergeTemplateExtension = (dto: TemplateExtensionDto) => {
    const mergeDto = { ...dto, id: dto.templateId };
    return mergeModel({
      dto: mergeDto,
      getNewModel: () => new TemplateExtension(mergeDto),
      map: this.templateExtensionMap
    });
  };

  @computed
  get settings() {
    const clinicalView = this.userSettingMap?.get(this.root.core.userId)
      ?.clinicalView;

    const patientSummaryPageDtos = clinicalView?.patientSummaryPage;

    const onlyShowMyNotesValue = clinicalView?.onlyShowMyNotes ?? false;

    const pastVisitsReasonAtBottomValue =
      clinicalView?.pastVisitsReasonAtBottom ?? false;

    const notesFormat =
      clinicalView?.clinicalNotesFormat ?? ClinicalNoteFormat.Default;

    return {
      patientSummaryPages: patientSummaryPageDtos,
      onlyShowMyNotes: onlyShowMyNotesValue,
      pastVisitsReasonAtBottom: pastVisitsReasonAtBottomValue,
      ClinicalNotesFormat: notesFormat
    };
  }

  async getCampaignRender(id: string, request: CampaignRenderArgsDto) {
    return await this.gateway.getCampaignRender(id, request).then(x => x);
  }

  @computed
  get currentUserOrPracticePatientLabel() {
    return (
      this.userSettingMap?.get(this.root.core.userId)?.patientLabel ||
      this.tenantPatientLabel
    );
  }

  @computed
  get tenantPatientLabel() {
    return this.tenantSetting?.patientLabel ?? PatientDisplayCode.Patient;
  }

  @computed
  get clinicalTaskSettings() {
    return (
      this.userSettingMap?.get(this.root.core.userId)?.clinicalTaskSettings ||
      this.tenantClinicalTaskSettings
    );
  }

  @computed
  get tenantClinicalTaskSettings() {
    return this.tenantSetting?.clinicalTaskSettings;
  }

  @computed
  get tenantSecuritySettings() {
    return this.tenantSetting?.securitySettings;
  }

  @computed
  get tenantCommsDefaultCampaignSettings() {
    return this.tenantSetting?.commsDefaultCampaign;
  }

  async getUserStorage(
    key: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<UserStorage | undefined> {
    if (!options.ignoreCache) {
      const value = this.userStorageMap.get(key);
      if (value) {
        return value;
      }
    }

    const resultDto = await this.gateway.getUserStorage(key);
    if (resultDto === undefined) {
      return undefined;
    }

    const result = new UserStorage(resultDto);
    runInAction(() => {
      this.userStorageMap.set(key, result);
    });
    return result;
  }

  async updateUserStorage(
    key: string,
    value: UserStorageDto
  ): Promise<UserStorage> {
    const resultDto = await this.gateway.updateUserStorage(key, value);
    const result = new UserStorage(resultDto);
    runInAction(() => {
      this.userStorageMap.set(key, result);
    });
    return result;
  }

  async addUserStorage(
    key: string,
    value: AddUserStorageDto
  ): Promise<UserStorage> {
    const resultDto = await this.gateway.addUserStorage(key, value);
    const result = new UserStorage(resultDto);
    runInAction(() => {
      this.userStorageMap.set(key, result);
    });
    return result;
  }

  localisedConfig = (
    label: keyof LocalisationSettings,
    options?: { capitalizeFirst?: boolean; plural?: boolean }
  ): string => {
    if (options?.capitalizeFirst && options?.plural) {
      return pluralizeAndCapitalizeString(
        settings[this.currentUserOrPracticePatientLabel][label]
      );
    } else if (options?.capitalizeFirst) {
      return capitalizeSentence(
        settings[this.currentUserOrPracticePatientLabel][label]
      );
    } else if (options?.plural) {
      return pluralizeString(
        settings[this.currentUserOrPracticePatientLabel][label]
      );
    }
    return settings[this.currentUserOrPracticePatientLabel][label];
  };

  @action async loadLocationBasedOrgUnitSetting() {
    const { locationId, hasMultipleActiveLocations, orgUnitsMap } =
      this.root.core;
    if (hasMultipleActiveLocations) {
      const locations = Array.from(orgUnitsMap.values()).filter(
        l => l.hierarchyType === OrgUnitHierarchyType.Location
      );
      await Promise.all(
        locations.map(location => this.getOrgUnitSetting(location.id))
      );
    } else {
      await this.getOrgUnitSetting(locationId);
    }
  }

  @action
  async loadTenantSetting(): Promise<TenantSetting | undefined> {
    try {
      const tenantSettingDto = await this.gateway.getTenantSetting();
      const dtoWithId = {
        ...tenantSettingDto,
        id: this.root.core.tenantDetails!.id
      };
      runInAction(() => {
        this.tenantSetting = new TenantSetting(dtoWithId);
      });
      return this.tenantSetting;
    } catch (error) {
      return catchNotFoundError(error);
    }
  }

  @action
  async updateTenantSetting(request: TenantSettingDto) {
    try {
      const response = await this.gateway.updateTenantSetting({
        ...request
      });

      const updatedDto = { ...response, id: this.root.core.tenantDetails!.id };
      runInAction(() => {
        this.tenantSetting = new TenantSetting(updatedDto);
      });
    } catch (error) {
      throw error;
    }
  }

  @action
  async patchUserSetting(request: PatchUserSettingDto) {
    try {
      return patchModel<
        UserSetting,
        UserSettingDto,
        PatchUserSettingDto & { id: string }
      >(
        { ...request, id: request.userId },
        ({ id, ...rest }) =>
          this.gateway.patchUserSetting({ ...rest, userId: id }),
        {
          modelMap: this.userSettingMap
        }
      );
    } catch (error) {
      throw Error(error.message);
    }
  }

  async getOrgUnitSetting(
    orgUnitId: string
  ): Promise<OrgUnitSetting | undefined> {
    return this.gateway
      .getOrgUnitSetting(orgUnitId)
      .then(this.mergeOrgUnitSetting)
      .catch(catchNotFoundError);
  }

  async updateOrgUnitSetting(request: OrgUnitSettingDto) {
    return this.gateway
      .updateOrgUnitSetting(request)
      .then(this.mergeOrgUnitSetting);
  }

  async getTemplateExtension(
    templateId: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<TemplateExtensionDto | undefined> {
    if (!options.ignoreCache) {
      const mapAutofill = this.templateExtensionMap.get(templateId);
      if (mapAutofill) {
        return mapAutofill;
      }
    }
    return await this.gateway
      .getTemplateExtension(templateId)
      .then(this.mergeTemplateExtension)
      .catch(catchNotFoundError);
  }

  async getEncounterExtension(encounterId: string) {
    return this.gateway
      .getEncounterExtension(encounterId)
      .catch(catchNotFoundError);
  }

  verifyPin(data: UserAccountVerifyPinRequestDto) {
    return this.gateway.postVerifyPinRequest(data);
  }

  async checkEncounter(
    key: UserStorageKeys,
    patientId: string
  ): Promise<boolean> {
    return await this.gateway.checkEncounter(key, patientId);
  }

  async getAllTemplateExtensions(
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<TemplateExtensionDto[]> {
    if (!options.ignoreCache) {
      if (this.templateExtensionMap.size) {
        return this.templateExtensions;
      }

      const dtos = await this.gateway.getAllTemplateExtension();
      return dtos.map(this.mergeTemplateExtension);
    }

    const dtos = await this.gateway.getAllTemplateExtension();
    return dtos.map(this.mergeTemplateExtension);
  }

  @computed
  get orgUnitSettingForLocation() {
    return this.orgUnitSettingMap.get(this.root.core.locationId);
  }

  @computed
  get quickAccessSettings() {
    const exclusions: string[] = [];

    if (!this.root.core.hasPermissions(Permission.RxPrescribeAllowed)) {
      exclusions.push(QuickAccessType.PrescribingRx);
    }

    if (!this.root.core.hasPermissions(Permission.ClinicalFormsAllowed)) {
      exclusions.push(QuickAccessType.OnlineForm);
    }

    return this.quickAccess?.quickAccessSettings.filter(
      q => !exclusions.includes(q.code)
    );
  }

  getQuickAccessIcon = (code: string) =>
    this.root.userExperience.ref.quickAccessIcon.get(code);

  @computed
  get quickLinksOnBottom() {
    const position = this.userStorageMap.get(
      UserStorageKeys.QuickLinksOnBottom
    );

    if (position) {
      return position.jsonData as boolean;
    }

    return true;
  }

  @computed
  get reverseObservationMatrixDates() {
    const reverse = this.userStorageMap.get(
      UserStorageKeys.ReverseObservationMatrixDates
    );

    if (reverse) {
      return reverse.jsonData as boolean;
    }

    return true;
  }
}
