import { observer } from "mobx-react-lite";
import { FC, useRef } from "react";

import { Heading } from "@bps/fluent-ui";
import { AddAppointmentTypeDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { AppointmentTypeFormValues } from "./AppointmentTypeForm.types.ts";
import { AppointmentTypeFormFields } from "./AppointmentTypeFormFields.tsx";
import { AppointmentTypeFormValidator } from "./AppointmentTypeFormValidator.ts";
import { ExtraActionsBetween } from "./OnlineSettings/ExtraActionsBetween.tsx";

type AppointmentTypeDialogProps = {
  closeModal: () => void;
  appointmentType?: AppointmentType;
};

export enum SubmitButtonType {
  SaveAndSetupOnline = "SaveAndSetupOnline",
  SetupOnline = "SetupOnline"
}
export const AppointmentTypeDialog: FC<AppointmentTypeDialogProps> = observer(
  ({ closeModal, appointmentType }) => {
    const { core, booking, bhb, userExperience } = useStores();
    const { SetupOnline, SaveAndSetupOnline } = SubmitButtonType;
    const readonly = !core.hasPermissions(Permission.AppointmentTypeWrite);
    const groupAppsAllowed = core.hasPermissions(
      Permission.GroupAppointmentsAllowed
    );

    const stepValidated = core.hasPermissions(
      Permission.ApptTypeBaseIntervalAllowed
    );

    const { apptTypeBaseInterval } =
      userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

    const step = parseInt(apptTypeBaseInterval || "15");

    const validator = new AppointmentTypeFormValidator(
      groupAppsAllowed,
      stepValidated,
      step
    );

    const omniAppointmentType = useRef<AppointmentType | undefined>(undefined);
    const submitButtonType = useRef<SubmitButtonType>(SetupOnline);

    const {
      setOmniAppointmentType,
      setShowOnlineSettingModal,
      setBHBAppointmentType
    } = useAppointmentTypeScreenContext();

    const openOnlineSettingsModal = async () => {
      const apptTypeId = omniAppointmentType.current?.id;
      if (apptTypeId) {
        const bhbAppointmentType = await bhb
          .getAppointmentTypeForLocation(apptTypeId)
          .catch(() => undefined);
        setBHBAppointmentType(bhbAppointmentType);
        setOmniAppointmentType(omniAppointmentType.current);
        setShowOnlineSettingModal(true);
      }
      omniAppointmentType.current = undefined;
    };

    const initialValues = appointmentType
      ? {
          duration: appointmentType.duration,
          isInactive: appointmentType.isInactive,
          name: appointmentType.name,
          icon: appointmentType.icon,
          hasDefaultServices: !!appointmentType.defaultServiceIds?.length,
          defaultServiceIds: appointmentType.defaultServiceIds,
          maxParticipants: groupAppsAllowed
            ? appointmentType.maxParticipants
            : undefined
        }
      : {
          duration: 15,
          isInactive: false,
          hasDefaultServices: false,
          defaultServiceIds: [],
          maxParticipants: groupAppsAllowed ? 1 : undefined
        };

    const onSubmit = async (values: AppointmentTypeFormValues) => {
      const data: AddAppointmentTypeDto = {
        isInactive: values.isInactive,
        length: values.duration,
        text: values.name!,
        icon: values.icon,
        defaultServiceIds: (values.defaultServiceIds || []).filter(
          x => x
        ) as string[],
        isSystemManaged: appointmentType?.isSystemManaged ?? false,
        maxParticipants: groupAppsAllowed ? values.maxParticipants : undefined
      };

      if (appointmentType) {
        omniAppointmentType.current = await booking.updateAppointmentType({
          ...appointmentType.dto,
          ...data
        });
      } else {
        omniAppointmentType.current = await booking.addAppointmentType(data);
      }
    };

    const onSetupOnlineClick = async () => {
      omniAppointmentType.current =
        appointmentType &&
        (await booking.getAppointmentType(appointmentType?.id));

      await openOnlineSettingsModal();
      closeModal();
    };
    let dialogType = "View";
    if (!readonly) {
      dialogType = appointmentType ? "Edit" : "New";
    }
    return (
      <SubmissionFormDialog
        formId="AppointmentType"
        dialogName={`${dialogType} appointment type dialog`}
        dialogProps={{
          minWidth: 560,
          onDismiss: closeModal,
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">
                {`${dialogType} appointment type`}
              </Heading>
            ),
            showCloseButton: true,
            className: "appointment-type-dialog"
          },
          modalProps: { titleAriaId: "appointment-type-dialog-id" }
        }}
        onSubmit={onSubmit}
        readOnly={readonly}
        onSubmitSucceeded={async () => {
          if (submitButtonType.current === SaveAndSetupOnline) {
            await openOnlineSettingsModal();
          }
          closeModal();
        }}
        initialValues={initialValues}
        validate={validator.validate}
        buttonsProps={{
          submitButtonProps: {
            text: "Save"
          },
          cancelButtonProps: {
            id: "close-appt-form-type-dialog"
          },
          hideButtonsSeparator: true,
          extraActionsBetween: () =>
            core.hasPermissions(Permission.BhbWrite) && (
              <ExtraActionsBetween
                onClickSubmit={() => {
                  submitButtonType.current = SaveAndSetupOnline;
                }}
                onOpenSetupOnlineModal={onSetupOnlineClick}
              />
            )
        }}
        styles={{ fields: { overflowY: "hidden" } }}
        hideButtons={readonly}
      >
        {() => (
          <AppointmentTypeFormFields
            isSystemManaged={appointmentType?.isSystemManaged}
          />
        )}
      </SubmissionFormDialog>
    );
  }
);
