import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";

export enum ConditionModalFormLabels {
  conditionType = "Condition type",
  referral = "Referred in",
  insurer = "Insurer",
  accPlaceholder = "e.g. ACC",
  claim = "Claim",
  reference = "Reference",
  claimStatus = "Status",
  sendAccForm = "Send ACC45 for patient to fill out",
  sendAccFormHeading = "ACC45",
  referralProvider = "Provider",
  referralDate = "Date",
  claimTransitionDisabledMessage = "claims cannot be made private",
  claimHasInvoicesMessage = "Cannot change this claim to private, as ACC has invoices associated to it. Cancel the invoices, then change to private",
  changeToPrivateCannotBeReversed = "Changing to a private condition cannot be reversed",
  cannotBeChangedBackToClaim = "This condition has been changed to private, and cannot be set back to a claim"
}

export type ConditionModalFormValues = {
  private?: boolean;
  insurerContactId?: string;
  insurerName?: string;
  referralIn?: boolean;
  referralNumber?: string;
  sendAccForm?: string;
  referralProvider?: string;
  referralDate?: Date;
  claimStatusCode?: ClaimStatuses;
  claimId?: string;
};

export const conditionModalFormNameOf =
  nameOfFactory<ConditionModalFormValues>();

export enum ConditionDetailLabels {
  Done = "Done",
  New = "New condition",
  CurrentConditions = "Current conditions",
  CreateNewCondition = "Create a new condition",
  NoConditionsToDisplay = "No conditions to display"
}

export enum EpisodeOfCareBadgesLabels {
  Private = "Private",
  Referred = "Referred",
  OnHold = "On hold"
}

export enum ConditionPivotHeader {
  InProgress = "In progress",
  Discharged = "Discharged",
  OnHold = "On hold"
}

export enum ClinicalDataTypes {
  Injury = "INJ"
}

export interface ConditionListItem extends ICondition {
  key: string;
}
