import { DateTime } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { ScheduleFields } from "./ScheduleFields.types.ts";
import { nameOfUserWorkingHourFormValues } from "./UserWorkingHoursFormFieldList.tsx";

export const useHandleAdd = () => {
  const { core } = useStores();
  const { fields } = useFieldArray(
    nameOfUserWorkingHourFormValues("userWorkingHours")
  );

  const getDefaultLocation = () => {
    return core.hasMultipleActiveLocations ? "" : core.locationId;
  };

  return () => {
    const scheduleField: ScheduleFields = {
      location: getDefaultLocation(),
      startTime: DateTime.today().plus({ hours: 8 }).toTimeInputFormat(),
      endTime: DateTime.today().plus({ hours: 17 }).toTimeInputFormat(),
      dayRecur: [],
      startDate: DateTime.today().toJSDate(),
      atWork: "1",
      count: 1,
      endScheduleType: EndScheduleType.Never,
      frequency: Frequency.Week,
      interval: 1,
      reasonForNotWorking: undefined,
      monthRecur: undefined,
      until: undefined
    };

    fields.push(scheduleField);
  };
};
